const userActivitiesTypes = {
     FETCH_USER_ACTIVITIES_LIST: 'FETCH_USER_ACTIVITIES_LIST',
     SET_USER_ACTIVITIES_LIST: 'SET_USER_ACTIVITIES_LIST',

     FETCH_ALL_USERS_USER_ACTIVITY_LIST: 'FETCH_ALL_USERS_USER_ACTIVITY_LIST',
     SET_ALL_USERS_USER_ACTIVITY_LIST: 'SET_ALL_USERS_USER_ACTIVITY_LIST',
}

export default userActivitiesTypes

export interface userActivityData {
     _id: string,
     user_name: string,
     user_email: string,
     campaign_name: string,
     module_name: string,
     configuration_name: string,
     configuration_old_value: string,
     configuration_action: string,
     configuration_new_value: string,
     datetime: string,

}

export interface AllUsersUserActivity {
     email: string;
     full_name: string;
     status: boolean | string;
     _id: string | number;
}

export interface UserActivitiesFilterState {
     searchText: string;
     module: string | number | undefined;
     email: string | number | undefined;
     campaign: string | number | undefined;
}

export interface UserActivitiesState {
     userActivityListData: userActivityData[];
     userActivityListDataCount:number;
     userActivityListDataLoader: boolean;
     allUsersUserActivityListData: AllUsersUserActivity[];
     allUsersUserActivityListDataLoader: boolean;
}
