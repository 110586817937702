import { call, put, takeLatest } from 'redux-saga/effects';
import reportTypes from './type';
import Apis from '../../../../../api';
import OverAllReportAction from './actions';
import overallReportUtils from '../utils';
import { CommonAction } from '../../../../auth/login/redux/types';

const fetchReportTopCards = function* fetchReportTopCards(action: CommonAction) {
     try {
          const { payload, id } = action.payload;
          const { data } = yield call(Apis.fetchOverAllReportTopCardsApi, payload, id);
          yield put(OverAllReportAction.setOverAllReportTopCards(data?.data));
     } catch (err) {
          yield put(OverAllReportAction.setOverAllReportTopCards(undefined));
     }
};

const fetchReportBudgetSpentGraphData = function* fetchReportBudgetSpentGraphData(action: CommonAction) {
     try {
          const { payload, id } = action.payload;
          const { data } = yield call(Apis.fetchReportsBudgetSpentGraphApi, payload, id);
          const formattedData = overallReportUtils.handleBudgetSpendData(data?.data);
          const isHourInterval = data?.data?.hour_interval ? data?.data?.hour_interval : false;
          yield put(OverAllReportAction.setBudgetSpentReportsData({ data: formattedData, totalBudget: data?.data?.total_budget_spent, isHourBudgetInterval: isHourInterval }));
     } catch (error) {
          yield put(OverAllReportAction.setBudgetSpentReportsData([]));
     }
}

const fetchReportCTRGraphData = function* fetchReportCTRGraphData(action: CommonAction) {
     try {
          const { payload, id } = action.payload;
          const { data } = yield call(Apis.fetchReportCTRGraphApi, payload, id);
          const formattedData = overallReportUtils.handleCtrData(data?.data?.CTR);
          yield put(OverAllReportAction.setCTRReportsData({ data: formattedData, totalCtr: data?.data?.total_CTR }));
     } catch (error) {
          yield put(OverAllReportAction.setCTRReportsData([]));
     }
}

const fetchReportImpClicksAcqGraphData = function* fetchReportImpClicksAcqGraphData(action: CommonAction) {
     try {
          const { payload, id } = action.payload;
          const { data } = yield call(Apis.fetchReportImpClickGraphApi, payload, id);
          yield put(OverAllReportAction.setImpClicksAcqReportsData({ imp: data?.data?.withImpressions, impClick: data?.data?.withClicks || [], impAcq: data?.data?.withAcqu || [] }));
     } catch (error) {
          yield put(OverAllReportAction.setImpClicksAcqReportsData({ impClick: [], impAcq: [] }));
     }
}

const fetchReportTopCountriesGraphData = function* fetchReportTopCountriesGraphData(action: CommonAction) {
     try {
          const { payload, id } = action.payload;
          const { data } = yield call(Apis.fetchReportsTopDataGraphApi, payload, id);
          const { implist, clickslist } = overallReportUtils.handleTopData(data?.data?.countries);
          yield put(OverAllReportAction.setTopCountriesReportsData({ imps: implist, clicks: clickslist }));
     } catch (error) {
          yield put(OverAllReportAction.setTopCountriesReportsData({ impClick: [], impAcq: [] }));
     }
}

const fetchReportTopDevicesGraphData = function* fetchReportTopDevicesGraphData(action: CommonAction) {
     try {
          const { payload, id } = action.payload;
          const { data } = yield call(Apis.fetchReportsTopDataGraphApi, payload, id);
          const { implist, clickslist } = overallReportUtils.handleTopData(data?.data?.devices);
          yield put(OverAllReportAction.setTopDevicesReportsData({ imps: implist, clicks: clickslist }));
     } catch (error) {
          yield put(OverAllReportAction.setTopDevicesReportsData({ impClick: [], impAcq: [] }));
     }
}

const fetchReportTopMediaGraphData = function* fetchReportTopMediaGraphData(action: CommonAction) {
     try {
          const { payload, id } = action.payload;
          const { data } = yield call(Apis.fetchReportsTopDataGraphApi, payload, id);
          const { implist, clickslist } = overallReportUtils.handleTopData(data?.data?.sizes);
          yield put(OverAllReportAction.setTopMediaSizesReportsData({ imps: implist, clicks: clickslist }));
     } catch (error) {
          yield put(OverAllReportAction.setTopMediaSizesReportsData({ impClick: [], impAcq: [] }));
     }
}

const fetchReportTopSitesGraphData = function* fetchReportTopSitesGraphData(action: CommonAction) {
     try {
          const { payload, id } = action.payload;
          const { data } = yield call(Apis.fetchReportsTopDataGraphApi, payload, id);
          const { implist, clickslist } = overallReportUtils.handleTopData(data?.data?.sites);
          yield put(OverAllReportAction.setTopSitesReportsData({ imps: implist, clicks: clickslist }));
     } catch (error) {
          yield put(OverAllReportAction.setTopSitesReportsData({ impClick: [], impAcq: [] }));
     }
}

const fetchReportsCampeignsData = function* fetchReportsCampeignsData(action: CommonAction) {
     try {
          const { pageNo, pageSize, payload } = action.payload;
          const { data } = yield call(Apis.fetchReportsCampeignsApi, payload, pageNo, pageSize);
          const response = {
               data: data?.data?.data,
               totalRecords: data?.data?.total
          }
          yield put(OverAllReportAction.setReportCampaigns(response || { data: [], totalRecords: 0 }));
     } catch (error) {
          yield put(OverAllReportAction.setReportCampaigns({ data: [], totalRecords: 0 }));
     }
}

const fetchReportsSitesData = function* fetchReportsSitesData(action: CommonAction) {
     try {
          const { pageNo, pageSize, payload } = action.payload;
          const { data } = yield call(Apis.fetchReportsSitesApi, payload, pageNo, pageSize);
          const response = {
               data: data?.data?.data,
               totalRecords: data?.data?.total
          }
          yield put(OverAllReportAction.setReportSites(response || { data: [], totalRecords: 0 }));
     } catch (error) {
          yield put(OverAllReportAction.setReportSites({ data: [], totalRecords: 0 }));
     }
}

const fetchCampsReportTopCountriesTable = function* fetchCampsReportTopCountriesTable(action: CommonAction) {
     try {
          const { payload, id, pageSize, pageNo } = action.payload;
          const { data } = yield call(Apis.fetchCampsReportTopPerformanceUnitTableApi, payload, id, pageSize, pageNo);
          yield put(OverAllReportAction.setCampReportTopCountriesTable({ data: data?.data?.data, count: data?.data?.total }));
     } catch (error) {
          yield put(OverAllReportAction.setCampReportTopCountriesTable({}));
     }
}

const fetchCampsReportTopDevicesTable = function* fetchCampsReportTopDevicesTable(action: CommonAction) {
     try {
          const { payload, id, pageSize, pageNo } = action.payload;
          const { data } = yield call(Apis.fetchCampsReportTopPerformanceUnitTableApi, payload, id, pageSize, pageNo);
          yield put(OverAllReportAction.setCampReportTopDevciesTable({ data: data?.data?.data, count: data?.data?.total }));
     } catch (error) {
          yield put(OverAllReportAction.setCampReportTopDevciesTable({}));
     }
}
const fetchCampsReportTopMediaTable = function* fetchCampsReportTopMediaTable(action: CommonAction) {
     try {
          const { payload, id, pageSize, pageNo } = action.payload;
          const { data } = yield call(Apis.fetchCampsReportTopPerformanceUnitTableApi, payload, id, pageSize, pageNo);
          yield put(OverAllReportAction.setCampReportTopMediaTable({ data: data?.data?.data, count: data?.data?.total }));
     } catch (error) {
          yield put(OverAllReportAction.setCampReportTopMediaTable({}));
     }
}
const fetchCampsReportTopSitesTable = function* fetchCampsReportTopSitesTable(action: CommonAction) {
     try {
          const { payload, id, pageSize, pageNo } = action.payload;
          const { data } = yield call(Apis.fetchCampsReportTopPerformanceUnitTableApi, payload, id, pageSize, pageNo);
          yield put(OverAllReportAction.setCampReportTopSitesTable({ data: data?.data?.data, count: data?.data?.total }));
     } catch (error) {
          yield put(OverAllReportAction.setCampReportTopSitesTable({}));
     }
}

const fetchCampsReportTopCreativeTable = function* fetchCampsReportTopCreativeTable(action: CommonAction) {
     try {
          const { payload, id, pageSize, pageNo } = action.payload;
          const { data } = yield call(Apis.fetchCampsReportTopPerformanceUnitTableApi, payload, id, pageSize, pageNo);
          yield put(OverAllReportAction.setCampReportTopCreativeTable({ data: data?.data?.data, count: data?.data?.total }));
     } catch (error) {
          yield put(OverAllReportAction.setCampReportTopCreativeTable({}));
     }
}

const fetchRealTimeReportImpClicksAcqGraphData = function* fetchRealTimeReportImpClicksAcqGraphData(action: CommonAction) {
     try {
          const { payload, id } = action.payload;
          const { data } = yield call(Apis.fetchRealTimeReportImpClickGraphApi, payload, id);
          yield put(OverAllReportAction.setRealTimeImpClicksAcqReportsData({ impClick: data?.data?.withClicks || [], impAcq: data?.data?.withAcqu || [], from: data?.data?.from, to: data?.data?.to }));
     } catch (error) {
          yield put(OverAllReportAction.setRealTimeImpClicksAcqReportsData({ impClick: [], impAcq: [] }));
     }
}

export default function* sagas() {
     yield takeLatest(reportTypes.FETCH_REPORT_TOPCARDS, fetchReportTopCards);
     yield takeLatest(reportTypes.FETCH_REPORTS_BUGDET_SPEND_GRAPH, fetchReportBudgetSpentGraphData);
     yield takeLatest(reportTypes.FETCH_REPORTS_CTR_GRAPH, fetchReportCTRGraphData);
     yield takeLatest(reportTypes.FETCH_REPORTS_IMP_CLICKS_GRAPH, fetchReportImpClicksAcqGraphData);
     yield takeLatest(reportTypes.FETCH_REPORTS_TOP_COUNTRIES_DATA_GRAPH, fetchReportTopCountriesGraphData);
     yield takeLatest(reportTypes.FETCH_REPORTS_TOP_DEVICES_DATA_GRAPH, fetchReportTopDevicesGraphData);
     yield takeLatest(reportTypes.FETCH_REPORTS_TOP_MEDIASIZES_DATA_GRAPH, fetchReportTopMediaGraphData);
     yield takeLatest(reportTypes.FETCH_REPORTS_TOP_SITES_DATA_GRAPH, fetchReportTopSitesGraphData);
     yield takeLatest(reportTypes.FETCH_REPORTS_CAMPAIGN_LIST, fetchReportsCampeignsData);
     yield takeLatest(reportTypes.FETCH_REPORTS_SITE_LIST, fetchReportsSitesData);
     yield takeLatest(reportTypes.FETCH_CAMP_REPORTS_COUNTRIES_TABLE, fetchCampsReportTopCountriesTable);
     yield takeLatest(reportTypes.FETCH_CAMP_REPORTS_DEVICES_TABLE, fetchCampsReportTopDevicesTable);
     yield takeLatest(reportTypes.FETCH_CAMP_REPORTS_MEDIA_TABLE, fetchCampsReportTopMediaTable);
     yield takeLatest(reportTypes.FETCH_CAMP_REPORTS_SITES_TABLE, fetchCampsReportTopSitesTable);
     yield takeLatest(reportTypes.FETCH_CAMP_REPORTS_CREATIVE_TABLE, fetchCampsReportTopCreativeTable);
     yield takeLatest(reportTypes.FETCH_REAL_TIME_REPORTS_IMP_CLICK_GRAPH, fetchRealTimeReportImpClicksAcqGraphData);
}
