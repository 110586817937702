import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import PButton from '../../../../common/Button';

type Props = {
    onClick?: () => void;
    onSaveButton?: () => void;
    loading: boolean;
    campaign_id: string | undefined;
    unsaveChanges:boolean;
};

const PNewCampaignHeader: React.FC<Props> = ({ onClick, onSaveButton, loading, campaign_id,unsaveChanges }) => {
    return (
        <div className="flex items-center  ant-layout-header fixed w-full z-10 pl-6 pr-6 bg-white h-20 flex justify-between items-center css-dev-only-do-not-override-qgg3xn">
            <div>
                <p className="flex items-center font-[Roboto-Medium] text-black font-medium text-2xl">
                    <BiArrowBack size={'1.75rem'} className="cursor-pointer mr-3.5" onClick={onClick} />
                    {campaign_id ? 'Edit Campaign' : 'Create New Campaign'}
                </p>
                <p className="font-[Roboto-Medium] md:text-[#056433] font-medium text-sm mt-1.5 ml-11"> Prebid Adapter - Ad Campaigns </p>
            </div>
            <div className="flex items-center">
                {/* <PButton title="Cancel" className="md:rounded-full h-10 py-1.5 px-6 md:border-[#056433] mr-4" light={true} /> */}
                {unsaveChanges && <span className="common_error mr-5">Unsaved changes , please update to save the changes.</span>}
                <PButton onClick={onSaveButton} title={campaign_id ? 'Update' : 'Save'} className="md:rounded-full border font-bold h-10 py-1.5 px-6 md:bg-[#b4d0c1] mr-4" light={true} loading={loading} />
                {/* <PButton title="Next" className="md:rounded-full py-1.5 px-6" /> */}
            </div>
        </div>
    );
};

export default PNewCampaignHeader;
