import download from 'downloadjs';
import React, { useEffect, useMemo, useState } from 'react';
import { GoDotFill } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import Apis from '../../../../../api';
import { RootState } from '../../../../../store/RootReducer';
import PButton from '../../../../common/Button';
import CustomTabSwitch from '../../../../common/CustomTabSwitch';
import MessageActions from '../../../../message/redux/actions';
import ImpClicksBiaxialDashedGraph from '../graphs&charts/ImpClicksBiaxialDashedGraph';
import OverAllReportAction from '../redux/actions';
type Props = {
    startDate: any;
    endDate: any;
    campId: any;
    applyDate: string;
    hourSelected: string;
};
const ImpressionClickGraph: React.FC<Props> = ({ startDate, endDate, campId, applyDate, hourSelected }) => {
    const [acqClickSwitch, setAcqClickSwitch] = useState<string>('Clicks');
    const switchTabs = ['Clicks', 'Acquisitions'];
    const dispatch = useDispatch();
    //day wise data
    const impClicksData = useSelector((state: RootState) => state.report.impClickGraphData);
    const impAcqData = useSelector((state: RootState) => state.report.impAcqGraphData);
    const impClickAcqLoader = useSelector((state: RootState) => state.report.impClickAcqGraphLoader);
    //real time data
    const realTimeImpClicksData = useSelector((state: RootState) => state.report.realTimeImpClickGraphData);
    const realTimeImpAcqData = useSelector((state: RootState) => state.report.realTimeImpAcqGraphData);
    const realTimeImpClicksAcqGraphLoader = useSelector((state: RootState) => state.report.realTimeImpClickAcqGraphLoader);
    const { impClickAcqGraphFrom, impClickAcqGraphTo } = useSelector((state: RootState) => state.report);
    const [impressionDownloader, setImpressionDownloader] = useState<boolean>(false);
    const data = useMemo(() => {
        if (applyDate === 'today') {
            if (acqClickSwitch === 'Clicks') {
                return realTimeImpClicksData;
            } else {
                return realTimeImpAcqData;
            }
        } else {
            if (acqClickSwitch === 'Clicks') {
                return impClicksData;
            } else {
                return impAcqData;
            }
        }
    }, [acqClickSwitch, applyDate, realTimeImpAcqData, realTimeImpClicksData, impClicksData, impAcqData]);

    const ApiCalls = () => {
        dispatch(
            OverAllReportAction.fetchRealTimeImpClicksAcqReportsData(
                {
                    time_interval: hourSelected,
                },
                campId,
            ),
        );
    };

    useEffect(() => {
        if (startDate && endDate && campId && applyDate !== 'today') {
            dispatch(
                OverAllReportAction.fetchImpClicksAcqReportsData(
                    {
                        start_dt: startDate,
                        end_dt: endDate,
                    },
                    campId,
                ),
            );
        } else if (applyDate === 'today' && hourSelected) {
            //fetch data when hourly
            ApiCalls();
            let interval = setInterval(() => {
                console.log(hourSelected);
                ApiCalls();
            }, 60000);

            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, campId, applyDate, hourSelected, dispatch]);

    const handleExportImpressionReport = () => {
        setImpressionDownloader(true);
        const payload = {
            start_dt: startDate,
            end_dt: endDate,
            type: '',
        };
        Apis.exportImpressionReport(payload, campId)
            .then(({ data }) => {
                download(data, campId === 'overall-report' ? `Overall Impression click report.xlsx` : `Impression click report.xlsx`);
            })
            .catch((err) => {
                dispatch(MessageActions.showMessage({ text: String('Something went wrong'), error: true }));
            })
            .finally(() => {
                setImpressionDownloader(false);
            });
    };

    const chartHeading: any = {
        Clicks: 'Impressions & Clicks',
        Acquisitions: 'Impressions & Acquisitions',
    };
    return (
        <div className="mt-8 rounded-lg drop-shadow-md bg-white px-3  h-full">
            <div className="flex justify-between items-center p-5 border-b-2">
                <div className="text-[18px] font-[700] font-[Roboto]">
                    {chartHeading[acqClickSwitch] || 'Impressions'}
                    {applyDate === 'today' && hourSelected === '1' && impClickAcqGraphFrom && impClickAcqGraphTo && <span className="px-2 font-[300] text-[16px]">({`From ${impClickAcqGraphFrom} To ${impClickAcqGraphTo}`})</span>}
                    {/* {applyDate === 'today' && hourSelected === '1' && (<span className='px-2 text-[14px]'>(From <span className='font-[300]'>{`1 am`}</span> To <span className='font-[200]'>{`2 am`}</span>)</span>)} */}
                </div>
                <div className="flex gap-4 h-[40px] items-center">
                    <div className="flex justify-start items-center gap-2 border border-[#A0A0A0] py-2 px-3 rounded-xl">
                        <GoDotFill size={15} color="#00a7a7" />
                        <p className="text-[#1E1B39] font-[Roboto] text-[12px] font-[400]">Impressions</p>
                    </div>
                    <div className="flex justify-start items-center gap-2 border border-[#A0A0A0] py-2 px-3 rounded-xl">
                        <GoDotFill size={15} color="#056433" />
                        <p className="text-[#1E1B39] font-[Roboto] text-[12px] font-[400]">Clicks</p>
                    </div>
                    <div className="flex justify-start items-center gap-2 border border-[#A0A0A0] py-2 px-3 rounded-xl">
                        <GoDotFill size={15} color="#54E69A" />
                        <p className="text-[#1E1B39] font-[Roboto] text-[12px] font-[400]">Acquisitions</p>
                    </div>
                    <div className="">
                        <CustomTabSwitch tabs={switchTabs} activeTab={acqClickSwitch} onChangeTab={(item: string) => setAcqClickSwitch(item)} />
                    </div>
                    <PButton loading={impressionDownloader} title={'Export'} onClick={handleExportImpressionReport} />
                </div>
            </div>
            <div className="flex justify-between text-[14px] font-[700] font-[Roboto] p-5">
                <span>IMPRESSIONS</span>
                <span>{acqClickSwitch === 'Clicks' ? 'CLICKS' : acqClickSwitch === 'Acquisitions' ? 'ACQUISITIONS' : ''}</span>
            </div>
            <div>
                <ImpClicksBiaxialDashedGraph data={data} loading={applyDate === 'today' ? realTimeImpClicksAcqGraphLoader : impClickAcqLoader} suffix="" prefix="" activeTab={acqClickSwitch} applyDate={applyDate} />
            </div>
        </div>
    );
};

export default ImpressionClickGraph;
