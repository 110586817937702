import PCard from "../../common/Card";
import React, { useState } from 'react';
import { HiOutlineMail } from 'react-icons/hi';
import PNormalInput from '../../common/NormalInput';
import PButton from '../../common/Button';
import { isEmail } from '../../../utils/Validation';
import logo from '../../../assets/images/publir.svg';
import { useNavigate } from "react-router-dom";
import Apis from "../../../api";
import { useDispatch } from "react-redux";
import MessageActions from "../../message/redux/actions";
const ForgotPassword = () => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const [email, setEmail] = useState('');
     const [isValidate, setValidate] = useState(false);
     const [loading, setLoading] = useState<boolean>(false);


     const handleForgotPassword = () => {
          setValidate(true);
          if (!email || email.trim() === "") {
               return;
          }
          setValidate(false)
          setLoading(true);
          Apis.forgotPassword({ email: email }).then((res) => {
               dispatch(MessageActions.showMessage({ text: res?.data?.data?.message, error: false }));
          }).catch((err) => {
               dispatch(MessageActions.showMessage({ text: String(err?.response?.data?.data?.message), error: true }));
          }).finally(() => { setLoading(false) })
     }
     return (
          <div className="w-full h-screen bg-gray-100">
               <div className="flex justify-center items-center h-full flex-col">
                    <img src={logo} alt="publirLogo" className="pb-5 w-36" />
                    <PCard className="px-6 py-4">
                         <div className="flex justify-center items-center pb-6">
                              <p className="font-bold montserrat-medium text-2xl">Forgot Password</p>
                         </div>
                         <div className="py-2">
                              <PNormalInput
                                   onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                             event.preventDefault();
                                             handleForgotPassword();
                                        }
                                   }}
                                   title="Username or Email"
                                   value={email}
                                   name="email"
                                   onChange={(e: any) => setEmail(e.value)}
                                   isRequired={true}
                                   suffix={<HiOutlineMail />}
                              />
                              {isValidate && !isEmail(email) && <span className="common_error">Please enter valid email</span>}
                         </div>

                         <div className="flex items-center mt-6 w-full justify-center">
                              <PButton title="Confirm" onClick={handleForgotPassword} loading={loading} />
                         </div>

                         <div className="flex justify-center items-center my-1">
                              <div className="font-bold underline text-[14px] cursor-pointer" onClick={() => navigate(-1)}>Back To Login</div>
                         </div>
                    </PCard>
               </div>
          </div>
     );
}

export default ForgotPassword;
