export const HEADERMENU_PATH = {
    login: '/login',
    signup:'/signup',
    compaigns: '/',
    compaignCreate: '/campaign/create',
    compaignView: '/campaign/',
    overallReport:'/report',
    userManagement:'/user_management',
    userActivities:'/user_activities',
    forgotPassword:'/forgot_password',
};
