import { useMemo, useState } from 'react';

interface PaginationOptions {
    initialPage?: number;
    itemsPerPage?: number;
}

interface PaginationResult {
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    currentItems: any[];
    totalPages: number;
    perPageSize: number;
}

const usePagination = (items: any[], options: PaginationOptions = {}): PaginationResult => {
    const { initialPage = 1, itemsPerPage = 10 } = options;

    const [currentPage, setCurrentPage] = useState(initialPage);

    const totalPages = useMemo(() => Math.ceil(items.length / itemsPerPage), [items.length, itemsPerPage]);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = items.slice(startIndex, endIndex);

    return {
        currentPage,
        setCurrentPage,
        currentItems,
        totalPages,
        perPageSize: itemsPerPage,
    };
};

export default usePagination;
