import { LocationItem } from "../components/Loaction";

// actionTypes.js
const campaignTypes = {
    FETCH_CAMPAIGNS: 'FETCH_CAMPAIGNS',
    SET_CAMPAIGNS: 'SET_CAMPAIGNS',

    FETCH_ACTIVE_CAMPAIGNS:'FETCH_ACTIVE_CAMPAIGNS',
    SET_ACTIVE_CAMPAIGNS:'SET_ACTIVE_CAMPAIGNS',

    FETCH_PAUSED_CAMPAIGNS:'FETCH_PAUSED_CAMPAIGNS',
    SET_PAUSED_CAMPAIGNS:'SET_PAUSED_CAMPAIGNS',

    FETCH_COMPLETED_CAMPAIGNS:'FETCH_COMPLETED_CAMPAIGNS',
    SET_COMPLETED_CAMPAIGNS:'SET_COMPLETED_CAMPAIGNS',

    //fetchconstants
    FETCH_CONSTANTS: 'FETCH_CONSTANTS',
    SET_CONSTANTS: 'SET_CONSTANTS',

    //FETCH_CAMPAIGN view
    FETCH_CAMPAIGN_VIEW: 'FETCH_CAMPAIGN_VIEW',
    SET_CAMPAIGN_VIEW: 'SET_CAMPAIGN_VIEW',
};

export interface CampaignList {
    budget: number;
    budget_type: string;
    campaign_type: string;
    created_at: string;
    frequency: number;
    frequency_type: string;
    is_duration_continues: boolean;
    name: string;
    start_dt: string;
    _id: string;
    end_dt: string;
    status: string;
    publisher_type?:string;
    create?:string;
    view?:string;
}

export interface Constants {
    browsers: string[];
    budget_types: string[];
    campaign_types: string[];
    categories: {name: string, categories: {name: string}}[];
    devices: string[];
    frequency_types: string[];
    gender: string[];
    location_types: string[];
    websites_types: string[];
    media_sizes: { name: string; size: string }[];
    educations: string[];
    occupations: string[];
    purchase: string[];
    interests: string[];
    website_categories: string[];
    websites:string[];
    cities: {city: string, state: string, country: string}[];
}

export interface DetailOfCampaign {
    browsers: string[];
    budget_types: string;
    campaign_types: string;
    categories: {name: string, categories: {name: string}}[];
    devices: string[];
    frequency_types: string;
    gender: string;
    location_types: string[];
    websites_types: string;
    media_sizes: { name: string; size: string }[];
    educations: string[];
    occupations: string[];
    purchase: string[];
    interest: string[];
    website_categories: string[];
    websites:string;
    cities: {city: string, state: string, country: string}[];
}

export const emptyConstants = {
    browsers: [],
    budget_types: [],
    campaign_types: [],
    categories: [],
    devices: [],
    frequency_types: [],
    gender: [],
    location_types: [],
    media_sizes: [],
    websites_types: [],
    occupations: [],
    educations: [],
    website_categories: [],
    websites:[],
    cities: [],
    purchase: [],
    interests: [],
};

export interface Campaign {
    name?: string;
    description?: string;
    type?: any;
    isCreative?: boolean;
    isContinuosly?: boolean;
    durationStart?: any;
    durationEnd?: any;
    startDataRun?: string;
    endDateRun?: any;
    frequencyCap?: any;
    frequencyPerDay?: string;
    rate?: any;
    conversionType?: any;
    conversionValue?: any;
    selectedBudget?: any;
    publirFee?: any;
    enteredBudget?: any;
    categoriesType?: any;
    WebsitesType?: any;
    targetDevices?: any;
    targetBrowsers?: any;
    isStartImmeditialey?: boolean;
    selectedMediaSizes?: any;
    include_gender_type?: any;
    include_min_age?: number;
    include_max_age?: number;
    include_Occupation?: string[];
    include_Education?: string[];
    include_Purchase?: string[];
    include_Interest?: string[];
    exclude_Purchase?: string[];
    exclude_Interest?: string[];
    exclude_gender_type?: any;
    exclude_min_age?: number;
    exclude_max_age?: number;
    exclude_Occupation?: string[];
    exclude_Education?: string[];
    in_selectedWebsite?: string[];
    ex_selectedWebsite?: string[];
    selected_in_Category?: string[];
    selected_ex_Category?: string[];
    in_selectedLocation?: LocationItem[];
    ex_selectedLocation?: LocationItem[];
    previewImagesDetail?: object[];
    error?: string;
    overLapError?: boolean;
    media?: { size: string; image?: any; text?: any; script?: any }[];
    daysAndTimeDetails?: { scheduleLineItems: string; daysTime: boolean; schedule: { start: string; end: string; dontRunDays: boolean; repeatOn: string[] }[] };
    mediaPartner: string;
}

export interface CampaignState {
    campaigns: CampaignList[];
    loading: boolean;
    constants: Constants;
    totalRecords:any;
    activeCampaigns: CampaignList[];
    activeCampaignsLoading: boolean;
    activeCampaignsTotalRecords:number
    
    pausedCampaigns: CampaignList[];
    pausedCampaignsLoading: boolean;
    pausedCampaignsTotalRecords:number;

    completedCampaigns: CampaignList[];
    completedCampaignsLoading: boolean;
    completedCampaignsTotalRecords:number;


}

export default campaignTypes;
