import userManagementTypes, { UserManagementState } from "./type";

const initialState: UserManagementState = {
     userListData: undefined,
     userListDataLoading: false,
     userListTotal: 0,
     allAssignCampList: [],
     allAssignCampListLoader: false
};

const userManagementReducer = (state = initialState, action: { type: string; payload: any }): UserManagementState => {
     switch (action.type) {
          case userManagementTypes.FETCH_USER_MANAG_USER_LIST:
               return {
                    ...state,
                    userListData: [],
                    userListDataLoading: true,
                    userListTotal: 0
               };

          case userManagementTypes.SET_USER_MANAG_USER_LIST:
               return {
                    ...state,
                    userListData: action.payload.data,
                    userListDataLoading: false,
                    userListTotal: action.payload.count
               };

          case userManagementTypes.FETCH_ASSIGN_CAMP_LIST:
               return {
                    ...state,
                    allAssignCampList: [],
                    allAssignCampListLoader: true
               }

          case userManagementTypes.SET_ASSIGN_CAMP_LIST:
               return {
                    ...state,
                    allAssignCampList: action.payload,
                    allAssignCampListLoader: false
               }
          default:
               return state;
     }
}

export default userManagementReducer;
