import download from 'downloadjs';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Apis from '../../../../../../api';
import PButton from '../../../../../common/Button';
import PTabs from '../../../../../common/Tabs';
import MessageActions from '../../../../../message/redux/actions';
import CountryPerformanceTable from './CountryPerformanceTable';
import DevicePerformanceTable from './DevicePerformanceTable';
import MediaPerformanceTable from './MediaPerformanceTable';
import SitesPerformanceTable from './SitesPerformanceTable';
import CreativePerformanceTable from './CreateivePerformanceTable';

type Props = {
    startDate?: string;
    endDate?: string;
    campId?: string;
};

const PerformanceTabsTable: React.FC<Props> = ({ startDate, endDate, campId }) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState<string>('Top Countries');
    const [downloadLoader, setDownloadLoader] = useState<boolean>(false);

    const onTabChange = (data: string) => {
        setActiveTab(data);
    };

    const table = useMemo(() => {
        const tables: any = {
            'Top Countries': <CountryPerformanceTable startDate={startDate} endDate={endDate} campId={campId} />,
            'Top Devices': <DevicePerformanceTable startDate={startDate} endDate={endDate} campId={campId} />,
            'Top Media Sizes': <MediaPerformanceTable startDate={startDate} endDate={endDate} campId={campId} />,
            'Top Sites': <SitesPerformanceTable startDate={startDate} endDate={endDate} campId={campId} />,
            'Top Creatives' :<CreativePerformanceTable startDate={startDate} endDate={endDate} campId={campId}/>
        };

        return tables[activeTab] || null;
    }, [activeTab, startDate, endDate, campId]);

    const handleExportReport = () => {
        const downloadType: any = { 'Top Countries': 'country', 'Top Devices': 'device', 'Top Media Sizes': 'size', 'Top Sites': 'site', 'Top Creatives':'creative'};
        const getType = downloadType[activeTab] || '';
        if (getType && campId) {
            setDownloadLoader(true);
            const payload = {
                start_dt: startDate,
                end_dt: endDate,
                type: getType,
            };

            Apis.exportIndividualCampTopDataApi(payload, campId)
                .then(({ data }) => {
                    download(data, `${activeTab}_report.xlsx`);
                })
                .catch((err) => {
                    dispatch(MessageActions.showMessage({ text: String('Something went wrong'), error: true }));
                })
                .finally(() => {
                    setDownloadLoader(false);
                });
        }
    };
    return (
        <div className="">
            <div className="w-[100%] relative flex flex-row-reverse">
                <PButton loading={downloadLoader} className="top-12" title={'Export'} onClick={handleExportReport} />
            </div>

            <PTabs
                activeTab={activeTab}
                setActiveTab={onTabChange}
                tabs={[
                    { key: 'Top Countries', title: 'Top Countries' },
                    { key: 'Top Devices', title: 'Top Devices' },
                    { key: 'Top Media Sizes', title: 'Top Media Sizes' },
                    { key: 'Top Sites', title: 'Top Sites' },
                    { key: 'Top Creatives', title: 'Top Creatives' },
                ]}
            />
            <div className="flex justify-end text-[12px] font-[Roboto] mt-1 text-[#056433]">Default sorting is done on Ad Impressions</div>
            <div className="pb-4">{table}</div>
        </div>
    );
};

export default PerformanceTabsTable;
