const handleBudgetSpendData = (data: { labels: string[], budget_spent: any, expected_budget: any }) => {
     const list: { date?: string, expected?: any, actual?: any }[] = [];
     data?.labels.forEach((item: any) => {
          let obj = {
               date: `${item}`,
               expected: data?.expected_budget?.[item],
               actual: data?.budget_spent?.[item],
          }
          list.push(obj);
     })
     return list;
}

const handleCtrData = (data: { crrCTR: any, crrDate: string, prevCTR: any, prevDate: string }[]) => {
     const list: { data?: string, "Last Period": any, "This Period": any }[] = [];
     data?.forEach((item: any) => {
          let obj = {
               "date": item?.crrDate,
               "Last Period": item?.prevCTR || 0,
               "This Period": item?.crrCTR || 0
          }
          list.push(obj);
     })
     return list;
}

const handleTopData = (data: { name: string, impressions: number, clicks: number }[]):{implist:any,clickslist:any} => {
     const implist:{name:string,value:number}[]=[]
     const clickslist:{name:string,value:number}[]=[]

     data?.forEach((item:any)=>{
          implist.push({"name":item?.name,"value":item?.impressions});
          clickslist.push({"name":item?.name,"value":item?.clicks});
     })
     return {implist,clickslist}
}
const overallReportUtils = {
     handleBudgetSpendData,
     handleCtrData,
     handleTopData,
}

export default overallReportUtils;



