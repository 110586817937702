import React, { useMemo, useState } from 'react';
import { BiSquare } from 'react-icons/bi';
import { CiSearch } from 'react-icons/ci';
import { MdClose } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward, IoMdCheckboxOutline } from 'react-icons/io';
import PNormalInput from '../../../../common/NormalInput';

type Props = {
    WebsitesType?: string;
    websiteList?: string[];
    selectedWebsite?: string[];
    handleAddItem: (item: string) => void;
    handleRemoveItem: (item: string) => void;
    isWebsiteValidate: boolean;
    hideItemFromList: string[] | undefined;
};
interface searchItem {
    searchWebsite: string;
}
const PWebsiteModalView: React.FC<Props> = ({ hideItemFromList, WebsitesType, websiteList, selectedWebsite, handleAddItem, handleRemoveItem, isWebsiteValidate }) => {
    const pageSize = 10;
    const selectedPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPageSize, setSelectedPageSize] = useState(1);

    const [searchValue, setSearchValue] = useState<searchItem>({
        searchWebsite: '',
    });
    const arrayWebsite = useMemo(() => {
        const filteredList = websiteList
            ?.filter((item) => !hideItemFromList?.some((selected) => selected === item))
            .filter((item) => {
                if (!selectedWebsite || selectedWebsite.indexOf(item) === -1) {
                    return item.includes(searchValue.searchWebsite);
                }
                return false; // Explicitly return false if the condition isn't met
            });

        return filteredList?.sort((a, b) => a.localeCompare(b));
    }, [searchValue.searchWebsite, selectedWebsite, websiteList, hideItemFromList]);

    const totalSize = arrayWebsite?.length ? Math.ceil(arrayWebsite?.length / pageSize) : 0;
    const selectedSize = selectedWebsite?.length ? Math.ceil(selectedWebsite?.length / selectedPage) : 0;
    const handleSearch = (data: { name: string; value: string | number | undefined | boolean }) => {
        const { name, value } = data;
        setSearchValue({
            ...searchValue,
            [name]: value,
        });
        setCurrentPage(1);
    };

    const removeEnteredText = () => {
        setSearchValue({
            ...searchValue,
            searchWebsite: '',
        });
    }

    return (
        <div>
            <p className="flex items-center font-[Roboto-Medium] md:text-black font-bold text-l mt-6 mb-6">Categories</p>
            <div>
                <p className="flex items-center font-[Roboto-Medium] md:text-[#056433] font-bold text-m mt-6 mb-2">{`${WebsitesType} Websites (${selectedWebsite?.length || 0})`}</p>
                <div className="mt-4">
                    {selectedWebsite?.slice((selectedPageSize - 1) * selectedPage, selectedPage * selectedPageSize)?.map((item, index) => (
                        <div key={index} className="flex items-center mt-4" onClick={() => handleRemoveItem(item)}>
                            <IoMdCheckboxOutline size={'1.25rem'} className="cursor-pointer ml-2" />
                            <p className="flex items-center font-[Roboto] text-black text-m ml-2 underline">{item}</p>
                        </div>
                    ))}
                    {isWebsiteValidate && !selectedWebsite?.length && <span className="common_error">Please select at least one website for {WebsitesType}</span>}
                </div>
                {selectedWebsite?.length ? (
                    <div className="flex justify-between items-center mt-2">
                        <span>Page {`${selectedPageSize}/${selectedSize}`}</span>
                        <div className="flex gap-1">
                            <IoIosArrowBack
                                className={selectedPageSize === 1 ? 'cursor-not-allowed' : 'cursor-pointer'}
                                color={selectedPageSize === 1 ? 'black' : '#056433'}
                                onClick={() => {
                                    if (selectedPageSize !== 1) {
                                        setSelectedPageSize(selectedPageSize - 1);
                                    }
                                }}
                                title="Back"
                            />
                            <IoIosArrowForward
                                className={selectedPageSize === selectedSize ? 'cursor-not-allowed' : 'cursor-pointer'}
                                color={selectedPageSize === selectedSize ? 'black' : '#056433'}
                                onClick={() => {
                                    if (selectedPageSize !== selectedSize) {
                                        setSelectedPageSize(selectedPageSize + 1);
                                    }
                                }}
                                title="Next"
                            />
                        </div>
                    </div>
                ) : null}
                <p className="cursor-pointer md:text-[#056433] flex font-[Roboto-Medium] font-medium text-xs mt-8">Add More Websites</p>

                <PNormalInput
                    prefix={<CiSearch />}
                    value={searchValue.searchWebsite}
                    name="searchWebsite"
                    className="md:py-1 md:px-2 mt-2"
                    onChange={handleSearch}
                    placeholder="Search Websites"
                    suffix={
                        <MdClose className='cursor-pointer' onClick={removeEnteredText} />
                    }
                />
                <div className="mt-4 mb-10">
                    {arrayWebsite?.sort((a, b) => a.localeCompare(b)).slice((currentPage - 1) * pageSize, pageSize * currentPage)?.map((item, index) => (
                        <div key={index} className="flex items-center mt-4" onClick={() => handleAddItem(item)}>
                            <BiSquare size={'1.25rem'} className="cursor-pointer ml-2" />
                            <p className="flex items-center font-[Roboto] text-black text-m ml-2 underline">{item}</p>
                        </div>
                    ))}
                    <div className="flex justify-between items-center mt-2">
                        <span>Page {`${currentPage}/${totalSize}`}</span>
                        <div className="flex gap-1">
                            <IoIosArrowBack
                                className={currentPage === 1 ? 'cursor-not-allowed' : 'cursor-pointer'}
                                color={currentPage === 1 ? 'black' : '#056433'}
                                onClick={() => {
                                    if (currentPage !== 1) {
                                        setCurrentPage(currentPage - 1);
                                    }
                                }}
                                title="Back"
                            />
                            <IoIosArrowForward
                                className={currentPage === totalSize ? 'cursor-not-allowed' : 'cursor-pointer'}
                                color={currentPage === totalSize ? 'black' : '#056433'}
                                onClick={() => {
                                    if (currentPage !== totalSize) {
                                        setCurrentPage(currentPage + 1);
                                    }
                                }}
                                title="Next"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PWebsiteModalView;
