import { VscGraph } from "react-icons/vsc";
import PDropdown from "../../../common/Dropdown";
import { CampaignList } from "../campaign/redux/type";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { RootState } from "../../../../store/RootReducer";
import { HEADERMENU_PATH } from "../../../../routes/RoutesURL";
import { commaSeperator } from "../../../../utils/Validation";
import moment from "moment";
import Apis from "../../../../api";
import campaginsActions from "../campaign/redux/actions";
import MessageActions from "../../../message/redux/actions";
import PCard from "../../../common/Card";
import PTable from "../../../common/Table";

type Props = {
     activeTab: string;
     searchText: string;
     setStatusLoading: any;
}
const PausedCampaigns: React.FC<Props> = ({ activeTab, searchText, setStatusLoading }) => {
     const navigate = useNavigate();
     const dispatch = useDispatch();

     const campaigns = useSelector((state: RootState) => state.campaign.pausedCampaigns);
     const loading = useSelector((state: RootState) => state.campaign.pausedCampaignsLoading);
     const totalRecords = useSelector((state: RootState) => state.campaign.pausedCampaignsTotalRecords);

     let campaignsData = campaigns;
     useEffect(() => {
          dispatch(campaginsActions.fetchPausedCampaigns());
     }, [dispatch]);

     const data = useMemo(() => {
          if (campaignsData) {
               const filteredData = campaignsData.filter((item: any) => item.name.toLowerCase().includes(searchText.toLowerCase())).filter((item: any) => activeTab === 'paused' || item.status.toLocaleLowerCase() === activeTab.toLocaleLowerCase());

               const dataSource = filteredData.map((item: any, index: number) => ({
                    ...item,
                    key: index.toString(),
               }));
               return dataSource;
          }
          return [];
     }, [campaignsData, searchText, activeTab]);

     const columnTable = [
          {
               title: 'Campaign Name',
               dataIndex: 'name',
               width: 280,
               fixed: 'left',
               render: (text: string, record: CampaignList) => (
                    <p
                         onClick={() => {
                              if (record?.publisher_type && record?.create === "false") {
                                   return;
                              }
                              navigate(`${HEADERMENU_PATH.compaignView}${record._id}`, { state: { create: true } });
                         }}
                         className={record?.publisher_type && record?.create === "false" ? "" : "underline"}
                    >
                         {text}
                    </p>
               ),
          },
          {
               title: 'Type',
               dataIndex: 'campaign_type',
               width: 70,
          },
          {
               title: 'Value',
               dataIndex: 'rate',
               width: 120,
               render: (text: any) => (
                    <div>${commaSeperator(parseFloat(text).toFixed(2))}</div>
               )
          },
          {
               title: 'Budget',
               key: 'budget',
               render: (_: any, record: CampaignList) => <span>{`$${record.budget}/${record.budget_type}`}</span>,
               width: 180,
               sorter: (a: CampaignList, b: CampaignList) => a.budget - b.budget,
          },
          {
               title: 'Start date',
               dataIndex: 'start_dt',
               width: 150,
               sorter: (a: CampaignList, b: CampaignList) => moment(a.start_dt).unix() - moment(b.start_dt).unix(),
          },
          {
               title: 'End date',
               dataIndex: 'end_dt',
               width: 150,
               render: (text: string) => <p>{text || '--NA--'}</p>,
               sorter: (a: CampaignList, b: CampaignList) => moment(a.end_dt).unix() - moment(b.end_dt).unix(),
          },
          {
               title: 'Report',
               dataIndex: 'site',
               width: 100,
               fixed: 'right',
               render: (text: string, row: CampaignList) => (
                    <div className='flex justify-center'>
                         {
                              (row?.publisher_type && row?.view === "false") ?
                                   (
                                        <div>-</div>
                                   ) : (
                                        <VscGraph
                                             size={16}
                                             onClick={() => {
                                                  navigate(`${HEADERMENU_PATH.overallReport}/${row._id}`, { state: { name: row.name, view: true } });
                                             }}
                                             color='#056433' />
                                   )
                         }

                    </div>
               )
          },
          {
               title: 'Status',
               dataIndex: 'status',
               width: 150,
               fixed: 'right',
               render: (text: string, campaign: CampaignList) => (
                    <PDropdown
                         options={['Completed', 'Active', 'Paused'].map((r) => {
                              return { label: r, value: r };
                         })}
                         name="status"
                         onChange={(d) => {
                              setStatusLoading(true);
                              Apis.campaignStatusUpdate(campaign._id, { status: d.value })
                                   .then(() => {
                                        let list = [...campaignsData];
                                        const index = list.findIndex((c) => c._id === campaign._id);
                                        // if (index !== -1) {
                                        //      list[index].status = String(d.value);
                                        // }
                                        list.splice(index,1);
                                        dispatch(campaginsActions.setPausedCampaigns({ data: list, totalRecords: totalRecords }));
                                        dispatch(MessageActions.showMessage({ text: 'Status updated successfully!', error: false }));
                                        Apis.generateTemperoryCampeign(campaign._id);
                                   })
                                   .catch((err) => {
                                        dispatch(MessageActions.showMessage({ text: 'error', error: true }));
                                   })
                                   .finally(() => {
                                        setStatusLoading(false);
                                   });
                         }}
                         label=""
                         required
                         value={text}
                    />
               ),
          },
     ];
     return (
          <div>
               <PCard>
                    <PTable loading={loading} pagination={{ isShow: true }} columns={columnTable} data={data || []} className="mt-5" rowKey={(c: CampaignList) => c._id} />
               </PCard>
          </div>
     )
}

export default PausedCampaigns;
