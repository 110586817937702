import React, { useEffect, useMemo, useState } from 'react';
// import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { CiSearch } from 'react-icons/ci';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/RootReducer';
import PNormalInput from '../../../../common/NormalInput';
import { Dropdown, MenuProps } from 'antd';
import { FaAngleDown } from 'react-icons/fa';
import { MdClose } from "react-icons/md";

type Props = {
    getSelectedCategoryList: (data: string[]) => void;
    selectedList: string[] | undefined;
    isValidateMsg: boolean;
    unableToshowList: string[] | undefined;
    otherList: any;
};

interface catModal {
    viewMore: boolean;
    searchText: string;
}

const PCategoryModalView: React.FC<Props> = ({ unableToshowList, selectedList, isValidateMsg, getSelectedCategoryList, otherList }) => {
    const constants = useSelector((state: RootState) => state.campaign.constants);
    const [displayArr, setDisplayArr] = useState<any>([]);
    const [selectedCatList, setSelectedCatList] = useState<string[]>(selectedList !== undefined ? selectedList : []);
    const [category, setCategory] = useState<catModal>({
        viewMore: false,
        searchText: '',
    });

    // Function to toggle showAll state
    const handleToggleShowAll = () => {
        setCategory({ ...category, viewMore: !category.viewMore });
    };

    const onSearchCatType = (e: { name: string; value: string | number | undefined }) => {
        const { name, value } = e;
        setCategory({
            ...category,
            [name]: value,
        });
    };

    const handleClick = (label: string) => {
        const index = selectedCatList.indexOf(label);
        if (index === -1) {
            // Category not selected, add it to the array
            setSelectedCatList([...selectedCatList, label]);
            getSelectedCategoryList([...selectedCatList, label]);
        } else {
            // Category already selected, remove it from the array
            const newSelectedCat = [...selectedCatList];
            newSelectedCat.splice(index, 1);
            setSelectedCatList(newSelectedCat);
            getSelectedCategoryList(newSelectedCat);
        }
    };


    const dataOfEdu = useMemo(() => {
        const lowerSearchText = category.searchText.toLowerCase();
        return constants.categories
            ?.filter((item: any) => {
                const data = typeof item === 'object';
                if (data) {
                    if (Object.keys(item)[0]?.toLowerCase()?.includes(lowerSearchText)) {
                        return item;
                    } else {
                        let valArr: any = Object.values(item)[0];
                        let ans = valArr.filter((el: any) => {
                            const subType = typeof el;
                            if (subType === 'string' && el.toLowerCase()?.includes(lowerSearchText)) {
                                return el;
                            }
                            return;
                        });
                        if (ans?.length > 0) {
                            return item;
                        }
                    }
                } else {
                    if (item.toLowerCase().includes(lowerSearchText)) {
                        return item;
                    }
                }
                return;
            })
            .filter(Boolean);
    }, [constants.categories, category.searchText]);

    const ifKeyAlreadyUsed = (data: string) => {
        const ans = otherList.includes(data);
        return ans;
    }

    const constructTree = (item: any) => {
        const itemType = typeof item;
        let arr = [];
        if (itemType === 'string') {
            return [];
        } else if (itemType === 'object') {
            let values: any = Object.values(item)[0];
            for (let i = 0; i < values?.length; i++) {
                if (values[i].length === undefined) {
                    let newItem: any = values[i];
                    let subItemKey = Object.keys(values[i])[0];
                    let newTree: any = constructTree(newItem);
                    if(ifKeyAlreadyUsed(subItemKey) === false){
                        arr.push({
                            label: <span onClick={() => handleClick(String(subItemKey))}>{subItemKey}</span>,
                            key: subItemKey,
                            children: newTree,
                        });
                    }
                } else {
                    if(ifKeyAlreadyUsed(values[i]) === false){
                        arr.push({
                            label: values[i],
                            key: values[i],
                        });
                    }
                }
            }
            return arr;
        }
    };

    const removeEnteredText = () => {
        setCategory({
            ...category,
            searchText: '',
        });
    }

    useEffect(() => {
        setDisplayArr(dataOfEdu);
    }, [dataOfEdu]);
    return (
        <div>
            <p className="flex items-center font-[Roboto-Medium] md:text-black font-bold text-l mt-6 mb-6">Categories</p>
            <div>
                <p className="flex items-center font-[Roboto-Medium] md:text-[#056433] font-bold text-m mt-6 mb-2">Included Categories ({selectedCatList.length})</p>
                <div className="mt-2 flex flex-wrap">
                    {selectedCatList?.map((item, index) => (
                        <p
                            onClick={() => handleClick(item)}
                            key={index}
                            className="cursor-pointer flex items-center justify-around border rounded md:bg-[#056433] md:border-[#e5e7eb] h-7 md:w-[auto] px-3 font-[Roboto-Medium] text-white font-medium text-xs mt-2 "
                        >
                            {item}
                            {/* <AiOutlineClose size={'1rem'} className="cursor-pointer ml-2 opacity-50" /> */}
                        </p>
                    ))}
                </div>
                {isValidateMsg && selectedCatList.length === 0 && <span className="common_error">Please Select at least one Category type</span>}
                {/* <p className="cursor-pointer md:text-[#056433] flex font-[Roboto-Medium] font-medium text-xs mt-8">
                    Add More
                </p> */}

                <PNormalInput
                    prefix={<CiSearch />}
                    value={category.searchText}
                    name="searchText"
                    className="md:py-1 md:px-2 mt-2"
                    onChange={onSearchCatType}
                    placeholder="Search Categories"
                    suffix={
                        <MdClose className='cursor-pointer' onClick={removeEnteredText} />
                    }
                />
                <div className="mt-2 flex flex-wrap">
                    {displayArr?.map((item: any, index: number) => {
                        var items: MenuProps['items'] | any = [];
                        // const itemType = typeof item;
                        items = constructTree(item);
                        const onClicked: MenuProps['onClick'] = ({ key }) => {
                            handleClick(String(key));
                        };
                        const titleKey = typeof item === 'object' ? String(Object.keys(item)[0]) : item;
                        return (
                            <>
                                {
                                    !ifKeyAlreadyUsed(titleKey) && (
                                        <p
                                            key={`${index}_category_${Object.keys(item)[0]}`}
                                            className="cursor-pointer flex items-center justify-around border rounded md:bg-[#f1f1f1] md:border-[#e5e7eb] h-7 md:w-[auto] px-3 font-[Roboto-Medium] text-black font-medium text-xs mt-2 ml-1 "
                                        >
                                            <div >
                                                {
                                                    items.length > 0 ?
                                                        <Dropdown menu={{ items, onClick: onClicked }} trigger={["click"]}>
                                                            <FaAngleDown size={"14"} className='mr-2' />
                                                        </Dropdown> : null
                                                }
                                            </div>
                                            <div onClick={() => handleClick(typeof item === 'object' ? String(Object.keys(item)[0]) : item)}>
                                                {titleKey}
                                            </div>
                                        </p>
                                    )
                                }
                            </>
                        )
                    })}
                    <p onClick={handleToggleShowAll} className="cursor-pointer md:text-[#056433] flex items-center justify-around px-4 font-[Roboto-Medium] font-medium text-xs mt-2 ml-1 ">
                        {category.viewMore ? 'View Less' : 'View More'}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PCategoryModalView;


