import { useState } from "react";
import PButton from "../../../../common/Button";
import PModal from "../../../../common/Modal";
import PNormalInput from "../../../../common/NormalInput";
import { MdAccountCircle } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { isEmail } from "../../../../../utils/Validation";
import Apis from "../../../../../api";
import { useDispatch } from "react-redux";
import MessageActions from "../../../../message/redux/actions";
import { Checkbox, Radio, Tooltip } from "antd";
import { generateCustomPassword } from "../../../../../utils/passwordGenerator";
import CopyToClipboard from "react-copy-to-clipboard";
import { IoCopy, IoCopyOutline } from "react-icons/io5";

type Props = {
     fetchUsersList: any;
}

const AddNewUser: React.FC<Props> = ({ fetchUsersList }) => {
     const dispatch = useDispatch();
     const [open, setOpen] = useState(false);
     const [isValidate, setIsValidate] = useState(false);
     const [loading, setLoading] = useState(false);
     const [copy, setCopy] = useState(false);
     const [generatePassword, setGeneratePassword] = useState({
          generate: false,
          password: ''
     });
     const [form, setForm] = useState({
          name: "",
          email: "",
          role: 'customer'
     })

     const closeModal = () => {
          setForm({ name: "", email: "", role: "customer" });
          setGeneratePassword({generate:false,password:""});
          setOpen(false);
          setIsValidate(false);
     }

     const handleCreateNewUser = () => {
          setIsValidate(true);
          if (!form.name || form.name.trim() === "" || !form.email || form.email.trim() === "" || !isEmail(form.email)) {
               return;
          }
          setIsValidate(false);
          setLoading(true);
          const registerPayload = {
               full_name: form.name.trim(),
               email: form.email.trim(),
               add_user: true,
               password: generatePassword.generate ? generatePassword.password : "password",
               user_role: form.role,
               create_password: generatePassword.generate
          }
          Apis.register(registerPayload).then((res: any) => {
               dispatch(MessageActions.showMessage({ text: "User created sucessfully", error: false }));
               fetchUsersList();
          }).catch((err: any) => {
               dispatch(MessageActions.showMessage({ text: String(err?.response?.data?.data?.message), error: true }));
          }).finally(() => {
               setLoading(false);
               closeModal();
          });
     }

     const handleGenerateNewPassword = (check: boolean) => {
          if (check) {
               const password = generateCustomPassword();
               setGeneratePassword({ generate: true, password: password });
          } else {
               setGeneratePassword({ generate: false, password: '' });
          }

     }
     const modalView = (
          <div>
               <div className="px-6 py-4">
                    <div className="flex justify-center items-center pb-6">
                         <p className="font-bold montserrat-medium text-2xl">Create New User</p>
                    </div>
                    <div className="py-2">
                         <PNormalInput
                              onKeyPress={(event) => {
                                   if (event.key === 'Enter') {
                                        event.preventDefault();
                                        handleCreateNewUser();
                                   }
                              }}
                              title="Name"
                              value={form.name}
                              name="name"
                              onChange={(e: any) => { setForm({ ...form, name: e.value }) }}
                              isRequired={true}
                              suffix={<MdAccountCircle />}
                         />
                         {isValidate && (form.name.trim() === "" || !form.name) && <span className="common_error">Please enter name</span>}
                    </div>
                    <div className="py-2">
                         <PNormalInput
                              onKeyPress={(event) => {
                                   if (event.key === 'Enter') {
                                        event.preventDefault();
                                        handleCreateNewUser();
                                   }
                              }}
                              title="Email"
                              value={form.email}
                              name="email"
                              onChange={(e: any) => { setForm({ ...form, email: e.value }) }}
                              isRequired={true}
                              suffix={<HiOutlineMail />}
                         />
                         {isValidate && !isEmail(form.email) && <span className="common_error">Please enter valid email</span>}
                    </div>
                    <div className="flex justify-start mt-2 pl-[7px] font-[Roboto]">
                         <Radio.Group value={form.role} name="role" onChange={(e: any) => setForm({ ...form, role: e.target.value })}>
                              <Radio key={'customer'} value={'customer'}>Customer</Radio>
                              <Radio key={'admin'} value={'admin'}>Admin</Radio>
                         </Radio.Group>
                    </div>

                    <div className="flex  items-center">
                         <Checkbox
                              style={{ fontWeight: 'bold' }}
                              className="customCheckBox2 m-2"
                              onChange={(e) => handleGenerateNewPassword(e.target.checked)}
                              checked={generatePassword.generate}
                         ></Checkbox>
                         <div className="">Generate Password</div>
                    </div>

                    {
                         generatePassword.generate && generatePassword.password && (
                              <div className="relative bg-[white] shadow-lg border border-[green] p-2  rounded-xl">
                                   <pre className="font-[500] font-[Roboto] text-[15px]">
                                        <code>{generatePassword.password}</code>
                                   </pre>

                                   <div className="absolute right-2 top-2 ">
                                        <CopyToClipboard
                                             text={generatePassword.password}
                                             onCopy={() => {
                                                  setCopy(true);
                                                  setTimeout(() => {
                                                       setCopy(false);
                                                  }, 2000);
                                             }}
                                        >
                                             {
                                                  copy ? <IoCopy size={22} />
                                                       :
                                                       <Tooltip title={"Copy"}>
                                                            <IoCopyOutline className="cursor-pointer" size={22} />
                                                       </Tooltip>
                                             }
                                        </CopyToClipboard>
                                   </div>
                              </div>
                         )
                    }

                    {
                         generatePassword.generate && (<div className="text-[12px] mt-2 text-[#c36e41]">please copy this password, email will not be sent to user.</div>)
                    }
                    <div className="flex items-center mt-6 w-full justify-center">
                         <PButton title="Create" onClick={handleCreateNewUser} loading={loading} />
                    </div>
               </div>
          </div>
     )
     return (
          <div>
               <PButton title="Add User" className=" md:rounded-full w-[220px] text-xs flex justify-center items-center py-2" onClick={() => setOpen(true)} />
               <PModal
                    isModalOpen={open}
                    OnClickOfCancel={closeModal}
                    width="400px"
                    className="noscrollbar"
                    ViewOfModal={modalView}
               />
          </div>
     )
}

export default AddNewUser;
