import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CollapsibleAction from './CollapsibleAction';
import { RootState } from '../../store/RootReducer';
import usePagination from './usePagination';
import CustomPagination from './CustomPagination';
import { Spin } from 'antd';
import LocationAction from '../main/prebidAdapter/campaign/modal/Redux/action';
import { LocationItem } from '../main/prebidAdapter/campaign/components/Loaction';
import { containsOnlyNumbers } from '../../utils/Validation';

type DisplayStructuredProps = {
    dataSource: any;
    getLocation?: any;
    locationData: { exclude: LocationItem[]; include: LocationItem[] };
    setLocationData: any;
    searchValue: any;
}

interface CitiesPaginationProps {
    cities: string[];
    handleGetExclude: (...args: any[]) => void;
    handleGetInclude: (...args: any[]) => void;
    listOfArray?: any;
    disable?: any;
    locationData: { exclude: LocationItem[]; include: LocationItem[] };
}

const CitiesPagination: React.FC<CitiesPaginationProps> = ({ cities, handleGetExclude, handleGetInclude, listOfArray, disable, locationData }) => {
    const { currentPage, setCurrentPage, currentItems, totalPages, perPageSize } = usePagination(cities, {
        initialPage: 1,
        itemsPerPage: 10,
    });

    return (
        <>
            <ul>
                {currentItems.map((city) => (
                    <CollapsibleAction
                        arrayLength={cities.length}
                        titleClassName="ml-4"
                        handleToggle={() => { }}
                        title={
                            <div className="flex gap-1 w-[100%] text-[10px] items-center">
                                <p className="font-medium">{city},</p>
                                <p className="text-zinc-400">City</p>
                            </div>
                        }
                        label={city}
                        hideAction={true}
                        showHide={false}
                        handleGetExclude={handleGetExclude}
                        handleGetInclude={handleGetInclude}
                        type={'city'}
                        listOfArray={listOfArray}
                        disable={disable}
                        locationData={locationData}
                    />
                ))}
            </ul>
            <CustomPagination currentPage={currentPage} totalPages={totalPages} data={cities} perPageSize={perPageSize} setCurrentPage={setCurrentPage} />
        </>
    );
};
const DisplayStructured: React.FC<DisplayStructuredProps> = ({ dataSource, getLocation, locationData, setLocationData, searchValue }) => {
    const dispatch = useDispatch();
    const [openCountries, setOpenCountries] = useState<string[]>([]);
    const [openStates, setOpenStates] = useState<{ [country: string]: string[] }>({});
    const { orgExclude, orgInclude, loading, countryLoading, stateLoading } = useSelector((state: RootState) => state.location);
    const [toggleCountryLoaderIndex, setToggleCountryLoaderIndex] = useState(-1);
    const [toggleStateLoaderIndex, setToggleStateLoaderIndex] = useState({ country: -1, state: -1 });
    let locationExIncData: any = { include: orgInclude || {}, exclude: orgExclude || {} };
    const toggleCountry = (country: string, index: number, type: string) => {
        if (openCountries.includes(country)) {
            setOpenCountries((prevOpenCountries) => prevOpenCountries.filter((c) => c !== country));
        } else {
            setOpenCountries((prevOpenCountries) => [...prevOpenCountries, country]);
            setToggleCountryLoaderIndex(index);
            if (searchValue.searchLocation === '') {
                const dataPresent = dataSource[country].allStatesPresent;
                const contentType = dataSource[country].contentType;
                if (!dataPresent || contentType === 'search') {
                    dispatch(LocationAction.fetchSingleCountryLocation({ country: country, state: '', prevData: dataSource, type: type }));

                }
            }
        }
    };

    const toggleState = (country: string, state: string, index: number, stateId: number) => {
        // setOpenStates((prevOpenStates) => {
        //     const countryStates = prevOpenStates[country] || [];
        //     return {
        //         ...prevOpenStates,
        //         [country]: countryStates.includes(state) ? countryStates.filter((s) => s !== state) : [...countryStates, state],
        //     };
        // });
        let previousOpenStates = { ...openStates };
        let prevStates = previousOpenStates[country] || [];

        if (prevStates.includes(state)) {
            prevStates = prevStates.filter((s) => s !== state);
            previousOpenStates[country] = prevStates;
            setOpenStates(previousOpenStates);
        } else {
            prevStates = [...prevStates, state];
            previousOpenStates[country] = prevStates;
            setOpenStates(previousOpenStates);
            setToggleStateLoaderIndex({ country: index, state: stateId });

            if (searchValue.searchLocation === '') {
                const tType = dataSource[country].citiesForStatesArr.includes(state);
                if (!tType) {
                    dispatch(LocationAction.fetchSingleCountryLocation({ country: country, state: state, prevData: dataSource, type: 'state' }));
                }
            }
        }


    };

    const isDataExist = (option: string, data: { country: string, state: string, city: string }, list: any) => {
        const arr = [...list];
        for (let i = 0; i < arr?.length; i++) {
            if ((arr[i]?.country === data?.country) && (arr[i]?.city === data?.city) && (arr[i]?.state === data?.state)) {
                return i;
            }
        }
        return -1;
    }

    const handleGetInclude = (option: any, label: any, type: any, listOfArray: any) => {
        const inclist = [...locationData?.include];
        const exclist = [...locationData?.exclude];
        if (type === 'country') {
            const ele: { country: string, state: string, city: string } = { country: label, state: label, city: label };
            // fist check on exclude list if this data exist if exist then remove it.

            const excindex = isDataExist('exclude', ele, exclist);
            if (excindex !== -1) {
                exclist.splice(excindex, 1);
            }
            // check if data already present in include list of not in include list then add it.
            let newInclist = [];
            for (let i = 0; i < inclist.length; i++) {
                let item = inclist[i];
                if (item.country !== ele.country) {
                    newInclist.push(item);
                }
            }
            newInclist.push(ele);
            setLocationData({
                include: newInclist, exclude: exclist
            })
        } else if (type === 'city') {
            const { country, state } = listOfArray;
            const ele: { country: string, state: string, city: string } = { country: country, state: state, city: label };
            const excindex = isDataExist('exclude', ele, exclist);// fist check on exclude list if this data exist if exist then remove it.
            if (excindex !== -1) {
                exclist.splice(excindex, 1);
            }

            const incindex = isDataExist('include', ele, inclist);// check if data already present in include list if not in include list then add it.
            const isStatePresent = isDataExist('include', { country: country, state: state, city: state }, inclist)
            const countryPresent = isDataExist('include', { country: country, state: country, city: country }, inclist)
            if (incindex === -1 && (isStatePresent === -1 && countryPresent === -1)) {
                inclist.push(ele);
            }
            setLocationData({
                include: inclist, exclude: exclist
            })
        }
        else if (type === 'state') {
            const { country, state } = listOfArray;
            const ele: { country: string, state: string, city: string } = { country: country, state: state, city: state };
            let newExclist = [];
            let newInclist = [];

            //manipulating exclude list
            for (let i = 0; i < exclist.length; i++) {
                const item = exclist[i];
                if (item.country !== ele.country && item.state !== ele.state) { //adding uniq country and state
                    newExclist.push(item);
                } else if (item.country === item.state && item.state === item.city) {  //adding country only
                    newExclist.push(item);
                } else if( item.country === ele.country && item.state !== ele.state && item.city !== ele.city){
                    newExclist.push(item);
                }
            }

            //manipulating include list
            let countryPresent = isDataExist('include', { country: country, state: country, city: country }, inclist)
            if (countryPresent === -1) {
                for (let i = 0; i < inclist.length; i++) {
                    const item = inclist[i];
                    if (item.country === ele.country) {
                        if (item.state !== ele.state) {
                            newInclist.push(item);
                        }
                    } else {
                        newInclist.push(item);
                    }
                    // if (item.country !== ele.country && item.state !== ele.state) {
                    //     newInclist.push(item);
                    // }
                }
                newInclist.push(ele);
            }else{
                newInclist = inclist;
            }

            setLocationData({
                exclude: [...newExclist], include: [...newInclist]
            })
        }
    };

    const handleGetExclude = (option: any, label: any, type: any, listOfArray: any) => {
        const inclist = [...locationData?.include];
        const exclist = [...locationData?.exclude];
        if (type === 'country') {
            const ele: { country: string, state: string, city: string } = { country: label, state: label, city: label };
            // fist check on include list if this data exist  then remove it.
            const removingIndex = isDataExist('include', ele, inclist);
            if (removingIndex !== -1) {
                inclist.splice(removingIndex, 1);
            }
            // check if data already present in exclude list of not in exclude list then add it.
            let newExclist = [];
            for (let i = 0; i < exclist.length; i++) {
                let item = exclist[i];
                if (item.country !== ele.country) {
                    newExclist.push(item);
                }
            }
            newExclist.push(ele);
            setLocationData({
                include: inclist, exclude: newExclist
            })
        } else if (type === 'city') {
            const { country, state } = listOfArray;
            const ele: { country: string, state: string, city: string } = { country: country, state: state, city: label };
            const removingIndex = isDataExist('include', ele, inclist);// fist check on exclude list if this data exist if exist then remove it.
            if (removingIndex !== -1) {
                inclist.splice(removingIndex, 1);
            }

            const incindex = isDataExist('exclude', ele, exclist);// check if data already present in exclude list if not in exclude list then add it.
            const isStatePresent = isDataExist('exclude', { country: country, state: state, city: state }, exclist)
            const countryPresent = isDataExist('exclude', { country: country, state: country, city: country }, exclist)
            if (incindex === -1 && isStatePresent === -1 && countryPresent === -1) {
                exclist.push(ele);
            }
            setLocationData({
                include: inclist, exclude: exclist
            })
        } else if (type === 'state') {
            const { country, state } = listOfArray;
            let newExclist = [];
            let newInclist = [];
            const ele: { country: string, state: string, city: string } = { country: country, state: state, city: state };

            //manipulating include list
            for (let i = 0; i < inclist.length; i++) {
                const item = inclist[i];
                if (item.country !== ele.country && item.state !== ele.state) {
                    newInclist.push(item);
                } else if (item.country === item.state && item.country === item.city) {
                    newInclist.push(item);
                } else if (item.country === ele.country && item.state !== ele.state && item.city !== ele.city){
                    newInclist.push(item);
                }
            }
            let countryPresent = isDataExist('exclude', { country: country, state: country, city: country }, exclist)
            //manipulating exclude list
            if(countryPresent === -1){
                for (let i = 0; i < exclist.length; i++) {
                    const item = exclist[i];
                    if (item.country === ele.country) {
                        if (item.state !== ele.state) {
                            newExclist.push(item);
                        }
                    } else {
                        newExclist.push(item);
                    }
                }
    
                newExclist.push(ele);
            }else{
                newExclist = exclist;
            }
            
            setLocationData({
                exclude: [...newExclist], include: [...newInclist]
            })
        }
    };
    return (
        <div>
            {
                loading || Object.keys(dataSource).length === 0 ? <div className="w-[100%] flex items-center justify-center">
                    <Spin />
                </div>
                    :
                    <div>
                        {
                            Object.entries(dataSource).map(([country, states], index) => {
                                // console.log('contyr,state = ',country,states)
                                const ddata: any = states
                                return (
                                    <>
                                        {
                                            (ddata.display) &&
                                            <div key={country} className=" my-1 pb-1">
                                                <CollapsibleAction
                                                    label={country}
                                                    titleClassName={'font-[Roboto-Medium] font-[800]'}
                                                    handleToggle={() => toggleCountry(country, index, 'country')}
                                                    title={country}
                                                    showHide={openCountries.includes(country)}
                                                    handleGetExclude={handleGetExclude}
                                                    handleGetInclude={handleGetInclude}
                                                    type={'country'}
                                                    listOfArray={dataSource}
                                                    disable={locationExIncData}
                                                    arrayLength={Object.entries(dataSource).length}
                                                    isHideButton={true}
                                                    locationData={locationData}
                                                />
                                                {
                                                    countryLoading === true && toggleCountryLoaderIndex === index &&
                                                    <div className='w-[100%] flex justify-center py-1'>
                                                        <Spin />
                                                    </div>
                                                }
                                                {openCountries.includes(country) && ddata && (
                                                    <>
                                                        {Object.entries(ddata?.data).map(([state, cities], stateId) => {
                                                            const ccities: any = cities;
                                                            return (
                                                                <>
                                                                    {
                                                                        containsOnlyNumbers(state) === false ?
                                                                            <div key={state}>
                                                                                <CollapsibleAction
                                                                                    arrayLength={ccities.length}
                                                                                    label={state}
                                                                                    titleClassName={' ml-1'}
                                                                                    className="font-normal"
                                                                                    handleToggle={() => toggleState(country, state, index, stateId)}
                                                                                    title={
                                                                                        <div className="flex gap-1 w-[100%] text-[12px] items-center">
                                                                                            <p className="font-[600] font-[Roboto-Medium]">{state},</p>
                                                                                            <p className="text-zinc-400 font-light">State</p>
                                                                                        </div>
                                                                                    }
                                                                                    handleGetExclude={handleGetExclude}
                                                                                    handleGetInclude={handleGetInclude}
                                                                                    showHide={openStates[country]?.includes(state)}
                                                                                    type={'state'}
                                                                                    listOfArray={{ country: country, state: state }}
                                                                                    disable={locationExIncData}
                                                                                    locationData={locationData}
                                                                                />
                                                                                {
                                                                                    stateLoading && toggleStateLoaderIndex.country === index && toggleStateLoaderIndex.state === stateId &&
                                                                                    <div className='w-[100%] flex justify-center py-1'>
                                                                                        <Spin />
                                                                                    </div>
                                                                                }
                                                                                {openStates[country]?.includes(state) && (
                                                                                    <CitiesPagination cities={ccities} handleGetExclude={handleGetExclude} handleGetInclude={handleGetInclude} listOfArray={{ country: country, state: state }} disable={locationExIncData} locationData={locationData} />
                                                                                )}
                                                                            </div> : null
                                                                    }
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                                <div className='w-[100%] border-b-2'></div>
                                            </div>
                                        }
                                    </>
                                )
                            }

                            )
                        }
                    </div>
            }
        </div>
    )
}

export default DisplayStructured
