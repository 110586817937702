import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom';
import { HEADERMENU_PATH } from './RoutesURL';
import Login from '../components/auth/login';
import PrebidAdapterList from '../components/main/prebidAdapter';
import PHeader from '../components/common/Header';
import CreateOrEditCampaign from '../components/main/prebidAdapter/campaign/CreateOrEditCampaign';
import Message from '../components/message/Meesage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/RootReducer';
import OverallReport from '../components/main/prebidAdapter/overallReport/OverallReport';
import UserManagement from '../components/main/prebidAdapter/user manamgement';
import ForgotPassword from '../components/auth/forgot password';
import { setJwtToken } from '../api/apiServices';
import AccessDenined from '../components/common/accessDenied';
import ChangePassword from '../components/auth/forgot password/changePassword';
import UserActivities from '../components/main/prebidAdapter/userActivities';
import Apis from '../api';
import { LoginResponse, UserState } from '../components/auth/login/redux/types';
import LoginActions from '../components/auth/login/redux/actions';
import MessageActions from '../components/message/redux/actions';
import PageNotFound from '../components/common/pageNotFound';

const RootRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={`${HEADERMENU_PATH.login}`} element={<Login />} />
                <Route path={HEADERMENU_PATH.forgotPassword} element={<ForgotPassword />} />
                <Route path={`${HEADERMENU_PATH.forgotPassword}/token/:token`} element={<ChangePassword />} />
                {/** After login other Route we write here */}
                <Route element={<PrivateRouter />}>
                    <Route path={HEADERMENU_PATH.compaigns} element={<PrebidAdapterList />} />
                </Route>
                <Route element={<PrivateRouter permission={"view_report"} />}>
                    <Route path={`${HEADERMENU_PATH.overallReport}/:id`} element={<OverallReport />} />
                </Route>
                <Route element={<PrivateRouter permission={"create_campaign"} />}>
                    <Route path={HEADERMENU_PATH.compaignCreate} element={<CreateOrEditCampaign />} />
                </Route>
                <Route element={<PrivateRouter permission={"update_campaign"} />}>
                    <Route path={`${HEADERMENU_PATH.compaignView}:campaign_id`} element={<CreateOrEditCampaign />} />
                </Route>
                <Route element={<PrivateRouter roles={["super_admin", "admin"]} />}>
                    <Route path={`${HEADERMENU_PATH.userManagement}`} element={<UserManagement />} />
                    <Route path={`${HEADERMENU_PATH.userActivities}`} element={<UserActivities />} />
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
            <Message />
        </BrowserRouter>
    );
};

export default RootRoutes;
type Props = {
    roles?: string[]
    permission?: string;
}
const PrivateRouter: React.FC<Props> = ({ roles, permission }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const routeData = location.state;
    const token = useSelector((state: RootState) => state.user.jwtToken);
    const accessType = useSelector((state: RootState) => state.user.user);
    const role: any = accessType?.publisher_type;
    const createCampaignPermission = accessType?.create_campaign;

    useEffect(() => {
        if (accessType?.publisher_type) {
            Apis.fetchUserDetails()
                .then(({ data }) => {
                    const response: LoginResponse = data?.data;
                    const { jwt_token, user } = response;
                    const userData: UserState = {
                        jwtToken: jwt_token?.token,
                        user,
                    };
                    dispatch(LoginActions.setUserData(userData));
                })
                .catch((err: any) => {
                    const msg = err?.response?.data?.data?.message;
                    if (msg === "User is not Active") {
                        dispatch(MessageActions.showMessage({ text: String(err?.response?.data?.data?.message), error: true }));
                        dispatch(LoginActions.removeUserData());
                        window.open(`${HEADERMENU_PATH.login}`)
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname,dispatch])

    if (!token) {
        return <Navigate to={HEADERMENU_PATH.login} />;
    } else {
        setJwtToken(String(token))
    }
    console.log(roles);
    return (
        <>
            {
                (roles && roles.length !== 0 && !roles.includes(role)) ||
                    (role !== "super_admin" && role !== "admin" && permission === "create_campaign" && !createCampaignPermission) ||
                    (role !== "super_admin" && role !== "admin" && permission === "view_report" && !routeData?.view) ||
                    (role !== "super_admin" && role !== "admin" && permission === "update_campaign" && !routeData?.create)
                    ? <div>
                        <AccessDenined />
                    </div> :
                    <div id="container">
                        <div>
                            <PHeader />
                        </div>
                        <div className="pt-24 px-6 pb-4">
                            <Outlet />
                        </div>
                    </div>
            }
        </>
    );
};
