import { combineReducers } from 'redux';
import { loginReducer, userReducer } from '../components/auth/login/redux/reducer';
import locationReducer from '../components/main/prebidAdapter/campaign/modal/Redux/reducer';
import campaignReducer from '../components/main/prebidAdapter/campaign/redux/reducer';
import messageReducer from '../components/message/redux/reducer';
import overAllReportReducer from '../components/main/prebidAdapter/overallReport/redux/reducer';
import userManagementReducer from '../components/main/prebidAdapter/user manamgement/redux/reducer';
import userActivityReducer from '../components/main/prebidAdapter/userActivities/redux/reducer';

export const rootReducer = combineReducers({
    login: loginReducer,
    user: userReducer,
    campaign: campaignReducer,
    message: messageReducer,
    location: locationReducer,
    report: overAllReportReducer,
    userManagement: userManagementReducer,
    userActivity: userActivityReducer
});

export const getRootReducer = () => rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
