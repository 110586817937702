import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

interface CustomPaginationProps {
    currentPage: number;
    totalPages: number;
    setCurrentPage: (value: number) => void;
    perPageSize?: number;
    data: any[];
}

const CustomPagination: React.FC<CustomPaginationProps> = ({ currentPage, totalPages, setCurrentPage, perPageSize = 10, data }) => {
    const handlePreviousClick = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage !== totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <>
            {data.length > perPageSize && (
                <div className="flex justify-between items-center p-2">
                    <span>{`Page ${currentPage}/${totalPages}`}</span>
                    <div className="flex gap-1">
                        <IoIosArrowBack className={currentPage === 1 ? 'cursor-not-allowed' : 'cursor-pointer'} color={currentPage === 1 ? 'black' : '#056433'} onClick={handlePreviousClick} title="Back" />
                        <IoIosArrowForward className={currentPage === totalPages ? 'cursor-not-allowed' : 'cursor-pointer'} color={currentPage === totalPages ? 'black' : '#056433'} onClick={handleNextClick} title="Next" />
                    </div>
                </div>
            )}
        </>
    );
};

export default CustomPagination;
