import { Radio, RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import PButton from '../../../../common/Button';
import PModal from '../../../../common/Modal';
import PCategoryModalView from '../modal/CategoryModalView';
import { IoCloseCircle } from 'react-icons/io5';

type Props = {
    categoriesType?: any;
    onCategoryChange: (data: string) => void;
    categoryChooseType?: string[];
    isValidate?: boolean;
    selected_in_Category?: string[];
    selected_ex_Category?: string[];
    handleSaveItem: (item: string[]) => void;
    setUnsaveChanges: any;
};

interface catDetail {
    selectedCatList: string[] | undefined;
    in_categoryList: string[] | undefined;
    ex_categoryList: string[] | undefined;
    isValidate: boolean;
    catType: object | undefined | string;
}

const PCategoriesOfCamp: React.FC<Props> = ({ categoryChooseType, categoriesType, onCategoryChange, isValidate, selected_in_Category, selected_ex_Category, handleSaveItem, setUnsaveChanges }) => {
    const [isModalOpenForCategory, setIsModalOpenForCategory] = useState(false);
    const [categoryData, setCategoryData] = useState<catDetail>({
        selectedCatList: undefined,
        in_categoryList: selected_in_Category,
        ex_categoryList: selected_ex_Category,
        isValidate: false,
        catType: undefined,
    });

    useEffect(() => {
        setCategoryData((prevData) => ({
            ...prevData,
            in_categoryList: selected_in_Category ? selected_in_Category : [],
            ex_categoryList: selected_ex_Category ? selected_ex_Category : [],
        }));
    }, [selected_in_Category, selected_ex_Category]);
    const openCategoryModal = () => {
        setIsModalOpenForCategory(true);
    };

    const closeCategoryModal = () => {
        setIsModalOpenForCategory(false);
        setCategoryData({ ...categoryData, isValidate: false });
    };

    const getSelectedCategoryList = (catList: string[]) => {
        setCategoryData({ ...categoryData, selectedCatList: catList });
    };

    const onSubmitOfCategoryType = () => {
        if (categoryData.selectedCatList && categoryData.selectedCatList.length > 0) {
            setIsModalOpenForCategory(false);
            if (categoriesType === 'Include') {
                setCategoryData({ ...categoryData, in_categoryList: categoryData.selectedCatList, isValidate: false });
            } else {
                setCategoryData({ ...categoryData, ex_categoryList: categoryData.selectedCatList, isValidate: false });
            }
            handleSaveItem(categoryData.selectedCatList);
            setUnsaveChanges(true)
        } else {
            setCategoryData({ ...categoryData, isValidate: true });
        }
    };

    const handleRemoveChipItem = (item: any) => {
        const arr = categoriesType === 'Include' ? categoryData.in_categoryList : categoryData.ex_categoryList;
        const index: any = arr?.indexOf(item);
        if (index !== -1) {
            arr?.splice(index, 1);
        }
        if (categoriesType === 'Include') {
            setCategoryData({ ...categoryData, in_categoryList: arr });
        } else {
            setCategoryData({ ...categoryData, ex_categoryList: arr });
        }
        setUnsaveChanges(true);
    }

    const onChangeCatType = (data: RadioChangeEvent) => {
        const { value } = data.target;
        setCategoryData({
            ...categoryData,
            catType: value,
            // in_categoryList: [],
            // selectedCatList: []
        });
        onCategoryChange(value);
        setUnsaveChanges(true)
    };

    const ViewOfModalCategory = (
        <PCategoryModalView
            getSelectedCategoryList={getSelectedCategoryList}
            selectedList={categoriesType === 'Include' ? categoryData.in_categoryList : categoryData.ex_categoryList}
            otherList = {categoriesType === 'Include' ?categoryData.ex_categoryList  :categoryData.in_categoryList}
            unableToshowList={categoryData.in_categoryList?.concat(categoryData.ex_categoryList !== undefined ? categoryData.ex_categoryList : '')}
            isValidateMsg={categoryData.isValidate}
        />
    );

    const customizeHeaderForCategory = (
        <div className="flex justify-between">
            <div>
                {categoriesType === 'Include' ? (
                    <p className="flex items-center font-[Roboto-Medium] md:text-[#056433] font-bold text-xl">Category (Include)</p>
                ) : (
                    <p className="flex items-center font-[Roboto-Medium] md:text-[red] font-bold text-xl">Category (Exclude)</p>
                )}
            </div>
            <div className="flex">
                <PButton onClick={onSubmitOfCategoryType} title="Submit" className="md:rounded-full md:h-[auto] text-xs py-px md:px-6 mr-4" />
                <AiOutlineCloseCircle size={'1.75rem'} className="cursor-pointer" onClick={closeCategoryModal} />
            </div>
        </div>
    );

    return (
        <div>
            <p className="flex items-center font-[Roboto-Medium] text-black font-medium text-sm mt-9">Categories</p>
            <div className=" p-4 mt-3 bg-white rounded-md shadow-md">
                <div>
                    <label className="ml-1 text-xs">
                        Choose
                        <span className="text-red-500">{'*'}</span>
                    </label>
                    <div className="mt-2 mb-4">
                        <Radio.Group onChange={onChangeCatType} value={categoriesType} name="categoriesType">
                            {categoryChooseType?.map((item, index) => (
                                <Radio key={index} value={item}>
                                    {item}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </div>
                    {categoriesType === 'Include' && categoryData.in_categoryList?.length ? (
                        <>
                            <div>
                                <label className="ml-1 text-xs">Categories ({categoryData.in_categoryList?.length})</label>
                                <div className="mt-2 flex flex-wrap">
                                    {categoryData.in_categoryList?.map((item, index) => (
                                        <p key={index} className="flex gap-1 items-center justify-around border rounded md:bg-[#f1f1f1] md:border-[#e5e7eb] h-7 md:w-[auto] pl-3 pr-1 font-[Roboto-Medium] text-black font-medium text-xs mt-2 ml-1 ">
                                            {item}
                                            <div className='cursor-pointer' onClick={() => handleRemoveChipItem(item)}><IoCloseCircle size={14} color='grey' /></div>
                                        </p>
                                    ))}
                                    <p onClick={openCategoryModal} className="cursor-pointer md:text-[#056433] flex items-center justify-around px-4 font-[Roboto-Medium] font-medium text-xs mt-2 ml-1 ">
                                        Add more
                                    </p>
                                </div>
                            </div>
                        </>
                    ) : null}

                    {categoriesType === 'Exclude' && categoryData.ex_categoryList?.length ? (
                        <>
                            <div>
                                <label className="ml-1 text-xs">Categories ({categoryData.ex_categoryList?.length})</label>
                                <div className="mt-2 flex flex-wrap">
                                    {categoryData.ex_categoryList?.map((item, index) => (
                                        <p key={index} className="flex gap-1 items-center justify-around border rounded md:bg-[#f1f1f1] md:border-[#e5e7eb] h-7 md:w-[auto] pl-3 pr-1 font-[Roboto-Medium] text-black font-medium text-xs mt-2 ml-1 ">
                                            {item}
                                            <div className='cursor-pointer' onClick={() => handleRemoveChipItem(item)}><IoCloseCircle size={14} color='grey' /></div>

                                        </p>
                                    ))}
                                    <p onClick={openCategoryModal} className="cursor-pointer md:text-[#056433] flex items-center justify-around px-4 font-[Roboto-Medium] font-medium text-xs mt-2 ml-1 ">
                                        Add more
                                    </p>
                                </div>
                            </div>
                        </>
                    ) : null}

                </div>
                {categoriesType === 'Include' && !categoryData.in_categoryList?.length && categoriesType ? (
                    <p onClick={openCategoryModal} className="cursor-pointer md:text-[#056433] font-[Roboto-Medium] font-medium text-xs ">
                        Add Category
                    </p>
                ) : null}
                {categoriesType === 'Exclude' && !categoryData.ex_categoryList?.length && categoriesType ? (
                    <p onClick={openCategoryModal} className="cursor-pointer md:text-[#056433] font-[Roboto-Medium] font-medium text-xs ">
                        Add Category
                    </p>
                ) : null}
                {isValidate && !categoriesType && <span className="common_error">Please select Category</span>}
                {isValidate && categoriesType && categoriesType !== 'All' && !selected_in_Category?.length && !selected_ex_Category?.length && <span className="common_error">Please select at list one category</span>}
            </div>

            {isModalOpenForCategory && <PModal ViewOfModal={ViewOfModalCategory} isModalOpen={isModalOpenForCategory} OnClickOfCancel={closeCategoryModal} customizeHeader={customizeHeaderForCategory} />}
        </div>
    );
};
export default PCategoriesOfCamp;
