import { Checkbox, DatePicker, Radio, RadioChangeEvent } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PButton from '../../../../common/Button';
import PDropdown from '../../../../common/Dropdown';
import PNormalInput from '../../../../common/NormalInput';
import AddRunning from '../components/AddRunning/AddRunning';

type Props = {
    durationStart?: any;
    durationEnd?: any;
    isCreative?: boolean;
    isStartImmeditialey?: boolean;
    type?: any;
    onChangeCamType?: (data: RadioChangeEvent) => void;
    frequencyCap?: any;
    frequencyPerDay?: string;
    selectedBudget?: any;
    publirFee?: any;
    enteredBudget?: any;
    onChangeFields: (data: { name: string; value: string | number | undefined | boolean }) => void;
    campaign_types?: string[];
    frequency_types?: string[];
    budget_types?: string[];
    onSelectOnlyOne: (data: CheckboxChangeEvent) => void;
    isValidate?: boolean;
    isContinuosly?: boolean;
    getDetailOfDaysAndTime: (data: any) => void;
    daysAndTimeDetails?: { scheduleLineItems: string; daysTime: boolean; schedule: { start: string; end: string; dontRunDays: boolean; repeatOn: string[] }[] };
    formFields: any;
    setFormFields: any;
    setUnsaveChanges: any;
    campId: any;
};

const PBidDetails: React.FC<Props> = ({
    budget_types,
    frequency_types,
    campaign_types,
    onChangeFields,
    onChangeCamType,
    type,
    isCreative,
    durationStart,
    durationEnd,
    frequencyCap,
    frequencyPerDay,
    selectedBudget,
    publirFee,
    enteredBudget,
    isStartImmeditialey,
    onSelectOnlyOne,
    isValidate,
    isContinuosly,
    getDetailOfDaysAndTime,
    daysAndTimeDetails,
    formFields,
    setFormFields,
    setUnsaveChanges,
    campId
}) => {
    const minDate = moment();
    const [copy, setCopy] = useState(false);
    const { startingDate, endingDate, isStartValid, isEndValid } = useMemo(() => {
        if (isStartImmeditialey) {
        }
        const startDate: string | undefined = durationStart;
        const endDate: string | undefined = durationEnd;
        const defaultStartDate: Dayjs = dayjs(startDate, 'YYYY-MM-DD HH:mm');
        const defaultEndDate: Dayjs = dayjs(endDate, 'YYYY-MM-DD HH:mm');
        const isStartValidDate: boolean = defaultStartDate.isValid();
        const isEndValidDate: boolean = defaultEndDate.isValid();

        return {
            startingDate: defaultStartDate,
            endingDate: defaultEndDate,
            isStartValid: isStartValidDate,
            isEndValid: isEndValidDate,
        };
    }, [durationStart, durationEnd, isStartImmeditialey]);
    const codeStringStatic = `<script>
    function getCookie(n) { for (var t = n + "=", e = document.cookie.split(";"), r = 0; r < e.length; r++) { for (var i = e[r]; " " === i.charAt(0);)i = i.substring(1, i.length); if (0 === i.indexOf(t)) return i.substring(t.length, i.length) } return null }
    function setCookie(e, t, i) { var o, n = new Date; n.setTime(n.getTime() + 24 * i * 60 * 60 * 1e3), o = "; expires=" + n.toUTCString(), document.cookie = e + "=" + t + o + "; path=/"; }
    const cookie = getCookie('_pub_pre_track');
    if (cookie != '' && cookie != null) {
        const cookieInfo = JSON.parse(cookie);
        const campInfo = { campType: 'static', campDefaultVal: ${formFields.conversionValue}, campId: cookieInfo.adCampId, campKeyId: cookieInfo.adKey };
        if(event){
            const orderInfo = {
                event_name: event.name,
                event_data: {
                    totalPrice: checkoutTotalPrice,
                    discountCodesUsed: allDiscountCodes,
                    firstItem: customItemPayload,
                    paymentTransactions: paymentTransactions,
                },
                event_info: event.data
            };
        }
        const payload = { orderInfo: orderInfo, campInfo: campInfo };
        fetch('https://lead.publir.com/publirPrebidAcquisitionTracker', { method: "POST", mode: "no-cors", body: JSON.stringify(payload), credentials: "include", headers: { "Content-Type": "application/json" } }).then(data => { setCookie('_pub_pre_track', '', -1); });
    }</script>`;

    const codeStringDynamic = `<script>
    function getCookie(n) { for (var t = n + "=", e = document.cookie.split(";"), r = 0; r < e.length; r++) { for (var i = e[r]; " " === i.charAt(0);)i = i.substring(1, i.length); if (0 === i.indexOf(t)) return i.substring(t.length, i.length) } return null }
    function setCookie(e, t, i) { var o, n = new Date; n.setTime(n.getTime() + 24 * i * 60 * 60 * 1e3), o = "; expires=" + n.toUTCString(), document.cookie = e + "=" + t + o + "; path=/"; }
    const cookie = getCookie('_pub_pre_track');
    if (cookie != '' && cookie != null) {
        const cookieInfo = JSON.parse(cookie);
        const campInfo = { campType: '${formFields.conversionType}', campDefaultVal: ${formFields.conversionValue}, campId: cookieInfo.adCampId, campKeyId: cookieInfo.adKey };
        if(event){
            const orderInfo = {
                event_name: event.name,
                event_data: {
                    totalPrice: checkoutTotalPrice,
                    discountCodesUsed: allDiscountCodes,
                    firstItem: customItemPayload,
                    paymentTransactions: paymentTransactions,
                },
                event_info: event.data
            };
        }
        const payload = { orderInfo: orderInfo, campInfo: campInfo };
        fetch('https://lead.publir.com/publirPrebidAcquisitionTracker', { method: "POST", mode: "no-cors", body: JSON.stringify(payload), credentials: "include", headers: { "Content-Type": "application/json" } }).then(data => { setCookie('_pub_pre_track', '', -1); });
    }</script>`;

    const codeStringPartner = `https://lead.publir.com/publirPrebidAcquisitionTracker?campType=${formFields.conversionType}&campDefaultVal=${formFields.conversionValue}&campId=${campId}`

    const codeText = useMemo(() => {
        if (formFields.mediaPartner === 'shopify'){
            return codeStringDynamic;
        } else if(formFields.mediaPartner === 'everflow') {
            return codeStringPartner+`&Sub1={Sub1}`;
        } else if(formFields.mediaPartner === 'hasoffers') {
            return codeStringPartner+`&source={source}`;
        } else {
            return codeStringPartner;
        }
    }, [formFields.mediaPartner, formFields.conversionType, formFields.conversionValue])

    const onFormFieldChange = (data: { name: string; value: string | number | undefined }) => {
        // const newValue = String(data.target.value);
        const { name, value } = data;
        onChangeFields({
            name: name,
            value: value,
        });
    };
    const customizeHeader = () => {
        return (
            <div className="timePickerHeader">
                <div style={{ paddingInlineStart: '12px' }}>HH</div>
                <div>MM</div>
            </div>
        );
    };
    return (
        <div>
            <p className="flex items-center font-[Roboto-Medium] text-black font-medium text-sm mt-9">Bid details</p>
            <div className=" p-4 mt-3 bg-white rounded-md shadow-md">
                <div>
                    <label className="ml-1 text-xs">
                        Select Campaign type
                        <span className="text-red-500">{'*'}</span>
                    </label>
                    <div className="mt-2">
                        <Radio.Group onChange={onChangeCamType} value={type} name="type">
                            {campaign_types?.map((item, index) => (
                                <Radio key={index} value={item}>
                                    {item}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </div>
                    {isValidate && !type && <span className="common_error">Please Select Campaign type</span>}
                </div>
                {(type === 'CPM' || type === 'CPC') && (
                    <div className="mt-4">
                        <label className="ml-1 text-xs">
                            Rate ($)
                            <span className="text-red-500">{'*'}</span>
                        </label>
                        <div>
                            <PNormalInput
                                type="number"
                                min={0}
                                prefix={<div className="mb-[1px]">$</div>}
                                className="md:rounded md:py-2 md:w-48 text-xs"
                                value={formFields.rate}
                                name="Rate"
                                onChange={(e) => {
                                    setFormFields({
                                        ...formFields,
                                        rate: e.value,
                                    });
                                    setUnsaveChanges(true)
                                }}
                                placeholder="Enter Rate"
                            />
                            {isValidate && (formFields.type === 'CPC' || formFields.type === 'CPM') && (formFields.rate === '' || formFields.rate === undefined) && <div className="common_error">Enter the rate</div>}
                        </div>
                    </div>
                )}
                {type === 'CPA' && (
                    <div className="mt-4">
                        <div className="flex gap-2">
                            <div className="w-[200px]">
                                <label className="ml-1 text-xs">
                                    Conversion Type
                                    <span className="text-red-500">{'*'}</span>
                                </label>
                                <PDropdown
                                    label=""
                                    name="convertion_value"
                                    required
                                    value={formFields.conversionType}
                                    options={[
                                        { label: 'Static', value: 'static' },
                                        { label: 'Dynamic', value: 'dynamic' },
                                    ]}
                                    onChange={(e) => {
                                        setFormFields({
                                            ...formFields,
                                            conversionType: e.value,
                                        });
                                        setUnsaveChanges(true)
                                    }}
                                />
                                {isValidate && (formFields.conversionType === '' || formFields.conversionType === undefined) && <div className="common_error">Select the value</div>}
                            </div>

                            <div className="w-[200px]">
                                <label className="ml-1 text-xs">
                                    Media Partner
                                    <span className="text-red-500">{'*'}</span>
                                </label>
                                <PDropdown
                                    label=""
                                    name="media_partner"
                                    required
                                    value={formFields.mediaPartner}
                                    options={[
                                        { label: 'Shopify', value: 'shopify' },
                                        { label: 'Ever Flow', value: 'everflow' },
                                        { label: 'Has Offers', value: 'hasoffers' },
                                    ]}
                                    onChange={(e) => {
                                        setFormFields({
                                            ...formFields,
                                            mediaPartner: e.value,
                                        });
                                        setUnsaveChanges(true)
                                    }}
                                />
                                {isValidate && (formFields.mediaPartner === '' || formFields.mediaPartner === undefined) && <div className="common_error">Select the value</div>}
                            </div>

                            <div>
                                <label className="ml-1 text-xs">
                                    {formFields?.conversionType === 'dynamic' ? 'Default Value' : 'Value'} ($)
                                    <span className="text-red-500">{'*'}</span>
                                </label>
                                <PNormalInput
                                    type={'number'}
                                    className="h-[32px] md:rounded md:py-2 md:w-48 text-xs"
                                    value={formFields.conversionValue}
                                    prefix={<div className="mb-[1px]">$</div>}
                                    name="publirfee"
                                    min={0}
                                    onChange={(val) => {
                                        setFormFields({ ...formFields, conversionValue: val.value })
                                        setUnsaveChanges(true)
                                    }}
                                    placeholder={`Conversion Value`}
                                />
                                {isValidate && (formFields.conversionValue === '' || formFields.conversionValue === undefined || formFields.conversionValue === 'NaN') && <div className="common_error mt-2">Enter the value.</div>}
                            </div>
                        </div>

                        {/* copy code snipet */}
                        {(formFields.conversionValue && formFields.conversionType && formFields.mediaPartner) ? (
                            <>
                                <div className="text-[12px] font-[Roboto] mt-2 ml-1">Please place this script on the form submission acknowledgement page, or on the purchase confirmation page.</div>
                                <div className="relative bg-[white] shadow-lg border border-[green] mt-2 pb-8   rounded-xl">
                                    <pre className="font-[500] font-[Roboto] text-[15px] pl-5 py-2">
                                        {/* <code>{formFields.conversionType === 'dynamic' ? codeStringDynamic : codeStringStatic}</code> */}
                                        <code>{codeText}</code>
                                    </pre>

                                    <div className="absolute right-2 bottom-1 ">
                                        <CopyToClipboard
                                            text={codeText}
                                            onCopy={() => {
                                                setCopy(true);
                                                setTimeout(() => {
                                                    setCopy(false);
                                                }, 2000);
                                            }}
                                        >
                                            <PButton title={copy ? 'Copied !' : 'Copy'} className="w-[70px] md:rounded-full md:h-[auto] text-xs py-px md:px-2" />
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                )}
                {/* <div className="mt-6">
                    <label className="ml-1 text-xs">Display Creative</label>
                    <div className="mt-2">
                        <Checkbox onChange={onSelectOnlyOne} checked={isCreative}>
                            Only one
                        </Checkbox>
                    </div>
                </div> */}
                <div className="mt-6">
                    <label className="ml-1 text-xs">
                        Duration
                        <span className="text-red-500">{'*'}</span>
                    </label>
                    <div className="mt-2">
                        <Radio.Group onChange={() => {
                            onChangeFields({ name: 'isContinuosly', value: true })
                        }} value={isContinuosly}>
                            <Radio value={true}>Run this campaign continuously</Radio>
                        </Radio.Group>
                    </div>
                </div>
                {isContinuosly === true && (
                    <div className="mt-6 flex">
                        <div>
                            <label className="ml-1 text-xs">Select start date</label>
                            <div className="mt-2">
                                <DatePicker
                                    renderExtraFooter={customizeHeader}
                                    showTime
                                    format="YYYY-MM-DD HH:mm"
                                    onChange={(date: any, dateString: string) => {
                                        onChangeFields({ name: 'durationStart', value: dateString })
                                        setUnsaveChanges(true)
                                    }}
                                    placeholder={isStartImmeditialey ? durationStart : 'Start Date'}
                                    name="durationStart"
                                    disabledDate={(current: any) => {
                                        return current && current < minDate.startOf('day');
                                    }}
                                    disabledTime={(current: any) => {
                                        const currentHour = moment().hour(); // Get current hour
                                        const currentMinute = moment().minute();
                                        const currentDate = moment().date();

                                        if (current?.['$D'] === currentDate) {
                                            return {
                                                disabledHours: () => Array.from({ length: currentHour }, (_, index) => index),
                                                disabledMinutes: () => Array.from({ length: currentMinute }, (_, index) => index),
                                            };
                                        } else {
                                            return {};
                                        }
                                    }}
                                    disabled={isStartImmeditialey}
                                    value={isStartValid && !isStartImmeditialey ? startingDate : undefined}
                                />
                            </div>
                        </div>
                        <div className="ml-7">
                            <label className="ml-1 text-xs"> </label>
                            <div className="mt-2">
                                <Checkbox
                                    onChange={(e: any) => {
                                        onChangeFields({ name: 'isStartImmeditialey', value: e.target.checked });
                                        setUnsaveChanges(true);
                                    }}
                                    checked={isStartImmeditialey}
                                >
                                    Start Immediately
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                )}
                {isValidate && isContinuosly && !isStartImmeditialey && !durationStart && <span className="common_error">Please Select start date or Immediately</span>}
                <div className="mt-6">
                    <div className="mt-2">
                        <Radio.Group onChange={() => {
                            onChangeFields({ name: 'isContinuosly', value: false })
                            setUnsaveChanges(true)
                        }} value={isContinuosly} name="durationEnd">
                            <Radio value={false}>Choose when to end this campaign</Radio>
                        </Radio.Group>
                    </div>
                </div>
                {isContinuosly === false && (
                    <div className="mt-6 flex">
                        <div>
                            <label className="ml-1 text-xs">Select start date</label>
                            <div className="mt-2">
                                <DatePicker
                                    renderExtraFooter={customizeHeader}
                                    showTime
                                    format="YYYY-MM-DD HH:mm"
                                    onChange={(date: any, dateString: string) => onChangeFields({ name: 'durationStart', value: dateString })}
                                    placeholder={'Start Date'}
                                    name="durationStart"
                                    disabledDate={(current) => {
                                        return current && current < minDate.startOf('day');
                                    }}
                                    disabledTime={(current: any) => {
                                        const currentHour = moment().hour(); // Get current hour
                                        const currentMinute = moment().minute();
                                        const currentDate = moment().date();
                                        if (current?.['$D'] === currentDate) {
                                            return {
                                                disabledHours: () => Array.from({ length: currentHour }, (_, index) => index),
                                                disabledMinutes: () => Array.from({ length: currentMinute }, (_, index) => index),
                                            };
                                        } else {
                                            return {};
                                        }
                                    }}
                                    value={isStartValid ? startingDate : undefined}
                                />
                            </div>
                        </div>
                        <div className="ml-7">
                            <label className="ml-1 text-xs">Select End date</label>
                            <div className="mt-2">
                                <DatePicker
                                    renderExtraFooter={customizeHeader}
                                    showTime
                                    format="YYYY-MM-DD HH:mm"
                                    onChange={(date: any, dateString: string) => onChangeFields({ name: 'durationEnd', value: dateString })}
                                    placeholder={'End Date'}
                                    name="durationEnd"
                                    // disabledDate={(current) => {
                                    //     return current && current < startingDate.startOf('day');
                                    // }}
                                    disabledDate={(current) => {
                                        return current.isBefore(startingDate);
                                    }}
                                    value={isEndValid ? endingDate : undefined}
                                    disabled={!durationStart}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {isValidate && !isContinuosly && isContinuosly !== undefined && (!durationEnd || !durationStart) && <span className="common_error">Please Select start date and end date</span>}
                {isValidate && isContinuosly === undefined && <span className="common_error">Please Select duration</span>}

                <AddRunning getDetailOfDaysAndTime={getDetailOfDaysAndTime} daysAndTimeDetails={daysAndTimeDetails} isValidate={isValidate} formFields={formFields} setFormFields={setFormFields} />

                <div className="mt-6">
                    <label className="ml-1 text-xs">Choose Frequency Cap</label>
                    <div className="mt-2">
                        <Radio.Group onChange={onChangeCamType} value={frequencyCap} name="frequencyCap">
                            {frequency_types?.map((item, index) => (
                                <Radio key={index} value={item}>
                                    {item}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </div>
                </div>
                {frequencyCap && frequencyCap !== 'None' && (
                    <div className="mt-6">
                        <label className="ml-1 text-xs">Enter Frequency {frequencyCap ? (frequencyCap === 'Hourly' ? '/Hour' : frequencyCap === 'Daily' ? '/Day' : frequencyCap === 'Monthly' ? '/Month' : frequencyCap === 'Weekly' && '/Week') : ''}</label>
                        <div>
                            <PNormalInput
                                type="number"
                                step="1"
                                onKeyDown={(e) => ['e', 'E', '+', '-', '.', 'ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                                min={1}
                                className="md:rounded md:py-2 md:w-48 text-xs"
                                value={frequencyPerDay}
                                name="frequencyPerDay"
                                onChange={onFormFieldChange}
                                placeholder="Enter Frequency"
                            />
                        </div>
                    </div>
                )}
                {isValidate && frequencyCap !== 'None' && frequencyCap && !frequencyPerDay && <span className="common_error">Please Enter Frequency</span>}
                <div className="mt-6">
                    <label className="ml-1 text-xs">
                        Budget
                        <span className="text-red-500">{'*'}</span>
                    </label>
                    <div className="mt-2">
                        <Radio.Group onChange={onChangeCamType} value={selectedBudget} name="selectedBudget">
                            {budget_types?.map((item, index) => (
                                <Radio key={index} value={item}>
                                    {item}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </div>
                    {isValidate && !selectedBudget && <span className="common_error">Please select budget</span>}
                </div>
                {selectedBudget && (
                    <div className="mt-6">
                        <label className="ml-1 text-xs">Enter Budget ($)/{selectedBudget}</label>
                        <div>
                            <PNormalInput
                                type={'number'}
                                className="md:rounded md:py-2 md:w-48 text-xs"
                                value={enteredBudget}
                                name="enteredBudget"
                                onChange={onFormFieldChange}
                                min={0}
                                onBlur={(e: any) => {
                                    const { value } = e.target;
                                    const numericValue = typeof value === 'string' ? parseFloat(value).toFixed(2) : parseFloat(value).toFixed(2) || 1;

                                    onFormFieldChange({ name: 'enteredBudget', value: numericValue });
                                }}
                                placeholder={`Enter Budget {$}/${selectedBudget}`}
                            />
                        </div>
                        {selectedBudget && !enteredBudget && <span className="common_error">Please Enter Budget</span>}
                        <div className="mt-4">
                            <label className="ml-1  text-xs">Publir Fee</label>
                        </div>
                        <div>
                            <PNormalInput
                                type={'number'}
                                className=" md:rounded md:py-2 md:w-48 text-xs"
                                value={publirFee}
                                suffix={<div className="mb-[1px]">%</div>}
                                name="publirfee"
                                min={0}
                                max={100}
                                onChange={(val) => onFormFieldChange({ name: 'publirFee', value: val.value })}
                                onBlur={(e: any) => {
                                    if (e.target.value > 100) {
                                        onFormFieldChange({ name: 'publirFee', value: 100 });
                                    }
                                }}
                                placeholder={`Publir Fee`}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default PBidDetails;
