
import React from 'react'
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { LocationItem } from '../main/prebidAdapter/campaign/components/Loaction';
interface collapseAction {
     handleToggle: (...args: any[]) => void;
     title: string | React.ReactNode;
     showHide: boolean;
     className?: string;
     hideAction?: boolean;
     titleClassName?: string;
     label: string;
     handleGetExclude: (...args: any[]) => void;
     handleGetInclude: (...args: any[]) => void;
     type: string;
     listOfArray?: any;
     disable?: any;
     arrayLength?: number;
     isHideButton?: boolean;
     locationData: { exclude: LocationItem[]; include: LocationItem[] };
}
const CollapsibleAction: React.FC<collapseAction> = ({ handleToggle, title, showHide, titleClassName, hideAction, handleGetExclude, handleGetInclude, label, type, listOfArray, disable, arrayLength, isHideButton, locationData }) => {
     const isAvailable = (buttonType: string) => {
          if (type === 'country') {
               return isLocationDataAvailable(disable[buttonType], label, label, label, arrayLength, buttonType);
          }
          return ['city', 'state'].includes(type) && isLocationDataAvailable(disable[buttonType], listOfArray.country, listOfArray.state, label, arrayLength, buttonType);
     };

     const isLocationDataAvailable = (locationExIncData: any, country: string, state: string, city: string, arrLength?: number, option?: any): boolean => {
          let arr = option === 'include' ? locationData?.include : locationData?.exclude;
          for (let i = 0; i < arr.length; i++) {
               if (arr[i]?.country === country && arr[i]?.state === state && arr[i]?.city === city) {
                    return true
               }
          }

          return false; // City not found, enable logic here
     };

     return (
          <div className={`flex justify-between items-center w-[100%]`} onClick={handleToggle}>
               <span className={titleClassName}>{title}</span>{' '}
               <div className="flex gap-2 justify-end items-center  font-[Roboto-Medium]">
                    <span
                         className={`${isAvailable('include') ? 'text-stone-400 cursor-not-allowed' : 'text-[#056433] cursor-pointer'} text-[0.75rem]`}
                         onClick={(e) => {
                              e.stopPropagation();
                              if (!isAvailable('include')) {
                                   handleGetInclude('include', label, type, listOfArray);
                              }
                         }}
                    >
                         Include
                    </span>
                    <span
                         className={`${isAvailable('exclude') ? 'text-stone-400 cursor-not-allowed' : 'text-[#f43838] cursor-pointer'} text-[0.75rem]`}
                         onClick={(e) => {
                              e.stopPropagation();
                              if (!isAvailable('exclude')) {
                                   handleGetExclude('exclude', label, type, listOfArray);
                              }
                         }}
                    >
                         Exclude
                    </span>

                    {hideAction ? <IoMdArrowDropup className="invisible" /> : showHide ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
               </div>
          </div>
     )
}

export default CollapsibleAction
