import OpenAI from 'openai';
import React, { useState } from 'react';
import PButton from '../../../../common/Button';
import PNormalInput from '../../../../common/NormalInput';
import PNormalTextArea from '../../../../common/NormalTextArea';
import Checkbox from 'antd/es/checkbox/Checkbox';
import PModal from '../../../../common/Modal';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import TextEditModal from './TextEditModal';
import { isValidUrl } from '../../../../../utils/Validation';

type Props = {
    onSubmitText: (name: string, body: string, url: string) => void;
    selectedEditData: any;
    closeModalOnSubmitText?: any;
    adSize?: any;
    setUnsaveChanges:any
};
interface addImage {
    name?: string;
    isValidate?: boolean;
    body?: any;
    url?: string;
    typeOfText?: boolean;
    responseList?: { finish_reason: string; index: number; message: any }[];
    selectedValue?: string;
    isValid?: boolean;
}

const PAddTextModalView: React.FC<Props> = ({ onSubmitText, selectedEditData, closeModalOnSubmitText, adSize, setUnsaveChanges }) => {
    const [addTextForm, setAddTextForm] = useState<addImage>({
        name: selectedEditData?.name ? selectedEditData?.name : undefined,
        isValidate: false,
        body: selectedEditData?.text ? selectedEditData?.text : undefined,
        url: selectedEditData?.destination_url ? selectedEditData?.destination_url : undefined,
        typeOfText: false,
        responseList: [],
        selectedValue: '',
        isValid: true,
    });
    const [loader, setLoader] = useState(false);
    const [aiList, setAiList] = useState<{ name?: string, body?: string, url?: string, id: any, content: any }[]>([]);
    const [openAiListModal, setOpenAiListModal] = useState(false);
    const [isValidateTextCards, setIsValidateTextCards] = useState(false);

    const onChangeFields = (data: { name: string; value: string | number | undefined }) => {
        const { name, value } = data;
        if (name === 'url' && value) {
            setAddTextForm({ ...addTextForm, [name]: value.toString(), isValid: isValidUrl(value.toString()) });
        } else {
            setAddTextForm({ ...addTextForm, [name]: value, responseList: [] });
        }
    };

    const onClickSubmit = async () => {
        if (addTextForm.typeOfText) {
            if (!addTextForm.responseList) {
                setAddTextForm({ ...addTextForm, isValidate: true });
                if (!addTextForm.name || addTextForm.name.trim() === '') {
                    return;
                }
            }
            try {
                setLoader(true);
                const openai = new OpenAI({ apiKey: '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', organization: 'org-HF8ZjLmN98kuQpcqgKIE5NBL', dangerouslyAllowBrowser: true });
                const result = await openai.chat.completions.create({
                    model: 'gpt-4', // Specify the model you want to use
                    //messages: [{ role: 'user', content: `Generate text (Max characters 100) and body (Max characters 300) for ${addTextForm.name}` }],
                    messages: [{ role: 'user', content: `Generate title and body for ads on ${addTextForm.name} with ads size of dimension ${adSize?.size} px with keywords Title and Body without adding nextline "\n"` }],
                    max_tokens: 60,
                    n: 2,
                    // response_format: { type: "json_object" },
                });
                setLoader(false);
                let dataList = addTextForm.responseList;
                // Map the Choice[] array to match the expected structure
                const formattedChoices = result?.choices.map((choice) => ({
                    finish_reason: choice.finish_reason,
                    index: choice.index,
                    message: choice.message,
                }));
                dataList?.push(...formattedChoices);
                setAddTextForm({ ...addTextForm, responseList: dataList });
            } catch (error: any) {
                console.log('Error interacting with ChatGPT:', error);
                setLoader(false);
            }
        } else {
            setAddTextForm({ ...addTextForm, isValidate: true });
            if (!addTextForm.name || addTextForm.name.trim() === '' || !addTextForm.body || addTextForm.body.trim() === '' || !addTextForm.url || addTextForm.url.trim() === '' || !addTextForm.isValid) {
                return;
            }
            setAddTextForm({ ...addTextForm, isValidate: false });
            onSubmitText(addTextForm.name, addTextForm.body, addTextForm.url);
            closeModalOnSubmitText();
            setUnsaveChanges(true)
        }
    };

    const onClickProceed = () => {
        setAddTextForm({ ...addTextForm, isValidate: true });
        if (aiList.length === 0) {
            return;
        }
        let arr = [...aiList];
        arr.forEach((item) => {
            const textAndBody = getMessage(item?.content);
            const titleData = (textAndBody && textAndBody[0]?.split(':')[1]?.trim()) || (textAndBody && textAndBody[0]);
            const bodyData = (textAndBody && textAndBody[1]?.split(':')[1]?.trim()) || (textAndBody && textAndBody[1]);

            item.name = titleData;
            item.body = bodyData;
        })
        setAiList(arr);
        setOpenAiListModal(true)
    };

    const changeTypeOfText = () => {
        setAddTextForm({
            ...addTextForm,
            typeOfText: !addTextForm.typeOfText,
            name: undefined,
            isValidate: false,
            body: undefined,
            url: undefined,
        });
        setAiList([]);
    };

    const getMessage = (msg: string) => {
        // Parse HTML to extract the message content
        const parser = new DOMParser();
        const doc = parser.parseFromString(msg, 'text/html');
        const textContent = doc.body.textContent;

        const paragraphs = textContent?.split(/\n+/);
        return paragraphs;
    };

    const addRemoveAiList = (item: any, index: number) => {
        const id = aiList.findIndex(el => el.id === index);
        if (id === -1) {
            setAiList([...aiList, { id: index, content: item.message.content }]);
        } else {
            const dumArr = [...aiList]
            dumArr.splice(id, 1);
            setAiList(dumArr)
        }
    }

    const parseAiGenerateTextArray = () => {
        for (let i = 0; i < aiList.length; i++) {
            if (!aiList[i]?.body || aiList[i]?.body?.trim() === "" || !aiList[i]?.url || aiList[i]?.url?.trim() === "" || !aiList[i]?.name || aiList[i]?.name?.trim() === "") {
                return true;
            }
        }
        return false;
    }
    const onSubmitAiGeneratedText = () => {
        //check for error messages
        setIsValidateTextCards(true)
        if (parseAiGenerateTextArray()) {
            return;
        }
        setIsValidateTextCards(false)

        //map into main courosal
        aiList?.forEach((item: any) => {
            onSubmitText(item?.name, item?.body, item?.url);
        });

        setOpenAiListModal(false);
        closeModalOnSubmitText();
        setUnsaveChanges(true);
    }

    const customizeHeaderForAddImage = (
        <div className="flex justify-between w-full">
            <div>
                <p className="flex items-center font-[Roboto-Medium] text-black font-bold text-xl">
                    Preview Text
                </p>
            </div>
            <div className="flex">
                <AiOutlineCloseCircle size={'1.75rem'} className="cursor-pointer" onClick={() => setOpenAiListModal(false)} />
            </div>
        </div>
    );

    const ViewOfAiListModal = (
        <div className='w-full h-[500px] flex flex-col justify-between'>
            <div>
                {
                    aiList?.map((item: any) => {
                        return (
                            <div className='overflow-auto '>
                                <div className='flex justify-between mt-2 p-2 min-h-[50px] bg-[#b4d0c1] border rounded-md'>
                                    <div className='font-bold font-[Montserrat]'>
                                        <div>Prompt : {item?.name}</div>
                                        <div>url : {item?.url}</div>
                                    </div>
                                    <div className=''>
                                        <TextEditModal item={item} aiList={aiList} setAiList={setAiList} />
                                    </div>
                                </div>
                                {isValidateTextCards && (!item?.name || !item?.url || !item?.body) && <span className="common_error">Required fields are empty!</span>}

                            </div>
                        )
                    })
                }
            </div>
            <div>
                <PButton onClick={onSubmitAiGeneratedText} title="Submit" className="md:rounded-full mt-5 md:h-[auto] text-xs py-px md:px-5 w-32" />
            </div>
        </div>
    )

    return (
        <>
            <div className={`w-full container-ad-text ${!addTextForm.typeOfText || (addTextForm.responseList && addTextForm.responseList.length > 0) ? 'expanded' : ''}`}>
                <div>
                    <div className="flex justify-end">
                        {addTextForm.typeOfText ? (
                            <label onClick={changeTypeOfText} className="ml-1 text-sm cursor-pointer font-bold">
                                Manually generate ad text
                            </label>
                        ) : (
                            <label onClick={changeTypeOfText} className="ml-1 text-sm cursor-pointer font-bold">
                                Use AI to generate ad text
                            </label>
                        )}
                    </div>
                    <div className="mt-2">

                        {
                            addTextForm.typeOfText ?
                                (
                                    <PNormalTextArea
                                        isRequired
                                        className="md:rounded md:py-2 text-xs"
                                        onChange={onChangeFields}
                                        rows={3}
                                        value={addTextForm.name}
                                        name="name"
                                        title="Prompt"
                                        placeholder="Enter Prompt"
                                    />

                                )
                                :
                                (
                                    <PNormalInput
                                        isRequired
                                        className="md:rounded md:py-2 text-xs"
                                        onChange={onChangeFields}
                                        value={addTextForm.name}
                                        name="name"
                                        title={'Title'}
                                        placeholder={'Enter Title'}
                                    />
                                )
                        }
                        {addTextForm.isValidate && !addTextForm.name && <span className="common_error">Please Enter title</span>}
                    </div>

                    {addTextForm.typeOfText && addTextForm.responseList && addTextForm.responseList.length > 0 && (
                        <div>
                            <div className="mt-4">
                                <div>
                                    {addTextForm.responseList?.map((item, index) => (
                                        <div key={index} className="mb-4 md:h-32 w-full leading-5 overflow-auto border rounded-md"
                                        >
                                            <div className="flex">
                                                <div>
                                                    <Checkbox
                                                        style={{ fontWeight: 'bold' }}
                                                        className="customCheckBox m-2"
                                                        onChange={(e) => addRemoveAiList(item, index)}
                                                    ></Checkbox>
                                                </div>
                                                <div className="whitespace-pre-line mt-2" dangerouslySetInnerHTML={{ __html: item.message.content }} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {addTextForm.responseList.length < 6 && (
                                    <p className=" md:text-[#056433] flex items-center justify-around ">
                                        <PButton loading={loader} onClick={onClickSubmit} title="Show more" className="custom-hover-color md:py-0.5 md:rounded-full  md:h-[auto] text-xs md:px-5 md:bg-[unset] md:text-[unset] md:border-[0]" />
                                    </p>
                                )}
                                {addTextForm.isValidate && aiList?.length === 0 && <span className="common_error">Please select one option</span>}
                            </div>
                            <PButton onClick={onClickProceed} title="Proceed" className="md:rounded-full mt-5 md:h-[auto] text-xs py-px md:px-5 w-32" />
                        </div>
                    )}

                    {!addTextForm.typeOfText && (
                        <div>
                            <div className="mt-4">
                                <PNormalTextArea isRequired className="md:rounded md:py-2 text-xs" onChange={onChangeFields} rows={3} value={addTextForm.body} name="body" title="Body" placeholder="Enter Body" />
                                {addTextForm.isValidate && !addTextForm.body && <span className="common_error">Please Enter Body</span>}
                            </div>
                            <div className="mt-4">
                                <PNormalInput isRequired className="md:rounded md:py-2 text-xs" onChange={onChangeFields} value={addTextForm.url} name="url" title="URL" detail={' (Url should be start with http:// or https://)'} placeholder="Enter URL" />
                                {addTextForm.isValidate && (!addTextForm.url || !addTextForm.isValid) && <span className="common_error">Please Enter valid url</span>}
                            </div>
                        </div>
                    )}
                    {!addTextForm.responseList || (addTextForm.responseList.length === 0 && <PButton loading={loader} onClick={onClickSubmit} title="Submit" className="md:rounded-full mt-5 md:h-[auto] text-xs py-px md:px-5 w-32" />)}
                </div>
            </div>
            {openAiListModal && <PModal width={800} ViewOfModal={ViewOfAiListModal} isModalOpen={openAiListModal} OnClickOfCancel={() => setOpenAiListModal(false)} customizeHeader={customizeHeaderForAddImage} />}

        </>
    );
};

export default PAddTextModalView;
