import { useNavigate } from "react-router-dom";
import PTable from "../../../common/Table";
import { Pagination } from "antd";
import { useEffect, useState } from "react";
import ManagementActions from "./components/managementActions";
import PDropdown from "../../../common/Dropdown";
import UserManagementActions from "./redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/RootReducer";
import BackDrop from "../../../common/BackDrop";
import Apis from "../../../../api";
import MessageActions from "../../../message/redux/actions";
import AddNewUser from "./components/addNewUser";
import PTabs from "../../../common/Tabs";

const UserManagement = () => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const [pageNo, setPageNo] = useState(1);
     const [pageSize, setPageSize] = useState(10);
     const [searchText, setSearchText] = useState("");
     const loading = useSelector((state: RootState) => state.userManagement.userListDataLoading)
     const userList = useSelector((state: RootState) => state.userManagement.userListData)
     const userListCount = useSelector((state: RootState) => state.userManagement.userListTotal)
     const [statusRoleLoading, setStatusRoleLoading] = useState(false);
     const [activeTab, setActiveTab] = useState('Active')
     const fetchUsersList = () => {
          dispatch(UserManagementActions.fetchUserManagementUserList(pageSize, pageNo, activeTab));
     }

     const columns = [
          {
               dataIndex: 'full_name',
               title: 'User Name',
               width: '',
               fixed: 'left',
               sorter: (a: any, b: any) => String(a.full_name).localeCompare(String(b.full_name)),
          },
          {
               dataIndex: 'email',
               title: 'Email',
               width: '',
               fixed: 'left',
               sorter: (a: any, b: any) => String(a.email).localeCompare(String(b.email)),
          },
          {
               title: 'Role',
               dataIndex: 'publisher_type',
               width: 200,
               fixed: '',
               render: (text: string, row: any) => (
                    <PDropdown
                         options={['Customer', 'Admin'].map((r) => {
                              return { label: r, value: r.toLowerCase() };
                         })}
                         name="status"
                         onChange={(d) => {
                              setStatusRoleLoading(true);
                              const payload = {
                                   user_id: row._id,
                                   role: d.value
                              }
                              Apis.updateUserManagementUserRole(payload).then((res) => {
                                   let list = [...userList];
                                   const index = list.findIndex((c) => c._id === row._id);
                                   if (index !== -1) {
                                        list[index].publisher_type = String(d.value);
                                   }
                                   dispatch(UserManagementActions.setUserManagementUserList({ data: list, count: userListCount }));
                                   dispatch(MessageActions.showMessage({ text: 'Role updated successfully!', error: false }));
                              }).catch((err) => {
                                   dispatch(MessageActions.showMessage({ text: 'error', error: true }));
                              }).finally(() => {
                                   setStatusRoleLoading(false);
                              })
                         }}
                         label=""
                         required
                         value={text}
                    />
               ),
          },
          {
               title: 'Status',
               dataIndex: 'status',
               width: 200,
               fixed: '',
               render: (text: string, row: any) => (
                    <PDropdown
                         options={['Active', 'In-Active'].map((r) => {
                              return { label: r, value: r };
                         })}
                         name="status"
                         onChange={(d) => {
                              setStatusRoleLoading(true);
                              const payload = {
                                   user_id: row._id,
                                   status: d.value
                              }
                              Apis.updateUserManagementUserStatus(payload).then((res) => {
                                   let list = [...userList];
                                   const index = list.findIndex((c) => c._id === row._id);
                                   if (index !== -1) {
                                        list[index].status = String(d.value);
                                   }
                                   dispatch(UserManagementActions.setUserManagementUserList({ data: list, count: userListCount }));
                                   dispatch(MessageActions.showMessage({ text: 'Status updated successfully!', error: false }));
                              }).catch((err) => {
                                   dispatch(MessageActions.showMessage({ text: 'error', error: true }));
                              }).finally(() => {
                                   setStatusRoleLoading(false);
                              })
                         }}
                         label=""
                         required
                         value={text}
                    />
               ),
          },
          {
               dataIndex: 'action',
               title: 'Action',
               fixed: 'right',
               width: '100px',
               render: (text: any, row: any) => (
                    <div className="flex items-center justify-center gap-3">
                         <ManagementActions id={row._id}
                              fetchUsersList={fetchUsersList}
                              campList={row.campaigns}
                              name={row.full_name}
                              email={row.email}
                              searchText={searchText}
                              setSearchText={setSearchText}
                              row={row}
                         />
                    </div>
               ),
          },
     ]

     useEffect(() => {
          fetchUsersList();
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [pageNo, pageSize, activeTab])

     useEffect(() => {
          const timer = setTimeout(() => {
               dispatch(UserManagementActions.fetchAllAssignCampList(searchText));
          }, 900);
          return () => clearTimeout(timer);
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [searchText])


     return (
          <div>
               {statusRoleLoading && <BackDrop />}
               <div className="flex justify-between">
                    <div className='flex items-center gap-2'>
                         <div className="bg-[#C2D9CD] w-[50px] flex justify-center items-center text-[14px] font-[Roboto] font-[500] py-1 px-3 rounded-lg cursor-pointer" onClick={() => navigate(-1)}>
                              Back
                         </div>
                         <div className='text-[24px] font-[Roboto] font-[500]'>Users</div>
                    </div>
                    <div><AddNewUser fetchUsersList={fetchUsersList} /></div>
               </div>
               <div className="mt-5">
                    <div className="mb-5">
                         <PTabs activeTab={activeTab} setActiveTab={(e) => setActiveTab(e)}
                              tabs={[
                                   { key: 'All', title: 'All' },
                                   { key: 'Active', title: 'Active' },
                              ]}
                         />
                    </div>
                    <PTable columns={columns} data={userList || []} pagination={{ isShow: false }} loading={loading} />
                    <div className='flex justify-end py-2'>
                         <Pagination
                              current={pageNo}
                              total={userListCount}
                              onChange={(e) => {
                                   setPageNo(e);
                              }}
                              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
                              showSizeChanger={true}
                              onShowSizeChange={(e, i) => {
                                   setPageSize(i);
                              }}
                              pageSizeOptions={[10, 20, 50, 100]}
                         />
                    </div>
               </div>
          </div>
     )
}

export default UserManagement;

