// ImageUploadForm.tsx
import React, { useEffect, useState } from 'react';
import { PiImageSquareThin } from 'react-icons/pi';

interface ImageUploadFormProps {
    onImageUpload: (file: File) => void;
    selectedImgOfAi: string | undefined;
}

const ImageUploadForm: React.FC<ImageUploadFormProps> = ({ onImageUpload , selectedImgOfAi}) => {
    // const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(selectedImgOfAi ? selectedImgOfAi : null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            // setSelectedFile(file);
            // Create a URL for the image preview
            const previewURL = URL.createObjectURL(file);
            setImagePreview(previewURL);
            onImageUpload(file);
        }
    };



    const handlePreviewClick = () => {
        // Trigger file input click
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // const handleFormSubmit = (event: React.FormEvent) => {
    //     event.preventDefault();
    //     if (selectedFile) {
    //         onImageUpload(selectedFile);
    //     }
    // };

    const fileInputRef = React.createRef<HTMLInputElement>();

    const checkMediaFile = (item: File | string) => {
        if (typeof item === 'string') {
            return item;
        } else {
            return URL.createObjectURL(item);
        }
    };


    useEffect(()=>{
        if(selectedImgOfAi){
            setImagePreview(selectedImgOfAi)
        }
    },[selectedImgOfAi])

    return (
        <div className="flex items-center flex-col">
            <div style={{ position: 'relative' }}>
                <input type="file" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} ref={fileInputRef} />
                {imagePreview ? (
                    <div className='flex flex-col justify-center'>
                        <img className=' object-contain' src={checkMediaFile(imagePreview)} alt="Selected Preview" style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px', cursor: 'pointer' }} onClick={handlePreviewClick} />
                        <p style={{ cursor: 'pointer', textAlign: 'center' }} onClick={handlePreviewClick}>
                            Click to change
                        </p>
                    </div>
                ) : (
                    <PiImageSquareThin onClick={handlePreviewClick} size={'8rem'} className="cursor-pointer opacity-20" />
                )}
            </div>
            <button onClick={handlePreviewClick} className="md:rounded-full md:bg-[#9c9c9c] md:text-[#fff] mt-4 md:h-[auto] text-sm py-px md:px-5 w-32">
                Upload Image
            </button>
        </div>
    );
};

export default ImageUploadForm;
