import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import DateFilter from "../../../common/DateFilter";
import PDropdown from "../../../common/Dropdown";
import UserActivitiesTable from "./components/userActivitiesTable";
import { AllUsersUserActivity, UserActivitiesFilterState } from "./redux/type";
import UserManagementActions from "../user manamgement/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/RootReducer";
import UserActivitiesActions from "./redux/actions";
export const API_date_format = 'YYYY-MM-DD';

const UserActivities: React.FC = () => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const [applyDate, setApplyDate] = useState('last7Day');
     const [customEndDate, setCustomEndDate] = useState<string | undefined>(undefined);
     const [customStartDate, setCustomStartDate] = useState<string | undefined>(undefined);
     const [compare, setCompare] = useState(false);
     const [dstring2, setDatestring2] = useState({ sdate: '', edate: '' });
     const allCampaignList = useSelector((state: RootState) => state.userManagement.allAssignCampList);
     const allUsersList = useSelector((state: RootState) => state.userActivity.allUsersUserActivityListData);
     const [userActivitiesFilters, setUserActivitiesFilter] = useState<UserActivitiesFilterState>({
          searchText: '',
          module: '',
          email: '',
          campaign: ''
     })
     const endDate = useMemo(() => {
          switch (applyDate) {
               case 'last1Day':
               case 'last7Day':
               case 'last30Day':
                    return moment().startOf('day').subtract(1, 'day').format(API_date_format);
               case 'today':
                    return moment().format(API_date_format);
               default:
                    return customEndDate;
          }
     }, [applyDate, customEndDate]);

     const startDate = useMemo(() => {
          switch (applyDate) {
               case 'last1Day':
                    return moment().startOf('day').subtract(1, 'day').format(API_date_format);
               case 'last7Day':
                    return moment().subtract(7, 'day').format(API_date_format);
               case 'last30Day':
                    return moment().subtract(30, 'day').format(API_date_format);
               case 'today':
                    return moment().format(API_date_format);
               default:
                    return customStartDate;
          }
     }, [applyDate, customStartDate]);

     //fetching campaign list
     //fetching users list
     useEffect(() => {
          dispatch(UserManagementActions.fetchAllAssignCampList(''));
          dispatch(UserActivitiesActions.fetchAllUsersUserActivitiesList());
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [])

     return (
          <div>
               <div className="flex justify-between">
                    <div className='flex items-center gap-2'>
                         <div className="bg-[#C2D9CD] w-[50px] flex justify-center items-center text-[14px] font-[Roboto] font-[500] py-1 px-3 rounded-lg cursor-pointer" onClick={() => navigate(-1)}>
                              Back
                         </div>
                         <div className='text-[24px] font-[Roboto] font-[500]'>User Activities</div>
                    </div>
                    <div className={applyDate === 'last1Day' ? "relative flex w-[300px] justify-end h-[45px]" : "relative flex w-[430px] justify-end h-[45px]"}>
                         <DateFilter
                              applyDate={applyDate}
                              setApplyDate={setApplyDate}
                              setCustomEndDate={setCustomEndDate}
                              setCustomStartDate={setCustomStartDate}
                              setDatestring2={setDatestring2}
                              dstring2={dstring2}
                              compare={compare}
                              setCompare={setCompare}
                              showCompare={false}
                              showCompareToDatePicker={false}
                         />
                    </div>

               </div>
               <div className="flex justify-between items-end">
                    <div className="flex gap-5">
                         <div className="w-[200px]">
                              <label className="ml-1 text-xs">
                                   Campaign
                              </label>
                              <PDropdown
                                   label=""
                                   name="campaign"
                                   required
                                   value={userActivitiesFilters.campaign}
                                   options={
                                        allCampaignList?.map((item) => {
                                             return { label: item?.name, value: item?.name };
                                        })
                                   }
                                   onChange={(e) => setUserActivitiesFilter({ ...userActivitiesFilters, campaign: e.value })}
                              />
                         </div>
                         <div className="w-[200px]">
                              <label className="ml-1 text-xs">
                                   User Email
                              </label>
                              <PDropdown
                                   label=""
                                   name="users"
                                   required
                                   value={userActivitiesFilters.email}
                                   options={
                                        allUsersList?.map((item: AllUsersUserActivity) => {
                                             return { label: item?.email, value: item?.email };
                                        })
                                   }
                                   onChange={(e) => setUserActivitiesFilter({ ...userActivitiesFilters, email: e.value })}
                              />
                         </div>
                         <div className="w-[200px]">
                              <label className="ml-1 text-xs">
                                   Module
                              </label>
                              <PDropdown
                                   label=""
                                   name="module"
                                   required
                                   placeholder="Select"
                                   value={userActivitiesFilters.module}
                                   options={[
                                        { label: 'Campaign', value: 'Campaign' },
                                        { label: 'User', value: 'User' },
                                   ]}
                                   onChange={(e) => setUserActivitiesFilter({ ...userActivitiesFilters, module: e.value })}
                              />
                         </div>
                    </div>
                    {/* <div className="flex gap-4 justify-end">
                         <PNormalInput
                              prefix={<CiSearch />}
                              value={userActivitiesFilters.searchText}
                              name="searchText"
                              className="md:py-1 md:w-80"
                              onChange={(e: any) => setUserActivitiesFilter({ ...userActivitiesFilters, searchText: e.value })}
                              placeholder="Search "
                              suffix={
                                   <MdClose className='cursor-pointer' onClick={() => setUserActivitiesFilter({ ...userActivitiesFilters, searchText: "" })} />
                              }
                         />
                    </div> */}
               </div>

               <div>
                    <UserActivitiesTable userActivitiesFilters={userActivitiesFilters} startDate={startDate} endDate={endDate} />
               </div>
          </div>
     )
}

export default UserActivities;
