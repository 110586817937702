import { Checkbox } from "antd";
import PButton from "../../../../common/Button";
import PModal from "../../../../common/Modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import PTable from "../../../../common/Table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/RootReducer";
import Apis from "../../../../../api";
import MessageActions from "../../../../message/redux/actions";
import PNormalInput from "../../../../common/NormalInput";
import { CiSearch } from "react-icons/ci";
import { MdClose } from "react-icons/md";
import { ManageActionState } from "../redux/type";

type Props = {
     open: boolean;
     setActionState: (value:ManageActionState)=>void;
     closeModal: any;
     title: string;
     id: string | number;
     campList: any[];
     fetchUsersList: any;
     name: string;
     email: string;
     searchText: string;
     setSearchText: any;
     createCampaign?: boolean;
     row: any;
}

type AssignData = {
     id: number | string;
     name: string;
     view: boolean;
     create: boolean;
}

const AssignModal: React.FC<Props> = ({ open, setActionState, closeModal, title, id, campList, fetchUsersList, name, email, searchText, setSearchText, createCampaign, row }) => {
     const dispatch = useDispatch();
     const [data, setData] = useState<AssignData[]>(campList || [])
     const [createCampaignCheckbox, setCreateCampaignChekbox] = useState(row?.create_campaign ? row?.create_campaign : false)
     const allCampList = useSelector((state: RootState) => state.userManagement.allAssignCampList);
     const allCampListLoader = useSelector((state: RootState) => state.userManagement.allAssignCampListLoader);
     const [assignLoader, setAssignLoader] = useState(false);

     const handleCloseModal = () => {
          setData([...campList])
          setSearchText("");
          closeModal();
     }


     const handleRemoveData = () => {
          const filterdData = data?.filter((item: AssignData) => {
               if (item.create === true || item.view === true) {
                    return item;
               }
               return null;
          })
          setData(filterdData);
     }

     const isCampCheck = (id: number | string, permission?: string) => {
          const index = data?.findIndex((item: AssignData) => id === item.id);
          if (permission === 'view' && index >= 0) {
               return data?.[index]?.view;
          } else if (permission === 'create' && index >= 0) {
               return data?.[index]?.create;
          }
          return false;
     }

     const handleAddRemoveCamp = (checked: boolean, id: number | string, permission: string, name: string) => {
          let prevData = [...data];
          const index = data?.findIndex((item: AssignData) => item.id === id);
          if (checked) {
               if (index >= 0) {
                    permission === "create" ? prevData[index].create = true : prevData[index].view = true;
               } else {
                    const newData: AssignData = {
                         id: id,
                         name: name,
                         create: permission === "create" ? true : false,
                         view: permission === "view" ? true : false,
                    }
                    prevData.push(newData);
               }

               setData(prevData);
          } else {
               if (index >= 0) {
                    permission === "create" ? prevData[index].create = false : prevData[index].view = false;
                    setData(prevData);
               }
               handleRemoveData();
          }
     }

     const handleAssignData = () => {
          const payload = {
               user_id: id,
               create_campaign: createCampaignCheckbox,
               data: data
          }
          setAssignLoader(true);
          Apis.assignCampUserManagement(payload).then((res) => {
               dispatch(MessageActions.showMessage({ text: String(res.data?.data?.message), error: false }));
               fetchUsersList();
          }).catch((err) => {
               dispatch(MessageActions.showMessage({ text: String(err?.response?.data?.data?.message), error: true }));
          }).finally(() => {
               setAssignLoader(false);
               setActionState({ assignModal: false,updateModal:false, deleteModal: false, type: '' })
          })
     }


     const columns = [
          {
               dataIndex: 'name',
               title: 'Campaign Name',
               render: (text: any, row: any) => <div className="flex items-center justify-start ">{text}</div>,
          },
          {
               dataIndex: 'permissions',
               title: 'Permissions',
               width: 350,
               render: (text: any, row: any) => (
                    <div className="flex gap-4 items-center justify-center">
                         <div className="flex gap-2">
                              <Checkbox
                                   style={{ fontWeight: 'bold' }}
                                   className="customCheckBox2"
                                   onChange={(e) => { handleAddRemoveCamp(e.target.checked, row._id, 'view', row.name) }}
                                   checked={isCampCheck(row._id, 'view')}
                              ></Checkbox>
                              <div>View Report</div>
                         </div>
                         <div className="flex gap-2">
                              <Checkbox
                                   style={{ fontWeight: 'bold' }}
                                   className="customCheckBox2"
                                   onChange={(e) => { handleAddRemoveCamp(e.target.checked, row._id, 'create', row.name) }}
                                   checked={isCampCheck(row._id, 'create')}
                              ></Checkbox>
                              <div>Update Campaign</div>
                         </div>
                    </div>
               ),
          },

     ]

     const modalView = (
          <div className="">
               <div className="flex justify-between  pb-2">
                    <div className="font-bold text-[22px]">{title}</div>
                    <div className="flex gap-2 items-center">
                         <PButton title="Assign" className=" md:rounded-full w-[100px] text-s flex justify-center items-center py-2" loading={assignLoader} onClick={() => handleAssignData()} />
                         <AiOutlineCloseCircle size={28} className="cursor-pointer" onClick={() => {
                              setData([...campList]);
                              setSearchText("");
                              setActionState({ assignModal: false, updateModal: false, deleteModal: false, type: '' });
                         }} />
                    </div>

               </div>
               <div className="flex justify-between items-center">
                    <div className="pl-2 font-[Roboto]">
                         <div className="flex gap-[25px]">
                              <div>Name : <span>{name}</span></div>
                              <div className="flex gap-2">
                                   <Checkbox
                                        style={{ fontWeight: 'bold' }}
                                        className="customCheckBox2"
                                        onChange={(e) => setCreateCampaignChekbox(e.target.checked)}
                                        checked={createCampaignCheckbox}
                                   ></Checkbox>
                                   <div>Create Campaign</div>
                              </div>
                         </div>
                         <div>Email : <span>{email}</span></div>
                    </div>
                    <div className="flex gap-4 justify-end pb-4 ">
                         <PNormalInput
                              prefix={<CiSearch />}
                              value={searchText}
                              name="searchText"
                              className="md:py-1 md:w-80"
                              onChange={(e: any) => setSearchText(e.value)}
                              placeholder="Search for Ad campaigns"
                              suffix={
                                   <MdClose className='cursor-pointer' onClick={() => setSearchText("")} />
                              }
                         />
                    </div>
               </div>
               <div className="max-h-[550px] overflow-auto">
                    <PTable className="" columns={columns} data={allCampList || []} pagination={{ isShow: true }}  loading={allCampListLoader}/>
               </div>
          </div>
     )

     useEffect(() => {
          if (createCampaign) {
               setCreateCampaignChekbox(createCampaign)
          }

     }, [createCampaign])

     return (
          <PModal
               isModalOpen={open}
               OnClickOfCancel={handleCloseModal}
               width="850px"
               className=""
               ViewOfModal={modalView}
          />
     )
}

export default AssignModal;




















