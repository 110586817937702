import { call, put, takeLatest } from 'redux-saga/effects';
import Apis from '../../../../../../api';
import LocationAction from './action';
import locationTypes from './types';

const fetchLocation = function* fetchLocation(payload: any) {
    const country = payload.payload.country;
    try {
        const { data } = yield call(Apis.getLocation, { search: country, type: '' });

        if (data.statusCode === 200) {
            const structuredData = data.data?.length
                ? data.data.reduce(
                    (
                        acc: any,
                        item: {
                            city: '';
                            state: '';
                            country: '';
                        },
                    ) => {
                        const { country, state, city } = item;
                        acc[country] = acc[country] || {};
                        acc[country][state] = acc[country][state] || [];
                        acc[country][state].push(city);
                        return acc;
                    },
                    {},
                )
                : {};

            let defaultData: any = { ...payload.payload.prevData };
            let defaultKeys = Object.keys(defaultData);
            let sortedKeys = Object.keys(structuredData).sort();
            for (let i = 0; i < defaultKeys.length; i++) {
                const key = defaultKeys[i];
                defaultData[key].display = false;
            }
            for (let i = 0; i < sortedKeys.length; i++) {
                const key = sortedKeys[i];
                defaultData[key].data = structuredData[key];
                defaultData[key].display = true;
                defaultData[key].contentType = 'search';
            }


            // const index = sortedKeys.indexOf('United States');
            // if (index !== -1) {
            //     sortedKeys.splice(index, 1)
            //     sortedKeys = ['United States', ...sortedKeys];
            // }

            // const sortedObj: any = {};
            // sortedKeys.forEach((key: any) => {
            //     sortedObj[key] = structuredData[key];
            // });
            yield put(LocationAction.setLocationAction(defaultData));
            yield put(LocationAction.modifyLocation(defaultData));
        } else {
            yield put(LocationAction.setLocationAction([]));
            yield put(LocationAction.modifyLocation({}));
        }
    } catch (error) {
        yield put(LocationAction.setLocationAction([]));
        yield put(LocationAction.modifyLocation({}));
    }
};

const fetchCountryLocation = function* fetchCountryLocation(payload: any) {
    const country = payload.payload.country;
    const state = payload.payload.state;
    const prevData = payload.payload.prevData;
    const type = payload.payload.type;
    try {
        if (type === 'state') { 
            yield put(LocationAction.setCitiesLocationLoading(true)) }
        else {
            yield put(LocationAction.setStateLocationLoading(true))
        }
        const { data } = yield call(Apis.getLocation, { search: type === 'country' ? country : state, type: type });

        if (data.statusCode === 200) {
            const structuredData = data.data?.length
                ? data.data.reduce(
                    (
                        acc: any,
                        item: {
                            city: '';
                            state: '';
                            country: '';
                        },
                    ) => {
                        const { country, state, city } = item;
                        acc[country] = acc[country] || {};
                        acc[country][state] = acc[country][state] || [];
                        acc[country][state].push(city);
                        return acc;
                    },
                    {},
                )
                : {};

                let dummyData = {...structuredData[country]};
                let modifiedDummyData:any = {};
                let dummyStatesArr = Object.keys(dummyData).sort();
                for(let i=0;i< dummyStatesArr.length;i++){
                    let ele = dummyStatesArr[i];
                    modifiedDummyData[ele] = dummyData[ele];
                }
                structuredData[country] = {...modifiedDummyData};
            let defaultData: any = { ...payload.payload.prevData };
            //let sortedKeys = Object.keys(structuredData).sort();
            if (type === 'country') {
                defaultData[country].data = structuredData[country];
                defaultData[country].allStatesPresent = true;
                defaultData[country].diaplay = true;
                defaultData[country].contentType = 'all';
                defaultData[country].citiesForStatesArr=[];
            } else {
                defaultData[country].data[state] = structuredData[country][state];
                defaultData[country].allStatesPresent = true;
                defaultData[country].diaplay = true;
                defaultData[country].citiesForStatesArr.push(state);
            }


            yield put(LocationAction.setSingleCountryLocation(defaultData));
            yield put(LocationAction.setCitiesLocationLoading(false));
            yield put(LocationAction.setStateLocationLoading(false));
        } else {
            yield put(LocationAction.setSingleCountryLocation(prevData));
            yield put(LocationAction.setCitiesLocationLoading(false));
            yield put(LocationAction.setStateLocationLoading(false));
        }
    } catch (error) {
        yield put(LocationAction.setSingleCountryLocation(prevData));
    }
};

export default function* sagas() {
    yield takeLatest(locationTypes.FETCH_LOCATION, fetchLocation);
    yield takeLatest(locationTypes.FETCH_SINGLE_COUNTRY_LOCATION, fetchCountryLocation);
}
