import { Empty, Spin } from 'antd';
import React from 'react'
import { CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { commaSeperator, numberFormatter } from '../../../../../utils/Validation';
import moment from 'moment';

type Props = {
     data: any;
     loading: boolean;
     suffix?: string;
     prefix?: string;
     activeTab?: string;
     applyDate?: string;
};
const ImpClicksBiaxialDashedGraph: React.FC<Props> = ({ data, loading, suffix, prefix, activeTab, applyDate }) => {
     const formatterLeft = (value: string | number) => `${prefix ? prefix : " "}${String(numberFormatter(Number(value)))}`;
     const xAxisFormatter = (value: string) => {
          if (applyDate === 'today' || applyDate === 'last1Day') {
               return value;
          } else {
               return `${moment(value).format("MMM DD")}`;
          }
     };
     return (
          <div className="">
               {!loading && (
                    <>
                         {
                              data?.length !== 0 ?
                                   <ResponsiveContainer height={300} width="100%">
                                        <ComposedChart
                                             data={data?.map((d: any) => {
                                                  return { ...d, revenue: parseFloat(d.revenue), request: parseInt(d.request) }
                                             })}
                                             margin={{
                                                  top: 10,
                                                  right: 15,
                                                  bottom: 20,
                                                  left: 0,
                                             }}
                                        >

                                             <XAxis axisLine={true} dataKey={applyDate === "today" ? "hour" : "date"} tickFormatter={xAxisFormatter} stroke="#fff" tickMargin={20} tick={{ fill: '#615E83', fontSize: '0.7rem', fontFamily: 'Roboto' }} />
                                             <YAxis yAxisId={'leftAxis'} axisLine={false} tickLine={false} tickFormatter={formatterLeft} tick={{ fill: '#615E83', fontSize: '0.8rem', fontFamily: 'Roboto' }} />
                                             {
                                                  activeTab !== "Impressions" && (<YAxis yAxisId={'rightAxis'} orientation='right' axisLine={false} tickLine={false} tick={{ fill: '#615E83', fontSize: '0.8rem', fontFamily: 'Roboto' }} />)
                                             }
                                             <Tooltip content={<CustomTooltip labelClassName={prefix} />} />
                                             <CartesianGrid vertical={false} />
                                             {/* <Bar yAxisId={'leftAxis'} maxBarSize={20} dataKey="value" fillOpacity={1} fill="#69A284" radius={4} ></Bar> */}
                                             <Line type="monotone" yAxisId={'leftAxis'} dataKey="impressions" stroke="#00a7a7" strokeDasharray="10 10" strokeWidth={3} />
                                             {
                                                  activeTab !== "Impressions" && (
                                                       activeTab === 'Clicks' ?
                                                            <Line type="monotone" yAxisId={'rightAxis'} dataKey="clicks" stroke="#056433" strokeWidth={3} strokeDasharray="10 10" /> :
                                                            <Line type="monotone" yAxisId={'rightAxis'} dataKey="acquisitions" stroke="#54E69A" strokeWidth={3} strokeDasharray="10 10" />
                                                  )
                                             }



                                        </ComposedChart>
                                   </ResponsiveContainer>
                                   :
                                   <div className="flex justify-center items-center h-[200px]">
                                        <Empty className='mt-5' description={'No data found'} />
                                   </div>
                         }

                    </>
               )}

               {loading && (
                    <div className="flex align-center justify-center h-[200px]">
                         <Spin className="m-auto" />
                    </div>
               )}
          </div>
     );


}

export default ImpClicksBiaxialDashedGraph

const CustomTooltip = ({ active, payload, label, labelClassName, wrapperClassName }: any) => {
     if (active) {
          return (
               <div className="shadow rounded" style={{ background: 'rgba(30, 30, 30, 0.8)' }}>
                    <div className="px-4 py-1" style={{ background: 'rgba(0, 0, 0, 0.7)', borderBottom: '1px solid #333' }}>
                         <p className="label text-xs roboto-medium text-white">{label}</p>
                    </div>
                    <div className="desc px-4 py-1 text-center">
                         {payload?.map((p: any, index: number) => (
                              <div key={`${index}_${p.name}`} className={'text-xs text-white flex justify-between items-center'}>
                                   <p>{p.name}&nbsp; :&nbsp;</p>
                                   <p>{labelClassName ? `${labelClassName}${commaSeperator(p.value)}` : commaSeperator(p.value)}</p>
                              </div>
                         ))}
                    </div>
               </div>
          );
     }

     return null;
};
