import { Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import PButton from '../../../../common/Button';
import PModal from '../../../../common/Modal';
import PWebsiteModalView from '../modal/WebsiteModalView';
import { IoCloseCircle } from 'react-icons/io5';

type Props = {
    WebsitesType?: any;
    onChangeCamType?: any;
    websiteChooseType?: string[];
    isValidate?: boolean;
    websiteList?: string[];
    in_selectedWebsite?: any;
    ex_selectedWebsite?: any;
    handleSaveItem: (item: string[]) => void;
    setUnsaveChanges:any;
};

const PWebsiteOfCamp: React.FC<Props> = ({ websiteChooseType, WebsitesType, onChangeCamType, isValidate, websiteList, in_selectedWebsite, ex_selectedWebsite, handleSaveItem ,setUnsaveChanges}) => {
    const [isModalOpenForWebsite, setIsModalOpenForWebsite] = useState(false);
    const [in_websiteData, setIn_WebSiteData] = useState<any>([]);
    const [ex_websiteData, setEx_WebSiteData] = useState<any>([]);
    const openWebsiteModal = () => {
        setIsModalOpenForWebsite(true);
    };
    const [isWebsiteValidate, setIsWebsiteValidate] = useState(false);

    useEffect(() => {
        setIn_WebSiteData(in_selectedWebsite);
        setEx_WebSiteData(ex_selectedWebsite)
    }, [in_selectedWebsite, ex_selectedWebsite])

    useEffect(() => {
        // setIn_WebSiteData([]);
        setIsWebsiteValidate(false);
    }, [WebsitesType]);

    const closeWebsiteModal = () => {
        setIsModalOpenForWebsite(false);
    };
    const handleRemoveItem = (value: any) => {
        const arr = WebsitesType === 'Include' ? [...in_websiteData] : [...ex_websiteData];
        const getIndex = arr.indexOf(value);
        if (getIndex !== -1) {
            arr.splice(getIndex, 1);
            if (WebsitesType === 'Include') {
                setIn_WebSiteData(arr);
            } else {
                setEx_WebSiteData(arr);
            }
        }
    };

    const handleRemoveChipItem = (value: any) => {
        const arr = WebsitesType === 'Include' ? [...in_selectedWebsite] : [...ex_selectedWebsite];
        const getIndex = arr.indexOf(value);
        if (getIndex !== -1) {
            arr.splice(getIndex, 1);
            if (WebsitesType === 'Include') {
                handleSaveItem(arr);
            } else {
                handleSaveItem(arr);
            }
        }
        setUnsaveChanges(true);
    }
    const ViewOfModalWebsite = (
        <PWebsiteModalView
            WebsitesType={WebsitesType}
            websiteList={websiteList}
            selectedWebsite={WebsitesType === 'Include' ? in_websiteData : ex_websiteData}
            handleAddItem={(item) => {
                if (WebsitesType === 'Include') {
                    setIn_WebSiteData([...(in_websiteData || []), item]);
                } else {
                    setEx_WebSiteData([...(ex_websiteData || []), item]);
                }
            }}
            handleRemoveItem={handleRemoveItem}
            isWebsiteValidate={isWebsiteValidate}
            hideItemFromList={in_selectedWebsite?.concat(ex_selectedWebsite !== undefined ? ex_selectedWebsite : [])}
        />
    );

    const customizeHeaderForWebsite = (
        <div className="flex justify-between">
            <div>
                <p className="flex items-center font-[Roboto-Medium] md:text-[#056433] font-bold text-xl">{`Website (${WebsitesType})`}</p>
            </div>
            <div className="flex">
                <PButton
                    title="Submit"
                    className="md:rounded-full md:h-[auto] text-xs py-px md:px-6 mr-4"
                    onClick={() => {
                        if (WebsitesType === 'Include') {
                            if (in_websiteData.length) {
                                setIsWebsiteValidate(false);
                                handleSaveItem(in_websiteData);
                                closeWebsiteModal();
                                setUnsaveChanges(true)
                            } else {
                                setIsWebsiteValidate(true);
                            }
                        } else {
                            if (ex_websiteData.length) {
                                setIsWebsiteValidate(false);
                                handleSaveItem(ex_websiteData);
                                closeWebsiteModal();
                                setUnsaveChanges(true)
                            } else {
                                setIsWebsiteValidate(true);
                            }
                        }
                    }}
                />
                <AiOutlineCloseCircle size={'1.75rem'} className="cursor-pointer" onClick={closeWebsiteModal} />
            </div>
        </div>
    );

    return (
        <div>
            <p className="flex items-center font-[Roboto-Medium] text-black font-medium text-sm mt-9">Websites</p>
            <div className=" p-4 mt-3 bg-white rounded-md shadow-md">
                <div>
                    <label className="ml-1 text-xs">
                        Choose
                        <span className="text-red-500">{'*'}</span>
                    </label>
                    <div className="mt-2 mb-4">
                        <Radio.Group onChange={onChangeCamType} value={WebsitesType} name="WebsitesType">
                            {websiteChooseType?.map((item, index) => (
                                <Radio key={index} value={item}>
                                    {item}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </div>
                    {WebsitesType === 'Include' && in_selectedWebsite?.length ? (
                        <>
                            <label className="ml-1 text-xs">{`Websites (${in_selectedWebsite?.length || 0})`}</label>
                            <div className="mt-2 flex flex-wrap">
                                {in_selectedWebsite.slice(0, 7)?.map((item: any, index: number) => (
                                    <p key={index} className="flex gap-1 md:text-[#056433] flex items-center justify-around border rounded md:bg-[#f1f1f1] md:border-[#e5e7eb] h-7 md:w-[auto] pl-4 pr-1 font-[Roboto-Medium] text-black font-medium text-xs mt-2 ml-1 ">
                                        {item}
                                        <div className='cursor-pointer' onClick={() => handleRemoveChipItem(item)}><IoCloseCircle size={14} /></div>
                                    </p>
                                ))}
                                <p onClick={openWebsiteModal} className="cursor-pointer md:text-[#056433] flex items-center justify-around px-4 font-[Roboto-Medium] font-medium text-xs mt-2 ml-1 ">
                                    Add more
                                </p>
                            </div>
                        </>
                    ) : null}
                    {isValidate && WebsitesType === 'Include' && (in_selectedWebsite?.length === 0 || in_selectedWebsite === undefined) && <span className="common_error">Please add atleast one website</span>}

                    {WebsitesType === 'Exclude' && ex_selectedWebsite?.length ? (
                        <>
                            <label className="ml-1 text-xs">{`Websites (${ex_selectedWebsite?.length || 0})`}</label>
                            <div className="mt-2 flex flex-wrap">
                                {ex_selectedWebsite.slice(0, 7)?.map((item: any, index: number) => (
                                    <p key={index} className=" flex gap-1 md:text-[#056433] flex items-center justify-around border rounded md:bg-[#f1f1f1] md:border-[#e5e7eb] h-7 md:w-[auto] pl-4 pr-1 font-[Roboto-Medium] text-black font-medium text-xs mt-2 ml-1 ">
                                        {item}
                                        <div className='cursor-pointer' onClick={() => handleRemoveChipItem(item)}><IoCloseCircle size={14} /></div>
                                    </p>
                                ))}
                                <p onClick={openWebsiteModal} className="cursor-pointer md:text-[#056433] flex items-center justify-around px-4 font-[Roboto-Medium] font-medium text-xs mt-2 ml-1 ">
                                    Add more
                                </p>

                            </div>
                        </>
                    ) : null}
                    {isValidate && WebsitesType === 'Exclude' && (ex_selectedWebsite?.length === 0 || ex_selectedWebsite === undefined) && <span className="common_error">Please select atleast one website</span>}

                </div>
                {WebsitesType === 'Include' && !in_selectedWebsite?.length && WebsitesType ? (
                    <p onClick={openWebsiteModal} className="cursor-pointer md:text-[#056433] font-[Roboto-Medium] font-medium text-xs ">
                        Add Website
                    </p>
                ) : null}
                {WebsitesType === 'Exclude' && !ex_selectedWebsite?.length && WebsitesType ? (
                    <p onClick={openWebsiteModal} className="cursor-pointer md:text-[#056433] font-[Roboto-Medium] font-medium text-xs ">
                        Add Website
                    </p>
                ) : null}
                {isValidate && !WebsitesType && <span className="common_error">Please select website</span>}
            </div>
            {isModalOpenForWebsite && <PModal ViewOfModal={ViewOfModalWebsite} isModalOpen={isModalOpenForWebsite} OnClickOfCancel={closeWebsiteModal} customizeHeader={customizeHeaderForWebsite} />}
        </div>
    );
};
export default PWebsiteOfCamp;
