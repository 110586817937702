const userManagementTypes = {
     FETCH_USER_MANAG_USER_LIST: 'FETCH_USER_MANAG_USER_LIST',
     SET_USER_MANAG_USER_LIST: 'SET_USER_MANAG_USER_LIST',

     FETCH_ASSIGN_CAMP_LIST: 'FETCH_ASSIGN_CAMP_LIST',
     SET_ASSIGN_CAMP_LIST: 'SET_ASSIGN_CAMP_LIST',
}

export default userManagementTypes;

export interface ManageActionState {
     assignModal: boolean,
     updateModal: boolean,
     deleteModal: boolean,
     type: string
}

export interface AssignCampList {
     _id: string,
     name: string,
     status: string
}

export interface UserManagementState {
     userListData: any;
     userListDataLoading: boolean;
     userListTotal: number;

     allAssignCampList: AssignCampList[];
     allAssignCampListLoader: boolean;
};
