import PCard from "../../common/Card";
import logo from '../../../assets/images/publir.svg';
import PNormalInput from "../../common/NormalInput";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { MdKeyOff } from "react-icons/md";
import PButton from "../../common/Button";
import { BsFillKeyFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import Apis from "../../../api";
import MessageActions from "../../message/redux/actions";
import { LoginResponse, UserState } from "../login/redux/types";
import LoginActions from "../login/redux/actions";
import { FaCheckCircle } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
interface ResetPasswordState {
     password: string;
     confirmPassword: string;
     passwordShow: boolean;
     confPasswordShow: boolean;
}
const ChangePassword = () => {
     const dispatch = useDispatch();
     const [form, setForm] = useState<ResetPasswordState>({
          password: '',
          confirmPassword: '',
          passwordShow: false,
          confPasswordShow: false
     });
     const [validPassword, setValidPassword] = useState({
          hasCapLetter: false,
          hasSmallLetter: false,
          hasSpecialChar: false,
          hasNumber: false,
          minLength: false,
          isPasswordValid: false,
     })
     const [isValidate, setValidate] = useState(false);
     const [loading, setLoading] = useState<boolean>(false);
     const params = useParams();

     const handleShowPassword = (value: boolean, type: string) => {
          if (type === 'pass') {
               setForm({
                    ...form,
                    passwordShow: value,
               });
          } else {
               setForm({
                    ...form,
                    confPasswordShow: value,
               });
          }
     };

     const isPasswordSame = () => {
          return form.password === form.confirmPassword;
     }

     const handleResetPassword = () => {
          setValidate(true);
          if (!form.password || !form.confirmPassword
               || form.password.trim() === "" || form.confirmPassword.trim() === "" || !isPasswordSame() || !validPassword.isPasswordValid
          ) {
               return;
          }
          setValidate(false);
          setLoading(true);
          if (params.token) {
               Apis.resetPassword({ token: params.token, password: form.password }).then((res) => {
                    const email = res?.data?.data?.user?.email;
                    const mssg = res?.data?.data?.message;
                    let payload: any = { email: email.trim(), password: form.password, login_type: "new" };
                    if (email) {
                         Apis.login(payload)
                              .then(({ data }) => {
                                   const response: LoginResponse = data?.data;
                                   const { jwt_token, user } = response;

                                   const userData: UserState = {
                                        jwtToken: jwt_token?.token,
                                        user,
                                   };
                                   dispatch(MessageActions.showMessage({ text: mssg, error: false }));
                                   dispatch(LoginActions.loginReq(userData));
                              })
                              .catch((err) => {
                                   dispatch(MessageActions.showMessage({ text: String(err?.response?.data?.data?.message), error: true }));
                              })
                    }

               }).catch((err) => {
                    dispatch(MessageActions.showMessage({ text: String(err?.response?.data?.data?.message), error: true }));
               }).finally(() => setLoading(false))
          }
     }


     useEffect(() => {
          const numPattern = /[0-9]+/;
          const capPattern = /[A-Z]+/;
          const smallPattern = /[a-z]+/;
          const specialPattern = /[^a-zA-Z0-9\s]/;

          const num = numPattern.test(form.password);
          const cap = capPattern.test(form.password);
          const small = smallPattern.test(form.password);
          const len = form.password?.length >= 12;
          const special = specialPattern.test(form.password);

          setValidPassword({
               hasCapLetter: cap,
               hasSmallLetter: small,
               hasSpecialChar: special,
               hasNumber: num,
               minLength: len,
               isPasswordValid: (num && cap && small && len && special)
          })
     }, [form.password])

     return (
          <div className="w-full h-screen bg-gray-100">
               <div className="flex justify-center items-center h-full flex-col">
                    <img src={logo} alt="publirLogo" className="pb-5 w-36" />
                    <PCard className="px-6 py-4">
                         <div className="flex justify-center items-center pb-6">
                              <p className="font-bold montserrat-medium text-2xl">Reset Password</p>
                         </div>
                         <div className="py-2">
                              <PNormalInput
                                   title="Password"
                                   type={form.passwordShow ? 'text' : 'password'}
                                   value={form.password}
                                   name="password"
                                   onChange={(e: any) => { setForm({ ...form, password: e.value }) }}
                                   isRequired={true}
                                   onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                             event.preventDefault();
                                             handleResetPassword();
                                        }
                                   }}
                                   suffix={form.passwordShow ? <MdKeyOff className="cursor-pointer " onClick={() => handleShowPassword(false, 'pass')} /> : <BsFillKeyFill className="cursor-pointer" onClick={() => handleShowPassword(true, 'pass')} />}
                              />
                              {isValidate && (!form.password || form.password.trim() === '') && <span className="common_error">Please enter password</span>}
                              {isValidate && form.password && !validPassword.isPasswordValid && <span className="common_error">
                                   password does not meet criteria
                                   {/* password should include Uppercase Letters (A-Z), Lowercase letters (a-z), Numbers (0-9),Special characters (!, @, #, $, etc ) and minimum length of 12 characters */}
                              </span>}
                         </div>
                         <div className="py-2">
                              <PNormalInput
                                   title="Confirm Password"
                                   type={form.confPasswordShow ? 'text' : 'password'}
                                   value={form.confirmPassword}
                                   name="conf-password"
                                   onChange={(e: any) => { setForm({ ...form, confirmPassword: e.value }) }}
                                   isRequired={true}
                                   onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                             event.preventDefault();
                                             handleResetPassword();
                                        }
                                   }}
                                   suffix={form.confPasswordShow ? <MdKeyOff className="cursor-pointer " onClick={() => handleShowPassword(false, 'confPass')} /> : <BsFillKeyFill className="cursor-pointer" onClick={() => handleShowPassword(true, 'confPass')} />}
                              />
                              {isValidate && (form.password && (!form.confirmPassword || form.confirmPassword.trim() === '')) && <span className="common_error">Please re-enter password</span>}
                              {isValidate && form.password && form.confirmPassword && (!isPasswordSame()) && <span className="common_error">Incorrect password</span>}
                         </div>
                         <div className="text-[12px]">
                              <div>Password Criteria :-</div>
                              <div className="flex gap-2 items-center">
                                   {validPassword.hasCapLetter ? <FaCheckCircle className="ml-[1px]"  color='#24D64F' size={14}/> : <RiCloseCircleFill color="red" size={16} />}
                                   <div>Capital Letters.</div>
                              </div>
                              <div className="flex gap-2 items-center">
                                   {validPassword.hasSmallLetter ? <FaCheckCircle className="ml-[1px]" color='#24D64F' size={14}/> : <RiCloseCircleFill color="red" size={16} />}
                                   <div>Small Letters.</div>
                              </div>
                              <div className="flex gap-2 items-center">
                                   {validPassword.hasNumber ? <FaCheckCircle className="ml-[1px]" color='#24D64F' size={14}/> : <RiCloseCircleFill color="red" size={16} />}
                                   <div>Numbers.</div>
                              </div>
                              <div className="flex gap-2 items-center">
                                   {validPassword.hasSpecialChar ? <FaCheckCircle className="ml-[1px]" color='#24D64F' size={14}/> : <RiCloseCircleFill color="red" size={16} />}
                                   <div>Special Characters.</div>
                              </div>
                              <div className="flex gap-2 items-center">
                                   {validPassword.minLength ? <FaCheckCircle className="ml-[1px]" color='#24D64F' size={14}/> : <RiCloseCircleFill color="red" size={16} />}
                                   <div>Min Length Of 12 Characters</div>
                              </div>
                         </div>

                         <div className="flex items-center mt-6 w-full justify-center">
                              <PButton title="Reset Password" onClick={handleResetPassword} loading={loading} />
                         </div>
                    </PCard>
               </div>
          </div>
     );
}

export default ChangePassword;
