// actions.ts
import { CommonAction } from '../../../../auth/login/redux/types';
import campaignTypes, { Constants, DetailOfCampaign } from './type';

/**Fetch Campaigns */
function fetchCampaigns(): CommonAction {
    return {
        type: campaignTypes.FETCH_CAMPAIGNS,
        payload: null,
    };
}

function setCampaigns(data: any): CommonAction {
    return {
        type: campaignTypes.SET_CAMPAIGNS,
        payload: data,
    };
}

function fetchActiveCampaigns(): CommonAction {
    return {
        type: campaignTypes.FETCH_ACTIVE_CAMPAIGNS,
        payload: null,
    };
}

function setActiveCampaigns(data: any): CommonAction {
    return {
        type: campaignTypes.SET_ACTIVE_CAMPAIGNS,
        payload: data,
    };
}

function fetchPausedCampaigns(): CommonAction {
    return {
        type: campaignTypes.FETCH_PAUSED_CAMPAIGNS,
        payload: null,
    };
}

function setPausedCampaigns(data: any): CommonAction {
    return {
        type: campaignTypes.SET_PAUSED_CAMPAIGNS,
        payload: data,
    };
}

function fetchCompletedCampaigns(): CommonAction {
    return {
        type: campaignTypes.FETCH_COMPLETED_CAMPAIGNS,
        payload: null,
    };
}

function setCompletedCampaigns(data: any): CommonAction {
    return {
        type: campaignTypes.SET_COMPLETED_CAMPAIGNS,
        payload: data,
    };
}
/**End Fetch Campaigns */

/** Fetch Constants */
function fetchConstants(): CommonAction {
    return {
        type: campaignTypes.FETCH_CONSTANTS,
        payload: undefined,
    };
}

function setConstants(constants: Constants): CommonAction {
    return {
        type: campaignTypes.SET_CONSTANTS,
        payload: constants,
    };
}

/** Fetch Constants */
function fetchCompaignView(): CommonAction {
    return {
        type: campaignTypes.FETCH_CAMPAIGN_VIEW,
        payload: undefined,
    };
}

function setCompaignView(detailOfCampaign: DetailOfCampaign): CommonAction {
    return {
        type: campaignTypes.SET_CAMPAIGN_VIEW,
        payload: detailOfCampaign,
    };
}

/**End Fetch Constants */
const campaginsActions = {
    fetchCampaigns,
    setCampaigns,
    fetchConstants,
    setConstants,
    fetchCompaignView,
    setCompaignView,
    fetchActiveCampaigns,
    setActiveCampaigns,
    fetchPausedCampaigns,
    setPausedCampaigns,
    fetchCompletedCampaigns,
    setCompletedCampaigns
};

export default campaginsActions;
