import React, { useState } from 'react';
import PButton from '../../../../common/Button';
import PNormalTextArea from '../../../../common/NormalTextArea';

type Props = {
    onSubmitText: (data: string) => void;
    selectedEditData: any;
    setUnsaveChanges:any;
};
interface addScript {
    script?: string;
    isValidate?: boolean
}

const PAddScriptModalView: React.FC<Props> = ({ onSubmitText, selectedEditData ,setUnsaveChanges}) => {
    const [addTScriptForm, setAddScriptForm] = useState<addScript>({
        script: selectedEditData?.script ? selectedEditData?.script : undefined,
        isValidate: false
    });

    const onChangeFields = (data: { name: string; value: string | number | undefined }) => {
        const { name, value } = data;
        setAddScriptForm({ ...addTScriptForm, [name]: value });
    };

    const onClickSubmit = () => {
        setAddScriptForm({ ...addTScriptForm, isValidate: true });
        if (
            !addTScriptForm.script ||
            addTScriptForm.script.trim() === ''
        ) {
            return;
        }
        setAddScriptForm({ ...addTScriptForm, isValidate: false });
        onSubmitText(addTScriptForm.script)
        setUnsaveChanges(true)
    }

    return (
        <div className="w-full">
            <div className="">
                <PNormalTextArea isRequired className="md:rounded md:py-2 text-xs" onChange={onChangeFields} rows={3} value={addTScriptForm.script} name="script" title="Add script" placeholder="Please Enter Script" />
                {addTScriptForm.isValidate && !addTScriptForm.script && <span className="common_error">Please Enter Script</span>}

                <PButton onClick={onClickSubmit} title="Submit" className="md:rounded-full mt-5 md:h-[auto] text-xs py-px md:px-5 w-32" />
            </div>
        </div>
    );
};

export default PAddScriptModalView;
