import React, { useEffect, useMemo, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { HEADERMENU_PATH } from '../../../routes/RoutesURL';
import BackDrop from '../../common/BackDrop';
import PButton from '../../common/Button';
import PNormalInput from '../../common/NormalInput';
import PTabs from '../../common/Tabs';
import LocationAction from './campaign/modal/Redux/action';

import { MdClose } from "react-icons/md";
import AllCampaigns from './campaignsTables/AllCampaigns';
import ActiveCampaigns from './campaignsTables/ActiveCampaigns';
import CompletedCampaigns from './campaignsTables/CompletedCampaigns';
import PausedCampaigns from './campaignsTables/PausedCampaigns';
import { RootState } from '../../../store/RootReducer';
const tabData = [
    { key: 'all', title: 'All' },
    { key: 'active', title: 'Active' },
    { key: 'completed', title: 'Completed' },
    { key: 'paused', title: 'Paused' },
];

interface CampaignTable {
    searchText: string;
}

const PrebidAdapterList: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState<string>('active');
    const [campTable, setCampTable] = useState<CampaignTable>({
        searchText: '',
    });
    const [statusLoading, setStatusLoading] = useState(false);
    const user = useSelector((state: RootState) => state.user.user);
    const role: any = user?.publisher_type;
    const selectTab = (value: string) => {
        setActiveTab(value);
    };

    const gotToCreateNewCampaign = () => {
        dispatch(LocationAction.editLocation({ exclude: [], include: [] }));
        navigate(HEADERMENU_PATH.compaignCreate);
    };

    const gotToOverallReport = () => {
        navigate(`${HEADERMENU_PATH.overallReport}/overall-report`, { state: { name: 'overAllReprt' } });
    };

    const Components = useMemo(() => {
        switch (activeTab) {
            case 'all': return <AllCampaigns activeTab={activeTab} searchText={campTable.searchText} setStatusLoading={setStatusLoading} />
            case 'active': return <ActiveCampaigns activeTab={activeTab} searchText={campTable.searchText} setStatusLoading={setStatusLoading} />
            case 'completed': return <CompletedCampaigns activeTab={activeTab} searchText={campTable.searchText} setStatusLoading={setStatusLoading} />
            case 'paused': return <PausedCampaigns activeTab={activeTab} searchText={campTable.searchText} setStatusLoading={setStatusLoading} />

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, dispatch, campTable.searchText]);


    const onSearchName = (e: { name: string; value: string | number | undefined }) => {
        const { name, value } = e;
        setCampTable({
            ...campTable,
            [name]: value,
        });
    };

    const removeEnteredText = () => {
        setCampTable({
            ...campTable,
            searchText: '',
        });
    }

    useEffect(() => {
        setCampTable({ searchText: "" });
    }, [activeTab])

    return (
        <div className="pt-4">
            {statusLoading && <BackDrop />}
            <div className="flex items-center justify-between">
                <p className="font-[Roboto-Medium] text-black font-medium text-2xl">Prebid Adapter - Ad Campaigns</p>
                <div className='md: flex items-center gap-2'>
                    {
                        (role === "super_admin" || role === "admin") && (
                            <PButton title="Overall Report" className=" md:rounded-full w-[220px] text-xs flex justify-center items-center py-2" onClick={gotToOverallReport} />
                        )
                    }
                    {
                        (role === "super_admin" || user?.create_campaign === true) && (
                            <PButton title="Create New Campaign" className="md:rounded-full px-9 text-xs flex justify-center items-center py-2" onClick={gotToCreateNewCampaign} />
                        )
                    }
                </div>
            </div>
            <div className="flex justify-between items-center pt-4">
                <PTabs activeTab={activeTab} setActiveTab={(e) => selectTab(e)}
                    tabs={tabData} />
                <div className="flex gap-4 justify-end items-center pb-4 border-b border-black">
                    <PNormalInput
                        prefix={<CiSearch />}
                        value={campTable.searchText}
                        name="searchText"
                        className="md:py-1 md:w-80"
                        onChange={onSearchName}
                        placeholder="Search for Ad campaigns"
                        suffix={
                            <MdClose className='cursor-pointer' onClick={removeEnteredText} />
                        }
                    />
                </div>
            </div>

            {Components}
        </div>
    );
};

export default PrebidAdapterList;
