import reportTypes, { OverallReportState } from "./type"

const initialState: OverallReportState = {
     overallReportTopCards: undefined,
     overallReportTopCardsLoader: false,
     budgetSpentReportData: [],
     budgetSpentReportLoader: false,
     totalBudgetSpend: 0,
     ctrReportData: [],
     ctrReportLoader: false,
     totalCtr: 0,
     impClickGraphData: [],
     impAcqGraphData: [],
     impClickAcqGraphLoader: false,
     impGraphData: [],
     topCountriesImpData: [],
     topCountriesClickData: [],
     topCountriesDataLoader: false,
     topDevicesImpData: [],
     topDevicesClickData: [],
     topDevicesDataLoader: false,
     topMediaSizesImpData: [],
     topMediaSizesClickData: [],
     topMediaSizesDataLoader: false,
     topSitesImpData: [],
     topSitesClickData: [],
     topSitesDataLoader: false,
     reportsCampeignList: [],
     reportsCampeignListLoader: false,
     totalCampeignListRecords: 0,
     reportsSiteList: [],
     reportsSiteListLoader: false,
     totalSiteListRecords: 0,
     campReportsTopCountriesTableData: undefined,
     campReportsTopCountriesTableLoader: false,
     campReportsTopCountriesTableRecordCount: 0,
     campReportsTopDevicesTableData: undefined,
     campReportsTopDevicesTableRecordCount: 0,
     campReportsTopDevicesTableLoader: false,
     campReportsTopMediaTableData: undefined,
     campReportsTopMediaTableRecordCount: 0,
     campReportsTopMediaTableLoader: false,
     campReportsTopSitesTableData: undefined,
     campReportsTopSitesTableRecordCount: 0,
     campReportsTopSitesTableLoader: false,
     realTimeImpClickGraphData: [],
     realTimeImpAcqGraphData: [],
     realTimeImpClickAcqGraphLoader: false,
     impClickAcqGraphFrom: '',
     impClickAcqGraphTo: '',
     campReportsTopCreativeTableData: undefined,
     campReportsTopCreativeTableRecordCount: 0,
     campReportsTopCreativeTableLoader: false,
     isHourBudgetInterval: false
}
const overAllReportReducer = (state = initialState, action: { type: string; payload: any }): OverallReportState => {
     switch (action.type) {
          case reportTypes.FETCH_REPORT_TOPCARDS:
               return {
                    ...state,
                    overallReportTopCardsLoader: true
               }
          case reportTypes.SET_REPORT_TOPCARDS:
               return {
                    ...state,
                    overallReportTopCards: action.payload,
                    overallReportTopCardsLoader: false
               }
          case reportTypes.FETCH_REPORTS_BUGDET_SPEND_GRAPH:
               return {
                    ...state,
                    budgetSpentReportLoader: true,
                    totalBudgetSpend: 0,
                    isHourBudgetInterval: false,
               }
          case reportTypes.SET_REPORTS_BUGDET_SPEND_GRAPH:
               return {
                    ...state,
                    budgetSpentReportData: action.payload.data,
                    totalBudgetSpend: action.payload.totalBudget,
                    isHourBudgetInterval: action.payload.isHourBudgetInterval,
                    budgetSpentReportLoader: false
               }

          case reportTypes.FETCH_REPORTS_CTR_GRAPH:
               return {
                    ...state,
                    ctrReportLoader: true,
                    totalCtr: 0
               }
          case reportTypes.SET_REPORTS_CTR_GRAPH:
               return {
                    ...state,
                    ctrReportData: action.payload.data,
                    totalCtr: action.payload.totalCtr,
                    ctrReportLoader: false
               }

          case reportTypes.FETCH_REPORTS_IMP_CLICKS_GRAPH:
               return {
                    ...state,
                    impClickAcqGraphLoader: true,
               }
          case reportTypes.SET_REPORTS_IMP_CLICKS_GRAPH:
               return {
                    ...state,
                    impGraphData: action.payload.imp,
                    impClickGraphData: action.payload.impClick,
                    impAcqGraphData: action.payload.impAcq,
                    impClickAcqGraphLoader: false,

               }

          case reportTypes.FETCH_REPORTS_TOP_COUNTRIES_DATA_GRAPH:
               return {
                    ...state,
                    topCountriesDataLoader: true,
               }
          case reportTypes.SET_REPORTS_TOP_COUNTRIES_DATA_GRAPH:
               return {
                    ...state,
                    topCountriesImpData: action.payload.imps,
                    topCountriesClickData: action.payload.clicks,
                    topCountriesDataLoader: false
               }

          case reportTypes.FETCH_REPORTS_TOP_DEVICES_DATA_GRAPH:
               return {
                    ...state,
                    topDevicesDataLoader: true
               }
          case reportTypes.SET_REPORTS_TOP_DEVICES_DATA_GRAPH:
               return {
                    ...state,
                    topDevicesImpData: action.payload.imps,
                    topDevicesClickData: action.payload.clicks,
                    topDevicesDataLoader: false
               }

          case reportTypes.FETCH_REPORTS_TOP_MEDIASIZES_DATA_GRAPH:
               return {
                    ...state,
                    topMediaSizesDataLoader: true,
               }
          case reportTypes.SET_REPORTS_TOP_MEDIASIZES_DATA_GRAPH:
               return {
                    ...state,
                    topMediaSizesImpData: action.payload.imps,
                    topMediaSizesClickData: action.payload.clicks,
                    topMediaSizesDataLoader: false
               }

          case reportTypes.FETCH_REPORTS_TOP_SITES_DATA_GRAPH:
               return {
                    ...state,
                    topSitesDataLoader: true,
               }
          case reportTypes.SET_REPORTS_TOP_SITES_DATA_GRAPH:
               return {
                    ...state,
                    topSitesImpData: action.payload.imps,
                    topSitesClickData: action.payload.clicks,
                    topSitesDataLoader: false
               }

          case reportTypes.FETCH_REPORTS_CAMPAIGN_LIST:
               return {
                    ...state,
                    reportsCampeignListLoader: true,
               }
          case reportTypes.SET_REPORTS_CAMPAIGN_LIST:
               return {
                    ...state,
                    reportsCampeignList: action.payload.data,
                    totalCampeignListRecords: action.payload.totalRecords,
                    reportsCampeignListLoader: false
               }

          case reportTypes.FETCH_REPORTS_SITE_LIST:
               return {
                    ...state,
                    reportsSiteListLoader: true,
               }
          case reportTypes.SET_REPORTS_SITE_LIST:
               return {
                    ...state,
                    reportsSiteList: action.payload.data,
                    totalSiteListRecords: action.payload.totalRecords,
                    reportsSiteListLoader: false
               }

          //top camp countries table data
          case reportTypes.FETCH_CAMP_REPORTS_COUNTRIES_TABLE:
               return {
                    ...state,
                    campReportsTopCountriesTableLoader: true,
               }
          case reportTypes.SET_CAMP_REPORTS_COUNTRIES_TABLE:
               return {
                    ...state,
                    campReportsTopCountriesTableData: action.payload.data,
                    campReportsTopCountriesTableRecordCount: action.payload.count,
                    campReportsTopCountriesTableLoader: false,
               }

          //top camp reports table data
          case reportTypes.FETCH_CAMP_REPORTS_DEVICES_TABLE:
               return {
                    ...state,
                    campReportsTopDevicesTableLoader: true,
               }
          case reportTypes.SET_CAMP_REPORTS_DEVICES_TABLE:
               return {
                    ...state,
                    campReportsTopDevicesTableData: action.payload.data,
                    campReportsTopDevicesTableRecordCount: action.payload.count,
                    campReportsTopDevicesTableLoader: false,
               }

          //top camp reports media table
          case reportTypes.FETCH_CAMP_REPORTS_MEDIA_TABLE:
               return {
                    ...state,
                    campReportsTopMediaTableLoader: true,
               }
          case reportTypes.SET_CAMP_REPORTS_MEDIA_TABLE:
               return {
                    ...state,
                    campReportsTopMediaTableData: action.payload.data,
                    campReportsTopMediaTableRecordCount: action.payload.count,
                    campReportsTopMediaTableLoader: false,
               }

          //top camp reports sites table
          case reportTypes.FETCH_CAMP_REPORTS_SITES_TABLE:
               return {
                    ...state,
                    campReportsTopSitesTableLoader: true,
               }
          case reportTypes.SET_CAMP_REPORTS_SITES_TABLE:
               return {
                    ...state,
                    campReportsTopSitesTableData: action.payload.data,
                    campReportsTopSitesTableRecordCount: action.payload.count,
                    campReportsTopSitesTableLoader: false,
               }

          //top camp reports creative table
          case reportTypes.FETCH_CAMP_REPORTS_CREATIVE_TABLE:
               return {
                    ...state,
                    campReportsTopCreativeTableLoader: true,
               }
          case reportTypes.SET_CAMP_REPORTS_CREATIVE_TABLE:
               return {
                    ...state,
                    campReportsTopCreativeTableData: action.payload.data,
                    campReportsTopCreativeTableRecordCount: action.payload.count,
                    campReportsTopCreativeTableLoader: false,
               }

          //real time data apis
          case reportTypes.FETCH_REAL_TIME_REPORTS_IMP_CLICK_GRAPH:
               return {
                    ...state,
                    realTimeImpClickAcqGraphLoader: true,
               }
          case reportTypes.SET_REAL_TIME_REPORTS_IMP_CLICK_GRAPH:
               return {
                    ...state,
                    realTimeImpClickGraphData: action.payload.impClick,
                    realTimeImpAcqGraphData: action.payload.impAcq,
                    realTimeImpClickAcqGraphLoader: false,
                    impClickAcqGraphFrom: action.payload.from,
                    impClickAcqGraphTo: action.payload.to,
               }

          default:
               return state;
     }
}

export default overAllReportReducer;
