import { Layout, Popover } from 'antd';
import React, { useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { MdLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import LogoImage from '../../assets/images/publir.svg';
import { HEADERMENU_PATH } from '../../routes/RoutesURL';
import { useDispatch, useSelector } from 'react-redux';
import LoginActions from '../auth/login/redux/actions';
import { RootState } from '../../store/RootReducer';
import { GrTask, GrUserAdmin } from "react-icons/gr";
const { Header } = Layout;

const PHeader: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const user = useSelector((state: RootState) => state.user.user);
    const role: any = user?.publisher_type;
    const onClickPrebid = () => {
        navigate('/');
    };

  const onClickLogout = () => {
        dispatch(LoginActions.removeUserData());
        navigate(HEADERMENU_PATH.login);
    };

    const content = (
        <div>
            {/* <p className="font-[Montserrat-Medium] text-base font-semibold">{user?.full_name}</p>
            <Divider orientationMargin={0} style={{ margin: 2 }} /> */}
            {
                (role === 'super_admin' || role === "admin") && (
                    <>
                        <div className="flex px-4 gap-2 py-3 pb-2 items-center cursor-pointer" onClick={() => {
                            setOpen(false);
                            navigate(HEADERMENU_PATH.userManagement);
                        }}>
                            <GrUserAdmin size={'1.2rem'} />
                            <p className="text-base">User Management</p>
                        </div>

                        <div className="flex px-4 gap-2 py-3 pb-2 items-center cursor-pointer" onClick={()=>{
                            setOpen(false);
                            navigate(HEADERMENU_PATH.userActivities);
                        }}>
                            <GrTask size={'1.2rem'} />
                            <p className="text-base">User Activities</p>
                        </div>
                    </>
                )
            }
            <div className="flex px-4 gap-2 py-3 pb-2 items-center cursor-pointer" onClick={onClickLogout}>
                <MdLogout size={'1.2rem'} />
                <p className="text-base">Logout</p>
            </div>

        </div>
    );

    return (
        <Header className="fixed w-full z-10 pl-6 pr-6 bg-white h-20 shadow-md flex justify-between items-center" id="headerbar">
            <div className="flex">
                <div className="mr-6">
                    <img src={LogoImage} loading="lazy" alt="imagelogo" className="logo cursor-pointer" onClick={onClickPrebid} />
                </div>
            </div>
            <div className="flex gap-4 items-center">
                <div className="font-[Roboto] text-base font-semibold">{user?.full_name}</div>
                <Popover content={content} open={open} onOpenChange={(value) => setOpen(value)} trigger="click" placement="bottomRight">
                    <div className="cursor-pointer">
                        <FaUserCircle size={'1.5rem'} />
                    </div>
                </Popover>
            </div>
        </Header>
    );
};

export default PHeader;
