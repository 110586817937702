import { Image } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { RiDeleteBin6Line, RiEdit2Line } from 'react-icons/ri';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel styles
import { v4 as uuidv4 } from 'uuid';
import PButton from '../../../../common/Button';
import PModal from '../../../../common/Modal';
import PTabs from '../../../../common/Tabs';
import PAddImagesModalView from '../modal/AddImagesModalView';
import PAddScriptModalView from '../modal/AddScriptModalView';
import PAddTextModalView from '../modal/AddTextModalView';

export interface ImagePreview {
    openModal?: boolean;
    modalType?: string | undefined;
    detailOfAddImage?: object;
    detailOfAddScript?: object;
    isSelectPreviewOpen: number;
    selectedSize: string;
    selectedIndexOfPreview: number;
    selectedEditData: any;
    previewIndex: number | undefined;
    previewTabIndex: number | undefined;
}

type Props = {
    selectedMediaSizes: string[];
    getSubmitedDetail: (data: object[]) => void;
    previewData?: { size: string; image?: any; text?: any; script?: any }[];
    isValidate?: boolean;
    setUnsaveChanges?: any;
    aiPrompt: string
    setAiPromptTextGlobally: (text:string)=>void
};

const tabData = [
    { key: 'text', title: 'Text', count: 0 },
    { key: 'script', title: 'Script', count: 0 },
    { key: 'image', title: 'Image', count: 0 },
];

const PImagePreviewUI: React.FC<Props> = ({ selectedMediaSizes, getSubmitedDetail, previewData, isValidate, setUnsaveChanges ,aiPrompt,setAiPromptTextGlobally}) => {
    const [activeTab, setActiveTab] = useState<string>('text');
    const [tabsData, setTabsData] = useState(tabData)
    const [previewDetail, setPreviewDetail] = useState<ImagePreview>({
        openModal: false,
        modalType: '',
        detailOfAddImage: {},
        detailOfAddScript: {},
        isSelectPreviewOpen: 0,
        selectedSize: '',
        selectedIndexOfPreview: 0,
        selectedEditData: undefined,
        previewIndex: undefined,
        previewTabIndex: undefined,
    });
    const [submitedData, setSubmitedData] = useState<any[]>([]);

    useEffect(() => {
        if (selectedMediaSizes && selectedMediaSizes.length > 0) {
            const filteredData = submitedData.filter((item) => selectedMediaSizes.includes(item.size));

            const newData = selectedMediaSizes
                .filter((size) => !filteredData.some((item) => item.size === size))
                .map((newSize) => {
                    let findPreview = previewData?.find((item) => item.size === newSize);
                    let { image, text, script } = findPreview || {};
                    return {
                        size: newSize,
                        text: text || [],
                        script: script || [],
                        image: image || [],
                    };
                });

            setSubmitedData([...filteredData, ...newData]);
        } else {
            setSubmitedData([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMediaSizes?.length]);

    const openCloseImagePrview = (value: number) => {
        setPreviewDetail({ ...previewDetail, isSelectPreviewOpen: value });
    };

    const openAddImageModal = (item: string, index: number) => {
        setPreviewDetail({ ...previewDetail, openModal: true, modalType: 'image', selectedSize: item, selectedIndexOfPreview: index });
    };

    const openAddScriptModal = (item: string, index: number) => {
        setPreviewDetail({ ...previewDetail, openModal: true, modalType: 'script', selectedSize: item, selectedIndexOfPreview: index });
    };

    const openAddTextModal = (item: string, index: number) => {
        setPreviewDetail({ ...previewDetail, openModal: true, modalType: 'text', selectedSize: item, selectedIndexOfPreview: index });
    };

    const closeAddImageModal = () => {
        setPreviewDetail({ ...previewDetail, openModal: false, modalType: '', selectedEditData: undefined, previewIndex: undefined, previewTabIndex: undefined, selectedSize: '' });
    };


    const closeModalOnSubmitText = () => {
        setPreviewDetail({ ...previewDetail, openModal: false, modalType: '', selectedEditData: undefined, previewIndex: undefined, previewTabIndex: undefined });

    }
    const onSubmitText = (name: string, body: string, url: string) => {
        if (previewDetail.selectedEditData !== undefined && previewDetail.previewIndex !== undefined && previewDetail.previewTabIndex !== undefined) {
            let copyOfArr = [...submitedData];
            copyOfArr[previewDetail?.previewIndex][activeTab][previewDetail.previewTabIndex].name = name;
            copyOfArr[previewDetail?.previewIndex][activeTab][previewDetail.previewTabIndex].text = body;
            copyOfArr[previewDetail?.previewIndex][activeTab][previewDetail.previewTabIndex].destination_url = url;
            setSubmitedData(copyOfArr);
            getSubmitedDetail(copyOfArr);
        } else {
            const copyOfArr = [...submitedData];
            const copyOfText = [...copyOfArr[previewDetail.selectedIndexOfPreview].text];
            copyOfArr[previewDetail.selectedIndexOfPreview].text = [...copyOfText, { name: name, text: body, destination_url: url }];
            setSubmitedData(copyOfArr);
            getSubmitedDetail(copyOfArr);
        }
        setPreviewDetail({ ...previewDetail, openModal: false, modalType: '', selectedEditData: undefined, previewIndex: undefined, previewTabIndex: undefined });
    };

    const onSubmitScript = (script: string) => {
        if (previewDetail.selectedEditData !== undefined && previewDetail.previewIndex !== undefined && previewDetail.previewTabIndex !== undefined) {
            let copyOfArr = submitedData;
            copyOfArr[previewDetail?.previewIndex][activeTab][previewDetail.previewTabIndex].script = script;
            setSubmitedData(copyOfArr);
            getSubmitedDetail(copyOfArr);
        } else {
            const copyOfArr = [...submitedData];
            const copyOfText = [...copyOfArr[previewDetail.selectedIndexOfPreview].script];
            copyOfArr[previewDetail.selectedIndexOfPreview].script = [...copyOfText, { script: script }];
            setSubmitedData(copyOfArr);
            getSubmitedDetail(copyOfArr);
        }
        setPreviewDetail({ ...previewDetail, openModal: false, modalType: '', selectedEditData: undefined, previewIndex: undefined, previewTabIndex: undefined });
    };

    const onSubmitImageDetail = (name: string, destination_url: string, file: File, tracker_script: string, prompt: string) => {
        if (previewDetail.selectedEditData !== undefined && previewDetail.previewIndex !== undefined && previewDetail.previewTabIndex !== undefined) {
            let copyOfArr = submitedData;
            copyOfArr[previewDetail?.previewIndex][activeTab][previewDetail.previewTabIndex].name = name;
            copyOfArr[previewDetail?.previewIndex][activeTab][previewDetail.previewTabIndex].destination_url = destination_url;
            copyOfArr[previewDetail?.previewIndex][activeTab][previewDetail.previewTabIndex].file = file;
            copyOfArr[previewDetail?.previewIndex][activeTab][previewDetail.previewTabIndex].tracker_script = tracker_script;
            copyOfArr[previewDetail?.previewIndex][activeTab][previewDetail.previewTabIndex].prompt = prompt;
            setSubmitedData(copyOfArr);
            getSubmitedDetail(copyOfArr);
        } else {
            const copyOfArr = [...submitedData];
            const copyOfText = [...copyOfArr[previewDetail.selectedIndexOfPreview].image];
            copyOfArr[previewDetail.selectedIndexOfPreview].image = [...copyOfText, { name: name, destination_url: destination_url, file: file, tracker_script: tracker_script, prompt: prompt }];
            setSubmitedData(copyOfArr);
            getSubmitedDetail(copyOfArr);
        }
        setPreviewDetail({ ...previewDetail, openModal: false, modalType: '', selectedEditData: undefined });
    };

    const selectTab = (value: string) => {
        setPreviewDetail({ ...previewDetail, selectedEditData: undefined });
        setActiveTab(value);
    };

    const checkMediaFile = (item: File | string) => {
        if (typeof item === 'string') {
            return item;
        } else {
            return URL.createObjectURL(item);
        }
    };

    const ViewOfModalAddImages = (
        <>
            {previewDetail.modalType === 'image' && <PAddImagesModalView onSubmitImageDetail={onSubmitImageDetail} selectedEditData={previewDetail.selectedEditData} selectedSize={previewDetail?.selectedSize} setUnsaveChanges={setUnsaveChanges} aiPrompt={aiPrompt} setAiPromptTextGlobally={setAiPromptTextGlobally}/>}
            {previewDetail.modalType === 'script' && <PAddScriptModalView onSubmitText={onSubmitScript} selectedEditData={previewDetail.selectedEditData} setUnsaveChanges={setUnsaveChanges} />}
            {previewDetail.modalType === 'text' && <PAddTextModalView onSubmitText={onSubmitText} selectedEditData={previewDetail.selectedEditData} closeModalOnSubmitText={closeModalOnSubmitText} adSize={previewDetail?.selectedSize} setUnsaveChanges={setUnsaveChanges} />}
        </>
    );

    const customizeHeaderForAddImage = (
        <div className="flex justify-between w-full">
            <div>
                <p className="flex items-center font-[Roboto-Medium] text-black font-bold text-xl">
                    {previewDetail.modalType === 'image'
                        ? previewDetail.selectedEditData
                            ? 'Edit Image'
                            : 'Add Image'
                        : previewDetail.modalType === 'script'
                            ? previewDetail.selectedEditData
                                ? 'Edit Script'
                                : 'Add Script'
                            : previewDetail.modalType === 'text'
                                ? previewDetail.selectedEditData
                                    ? 'Edit Text'
                                    : 'Add Text'
                                : 'Ask to Chat GPT'}
                </p>
            </div>
            <div className="flex">
                <AiOutlineCloseCircle size={'1.75rem'} className="cursor-pointer" onClick={closeAddImageModal} />
            </div>
        </div>
    );

    const deleteSelectedTextItem = (parentIndex: number, deleteIndex: number) => {
        // Create a copy of the array
        const newArray = [...submitedData];
        // Use splice to remove the element at the specified index
        newArray[parentIndex][activeTab].splice(deleteIndex, 1);
        // Update the state with the new array
        setSubmitedData(newArray);
        setPreviewDetail({ ...previewDetail, selectedEditData: undefined, previewIndex: undefined, previewTabIndex: undefined });
        getSubmitedDetail(newArray);
        setUnsaveChanges(true)
    };

    const editSelectedTextItem = (parentIndex: number, index: number) => {
        const newArray = [...submitedData];
        setPreviewDetail({
            ...previewDetail,
            previewIndex: parentIndex,
            previewTabIndex: index,
            selectedEditData: newArray[parentIndex][activeTab][index],
            openModal: true,
            modalType: activeTab === 'text' ? 'text' : activeTab === 'script' ? 'script' : 'image',
            selectedIndexOfPreview: parentIndex,
            selectedSize: submitedData[parentIndex]
        });
    };

    useEffect(() => {
        const dumData = [
            { key: 'text', title: 'Text', count: submitedData?.[previewDetail.isSelectPreviewOpen]?.text?.length },
            { key: 'script', title: 'Script', count: submitedData?.[previewDetail.isSelectPreviewOpen]?.script?.length },
            { key: 'image', title: 'Image', count: submitedData?.[previewDetail.isSelectPreviewOpen]?.image?.length },
        ];
        setTabsData(dumData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitedData?.[previewDetail.isSelectPreviewOpen]?.image?.length, submitedData?.[previewDetail.isSelectPreviewOpen]?.script?.length, submitedData?.[previewDetail.isSelectPreviewOpen]?.text?.length])

    const Components = useMemo(() => {
        const carouselImage = (
            <>
                {/* <Carousel>
                    {submitedData?.[previewDetail.isSelectPreviewOpen]?.image?.map((item: any, index: number) => (
                        <div key={`${index}_${uuidv4()}`}>
                            <img src={checkMediaFile(item.file)} alt="Selected Preview" style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px', cursor: 'pointer' }} />
                            <h3>{item.name}</h3>
                            <h2>{item.destination_url}</h2>
                        </div>
                    ))}
                </Carousel> */}

                <div className="carousel-container">
                    {submitedData?.[previewDetail.isSelectPreviewOpen]?.image?.length > 0 ? (
                        <Carousel showStatus={false} showArrows={true} infiniteLoop={true} showThumbs={false} className="custom-carousel">
                            {submitedData?.[previewDetail.isSelectPreviewOpen]?.image?.map((item: any, index: number) => (
                                <div key={index}>
                                    <div className="flex absolute z-10 mt-2.5 right-9">
                                        <RiEdit2Line className="cursor-pointer mr-3" onClick={() => editSelectedTextItem(previewDetail.isSelectPreviewOpen, index)} />
                                        <RiDeleteBin6Line className="cursor-pointer" onClick={() => deleteSelectedTextItem(previewDetail.isSelectPreviewOpen, index)} />
                                    </div>
                                    <div key={`text_div_${index}`} className="carousel-item-img">
                                        <div className="flex items-center justify-center w-[100%]">
                                            <Image style={{ maxWidth: 300, maxHeight: 300 }} src={`${checkMediaFile(item.file)}`} preview={true} />
                                        </div>
                                        <div className="flex text-[14px]">
                                            <h3>Name : </h3>
                                            <h3>&nbsp;{item.name}</h3>
                                        </div>
                                        <div className="flex text-[14px]">
                                            <h3>Destination url : </h3>
                                            <h2>&nbsp;{item.destination_url}</h2>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    ) : (
                        <p>No items to display</p>
                    )}
                </div>
            </>
        );

        const carouselForScript = (
            <div className="carousel-container">
                {submitedData?.[previewDetail.isSelectPreviewOpen]?.script?.length > 0 ? (
                    <Carousel showStatus={false} showArrows={true} infiniteLoop={true} showThumbs={false} className="custom-carousel">
                        {submitedData?.[previewDetail.isSelectPreviewOpen]?.script?.map((item: any, index: number) => (
                            <div key={index}>
                                <div className="flex absolute mt-2.5 right-9">
                                    <RiEdit2Line className="cursor-pointer mr-3" onClick={() => editSelectedTextItem(previewDetail.isSelectPreviewOpen, index)} />
                                    <RiDeleteBin6Line className="cursor-pointer" onClick={() => deleteSelectedTextItem(previewDetail.isSelectPreviewOpen, index)} />
                                </div>
                                <div key={`text_div_${index}`} className="carousel-item">
                                    <div className="flex">
                                        {/* <h3>Script : </h3>
                                        <h3>&nbsp;{item.script}</h3> */}
                                        <iframe
                                            title="Script Iframe"
                                            width="100%"
                                            height="135px"
                                            srcDoc={`
                                                <html>
                                                <head>
                                                    <style>
                                                    ::-webkit-scrollbar {
                                                        display: none;
                                                    }
                                                    </style>
                                                </head>
                                                <body>
                                                    ${item.script}
                                                </body>
                                                </html>`}
                                            frameBorder={0}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                ) : (
                    <p>No items to display</p>
                )}
            </div>
        );

        const carouselForText = (
            <div className="carousel-container">
                {submitedData?.[previewDetail.isSelectPreviewOpen]?.text?.length > 0 ? (
                    <Carousel showStatus={false} showArrows={true} infiniteLoop={true} showThumbs={false} className="custom-carousel">
                        {submitedData?.[previewDetail.isSelectPreviewOpen]?.text?.map((item: any, index: number) => (
                            <div key={index}>
                                <div className="flex absolute mt-2.5 right-9">
                                    <RiEdit2Line className="cursor-pointer mr-3" onClick={() => editSelectedTextItem(previewDetail.isSelectPreviewOpen, index)} />
                                    <RiDeleteBin6Line className="cursor-pointer" onClick={() => deleteSelectedTextItem(previewDetail.isSelectPreviewOpen, index)} />
                                </div>
                                <div key={`text_div_${index}`} className="carousel-item-text flex flex-col">
                                    <div className="flex">
                                        <h3 className="contents">Title: </h3>
                                        <h2>&nbsp;{item.name}</h2>
                                    </div>

                                    <div className="flex">
                                        <div className=" text-[14px]">Body: </div>
                                        <div className='text-[14px]'>&nbsp;{item.text}</div>
                                    </div>

                                    {item.destination_url && (
                                        <div className="flex text-[14px]">
                                            <h3>Url : </h3>
                                            <h3>&nbsp;{item.destination_url}</h3>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </Carousel>
                ) : (
                    <p>No items to display</p>
                )}
            </div>
        );
        switch (activeTab) {
            case 'image':
                return carouselImage;
            case 'text':
                return carouselForText;
            case 'script':
                return carouselForScript;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, submitedData, previewDetail]);

    return (
        <div>
            {submitedData &&
                submitedData.length > 0 &&
                submitedData.map((item: any, index: number) => (
                    <div key={`${index}_${uuidv4()}`} className="p-4 mt-3 bg-white rounded-md shadow-md md:w-[90%]">
                        <div className="flex justify-between">
                            <p onClick={() => openCloseImagePrview(index)} className="cursor-pointer flex items-center font-[Roboto-Medium] text-black font-medium text-sm">
                                Preview ({item.size})
                            </p>
                            <div className="flex">
                                <PButton title="Add Text" onClick={() => openAddTextModal(item, index)} className="md:rounded-full md:h-[auto] text-xs py-px md:px-2" />
                                <PButton title="Add Script" onClick={() => openAddScriptModal(item, index)} className="md:rounded-full md:h-[auto] text-xs py-px md:px-2 ml-1" />
                                <PButton title="Add Images" onClick={() => openAddImageModal(item, index)} className="md:rounded-full md:h-[auto] text-xs py-px md:px-2 ml-1" />
                                {previewDetail.isSelectPreviewOpen === index ? (
                                    <IoMdArrowDropup size={'1.25rem'} className="cursor-pointer mr-3.5" onClick={() => openCloseImagePrview(index)} />
                                ) : (
                                    <IoMdArrowDropdown size={'1.25rem'} className="cursor-pointer mr-3.5" onClick={() => openCloseImagePrview(index)} />
                                )}
                            </div>
                        </div>
                        {previewDetail.isSelectPreviewOpen === index && (
                            <div>
                                <div>
                                    <PTabs activeTab={activeTab} setActiveTab={(e) => selectTab(e)} tabs={tabsData} />
                                </div>

                                <div className="flex justify-center ">
                                    {Components}
                                    {/* <img src={item.imageUrl} alt="publirLogo" className="pb-5 w-60" /> */}
                                </div>
                            </div>
                        )}
                        {isValidate && submitedData?.[index]?.text.length === 0 && submitedData?.[index]?.script.length === 0 && submitedData?.[index]?.image.length === 0 && <span className="common_error">Please Enter Image preview details</span>}
                    </div>
                ))}
            {previewDetail.openModal && <PModal ViewOfModal={ViewOfModalAddImages} isModalOpen={previewDetail.openModal} OnClickOfCancel={closeAddImageModal} customizeHeader={customizeHeaderForAddImage} />}
        </div>
    );
};

export default PImagePreviewUI;
