import { SelectEffect, all, fork, select, takeEvery } from 'redux-saga/effects';
import loginSagas from '../components/auth/login/redux/sagas';
import locationSagas from '../components/main/prebidAdapter/campaign/modal/Redux/sagas';
import campaignSagas from '../components/main/prebidAdapter/campaign/redux/sagas';
import overallReportSagas from '../components/main/prebidAdapter/overallReport/redux/sagas';
import userManagementSagas from '../components/main/prebidAdapter/user manamgement/redux/saga';
import userActivitySagas from '../components/main/prebidAdapter/userActivities/redux/saga';
function* watchAndLog() {
    yield takeEvery('*', function* logger(action) {
        const state: SelectEffect = yield select();
        console.debug('action', action);
        console.debug('state after', state);
    });
}

export default function* root() {
    const allForks = [fork(campaignSagas), fork(loginSagas), fork(locationSagas), fork(overallReportSagas),fork(userManagementSagas),fork(userActivitySagas)];
    if (process.env.NODE_ENV === 'development') {
        allForks.unshift(fork(watchAndLog));
    }
    yield all(allForks);
}
