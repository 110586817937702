import { CommonAction } from "../../../../message/redux/types";
import { call, put, takeLatest } from 'redux-saga/effects';
import userActivitiesTypes from "./type";
import Apis from "../../../../../api";
import UserActivitiesActions from "./actions";

const fetchUserActivitiesListData = function* fetchUserActivitiesListData(action: CommonAction) {
     try {
          const { startDate, endDate, module, email, campaign } = action.payload;
          const { data } = yield call(Apis.fetchUserActivitiesDataList, startDate, endDate, module, email, campaign);
          yield put(UserActivitiesActions.setUserActivitiesList({ data: data?.data?.data, count: data?.data?.total }));
     } catch (error) {
          yield put(UserActivitiesActions.setUserActivitiesList({}));
     }
}

const fetchAllUsersUserActivitiesListData = function* fetchAllUsersUserActivitiesListData(action: CommonAction) {
     try {
          const { data } = yield call(Apis.fetchAllUsersListActivitiesApi);
          yield put(UserActivitiesActions.setAllUsersUserActivitiesList(data?.data?.data));
     } catch (error) {
          yield put(UserActivitiesActions.setAllUsersUserActivitiesList([]));
     }
}

export default function* sagas() {
     yield takeLatest(userActivitiesTypes.FETCH_USER_ACTIVITIES_LIST, fetchUserActivitiesListData);
     yield takeLatest(userActivitiesTypes.FETCH_ALL_USERS_USER_ACTIVITY_LIST, fetchAllUsersUserActivitiesListData);
}
