import React from 'react';
import { MdDelete, MdDone } from 'react-icons/md';
import PDropdown, { DropdownOption } from '../../../../../common/Dropdown';

interface AddScheduleProps {
    index: number;
    totalLength: number;
    scheduleStartValue: string;
    scheduleEndValue: string;
    handleChange: (data: { name: string; value: string | boolean | undefined }, index: number) => void;
    scheduleStartOptions: DropdownOption[];
    scheduleEndOptions: DropdownOption[];
    repeatOn: string[];
    dontRunDays: boolean;
    setScheduleDaysTimes?: any;
    selecctedIndex: number;
    selectItem: (data: string, selecctedIndex: number) => void;
    handleRemoveSchedule: (selectedIndex: number) => void;
    isValidate?: boolean;
    showOverLapError?: boolean;
}

const AddSchedule: React.FC<AddScheduleProps> = ({
    selectItem,
    selecctedIndex,
    scheduleStartValue,
    scheduleEndValue,
    handleChange,
    showOverLapError,
    scheduleStartOptions,
    scheduleEndOptions,
    repeatOn,
    dontRunDays,
    setScheduleDaysTimes,
    index,
    totalLength,
    handleRemoveSchedule,
    isValidate,
}) => {
    return (
        <div className="mt-2">
            <div className="bg-[#F1F3F4] rounded-sm p-2">
                {showOverLapError && <div className="common_error my-1">Time period has been overlapped.</div>}

                <div className="flex justify-between">
                    <div className="flex gap-1 text-sm">
                        <PDropdown
                            label="Start Time"
                            name="start"
                            required
                            value={scheduleStartValue}
                            options={scheduleStartOptions}
                            onChange={(e) => {
                                handleChange(e, selecctedIndex);
                            }}
                        />

                        <PDropdown
                            label="End Time"
                            name="end"
                            required
                            value={scheduleEndValue}
                            options={scheduleEndOptions}
                            onChange={(e) => {
                                handleChange(e, selecctedIndex);
                            }}
                        />
                    </div>
                    {totalLength > 1 ? (
                        <div className="p-1 cursor-pointer" onClick={() => handleRemoveSchedule(index)}>
                            <MdDelete color="green" />
                        </div>
                    ) : null}
                </div>
                {isValidate && (scheduleStartValue === '' || scheduleStartValue === undefined || scheduleEndValue === '' || scheduleEndValue === undefined) ? <span className="common_error">Please select start time and end time.</span> : null}

                <div>
                    <p className="text-sm mt-3">
                        Repeat On <span className="text-red-500">{'*'}</span>
                    </p>
                    <div className="flex gap-2 items-center m-2 flex-wrap">
                        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((item: string) => {
                            const isChecked = repeatOn.includes(item);
                            return (
                                <p
                                    key={item}
                                    className={`p-1 cursor-pointer text-sm justify-center items-center flex gap-1 text-center border ] border-[#BCBEC0] font-semibold ${
                                        isChecked ? 'bg-[#b4d0c1] text-[#056433] min-w-[70px] border-[#b4d0c1]' : 'bg-white text-[#BCBEC0] min-w-[60px]'
                                    } rounded-[40px] `}
                                    onClick={() => selectItem(item, selecctedIndex)}
                                >
                                    {isChecked && <MdDone color={'#056433'} />}
                                    {item}
                                </p>
                            );
                        })}
                    </div>
                    {isValidate && repeatOn?.length === 0 && <span className="common_error">Please select at least one day.</span>}
                    {/* <div className="flex gap-2 text-sm mt-6">
                        <Checkbox
                            checked={dontRunDays}
                            name='dontRunDays'
                            onChange={(e) => {
                                handleChange({
                                    name: 'dontRunDays',
                                    value: e.target.checked
                                }, selecctedIndex)
                            }}
                        />
                        <span>Don't run these Days</span>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default AddSchedule;
