import React, { useState } from "react";
import PNormalInput from "../../../../common/NormalInput";
import ColorPicker from "../../../../common/colorPicker";
import PTabs from "../../../../common/Tabs";
import PNormalTextArea from "../../../../common/NormalTextArea";
import PDropdown from "../../../../common/Dropdown";

type Props = {
     upComingHeight: any;
     upComingWidth: any;
     file: any;
}
const ImageEditTextAndPreview: React.FC<Props> = ({ upComingHeight, upComingWidth, file }) => {
     const [activeTab, setActiveTab] = useState("title");
     const [titleConfig, setTitleConfig] = useState({
          title: "",
          titleBackgroundColor:'',
          position: "top-0 left-0",
          color: '#000000',
          font:'Roboto',
          fontSize: 20,
          fontWeight: 500,
          marginX: 1,
          marginY: 1,
          paddingX: 1,
          paddingY: 1
     })
     const [descriptionConfig, setDescriptionConfig] = useState({
          description: "",
          descriptionBackgroundColor:"",
          position: "bottom-0 left-0",
          color: '',
          font:'Roboto',
          fontSize: 20,
          fontWeight: 500,
          marginX: 1,
          marginY: 1,
          paddingX: 1,
          paddingY: 1
     })
     const positionConstants: any = [
          { "label": "Top-Left", "value": 'top-0 left-0' },
          { "label": "Top-Center", "value": "top-0 left-[50%]  -translate-x-1/2" },
          { "label": "Top-Right", "value": "top-0 right-0" },
          { "label": "Center-Left", "value": "top-[50%] left-0 -translate-y-1/2" },
          { "label": "Center", "value": "top-[50%] left-[50%]  -translate-x-1/2  -translate-y-1/2" },
          { "label": "Center-Right", "value": "top-[50%] right-0 -translate-y-1/2" },
          { "label": "Bottom-Left", "value": "bottom-0 left-0" },
          { "label": "Bottom-Center", "value": "bottom-0 left-[50%] -translate-x-1/2" },
          { "label": "Bottom-Right", "value": "bottom-0 right-0" },

     ]

     const fontConstants:any = [
          {label:"Roboto" , value:"Roboto"},
          {label:"Roboto-Medium",value:"Roboto-Medium"},
          {label:"Montserrat-Medium",value:"Montserrat-Medium"},
          {label:"Montserrat",value:"Montserrat"},
          {label:"Poppins",value:"Poppins"},
          {label:"Poppins-Bold",value:"Poppins-Bold"},
          {label:"Poppins-Light",value:"Poppins-Light"},
          {label:"Poppins-Italic",value:"Poppins-Italic"},
          {label:"Truculenta",value:"Truculenta"},
          {label:"Truculenta-Bold",value:"Truculenta-Bold"},
          {label:"Truculenta-Light",value:"Truculenta-Light"},
          {label:"Diplomata",value:"Diplomata"},
          {label:"Caveat",value:"Caveat"},
          {label:"Caveat-Bold",value:"Caveat-Bold"},
     ]

     const tabData = [
          { key: 'title', title: 'Title' },
          { key: 'description', title: 'Description' },
     ];

     return (
          <div className=" w-full flex justify-between ">
               <div className="grow flex justify-center items-center m-5 bg-[#4f4e4e]">
                    <div id='customImage' className="relative shadow-xl shadow-[#6c6c6c]">
                         <img alt="custom" className={parseInt(upComingHeight) > parseInt(upComingWidth) ? `h-[${upComingHeight}px]` : `w-[${upComingWidth}px]`} src={URL.createObjectURL(file)} />
                         <div
                              style={{
                                   fontSize: `${titleConfig.fontSize}px`,
                                   color: titleConfig.color,
                                   backgroundColor:titleConfig.titleBackgroundColor,
                                   fontWeight: titleConfig.fontWeight,
                                   fontFamily:titleConfig.font,
                                   marginLeft: `${titleConfig.marginX}px`,
                                   marginRight: `${titleConfig.marginX}px`,
                                   marginTop: `${titleConfig.marginY}px`,
                                   marginBottom: `${titleConfig.marginY}px`,
                                   paddingLeft: `${titleConfig.paddingX}px`,
                                   paddingRight: `${titleConfig.paddingX}px`,
                                   paddingTop: `${titleConfig.paddingY}px`,
                                   paddingBottom: `${titleConfig.paddingY}px`,
                              }}
                              className={`absolute ${titleConfig?.position}`}>
                              {titleConfig?.title}
                         </div>
                         <div
                              style={{
                                   fontSize: `${descriptionConfig.fontSize}px`,
                                   color: descriptionConfig.color,
                                   backgroundColor:descriptionConfig.descriptionBackgroundColor,
                                   fontWeight: descriptionConfig.fontWeight,
                                   fontFamily:descriptionConfig.font,
                                   marginLeft: `${descriptionConfig.marginX}px`,
                                   marginRight: `${descriptionConfig.marginX}px`,
                                   marginTop: `${descriptionConfig.marginY}px`,
                                   marginBottom: `${descriptionConfig.marginY}px`,
                                   paddingLeft: `${descriptionConfig.paddingX}px`,
                                   paddingRight: `${descriptionConfig.paddingX}px`,
                                   paddingTop: `${descriptionConfig.paddingY}px`,
                                   paddingBottom: `${descriptionConfig.paddingY}px`,
                              }}
                              className={`absolute ${descriptionConfig?.position}`}>
                              {descriptionConfig?.description}
                         </div>
                    </div>
               </div>
               <div className="w-[400px] bg-white border-l-2 p-5 h-[600px]">
                    <PTabs activeTab={activeTab} setActiveTab={(e) => setActiveTab(e)} tabs={tabData} />
                    {
                         activeTab === 'title' ?
                              <div className="mt-4">
                                   <PNormalInput
                                        className="md:rounded md:py-2 text-xs"
                                        value={titleConfig.title}
                                        name="title"
                                        title="Enter Title"
                                        onChange={(e: any) => setTitleConfig({ ...titleConfig, title: e.value })}
                                        placeholder="Enter Add Title"
                                   />

                                   <div className="flex gap-5 mt-2">
                                        <div className="min-w-[150px] w-1/2">
                                             <PNormalInput
                                                  className="md:rounded md:py-2 text-xs"
                                                  value={titleConfig.fontSize}
                                                  name="font-size"
                                                  title="Font Size"
                                                  type="number"
                                                  min={1}
                                                  onChange={(e: any) => setTitleConfig((Perv) => ({ ...titleConfig, fontSize: e.value }))}
                                                  placeholder="Enter Font Size"
                                             />
                                        </div>
                                        <div className="min-w-[150px] w-1/2">
                                             <PNormalInput
                                                  className="md:rounded md:py-2 text-xs"
                                                  value={titleConfig.fontWeight}
                                                  name="font-weight"
                                                  title="Font Weight"
                                                  type="number"
                                                  step={100}
                                                  min={100}
                                                  max={1000}
                                                  onChange={(e: any) => setTitleConfig({ ...titleConfig, fontWeight: e.value })}
                                                  placeholder="Enter Font Weight"
                                             />
                                        </div>
                                   </div>

                                   {/* Margin components */}
                                   <div className="flex gap-5 mt-2">
                                        <div className="min-w-[150px] w-1/2">
                                             <PNormalInput
                                                  className="md:rounded md:py-2 text-xs"
                                                  value={titleConfig.marginX}
                                                  name="marginx"
                                                  title="Margin-X"
                                                  type="number"
                                                  min={0}
                                                  onChange={(e: any) => setTitleConfig({ ...titleConfig, marginX: e.value })}
                                                  placeholder="Enter marginX"
                                             />
                                        </div>
                                        <div className="min-w-[150px] w-1/2">
                                             <PNormalInput
                                                  className="md:rounded md:py-2 text-xs"
                                                  value={titleConfig.marginY}
                                                  name="marginy"
                                                  title="Margin-Y"
                                                  type="number"
                                                  min={0}
                                                  onChange={(e: any) => setTitleConfig({ ...titleConfig, marginY: e.value })}
                                                  placeholder="Enter marginY"
                                             />
                                        </div>
                                   </div>

                                   {/* padding Components */}
                                   <div className="flex gap-5 mt-2">
                                        <div className="min-w-[150px] w-1/2">
                                             <PNormalInput
                                                  className="md:rounded md:py-2 text-xs"
                                                  value={titleConfig.paddingX}
                                                  name="paddingx"
                                                  title="Padding-X"
                                                  type="number"
                                                  min={0}
                                                  onChange={(e: any) => setTitleConfig({ ...titleConfig, paddingX: e.value })}
                                                  placeholder="Enter PaddingX"
                                             />
                                        </div>
                                        <div className="min-w-[150px] w-1/2">
                                             <PNormalInput
                                                  className="md:rounded md:py-2 text-xs"
                                                  value={titleConfig.paddingY}
                                                  name="paddingy"
                                                  title="Padding-Y"
                                                  type="number"
                                                  min={0}
                                                  onChange={(e: any) => setTitleConfig({ ...titleConfig, paddingY: e.value })}
                                                  placeholder="Enter PaddingY"
                                             />
                                        </div>
                                   </div>

                                   <div className="flex gap-5 mt-2">
                                        <div className="min-w-[150px] w-1/2">
                                             <div className="ml-1 font-[MontSerrat]">Position</div>
                                             <PDropdown
                                                  label=""
                                                  name="position"
                                                  required
                                                  value={titleConfig.position}
                                                  options={positionConstants}
                                                  allowClear={false}
                                                  onChange={(e: any) => {
                                                       setTitleConfig({
                                                            ...titleConfig,
                                                            position: e.value,
                                                       });
                                                  }}
                                             />
                                        </div>

                                        <div className="min-w-[150px] w-1/2">
                                             <div className="ml-1 font-[MontSerrat]">Font Family</div>
                                             <PDropdown
                                                  label=""
                                                  name="font"
                                                  required
                                                  value={titleConfig.font}
                                                  options={fontConstants}
                                                  allowClear={false}
                                                  onChange={(e: any) => {
                                                       setTitleConfig({
                                                            ...titleConfig,
                                                            font: e.value,
                                                       });
                                                  }}
                                             />
                                        </div>
                                   </div>
                                   
                                                  {/* color palets */}

                                   <div className="flex gap-5 mt-2">
                                        <div className="common_normalInput w-1/2">
                                             <div className="ml-1 font-[MontSerrat]">Text Color</div>
                                             <ColorPicker
                                                  className={"h-[40px] w-[100%]"}
                                                  color={titleConfig?.color}
                                                  handleColor={(e: any) => {
                                                       setTitleConfig((prev) => ({ ...prev, color: e.target.value }))
                                                  }}
                                             />
                                        </div>

                                        <div className="common_normalInput w-1/2">
                                             <div className="ml-1 font-[MontSerrat]">Text Background Color</div>
                                             <ColorPicker
                                                  className={"h-[40px]  w-[100%]"}
                                                  color={titleConfig?.titleBackgroundColor}
                                                  handleColor={(e: any) => {
                                                       setTitleConfig((prev) => ({ ...prev, titleBackgroundColor: e.target.value }))
                                                  }}
                                             />
                                        </div>
                                   </div>
                              </div>
                              :
                              <div className="mt-4">
                                   <PNormalTextArea
                                        className="md:rounded md:py-2 text-xs"
                                        rows={3}
                                        value={descriptionConfig.description}
                                        name="description"
                                        title="Description"
                                        onChange={(e: any) => setDescriptionConfig({ ...descriptionConfig, description: e.value })}
                                        placeholder="Enter description"
                                   />
                                   {/* font and weight */}
                                   <div className="flex gap-5 mt-2">
                                        <div className="min-w-[150px] w-1/2">
                                             <PNormalInput
                                                  className="md:rounded md:py-2 text-xs"
                                                  value={descriptionConfig.fontSize}
                                                  name="font-size"
                                                  title="Font Size"
                                                  type="number"
                                                  min={1}
                                                  onChange={(e: any) => setDescriptionConfig({ ...descriptionConfig, fontSize: e.value })}
                                                  placeholder="Enter Font Size"
                                             />
                                        </div>
                                        <div className="min-w-[150px] w-1/2">
                                             <PNormalInput
                                                  className="md:rounded md:py-2 text-xs"
                                                  value={descriptionConfig.fontWeight}
                                                  name="font-weight"
                                                  title="Font Weight"
                                                  type="number"
                                                  step={100}
                                                  min={100}
                                                  max={1000}
                                                  onChange={(e: any) => setDescriptionConfig({ ...descriptionConfig, fontWeight: e.value })}
                                                  placeholder="Enter Font Weight"
                                             />
                                        </div>
                                   </div>

                                   {/* Margin components */}
                                   <div className="flex gap-5 mt-2">
                                        <div className="min-w-[150px] w-1/2">
                                             <PNormalInput
                                                  className="md:rounded md:py-2 text-xs"
                                                  value={descriptionConfig.marginX}
                                                  name="marginx"
                                                  title="Margin-X"
                                                  type="number"
                                                  min={0}
                                                  onChange={(e: any) => setDescriptionConfig({ ...descriptionConfig, marginX: e.value })}
                                                  placeholder="Enter marginX"
                                             />
                                        </div>
                                        <div className="min-w-[150px] w-1/2">
                                             <PNormalInput
                                                  className="md:rounded md:py-2 text-xs"
                                                  value={descriptionConfig.marginY}
                                                  name="marginy"
                                                  title="Margin-Y"
                                                  type="number"
                                                  min={0}
                                                  onChange={(e: any) => setDescriptionConfig({ ...descriptionConfig, marginY: e.value })}
                                                  placeholder="Enter marginY"
                                             />
                                        </div>
                                   </div>

                                   {/* padding Components */}
                                   <div className="flex gap-5 mt-2">
                                        <div className="min-w-[150px] w-1/2">
                                             <PNormalInput
                                                  className="md:rounded md:py-2 text-xs"
                                                  value={descriptionConfig.paddingX}
                                                  name="paddingx"
                                                  title="Padding-X"
                                                  type="number"
                                                  min={0}
                                                  onChange={(e: any) => setDescriptionConfig({ ...descriptionConfig, paddingX: e.value })}
                                                  placeholder="Enter PaddingX"
                                             />
                                        </div>
                                        <div className="min-w-[150px] w-1/2">
                                             <PNormalInput
                                                  className="md:rounded md:py-2 text-xs"
                                                  value={descriptionConfig.paddingY}
                                                  name="paddingy"
                                                  title="Padding-Y"
                                                  type="number"
                                                  min={0}
                                                  onChange={(e: any) => setDescriptionConfig({ ...descriptionConfig, paddingY: e.value })}
                                                  placeholder="Enter PaddingY"
                                             />
                                        </div>
                                   </div>

                                   <div className="flex gap-5 mt-2">
                                        <div className="min-w-[150px] w-1/2">
                                             <div className="ml-1 font-[MontSerrat]">Position</div>
                                             <PDropdown
                                                  label=""
                                                  name="position"
                                                  required
                                                  value={descriptionConfig.position}
                                                  options={positionConstants}
                                                  allowClear={false}
                                                  onChange={(e: any) => {
                                                       setDescriptionConfig({
                                                            ...descriptionConfig,
                                                            position: e.value,
                                                       });
                                                  }}
                                             />
                                        </div>
                                        <div className="min-w-[150px] w-1/2">
                                             <div className="ml-1 font-[MontSerrat]">Font Family</div>
                                             <PDropdown
                                                  label=""
                                                  name="font"
                                                  required
                                                  value={descriptionConfig.font}
                                                  options={fontConstants}
                                                  allowClear={false}
                                                  onChange={(e: any) => {
                                                       setDescriptionConfig({
                                                            ...descriptionConfig,
                                                            font: e.value,
                                                       });
                                                  }}
                                             />
                                        </div>
                                   </div>

                                   <div className="flex gap-5 mt-2">
                                        
                                        <div className="common_normalInput w-1/2">
                                             <div className="ml-1 font-[MontSerrat]">Text Color</div>
                                             <ColorPicker
                                                  className={"h-[40px] w-[100%]"}
                                                  color={descriptionConfig?.color}
                                                  handleColor={(e: any) => {
                                                       setDescriptionConfig({ ...descriptionConfig, color: e.target.value })
                                                  }}
                                             />
                                        </div>

                                        <div className="common_normalInput w-1/2">
                                             <div className="ml-1 font-[MontSerrat]">Text Background Color</div>
                                             <ColorPicker
                                                  className={"h-[40px] w-[100%]"}
                                                  color={descriptionConfig?.descriptionBackgroundColor}
                                                  handleColor={(e: any) => {
                                                       setDescriptionConfig({ ...descriptionConfig, descriptionBackgroundColor: e.target.value })
                                                  }}
                                             />
                                        </div>
                                   </div>
                              </div>
                    }

               </div>
          </div>
     )
}

export default ImageEditTextAndPreview;
