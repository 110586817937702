import { Checkbox, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { timeSpan } from '../../../../../../utils/staticData';
import AddSchedule from './AddSchedule';

type AddRunningProps = {
    getDetailOfDaysAndTime: (data: any) => void;
    daysAndTimeDetails?: { scheduleLineItems: string; daysTime: boolean; schedule: { start: string; end: string; dontRunDays: boolean; repeatOn: string[] }[] };
    isValidate?: boolean;
    formFields?: any;
    setFormFields?: any;
};
interface TimeLabel {
    label: string;
    value: string;
}

interface TimeObject {
    AM: TimeLabel[];
    PM: TimeLabel[];
}
interface dataObj {
    start: string;
    end: string;
    repeatOn: string[];
    dontRunDays: boolean;
}
// interface ScheduleDaysTimes {
//     schedule: dataObj[];
//     daysTime: boolean;
//     scheduleLineItems: string;
// }
const AddRunning: React.FC<AddRunningProps> = ({ getDetailOfDaysAndTime, daysAndTimeDetails, isValidate, formFields, setFormFields }) => {
    const [overLapErrorArr, setOverLapErrorArr] = useState<any>([]);
    const generateTimeObject = (startTime?: any): TimeObject => {
        const times: TimeObject = { AM: [], PM: [] };
        const [startHour, startMinute, period] = startTime ? startTime.split(/:| /) : '00:00 AM';
        const totalMinutes = 24 * 60 - 15; // Total minutes until 12 PM
        const startMinutes = parseInt(startHour, 10) * 60 + parseInt(startMinute, 10) + (period === 'PM' ? 12 * 60 : 0);

        for (let minutes = startMinutes; minutes <= totalMinutes; minutes += 15) {
            const hour = Math.floor(minutes / 60) % 12 || 12;
            const minute = minutes % 60 === 0 ? '00' : minutes % 60;
            const currentPeriod = minutes < 720 ? 'AM' : 'PM';
            const formattedHour = hour.toString().padStart(2, '0');
            const timeLabel = `${formattedHour}:${minute.toString().padStart(2, '0')} ${currentPeriod}`;
            const timeObject: TimeLabel = { label: timeLabel, value: timeLabel };

            times.AM.push(timeObject);
        }

        return times;
    };
    const timeObject = generateTimeObject();
    const handleChangeDays = (e: { name: string; value: string | number | undefined | boolean }, selectedIndex: number) => {
        const { value, name } = e;
        const newData = [...(daysAndTimeDetails?.schedule || [])];
        newData[selectedIndex] = {
            ...newData[selectedIndex],
            [name]: value,
        };

        getDetailOfDaysAndTime({ ...daysAndTimeDetails, schedule: newData });
    };

    const selectItem = (item: string, selectedIndex: number) => {
        const index = daysAndTimeDetails?.schedule[selectedIndex].repeatOn.indexOf(item);
        if (index === -1) {
            console.log('item not there n list');
            const newData = [...(daysAndTimeDetails?.schedule || [])];
            newData[selectedIndex].repeatOn.push(item);

            getDetailOfDaysAndTime({ ...daysAndTimeDetails, schedule: newData });
        } else {
            const newData = [...(daysAndTimeDetails?.schedule || [])];
            const newSelected = [...newData[selectedIndex].repeatOn];
            console.log('from selected new data = ', newSelected);
            console.log('to remove', item, selectedIndex);
            let newArr = newSelected.filter((el) => {
                return el !== item;
            });
            newData[selectedIndex].repeatOn = newArr;

            getDetailOfDaysAndTime({ ...daysAndTimeDetails, schedule: newData });
        }
    };

    const handleRemoveSchedule = (selectedIndex: number) => {
        let dummyArr = daysAndTimeDetails?.schedule || [];
        dummyArr.splice(selectedIndex, 1);
        getDetailOfDaysAndTime({ ...daysAndTimeDetails, schedule: dummyArr });
    };

    const timeOverlap = (idx1: number, idx2: number) => {
        let data1 = daysAndTimeDetails?.schedule[idx1];
        let data2 = daysAndTimeDetails?.schedule[idx2];

        let d1StartTime = timeSpan[`${data1?.start}`];
        let d1EndTime = timeSpan[`${data1?.end}`];
        let d2StartTime = timeSpan[`${data2?.start}`];
        let d2EndTime = timeSpan[`${data2?.end}`];

        if (d1StartTime > d2EndTime) {
            return false;
        } else {
            if (d1EndTime < d2StartTime) {
                return false;
            }
        }
        return true;
    };

    const daysOverlap = (idx1: number, idx2: number) => {
        let days1: any = daysAndTimeDetails?.schedule[idx1].repeatOn;
        let days2: any = daysAndTimeDetails?.schedule[idx2].repeatOn;
        let newarr = Array.from(new Set([...days1, ...days2]));
        if (days1.length + days2.length > newarr.length) {
            return true;
        }
        return false;
    };

    const compareSchedules = (idx1: number, idx2: number) => {
        if (timeOverlap(idx1, idx2)) {
            if (daysOverlap(idx1, idx2)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const setGlobalOverlappingError = (arr: any) => {
        let ans = false;
        arr?.filter((item: any) => {
            if (item === true) {
                ans = true;
            }
        });
        setFormFields({
            ...formFields,
            overLapError: ans,
        });
    };

    const checkOverlappingSchedules = () => {
        const len = daysAndTimeDetails?.schedule?.length || 1;
        const errArr = new Array(len).fill(false);
        setOverLapErrorArr([...errArr]);
        if (len > 1) {
            for (let i = 1; i < len; i++) {
                for (let j = 0; j < i; j++) {
                    let ans = compareSchedules(i, j);
                    if (errArr[i] === false) {
                        errArr[i] = ans;
                    }
                }
            }
        } else {
            setFormFields({
                ...formFields,
                overLapError: false,
            });
        }
        setOverLapErrorArr(errArr);
        setGlobalOverlappingError(errArr);
    };
    useEffect(() => {
        checkOverlappingSchedules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [daysAndTimeDetails]);
    return (
        <div className="mt-6">
            <div className="flex gap-2">
                <Checkbox
                    checked={daysAndTimeDetails?.daysTime}
                    onChange={(e) => {
                        getDetailOfDaysAndTime({ ...daysAndTimeDetails, daysTime: e.target.checked });
                    }}
                />
                <span className="ml-1 text-sm">Set Days and times</span>
            </div>
            {daysAndTimeDetails?.daysTime && (
                <div className="ml-8">
                    <p className="text-sm mt-5">
                        Schedule line items using
                        <span className="text-red-500">{'*'}</span>
                    </p>
                    <Radio.Group
                        className="mt-2 mb-3"
                        onChange={(e) => {
                            getDetailOfDaysAndTime({ ...daysAndTimeDetails, scheduleLineItems: e.target.value });
                        }}
                        value={daysAndTimeDetails?.scheduleLineItems}
                    >
                        <Radio value="publisherTimeZone">Publisher's time Zone</Radio>
                        <Radio value="userTimeZone">User's time Zone</Radio>
                    </Radio.Group>
                    {isValidate && daysAndTimeDetails?.daysTime && !daysAndTimeDetails?.scheduleLineItems ? <div className="common_error">Please select zone</div> : null}
                    {daysAndTimeDetails?.schedule?.map((item: dataObj, index: number) => {
                        return (
                            <AddSchedule
                                index={index}
                                totalLength={daysAndTimeDetails?.schedule?.length}
                                scheduleEndOptions={generateTimeObject(item.start).AM}
                                scheduleStartValue={item.start}
                                scheduleEndValue={item.end}
                                handleChange={handleChangeDays}
                                setScheduleDaysTimes={getDetailOfDaysAndTime}
                                scheduleStartOptions={timeObject.AM}
                                repeatOn={item.repeatOn}
                                dontRunDays={item.dontRunDays}
                                selecctedIndex={index}
                                selectItem={selectItem}
                                handleRemoveSchedule={handleRemoveSchedule}
                                isValidate={isValidate}
                                showOverLapError={overLapErrorArr[index]}
                            />
                        );
                    })}
                    <p
                        className="cursor-pointer text-[#056433] text-sm mt-5 font-[Roboto-Medium] font-medium"
                        onClick={() => {
                            getDetailOfDaysAndTime({ ...daysAndTimeDetails, schedule: [...(daysAndTimeDetails?.schedule || []), { repeatOn: [], dontRunDays: false, start: '', end: '' }] });
                        }}
                    >
                        Add time Period
                    </p>
                </div>
            )}
        </div>
    );
};

export default AddRunning;
