import { CommonAction } from "../../../../message/redux/types";
import userActivitiesTypes, { UserActivitiesState } from "./type";

function fetchUserActivitiesList(payload: {startDate: string, endDate: string, module: any, email: any, campaign: any }): CommonAction {
     return {
          type: userActivitiesTypes.FETCH_USER_ACTIVITIES_LIST,
          payload: payload,
     };
}

function setUserActivitiesList(data?: {data?:UserActivitiesState[],count?:number}): CommonAction {
     return {
          type: userActivitiesTypes.SET_USER_ACTIVITIES_LIST,
          payload: data,
     };
}

function fetchAllUsersUserActivitiesList(): CommonAction {
     return {
          type: userActivitiesTypes.FETCH_ALL_USERS_USER_ACTIVITY_LIST,
          payload: null,
     };
}

function setAllUsersUserActivitiesList(data: any): CommonAction {
     return {
          type: userActivitiesTypes.SET_ALL_USERS_USER_ACTIVITY_LIST,
          payload: data,
     };
}

const UserActivitiesActions = {
     fetchUserActivitiesList,
     setUserActivitiesList,
     fetchAllUsersUserActivitiesList,
     setAllUsersUserActivitiesList,
}

export default UserActivitiesActions;
