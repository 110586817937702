import { CommonAction } from '../../../../../auth/login/redux/types';
import locationTypes from './types';
interface LocationItem {
    country: string;
    state: string;
    city: string;
}

type LocationData = Record<string, Record<string, string[]>>;
const transformLocationData = (data: LocationData): LocationItem[] => {
    return Object.entries(data || {}).flatMap(([country, states]) => Object.entries(states || {}).flatMap(([state, cities]) => cities.map((city) => ({ country, state, city }))));
};
const transformIntObj = (arr: any) => {
    return arr.reduce(
        (
            acc: any,
            item: {
                city: '';
                state: '';
                country: '';
            },
        ) => {
            const { country, state, city } = item;

            acc[country] = acc[country] || {};
            acc[country][state] = acc[country][state] || [];
            acc[country][state].push(city);

            return acc;
        },
        {},
    );
};
function getLocationAction(payload: any): CommonAction {
    return {
        type: locationTypes.FETCH_LOCATION,
        payload: payload,
    };
}
function setLocationAction(payload: any): CommonAction {
    return {
        type: locationTypes.SET_LOCATION,
        payload: payload,
    };
}
function modifyLocation(payload: any): CommonAction {
    return {
        type: locationTypes.MODIFY_LOCATION,
        payload: payload,
    };
}

function setAllDefaultCountries(payload: any): CommonAction {
    return {
        type: locationTypes.SET_ALLDEFAULT_COUNTRY_LOCATION,
        payload: payload,
    };
}

function fetchSingleCountryLocation(payload: any): CommonAction {
    return {
        type: locationTypes.FETCH_SINGLE_COUNTRY_LOCATION,
        payload: payload,
    };
}
function setCitiesLocationLoading(payload: any): CommonAction {
    return {
        type: locationTypes.SET_ALL_CITIES_LOCATION_LOADING,
        payload: payload,
    };
}

function setStateLocationLoading(payload: any): CommonAction {
    return {
        type: locationTypes.SET_ALL_STATES_LOCATION_LOADING,
        payload: payload,
    };
}

function setSingleCountryLocation(payload: any): CommonAction {
    return {
        type: locationTypes.SET_SINGLE_COUNTRY_LOCATION,
        payload: payload,
    };
}
function setSelectedLocation(payload: any) {
    const modify = {
        exclude: transformLocationData(payload.exclude),
        include: transformLocationData(payload.include),
        orgExclude: payload.exclude,
        orgInclude: payload.include,
    };
    return {
        type: locationTypes.SELECTED_LOCATION,
        payload: modify,
    };
}
function editLocation(payload: any) {
    const modify = {
        exclude: payload.exclude,
        include: payload.include,
        orgExclude: transformIntObj(payload.exclude),
        orgInclude: transformIntObj(payload.include),
    };
    return {
        type: locationTypes.EDIT_LOCATION,
        payload: modify,
    };
}
const LocationAction = {
    getLocationAction,
    setLocationAction,
    modifyLocation,
    setSelectedLocation,
    editLocation,
    fetchSingleCountryLocation,
    setSingleCountryLocation,
    setAllDefaultCountries,
    setCitiesLocationLoading,
    setStateLocationLoading,
};

export default LocationAction;
