/* Action Tyes */
const loginTypes = {
    LOGIN_REQ: 'LOGIN_REQ',
    LOGIN_REQ_SUCCESS: 'LOGIN_REQ_SUCCESS',
    LOGIN_REQ_FAILED: 'LOGIN_REQ_FAILED',
    SET_USER_DATA: 'SET_USER_DATA',
    REMOVE_USER_DATA: 'REMOVE_USER_DATA',
};

export default loginTypes;

export type LoginResponse = {
    jwt_token?: {
        token: string;
    };
    user?: User;
    reset_link?:string;
};

export interface User {
    id: number;
    publisher_id: string;
    full_name: string;
    email: string;
    publisher_type: string;
    access_type: string;
    business_name: string;
    status: string;
    new_user?:boolean | string;
    create_campaign?:boolean;
}

export interface CommonAction {
    type: string;
    payload: any;
}

export interface LoginForm {
    username: string;
    password: string;
}

export interface LoginState {
    loading: boolean;
    error?: string;
}

export interface UserState {
    user?: User;
    jwtToken?: string;
}

/*  actions protocals */
export interface loginReqAction {
    type: typeof loginTypes.LOGIN_REQ;
    payload: UserState;
}

export interface loginReqSuccessAction {
    type: typeof loginTypes.LOGIN_REQ_SUCCESS;
}

export interface loginReqFailedAction {
    type: typeof loginTypes.LOGIN_REQ_FAILED;
}

export interface setUserDataAction {
    type: typeof loginTypes.SET_USER_DATA;
    payload: UserState;
}

export interface removeUserDataAction {
    type: typeof loginTypes.REMOVE_USER_DATA;
}

export type LoginActionTypes = loginReqAction | loginReqSuccessAction | loginReqFailedAction;
