export const timeSpan:any= {
     '12:00 AM': 0,
     '12:15 AM': 1,
     '12:30 AM': 2,
     '12:45 AM': 3,
     '01:00 AM': 4,
     '01:15 AM': 5,
     '01:30 AM': 6,
     '01:45 AM': 7,
     '02:00 AM': 8,
     '02:15 AM': 9,
     '02:30 AM': 10,
     '02:45 AM': 11,
     '03:00 AM': 12,
     '03:15 AM': 13,
     '03:30 AM': 14,
     '03:45 AM': 15,
     '04:00 AM': 16,
     '04:15 AM': 17,
     '04:30 AM': 18,
     '04:45 AM': 19,
     '05:00 AM': 20,
     '05:15 AM': 21,
     '05:30 AM': 22,
     '05:45 AM': 23,
     '06:00 AM': 24,
     '06:15 AM': 25,
     '06:30 AM': 26,
     '06:45 AM': 27,
     '07:00 AM': 28,
     '07:15 AM': 29,
     '07:30 AM': 30,
     '07:45 AM': 31,
     '08:00 AM': 32,
     '08:15 AM': 33,
     '08:30 AM': 34,
     '08:45 AM': 35,
     '09:00 AM': 36,
     '09:15 AM': 37,
     '09:30 AM': 38,
     '09:45 AM': 39,
     '10:00 AM': 40,
     '10:15 AM': 41,
     '10:30 AM': 42,
     '10:45 AM': 43,
     '11:00 AM': 44,
     '11:15 AM': 45,
     '11:30 AM': 46,
     '11:45 AM': 47,
     '12:00 PM': 48,
     '12:15 PM': 49,
     '12:30 PM': 50,
     '12:45 PM': 51,
     '01:00 PM': 52,
     '01:15 PM': 53,
     '01:30 PM': 54,
     '01:45 PM': 55,
     '02:00 PM': 56,
     '02:15 PM': 57,
     '02:30 PM': 58,
     '02:45 PM': 59,
     '03:00 PM': 60,
     '03:15 PM': 61,
     '03:30 PM': 62,
     '03:45 PM': 63,
     '04:00 PM': 64,
     '04:15 PM': 65,
     '04:30 PM': 66,
     '04:45 PM': 67,
     '05:00 PM': 68,
     '05:15 PM': 69,
     '05:30 PM': 70,
     '05:45 PM': 71,
     '06:00 PM': 72,
     '06:15 PM': 73,
     '06:30 PM': 74,
     '06:45 PM': 75,
     '07:00 PM': 76,
     '07:15 PM': 77,
     '07:30 PM': 78,
     '07:45 PM': 79,
     '08:00 PM': 80,
     '08:15 PM': 81,
     '08:30 PM': 82,
     '08:45 PM': 83,
     '09:00 PM': 84,
     '09:15 PM': 85,
     '09:30 PM': 86,
     '09:45 PM': 87,
     '10:00 PM': 88,
     '10:15 PM': 89,
     '10:30 PM': 90,
     '10:45 PM': 91,
     '11:00 PM': 92,
     '11:15 PM': 93,
     '11:30 PM': 94,
     '11:45 PM': 95
   }