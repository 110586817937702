import { useState } from "react"
import { IoEyeOutline } from "react-icons/io5"
import PModal from "../../../../common/Modal";
import { RiCloseCircleLine } from "react-icons/ri";

type Props = {
     data: any;
}

const CompareOldNewValuesModal: React.FC<Props> = ({ data }) => {
     const [open, setOpen] = useState(false);

     const modalView = (
          <div className="h-[600px]">
               <div className="flex justify-between items-center">
                    <div className="text-[17px] font-[Montserrat] font-extrabold">Configuration Comparison</div>
                    <div className="cursor-pointer" onClick={()=>setOpen(false)}><RiCloseCircleLine size={22} /></div>
               </div>
               <div className="flex font-[Montserrat] font-bold text-[14px] mt-2">
                    <div className="w-1/2 bg-[#b4d0c1] text-center py-2 rounded-tl-xl">Configuration Old Values</div>
                    <div className="w-1/2 bg-[#b4d0c1] text-center py-2 rounded-tr-xl">Configuration New Values</div>
               </div>
               <div className="flex h-[calc(100%-60px)] overflow-y-scroll">
                    <div className="w-1/2 border-r-[1px] p-3">
                         <pre>
                              {JSON.stringify(data?.configuration_old_value, null, 2)}
                         </pre>

                    </div>
                    <div className="w-1/2 border-l-[1px] p-3">
                         <pre>
                              {JSON.stringify(data?.configuration_new_value, null, 2)}
                         </pre>
                    </div>
               </div>
          </div>
     )
     return (
          <div>
               <div className="cursor-pointer">
                    <IoEyeOutline size={16} onClick={() => setOpen(true)} />
               </div>
               <PModal
                    isModalOpen={open}
                    OnClickOfCancel={() => setOpen(false)}
                    width="900px"
                    className="noscrollbar"
                    ViewOfModal={modalView}
               />
          </div>
     )
}

export default CompareOldNewValuesModal