import React from 'react';

const BackDrop: React.FC = () => {
    return (
        <div className="fixed top-0 w-full max-h-screen bottom-0 bg-black opacity-70 flex justify-center items-center left-0" style={{ zIndex: 100000 }}>
            <div className="h-screen flex w-full  justify-center items-center">
                <div style={{ borderTopColor: 'transparent' }} className={`w-10 h-10 border-4 border-white border-solid rounded-full animate-spin`} />
            </div>
        </div>
    );
};

export default BackDrop;
