import { Dropdown, MenuProps } from "antd";
import ThreeDots from "../../../../../assets/images/threeDots.png";
import {useMemo, useState } from "react";
import ConfirmModal from "./confrmModal";
import AssignModal from "./assignModal";
import Apis from "../../../../../api";
import { useDispatch, useSelector } from "react-redux";
import MessageActions from "../../../../message/redux/actions";
import { RootState } from "../../../../../store/RootReducer";
import { ManageActionState } from "../redux/type";
import UpdateModal from "./updateModal";
type Props = {
     id: string;
     fetchUsersList: any;
     campList: any[];
     name: string;
     email: string;
     searchText: string;
     setSearchText: any;
     row: any;
}
const ManagementActions: React.FC<Props> = ({ id, fetchUsersList, campList, name, email, searchText, setSearchText, row }) => {
     const dispatch = useDispatch();
     const [actionState, setActionState] = useState<ManageActionState>({
          assignModal: false,
          updateModal:false,
          deleteModal: false,
          type: ''
     })
     const [loading, setLoading] = useState(false);
     const globalUserRole = useSelector((state: RootState) => state.user.user?.publisher_type);



     const items = useMemo(() => {
          let itemList: MenuProps['items'] = [
               {
                    key: 'Assign',
                    label: 'Assign',
               },

          ];
          if (globalUserRole === "super_admin" || globalUserRole === "admin") {
               itemList.push({
                    key: 'Update',
                    label: 'Update',
               })
          }
          if (globalUserRole === "super_admin") {
               itemList.push({
                    key: 'Delete',
                    label: 'Delete',
               })
          }

          return itemList;
     }, [globalUserRole])

     const onMenuClick: MenuProps['onClick'] = (e) => {
          const key = e.key;
          switch (key) {
               case 'Assign':
                    setActionState({ assignModal: true,updateModal:false, deleteModal: false, type: 'Assign' });
                    break;

               case 'Update':
                    setActionState({ assignModal: false,updateModal:true, deleteModal: false, type: 'Update' });
                    break;

               case 'Delete':
                    setActionState({ assignModal: false,updateModal:false, deleteModal: true, type: 'Delete' });
                    break;

               default:
                    setActionState({ assignModal: false,updateModal:false, deleteModal: false, type: '' });
                    break;
          }
     };

     const handleDeleteUser = () => {
          if (id) {
               setLoading(true);
               Apis.deleteUserManagementUser({ user_id: id })
                    .then((res: any) => {
                         setActionState({ assignModal: false,updateModal:false, deleteModal: false, type: '' });
                         dispatch(MessageActions.showMessage({ text: String(res.data?.data?.message), error: false }));
                         fetchUsersList();
                    }).catch((err) => {
                         setActionState({ assignModal: false,updateModal:false, deleteModal: false, type: '' });
                         dispatch(MessageActions.showMessage({ text: String(err?.response?.data?.data?.message), error: true }));
                    }).finally(() => setLoading(false))
          }
     }

     return (
          <div>
               <div className=" w-[30px]">
                    <Dropdown
                         menu={{
                              items,
                              onClick: onMenuClick,
                         }}
                         placement="bottomLeft"
                         trigger={['click']}
                         overlayClassName="common_dropdown"
                    //disabled={items.length === 0}
                    >
                         <img className="w-[28px] h-[28px]" src={ThreeDots} alt="icon"></img>
                    </Dropdown>
               </div>
               <ConfirmModal
                    setActionState={setActionState}
                    open={actionState.deleteModal}
                    closeModal={() => setActionState({ ...actionState, deleteModal: false })}
                    onOkay={handleDeleteUser}
                    loading={loading}
                    msg={`Are you sure you want to delete user ${name}`}
                    title="Delete User"
               />

               <AssignModal
                    setActionState={setActionState}
                    open={actionState.assignModal}
                    closeModal={() => setActionState({ ...actionState, assignModal: false })}
                    title="Assign Campaign"
                    id={id}
                    campList={campList}
                    fetchUsersList={fetchUsersList}
                    name={name}
                    email={email}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    row={row}
               />
               <UpdateModal
                    setActionState={setActionState}
                    open={actionState.updateModal}
                    closeModal={() => setActionState({ ...actionState, updateModal: false })}
                    email={email}
                    prevName={name}
                    id={id}
                    fetchUsersList={fetchUsersList}
               />
          </div>
     )
}

export default ManagementActions;
