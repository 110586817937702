import { Spin } from 'antd';
import React, { useEffect } from 'react'
import PCard from '../../../../common/Card';
import DeltaBox from '../../../../common/DeltaBox';
import { commaSeperator } from '../../../../../utils/Validation';
import { useDispatch, useSelector } from 'react-redux';
import OverAllReportAction from '../redux/actions';
import { RootState } from '../../../../../store/RootReducer';

type Props = {
    startDate: any;
    endDate: any;
    campId: any;
}
const ReportTopCards: React.FC<Props> = ({ startDate, endDate, campId = 'overall' }) => {
    const dispatch = useDispatch();
    const topCardData = useSelector((state: RootState) => state.report.overallReportTopCards)
    const topCardDataLoader = useSelector((state: RootState) => state.report.overallReportTopCardsLoader)
    useEffect(() => {
        if (startDate && endDate && campId) {
            dispatch(
                OverAllReportAction.fetchOverAllReportTopCards({
                    start_dt: startDate,
                    end_dt: endDate
                }, campId)
            )
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, campId])
    const tops = [
        {
            title: 'Budget Spent',
            percentage: parseFloat(String(topCardData?.percentage_budget_spent || 0)).toFixed(2),
            isUp: 0,
            value: `$${commaSeperator(parseFloat(String(topCardData?.new_budget_spent || 0)).toFixed(2))}`,
            budgetPercentage: `${parseFloat(String(topCardData?.budget_percentage || 0)).toFixed(2)}%`,
            totalBudget: `$${commaSeperator(parseFloat(String(topCardData?.total_budget || 0)).toFixed(2))}`,
            loading: topCardDataLoader,
            key: 'Budget Spent',
        },
        {
            title: 'eCPM',
            percentage: parseFloat(String(topCardData?.percentage_eCPM || 0)).toFixed(2),
            isUp: 0,
            value: `$${commaSeperator(parseFloat(String(topCardData?.new_eCPM || 0)).toFixed(2))}`,
            loading: topCardDataLoader,
            key: 'ECPM',
        },
        {
            title: 'Click Through Rate (CTR)',
            percentage: parseFloat(String(topCardData?.percentage_CTR || 0)).toFixed(2),
            isUp: 0,
            value: `${parseFloat(String(topCardData?.new_CTR)).toFixed(2) || '0.00'}%`,
            loading: topCardDataLoader,
            key: 'CTR',
        },
        {
            title: 'Ad Impressions',
            percentage: parseFloat(String(topCardData?.percentage_ad_impression || 0)).toFixed(2),
            isUp: 0,
            value: commaSeperator(topCardData?.new_ad_impression || 0),
            loading: topCardDataLoader,
            key: 'Ad Impressions',
        },
    ];
    return (
        <div className="pt-4">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
                {tops.map((t, index) => (

                    <PCard
                        className="px-4 py-3 pb-2 w-full font-[Roboto] cursor-pointer topCard rounded-lg flex flex-col justify-between"
                        key={`${index}_${t.title}`}
                    >
                        <div className="flex justify-between items-center">
                            <p className="text-xs roboto-medium color-056433 title">{t.title}</p>
                            {!t.loading ? <DeltaBox width="w-auto" row={t.percentage} /> : <div className="h-7" />}
                        </div>
                        <div className="my-4 mb-3">
                            {!t.loading && (<p className="roboto-medium font-semibold text-2xl value">{t.value}</p>)}
                            {t.loading && (
                                <div className="flex justify-center items-center">
                                    <Spin />
                                </div>
                            )}
                        </div>
                    </PCard>
                ))}
            </div>
        </div>
    )
}

export default ReportTopCards

