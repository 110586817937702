import React, { useEffect, useState } from 'react'
import PTable from '../../../../../common/Table';
import { commaSeperator } from '../../../../../../utils/Validation';
import PCard from '../../../../../common/Card';
import { Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import OverAllReportAction from '../../redux/actions';
import { RootState } from '../../../../../../store/RootReducer';
import { TabsPerformanceTable } from '../../redux/type';

type Props = {
  startDate?: string;
  endDate?: string;
  campId?: string;
}

const DevicePerformanceTable: React.FC<Props> = ({ startDate, endDate, campId }) => {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();

  const topDevicesData = useSelector((state: RootState) => state.report.campReportsTopDevicesTableData);
  const topDevicesLoader = useSelector((state: RootState) => state.report.campReportsTopDevicesTableLoader);
  const totalCount = useSelector((state: RootState) => state.report.campReportsTopDevicesTableRecordCount);

  useEffect(() => {
    dispatch(OverAllReportAction.fetchCampReportTopDevicesTable({
      start_dt: startDate,
      end_dt: endDate,
      value: 'device'
    }, campId,pageSize,pageNo));
  }, [startDate, endDate, campId, pageNo, pageSize, dispatch])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text: string) => (
        <p>
          {text}
        </p>
      ),
      sorter: (a: TabsPerformanceTable, b: TabsPerformanceTable) => String(a?.name).localeCompare(String(b?.name)),
    },
    {
      title: 'eCPM',
      dataIndex: 'eCPM',
      render: (text: string) => (
        <p className='flex justify-end'>
          ${text ? commaSeperator(parseFloat(text).toFixed(2)) : '0'}
        </p>
      ),
      sorter: (a: TabsPerformanceTable, b: TabsPerformanceTable) => a?.eCPM - b?.eCPM,
    },
    {
      title: 'Click Through Rate(CTR)',
      dataIndex: 'CTR',
      render: (text: string) => (
        <p className='flex justify-end'>
          {text ? parseFloat(text).toFixed(1) : 0}%
        </p>
      ),
      sorter: (a: TabsPerformanceTable, b: TabsPerformanceTable) => a?.CTR - b?.CTR,
    },
    {
      title: 'Ad Impressions',
      dataIndex: 'ad_impressions',
      render: (text: string) => (
        <p className='flex justify-end'>
          {text ? commaSeperator(text) : 0}
        </p>
      ),
      sorter: (a: TabsPerformanceTable, b: TabsPerformanceTable) => a?.ad_impressions - b?.ad_impressions,
    },
    {
      title: 'Spent',
      dataIndex: 'budget_spent',
      render: (text: string) => (
        <p className='flex justify-end'>
          ${commaSeperator(parseFloat(text).toFixed(2))}
        </p>
      ),
      sorter: (a: TabsPerformanceTable, b: TabsPerformanceTable) => a?.budget_spent - b?.budget_spent,
    },
  ];
  return (
    <div className="mt-1">
      <PCard>
        <PTable columns={columns} className="dashboard_table" data={topDevicesData} loading={topDevicesLoader} />
        <div className='flex justify-end py-2'>
          <Pagination
            current={pageNo}
            total={totalCount}
            onChange={(e) => {
              setPageNo(e);
            }}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
            showSizeChanger={true}
            onShowSizeChange={(e, i) => {
              setPageSize(i);
            }}
            pageSizeOptions={[10, 20, 50, 100]}
          />
        </div>
      </PCard>
    </div>
  )
}

export default DevicePerformanceTable
