import campaignTypes, { CampaignState, emptyConstants } from './type';

const initialState: CampaignState = {
    campaigns: [],
    totalRecords: 0,
    loading: false,
    constants: emptyConstants,
    activeCampaigns: [],
    activeCampaignsLoading: false,
    pausedCampaigns: [],
    pausedCampaignsLoading: false,
    completedCampaigns: [],
    completedCampaignsLoading: false,
    activeCampaignsTotalRecords: 0,
    pausedCampaignsTotalRecords: 0,
    completedCampaignsTotalRecords: 0
};

const campaignReducer = (state = initialState, action: { type: string; payload: any }): CampaignState => {
    switch (action.type) {
        case campaignTypes.FETCH_CAMPAIGNS:
            return {
                ...state,
                loading: true,
                campaigns: [],
                totalRecords: 0
            };
        case campaignTypes.SET_CAMPAIGNS:
            return {
                ...state,
                loading: false,
                campaigns: action.payload.data,
                totalRecords: action.payload.totalRecords
            };
        case campaignTypes.SET_CONSTANTS:
            return {
                ...state,
                constants: action.payload,
            };
        //active campaigns
        case campaignTypes.FETCH_ACTIVE_CAMPAIGNS:
            return {
                ...state,
                activeCampaignsLoading: true,
                activeCampaigns: [],
                activeCampaignsTotalRecords: 0
            };
        case campaignTypes.SET_ACTIVE_CAMPAIGNS:
            return {
                ...state,
                activeCampaignsLoading: false,
                activeCampaigns: action.payload.data,
                activeCampaignsTotalRecords: action.payload.totalRecords
            };

             //paused campaigns
        case campaignTypes.FETCH_PAUSED_CAMPAIGNS:
            return {
                ...state,
                pausedCampaignsLoading: true,
                pausedCampaigns: [],
                pausedCampaignsTotalRecords: 0
            };
        case campaignTypes.SET_PAUSED_CAMPAIGNS:
            return {
                ...state,
                pausedCampaignsLoading: false,
                pausedCampaigns: action.payload.data,
                pausedCampaignsTotalRecords: action.payload.totalRecords
            };

                  //completed campaigns
        case campaignTypes.FETCH_COMPLETED_CAMPAIGNS:
            return {
                ...state,
                completedCampaignsLoading: true,
                completedCampaigns: [],
                completedCampaignsTotalRecords: 0
            };
        case campaignTypes.SET_COMPLETED_CAMPAIGNS:
            return {
                ...state,
                completedCampaignsLoading: false,
                completedCampaigns: action.payload.data,
                completedCampaignsTotalRecords: action.payload.totalRecords
            };
        default:
            return state;
    }
};

export default campaignReducer;
