import locationTypes from './types';
interface locationState {
    location: any;
    modifyLocation: any;
    allDefaultCountries: any;
    loading: boolean;
    countryLoading: boolean;
    stateLoading: boolean;
    exclude: any;
    include: any;
    orgInclude: object;
    orgExclude: object;
}
const initialState: locationState = {
    location: [],
    modifyLocation: {},
    allDefaultCountries: {},
    loading: false,
    countryLoading: false,
    stateLoading: false,
    exclude: [],
    include: [],
    orgInclude: {},
    orgExclude: {},
};

const locationReducer = (state = initialState, action: { type: string; payload: any }): locationState => {
    switch (action.type) {
        case locationTypes.FETCH_LOCATION:
            return {
                ...state,
                loading: true,
                location: [],
            };
        case locationTypes.SET_LOCATION:
            return {
                ...state,
                loading: false,
                location: action.payload,
            };
        case locationTypes.MODIFY_LOCATION:
            return {
                ...state,
                loading: false,
                modifyLocation: action.payload,
            };

        case locationTypes.SET_ALLDEFAULT_COUNTRY_LOCATION:
            return {
                ...state,
                modifyLocation: action.payload,
                allDefaultCountries: action.payload,
            };
        case locationTypes.SELECTED_LOCATION:
            return {
                ...state,
                exclude: action.payload.exclude,
                include: action.payload.include,
                orgExclude: action.payload.orgExclude,
                orgInclude: action.payload.orgInclude,
            };

        case locationTypes.EDIT_LOCATION:
            return {
                ...state,
                exclude: action.payload.exclude,
                include: action.payload.include,
                orgExclude: action.payload.orgExclude,
                orgInclude: action.payload.orgInclude,
            };

        case locationTypes.FETCH_SINGLE_COUNTRY_LOCATION:
            return {
                ...state,
            }

        case locationTypes.SET_SINGLE_COUNTRY_LOCATION:
            return {
                ...state,
                modifyLocation: action.payload,
                countryLoading: false
            }
        case locationTypes.SET_ALL_CITIES_LOCATION_LOADING:
            return {
                ...state,
                stateLoading: action.payload,
                countryLoading: action.payload
            }
        // case locationTypes.SET_ALL_CITIES_LOCATION_LOADING:
        //     return {
        //         ...state,
        //         countryLoading: action.payload
        //     }
        default:
            return state;
    }
};

export default locationReducer;
