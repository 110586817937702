import { CommonAction } from "../../../../message/redux/types";
import userManagementTypes, { AssignCampList } from "./type";

function fetchUserManagementUserList(pageSize?: number | string, pageNo?: number | string, activeTab?: string): CommonAction {
     return {
          type: userManagementTypes.FETCH_USER_MANAG_USER_LIST,
          payload: { pageNo: pageNo, pageSize: pageSize, activeTab: activeTab },
     };
}

function setUserManagementUserList(data: any): CommonAction {
     return {
          type: userManagementTypes.SET_USER_MANAG_USER_LIST,
          payload: data,
     };
}

function fetchAllAssignCampList(search: string): CommonAction {
     return {
          type: userManagementTypes.FETCH_ASSIGN_CAMP_LIST,
          payload: search,
     };
}

function setAllAssignCampList(data: AssignCampList[]): CommonAction {
     return {
          type: userManagementTypes.SET_ASSIGN_CAMP_LIST,
          payload: data,
     };
}

const UserManagementActions = {
     fetchUserManagementUserList,
     setUserManagementUserList,
     fetchAllAssignCampList,
     setAllAssignCampList,
}

export default UserManagementActions;
