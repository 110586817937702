import userActivitiesTypes, { UserActivitiesState } from "./type";

const initialState: UserActivitiesState = {
     userActivityListData: [],
     userActivityListDataLoader: false,
     allUsersUserActivityListData: [],
     allUsersUserActivityListDataLoader: false,
     userActivityListDataCount: 0
};

const userActivityReducer = (state = initialState, action: { type: string; payload: any }): UserActivitiesState => {
     switch (action.type) {
          case userActivitiesTypes.FETCH_USER_ACTIVITIES_LIST:
               return {
                    ...state,
                    userActivityListData: [],
                    userActivityListDataCount: 0,
                    userActivityListDataLoader: true
               };

          case userActivitiesTypes.SET_USER_ACTIVITIES_LIST:
               return {
                    ...state,
                    userActivityListData: action.payload.data,
                    userActivityListDataCount: action.payload.count,
                    userActivityListDataLoader: false
               };

          case userActivitiesTypes.FETCH_ALL_USERS_USER_ACTIVITY_LIST:
               return {
                    ...state,
                    allUsersUserActivityListDataLoader: true,
               };
          case userActivitiesTypes.SET_ALL_USERS_USER_ACTIVITY_LIST:
               return {
                    ...state,
                    allUsersUserActivityListData: action.payload,
                    allUsersUserActivityListDataLoader: false,
               };

          default:
               return state;
     }
}

export default userActivityReducer;
