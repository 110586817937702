import api from './apiServices';

/**Login */
const login = (payload: Object) => api.post(`/login`, payload);
const register = (payload: Object) => api.post(`/register`, payload);
const forgotPassword = (payload: Object) => api.post(`/forgot-password`, payload);
const resetPassword = (payload: Object) => api.post(`/reset-password`, payload);
const fetchUserDetails = () => api.get(`user-details`);

/**Fetch All Form Data */
const fetchConstants = () => api.get(`/pre-campaign/get-form-data`);
const fetchCampaigns = () => api.get(`/campaigns`);
const fetchActiveCampaigns = () => api.get(`/campaigns?status=Active`);
const fetchPausedCampaigns = () => api.get(`/campaigns?status=Paused`);
const fetchCompletedCampaigns = () => api.get(`/campaigns?status=Completed`);

const createCampaign = (payload: Object) => api.post(`/campaigns/create`, payload);
const editCampaign = (payload: Object, campaign_id: string) => api.post(`/campaigns/${campaign_id}`, payload)
const campaignStatusUpdate = (campaignUUID: string, payload: Object) => api.post(`/campaigns/${campaignUUID}/update-status`, payload);
/** validate Campaign Name */
const validateCampaignName = (payload: { campaign_name: string }) => api.get('/pre-campaign/validate-unique-campaign-name', { params: payload })

/** get Location */
const getLocation = (payload: { search: string, type: string }) => api.get('/pre-campaign/get-locations-by-city-state-country', { timeout: Infinity, params: payload })

// get comapaign view according to id
const fetchCompaignView = (campaign_id: string) => api.get(`/campaigns/${campaign_id}`)
const generateTemperoryCampeign = (id: string) => api.get(`/scheduled/temporaryCampaignGenerate/${id}`)

//OverAllReportApis
const fetchOverAllReportTopCardsApi = (payload: Object, id: string) => api.post(`/reports/${id}`, payload);
const fetchReportsBudgetSpentGraphApi = (payload: Object, id: string) => api.post(`/reports/budget-spent/${id}`, payload);
const fetchReportCTRGraphApi = (payload: Object, id: string) => api.post(`/reports/click-through-rate/${id}`, payload);
const fetchReportImpClickGraphApi = (payload: Object, id: string) => api.post(`/reports/impressions-clicks/${id}`, payload);
const fetchReportsTopDataGraphApi = (payload: Object, id: string) => api.post(`/reports/topdata/${id}`, payload);
const fetchReportsCampeignsApi = (payload: Object, pageNo: number, pageSize: number) => api.post(`/reports/campaign-performance/overall-report?per_page=${pageSize}&page=${pageNo}`, payload);
const fetchReportsSitesApi = (payload: Object, pageNo: number, pageSize: number) => api.post(`/reports/overall-report/site-performance?per_page=${pageSize}&page=${pageNo}`, payload);
const fetchCampsReportTopPerformanceUnitTableApi = (payload: Object, id: string, pageSize: number, pageNo: number) => api.post(`/reports/top-campaign-performance/${id}?per_page=${pageSize}&page=${pageNo}`, payload);

//realtime apis
const fetchRealTimeReportImpClickGraphApi = (payload: Object, id: string) => api.post(`/reports/hourly-impressions-clicks/${id}`, payload);
const exportIndividualCampTopDataApi = (payload: Object, id: string) => api.post(`/exports/export-data/${id}`, payload, { responseType: 'arraybuffer' });

//impressiondownload
const exportImpressionReport = (payload: Object, id: string) => api.post(`/exports/export-imps-clicks/${id}`, payload, { responseType: 'arraybuffer' });
const exportCampaignPerformance = (payload: Object) => api.post('/exports/campaign-performance', payload, { responseType: 'arraybuffer' })

//user management
const userManagementUserListApi = (pageSize: number, pageNo: number, activeTab: string) => api.get(`/campaigns/users/list?per_page=${pageSize}&page=${pageNo}&user_status=${activeTab}`);
const updateUserManagementUserStatus = (payload: Object) => api.post(`/campaigns/users/status`, payload);
const deleteUserManagementUser = (payload: Object) => api.post(`/campaigns/users/delete`, payload);
const updateUserManagementUserRole = (payload: Object) => api.post(`/campaigns/users/role`, payload);
const assignCampUserManagement = (payload: Object) => api.post(`/campaigns/users/assign`, payload);
const assignCampList = (search: string) => api.get(`/campaigns/camp/assign?search=${search}`);
const updateUserManagementUserDetails = (payload: Object) => api.post(`/user-update`, payload);

//user activity
const fetchUserActivitiesDataList = ( startDate: string, endDate: string, module: string, email: string, campaign: string) =>
    api.get(`/user-activities?start_dt=${startDate}&end_dt=${endDate}&module_name=${module}&user_email=${email}&campaign_name=${campaign}`);
const fetchAllUsersListActivitiesApi = () => api.get(`/campaigns/users/listing`);
const Apis = {
    login,
    register,
    forgotPassword,
    resetPassword,
    fetchUserDetails,
    fetchConstants,
    fetchCampaigns,
    fetchActiveCampaigns,
    fetchPausedCampaigns,
    fetchCompletedCampaigns,
    createCampaign,
    campaignStatusUpdate,
    validateCampaignName,
    getLocation,
    fetchCompaignView,
    editCampaign,
    generateTemperoryCampeign,
    fetchOverAllReportTopCardsApi,
    fetchReportsBudgetSpentGraphApi,
    fetchReportCTRGraphApi,
    fetchReportImpClickGraphApi,
    fetchReportsTopDataGraphApi,
    fetchReportsCampeignsApi,
    fetchReportsSitesApi,
    fetchCampsReportTopPerformanceUnitTableApi,
    fetchRealTimeReportImpClickGraphApi,
    exportIndividualCampTopDataApi,
    exportImpressionReport,
    exportCampaignPerformance,
    userManagementUserListApi,
    updateUserManagementUserRole,
    updateUserManagementUserStatus,
    deleteUserManagementUser,
    assignCampUserManagement,
    assignCampList,
    updateUserManagementUserDetails,
    fetchUserActivitiesDataList,
    fetchAllUsersListActivitiesApi
};

export default Apis;
