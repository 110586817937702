import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
// import {  useSelector } from 'react-redux';
// import { RootState } from '../../../../../store/RootReducer';
import CustomPagination from '../../../../common/CustomPagination';
import usePagination from '../../../../common/usePagination';

interface LocationItem {
    country: string;
    state: string;
    city: string;
}

interface LocationItemsProps {
    color?: string;
    title?: string;
    locationIncludeData: any[];
    setLocationData: any;
    locationData: any;
    type: string;
}

const useLocationItemsPagination = (items: LocationItem[], type: string, setLocationData: any, locationData: any) => {
    const { currentPage, setCurrentPage, currentItems, totalPages, perPageSize } = usePagination(items, {
        initialPage: 1,
        itemsPerPage: 10,
    });
   // const { orgExclude, orgInclude } = useSelector((state: RootState) => state.location);
    // let locationExIncData: any = { include: orgInclude || {}, exclude: orgExclude || {} };

    const getIndex = (option: string, data: { country: string, state: string, city: string }) => {
        const arr = [...items];
        for (let i = 0; i < arr?.length; i++) {
            if ((arr[i]?.country === data?.country) && (arr[i]?.city === data?.city) && (arr[i]?.state === data?.state)) {
                return i;
            }
        }
        return -1;
    }

    const handleClear = (country: string, state: string, city: string, option: string) => {
        const index = getIndex(type, { country, state, city });
        let arr = [...items];
        if (index !== -1) {
            arr.splice(index, 1);
        }
        if (option === 'include') {
            setLocationData({ ...locationData, include: arr })
        } else {
            setLocationData({ ...locationData, exclude: arr })
        }
    };
    const listView = currentItems.map((item, index) => (
        <div key={index} className="mt-4 ml-4 flex gap-1 justify-between items-center">
            {item.country === item.state && item.state === item.city ?
                <span className="font-[Roboto] font-bold text-xs flex mb-1">
                    {`${item.country}`}
                </span>
                :
                (item.country !== item.state && item.state === item.city) ?
                    <span className="font-[Roboto] font-bold text-xs flex mb-1">
                        {`${item.country}, ${item.state}, `}
                    </span>
                    :
                    <span className="font-[Roboto] font-bold text-xs flex mb-1">
                        {`${item.country}, ${item.state}, `}
                        <p className="flex font-[Roboto] md:font-normal text-xs ml-2">{item.city}</p>
                    </span>
            }
            <AiOutlineClose size={'.85rem'} className="cursor-pointer ml-2 opacity-50" onClick={() => handleClear(item.country, item.state, item.city, type)} />
        </div>
    ));

    return {
        listView: items.length ? (
            <>
                {listView}
                <CustomPagination currentPage={currentPage} totalPages={totalPages} data={items} perPageSize={perPageSize} setCurrentPage={setCurrentPage} />
            </>
        ) : (
            <div key="empty" />
        ),
        listLength: items.length,
    };
};

const LocationItems: React.FC<LocationItemsProps> = ({ locationIncludeData, color = '', title = '', type, setLocationData, locationData }) => {
    const { listView, listLength } = useLocationItemsPagination(locationIncludeData, type, setLocationData, locationData);

    return (
        <>
            <p className={`cursor-pointer text-[${color}] flex justify-start font-[Roboto-Medium] font-medium text-xs mt-10 ml-4`}>
                {title} ({listLength})
            </p>
            {listView}
        </>
    );
};

export default LocationItems;
