import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DateFilter from '../../../common/DateFilter';
import moment from 'moment-timezone';
import ReportTopCards from './components/ReportTopCards';
import BudgetSpentGraph from './components/BudgetSpentGraph';
import ImpressionClickGraph from './components/ImpressionClickGraph';
import TopGraphForMediaSiteCountryDevice from './components/TopGraphForMediaSiteCountryDevice';
import CapmaignPerformanceTable from './components/CapmaignPerformanceTable';
import PerformanceTabsTable from './components/PerformanceTables/PerformanceTabsTable';
import HourPellet from './components/HourPellet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/RootReducer';
import { commaSeperator } from '../../../../utils/Validation';
import PTabs from '../../../common/Tabs';
import SitePerformanceTable from './components/SitePerformanceTable';
import PButton from '../../../common/Button';
import Apis from '../../../../api';
import download from 'downloadjs';
import MessageActions from '../../../message/redux/actions';
export const API_date_format = 'YYYY-MM-DD';


const OverallReport: React.FC = () => {
     const dispatch = useDispatch();
     const navigate = useNavigate();
     const location = useLocation();
     const params = useParams();
     const [applyDate, setApplyDate] = useState('last7Day');
     const [hourSelected, setHourSelected] = useState('1');
     const [customEndDate, setCustomEndDate] = useState<string | undefined>(undefined);
     const [customStartDate, setCustomStartDate] = useState<string | undefined>(undefined);
     const [compare, setCompare] = useState(false);
     const [dstring2, setDatestring2] = useState({ sdate: '', edate: '' });
     const topCardData = useSelector((state: RootState) => state.report.overallReportTopCards)
     const endDate = useMemo(() => {
          switch (applyDate) {
               case 'last1Day':
               case 'last7Day':
               case 'last30Day':
                    return moment().startOf('day').subtract(1, 'day').format(API_date_format);
               case 'today':
                    return "";
               default:
                    return customEndDate;
          }
     }, [applyDate, customEndDate]);

     const startDate = useMemo(() => {
          switch (applyDate) {
               case 'last1Day':
                    return moment().startOf('day').subtract(1, 'day').format(API_date_format);
               case 'last7Day':
                    return moment().subtract(7, 'day').format(API_date_format);
               case 'last30Day':
                    return moment().subtract(30, 'day').format(API_date_format);
               case 'today':
                    return "";
               default:
                    return customStartDate;
          }
     }, [applyDate, customStartDate]);

     const [downloadLoader, setDownloadLoader] = useState<boolean>(false);
     const [activeTab, setActiveTab] = useState<string>('Top Campaign Performances');
     const onTabChange = (data: string) => {
          setActiveTab(data);
     };
     const table = useMemo(() => {
          const tables: any = {
               'Top Campaign Performances': <CapmaignPerformanceTable startDate={startDate} endDate={endDate} />,
               'Top Site Performances': <SitePerformanceTable startDate={startDate} endDate={endDate} />,
          };

          return tables[activeTab] || null;
     }, [activeTab, startDate, endDate]);

     const handlePerformanceDownload = () => {
          setDownloadLoader(true);
          const payload = {
              start_dt: startDate,
              end_dt: endDate,
          };
  
          Apis.exportCampaignPerformance(payload)
              .then(({ data }) => {
                  download(data, 'Campaign Performance Report.xlsx');
              })
              .catch((err) => {
                  dispatch(MessageActions.showMessage({ text: String('Something went wrong'), error: true }));
              })
              .finally(() => {
                  setDownloadLoader(false);
              });
      };

     return (
          <div>
               <div className='flex justify-between items-start relative  h-[70px]'>
                    <div className='flex items-center gap-2'>
                         <div className="bg-[#C2D9CD] w-[50px] flex justify-center items-center text-[14px] font-[Roboto] font-[500] py-1 px-3 rounded-lg cursor-pointer" onClick={() => navigate(-1)}>
                              Back
                         </div>
                         {
                              location?.state?.name && location?.state?.name === 'overAllReprt' ?
                                   <div className='text-[24px] font-[Roboto] font-[500]'>Overall Report</div> :
                                   <div className='text-[24px] font-[Roboto] font-[500]'>{location?.state?.name}</div>
                         }

                    </div>
                    {/* ------------------custom date filter component for changing date----------- */}
                    <div className='flex gap-5 items-end'>
                         {
                              applyDate === 'today' && (
                                   <HourPellet hourSelected={hourSelected} setHourSelected={setHourSelected} />
                              )
                         }
                         <DateFilter
                              applyDate={applyDate}
                              setApplyDate={setApplyDate}
                              setCustomEndDate={setCustomEndDate}
                              setCustomStartDate={setCustomStartDate}
                              setDatestring2={setDatestring2}
                              dstring2={dstring2}
                              compare={compare}
                              setCompare={setCompare}
                              showCompare={true}
                         />
                    </div>
               </div>
               {
                    location?.state?.name !== 'overAllReprt' &&
                    <div className='my-2'>
                         {/* site name and settings */}
                         {/* <div className='flex gap-4 items-center'>
                              <div className='flex justify-between items-center gap-2 h-[45px] px-3 bg-[#ECECEC] rounded-md'>
                                   <div className='text-[24px] font-[Roboto] font-[500]'>{location?.state?.name}</div>
                                   <div> <FaSortDown size={22} /></div>
                              </div>
                              <div className='bg-[#ECECEC] p-1 rounded-full'>
                                   <IoEllipsisVertical size={22} />
                              </div>
                         </div> */}

                         <div className='font-[Roboto] font-[400] text-[12px] text-[#A0A0A0] mt-2'>
                              <span className='font-[700]'>Start Date</span>: <span className='text-black font-[700]'>{`${topCardData?.live_on ? moment(topCardData?.live_on).format('DD MMM YYYY , hh:mm:ss zz') : ""}`}</span>
                              {topCardData?.end_date && (<div className='font-[700] mt-2'>End Date : <span className='text-black'>{`${moment(topCardData?.end_date).format('DD MMM YYYY , hh:mm:ss zz')}`}</span></div>)}
                         </div>
                         <div className='font-[Roboto] font-[400] text-[12px] text-[#A0A0A0] mt-2'>
                              <div className='font-[700]'>Current Status : <span className='text-black'>{`${topCardData?.status ? topCardData?.status : ''}`}</span></div>
                         </div>
                         <div className='flex gap-5 font-[Roboto] font-[400] text-[12px] text-[#A0A0A0]  mt-2'>
                              <div className='font-[700]'>Total Budget  : <span className='text-black'>${commaSeperator(parseFloat(String(topCardData?.total_budget)).toFixed(2))} </span></div>
                              <div className='font-[700]'>Campaign Type : <span className='text-black'>{topCardData?.campaign_type} </span></div>
                              <div className='font-[700]'>Value  : <span className='text-black'>${commaSeperator(parseFloat(String(topCardData?.rate)).toFixed(2))} </span></div>
                         </div>

                    </div>
               }

               {/*---------------- five top cards component ------------------"2023-12-30 09:48:20 am"*/}
               {
                    applyDate !== 'today' && (<ReportTopCards startDate={startDate} endDate={endDate} campId={params.id} />)
               }
               {
                    applyDate !== 'today' && (
                         <div className="flex flex-col gap-5 mt-5">
                              <div className="grid grid-cols-2 gap-5">
                                   {/* -----------------acauisation over time line area graph component--------------- */}
                                   {/* <AcquisitionGraph /> */}

                                   {/* ------------------cpa revenue single bar graph component -----------*/}
                                   {/* <CpaRevenueGraph /> */}
                              </div>
                              <div className="">
                                   {/*-------------------- Bugdet Revenue dual line graph component---------*/}
                                   <BudgetSpentGraph startDate={startDate} endDate={endDate} campId={params.id} showExpected={topCardData?.end_date ? true : false} />

                                   {/* --------------------click through rate(CTR) dual line graph component -----------*/}
                                   {/* <CTRGraph startDate={startDate} endDate={endDate} campId={params.id} /> */}
                              </div>
                         </div>
                    )
               }

               {/* ------------------impressions and click biaxial dashed-line and bars graph---------- */}
               <div>
                    <ImpressionClickGraph startDate={startDate} endDate={endDate} campId={params.id} applyDate={applyDate} hourSelected={hourSelected} />
               </div>

               {
                    applyDate !== 'today' && (
                         <div className="grid grid-cols-2 gap-5 mt-10">
                              {/*-------------------- Top Country -------------- */}
                              <TopGraphForMediaSiteCountryDevice title={'Top Countries'} top={'country'} startDate={startDate} endDate={endDate} campId={params.id} />

                              {/* --------------------Top Device ------------------*/}
                              <TopGraphForMediaSiteCountryDevice title={'Top Devices'} top={'device'} startDate={startDate} endDate={endDate} campId={params.id} />
                         </div>
                    )
               }

               {
                    applyDate !== 'today' && (
                         <div className="grid grid-cols-2 gap-5 mt-10">
                              {/*-------------------- Top Media Size -------------- */}
                              <TopGraphForMediaSiteCountryDevice title={'Top Media Sizes'} top={'size'} startDate={startDate} endDate={endDate} campId={params.id} />

                              {/* --------------------Top Sites ------------------*/}
                              <TopGraphForMediaSiteCountryDevice title={'Top Sites'} top={'site'} startDate={startDate} endDate={endDate} campId={params.id} />
                         </div>
                    )
               }

               {
                    applyDate !== 'today' && (
                         <div>
                              {
                                   location?.state?.name && location?.state?.name === 'overAllReprt' ?
                                        <div className="">
                                             <div className="w-[100%] relative flex flex-row-reverse">
                                             <PButton loading={downloadLoader} className="top-12" title={'Export'} onClick={handlePerformanceDownload} />
                                             </div>
                                             <PTabs
                                                  activeTab={activeTab}
                                                  setActiveTab={onTabChange}
                                                  tabs={[
                                                       { key: 'Top Campaign Performances', title: 'Top Campaign Performances' },
                                                       { key: 'Top Site Performances', title: 'Top Site Performances' },
                                                  ]}
                                             />
                                             <div className="flex justify-end text-[12px] font-[Roboto] mt-1 text-[#056433]">Default sorting is done on Ad Impressions</div>
                                             <div className="pb-4">{table}</div>
                                        </div>
                                        :
                                        <PerformanceTabsTable startDate={startDate} endDate={endDate} campId={params.id} />
                              }
                         </div>
                    )
               }
          </div>
     )
}

export default OverallReport
