import React, { useEffect } from 'react';
import Apis from '../../../../../api';
import PNormalInput from '../../../../common/NormalInput';
import PNormalTextArea from '../../../../common/NormalTextArea';

type Props = {
    onChangeFields: (data: { name: string; value: string | number | undefined }) => void;
    description?: string;
    name?: string;
    isValidate: boolean;
    error?: string;
    setUnsaveChanges: any;
    prevName?:any;
    setPrevName?:any;
};

const PInputNameDes: React.FC<Props> = ({ onChangeFields, description, name, isValidate, error, setUnsaveChanges ,prevName,setPrevName}) => {
    const onFormFieldChange = (data: { name: string; value: string | number | undefined }) => {
        const { name, value } = data;
        onChangeFields({
            name: name,
            value: value,
        });
        if(name === 'description'){
            setUnsaveChanges(true)
        }
    };
    const handleCheckCampaign = (value: string) => {
        if (prevName && name?.trim() !== prevName.trim()) {
            setUnsaveChanges(true)
            Apis.validateCampaignName({ campaign_name: value })
                .then(({ data }) => {
                    if (data.data.status === true) {
                        onChangeFields({
                            name: 'error',
                            value: '',
                        });
                    }
                })
                .catch((err) => {
                    if (err.response.data.data.status === false) {
                        onChangeFields({
                            name: 'error',
                            value: err.response.data.data.message,
                        });
                    }
                })     }
    };

    //setting prevname for first time only
    useEffect(() => {
        if (name && !prevName) {
            setPrevName(name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name])


    return (
        <div className="p-4 mt-3 bg-white rounded-md shadow-md">
            <div>
                <PNormalInput
                    isRequired={true}
                    className="md:rounded md:py-2 text-xs"
                    value={prevName}
                    name="name"
                    title="Campaign Name"
                    onChange={(e)=>setPrevName(e.value)}
                    placeholder="Enter campagin name"
                    onBlur={(e: any) => {
                        handleCheckCampaign(e.target.value);
                    }}
                />
                {isValidate && (!prevName || prevName.trim() === '') && <span className="common_error">Please enter name</span>}
                {error && (prevName !== name) && <span className="common_error">{error}</span>}
            </div>
            <PNormalTextArea className="md:rounded md:py-2 text-xs" rows={3} value={description} name="description" title="Campaign Description" onChange={onFormFieldChange} placeholder="Enter campagin description" />
        </div>
    );
};

export default PInputNameDes;
