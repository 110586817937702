import { CommonAction } from "../../../../auth/login/redux/types";
import reportTypes, { ReportGeneralReport, ReportGraphData, reportTopCardsType } from "./type";

function fetchOverAllReportTopCards(payload?: ReportGeneralReport, id?: number): CommonAction {
     return {
          type: reportTypes.FETCH_REPORT_TOPCARDS,
          payload: { payload, id },
     };
}

function setOverAllReportTopCards(payload?: reportTopCardsType): CommonAction {
     return {
          type: reportTypes.SET_REPORT_TOPCARDS,
          payload: payload,
     };
}

function fetchBudgetSpentReportsData(payload?: ReportGeneralReport, id?: number | string): CommonAction {
     return {
          type: reportTypes.FETCH_REPORTS_BUGDET_SPEND_GRAPH,
          payload: { payload, id },
     };
}

function setBudgetSpentReportsData(payload?: any): CommonAction {
     return {
          type: reportTypes.SET_REPORTS_BUGDET_SPEND_GRAPH,
          payload: payload,
     };
}

function fetchCTRReportsData(payload?: ReportGeneralReport, id?: number | string): CommonAction {
     return {
          type: reportTypes.FETCH_REPORTS_CTR_GRAPH,
          payload: { payload, id },
     };
}

function setCTRReportsData(payload: any): CommonAction {
     return {
          type: reportTypes.SET_REPORTS_CTR_GRAPH,
          payload: payload,
     };
}

function fetchImpClicksAcqReportsData(payload?: ReportGeneralReport,  id?: number | string): CommonAction {
     return {
          type: reportTypes.FETCH_REPORTS_IMP_CLICKS_GRAPH,
          payload: { payload, id },
     };
}

function setImpClicksAcqReportsData(payload?:ReportGraphData): CommonAction {
     return {
          type: reportTypes.SET_REPORTS_IMP_CLICKS_GRAPH,
          payload: payload,
     };
}

function fetchTopCountriesReportsData(payload?: ReportGeneralReport,  id?: number | string): CommonAction {
     return {
          type: reportTypes.FETCH_REPORTS_TOP_COUNTRIES_DATA_GRAPH,
          payload: { payload, id },
     };
}

function setTopCountriesReportsData(payload: any): CommonAction {
     return {
          type: reportTypes.SET_REPORTS_TOP_COUNTRIES_DATA_GRAPH,
          payload: payload,
     };
}


function fetchTopDevicesReportsData(payload?: ReportGeneralReport,  id?: number | string): CommonAction {
     return {
          type: reportTypes.FETCH_REPORTS_TOP_DEVICES_DATA_GRAPH,
          payload: { payload, id },
     };
}
function setTopDevicesReportsData(payload: any): CommonAction {
     return {
          type: reportTypes.SET_REPORTS_TOP_DEVICES_DATA_GRAPH,
          payload: payload,
     };
}

function fetchTopMediaSizesReportsData(payload?: ReportGeneralReport,  id?: number | string): CommonAction {
     return {
          type: reportTypes.FETCH_REPORTS_TOP_MEDIASIZES_DATA_GRAPH,
          payload: { payload, id },
     };
}
function setTopMediaSizesReportsData(payload: any): CommonAction {
     return {
          type: reportTypes.SET_REPORTS_TOP_MEDIASIZES_DATA_GRAPH,
          payload: payload,
     };
}

function fetchTopSitesReportsData(payload?: ReportGeneralReport,  id?: number | string): CommonAction {
     return {
          type: reportTypes.FETCH_REPORTS_TOP_SITES_DATA_GRAPH,
          payload: { payload, id },
     };
}
function setTopSitesReportsData(payload: any): CommonAction {
     return {
          type: reportTypes.SET_REPORTS_TOP_SITES_DATA_GRAPH,
          payload: payload,
     };
}

function fetchReportCampaigns(pageNo: number, pageSize: number, payload?:ReportGeneralReport): CommonAction {
     return {
          type: reportTypes.FETCH_REPORTS_CAMPAIGN_LIST,
          payload: { pageNo: pageNo, pageSize: pageSize, payload: payload },
     };
}

function setReportCampaigns(data: any): CommonAction {
     return {
          type: reportTypes.SET_REPORTS_CAMPAIGN_LIST,
          payload: data,
     };
}

function fetchReportSites(pageNo: number, pageSize: number, payload?:ReportGeneralReport): CommonAction {
     return {
          type: reportTypes.FETCH_REPORTS_SITE_LIST,
          payload: { pageNo: pageNo, pageSize: pageSize, payload: payload },
     };
}

function setReportSites(data: any): CommonAction {
     return {
          type: reportTypes.SET_REPORTS_SITE_LIST,
          payload: data,
     };
}

function fetchCampReportTopCountriesTable(payload?: ReportGeneralReport,  id?: number | string, pageSize?: number | string, pageNo?: number | string): CommonAction {
     return {
          type: reportTypes.FETCH_CAMP_REPORTS_COUNTRIES_TABLE,
          payload: { payload, id ,pageSize,pageNo},
     };
}
function setCampReportTopCountriesTable(payload: any): CommonAction {
     return {
          type: reportTypes.SET_CAMP_REPORTS_COUNTRIES_TABLE,
          payload: payload,
     };
}

function fetchCampReportTopDevicesTable(payload?: ReportGeneralReport,  id?: number | string, pageSize?: number | string, pageNo?: number | string): CommonAction {
     return {
          type: reportTypes.FETCH_CAMP_REPORTS_DEVICES_TABLE,
          payload: { payload, id ,pageSize,pageNo},
     };
}
function setCampReportTopDevciesTable(payload: any): CommonAction {
     return {
          type: reportTypes.SET_CAMP_REPORTS_DEVICES_TABLE,
          payload: payload,
     };
}

function fetchCampReportTopMediaTable(payload?: ReportGeneralReport,  id?: number | string, pageSize?: number | string, pageNo?: number | string): CommonAction {
     return {
          type: reportTypes.FETCH_CAMP_REPORTS_MEDIA_TABLE,
          payload: { payload, id ,pageSize,pageNo},
     };
}
function setCampReportTopMediaTable(payload: any): CommonAction {
     return {
          type: reportTypes.SET_CAMP_REPORTS_MEDIA_TABLE,
          payload: payload,
     };
}

function fetchCampReportTopSitesTable(payload?: ReportGeneralReport, id?: number | string, pageSize?: number | string, pageNo?: number | string): CommonAction {
     return {
          type: reportTypes.FETCH_CAMP_REPORTS_SITES_TABLE,
          payload: { payload, id ,pageSize,pageNo},
     };
}
function setCampReportTopSitesTable(payload: any): CommonAction {
     return {
          type: reportTypes.SET_CAMP_REPORTS_SITES_TABLE,
          payload: payload,
     };
}

function fetchCampReportTopCreateiveTable(payload?: ReportGeneralReport, id?: number | string, pageSize?: number | string, pageNo?: number | string): CommonAction {
     return {
          type: reportTypes.FETCH_CAMP_REPORTS_CREATIVE_TABLE,
          payload: { payload, id ,pageSize,pageNo},
     };
}
function setCampReportTopCreativeTable(payload: any): CommonAction {
     return {
          type: reportTypes.SET_CAMP_REPORTS_CREATIVE_TABLE,
          payload: payload,
     };
}

function fetchRealTimeImpClicksAcqReportsData(payload?: ReportGeneralReport,  id?: number | string): CommonAction {
     return {
          type: reportTypes.FETCH_REAL_TIME_REPORTS_IMP_CLICK_GRAPH,
          payload: { payload, id },
     };
}

function setRealTimeImpClicksAcqReportsData(payload: any): CommonAction {
     return {
          type: reportTypes.SET_REAL_TIME_REPORTS_IMP_CLICK_GRAPH,
          payload: payload,
     };
}



const OverAllReportAction = {
     fetchOverAllReportTopCards,
     setOverAllReportTopCards,
     fetchBudgetSpentReportsData,
     setBudgetSpentReportsData,
     fetchCTRReportsData,
     setCTRReportsData,
     fetchImpClicksAcqReportsData,
     setImpClicksAcqReportsData,
     fetchTopCountriesReportsData,
     setTopCountriesReportsData,
     fetchTopDevicesReportsData,
     setTopDevicesReportsData,
     fetchTopMediaSizesReportsData,
     setTopMediaSizesReportsData,
     fetchTopSitesReportsData,
     setTopSitesReportsData,
     fetchReportCampaigns,
     setReportCampaigns,
     fetchReportSites,
     setReportSites,
     fetchCampReportTopCountriesTable,
     setCampReportTopCountriesTable,
     fetchCampReportTopDevicesTable,
     setCampReportTopDevciesTable,
     fetchCampReportTopMediaTable,
     setCampReportTopMediaTable,
     fetchCampReportTopSitesTable,
     setCampReportTopSitesTable,
     fetchCampReportTopCreateiveTable,
     setCampReportTopCreativeTable,
     fetchRealTimeImpClicksAcqReportsData,
     setRealTimeImpClicksAcqReportsData,
}
export default OverAllReportAction;
