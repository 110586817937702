import React, { useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { LiaEdit } from 'react-icons/lia';
import { useDispatch } from 'react-redux';
import PButton from '../../../../common/Button';
import PModal from '../../../../common/Modal';
import PLocationModalView from '../modal/LocationModalView';
import LocationAction from '../modal/Redux/action';
export interface LocationItem {
    country: string;
    state: string;
    city: string;
}

interface ShowSelectedLocationProps {
    title: string;
    array?: LocationItem[];
    color: string;
    type?: string;
    openLocationsModal: () => void; // Assuming this is a function to open a modal
}
type Props = {
    isValidate: boolean;
    locationList?: { city: string; state: string; country: string }[];
    in_selectedLocation?: LocationItem[];
    ex_selectedLocation?: LocationItem[];
    handleSaveItem: (in_item: LocationItem[], ex_item: LocationItem[]) => void;
    setUnsaveChanges:any;
};

const PLocation: React.FC<Props> = ({ in_selectedLocation = [], ex_selectedLocation = [], locationList, isValidate, handleSaveItem ,setUnsaveChanges }) => {
    // const location = useSelector((state: RootState) => state.location);
    const [isModalOpenForLocations, setIsModalOpenForLocations] = useState(false);
    const [isLocationValidate, setIsLocationValidate] = useState(false);
    const [locationData, setLocationData] = useState<{ exclude: LocationItem[]; include: LocationItem[] }>({ exclude: [], include: [] });
    const [displayLocationData, setDisplayLocationData] = useState<{ exclude: LocationItem[]; include: LocationItem[] }>({ exclude: [], include: [] });
    React.useEffect(() => {
        setLocationData({ exclude: ex_selectedLocation || [], include: in_selectedLocation || [] });
        setDisplayLocationData({ exclude: ex_selectedLocation || [], include: in_selectedLocation || [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [in_selectedLocation?.length, ex_selectedLocation?.length]);
    const dispatch = useDispatch();
    const openLocationsModal = () => {
        setIsModalOpenForLocations(true);
        dispatch(LocationAction.editLocation(locationData));
    };

    const closeLocationsModal = () => {
        setIsModalOpenForLocations(false);
    };



    const ViewOfModalLocations = (
        <PLocationModalView
            in_selectedLocation={in_selectedLocation}
            ex_selectedLocation={ex_selectedLocation}
            handleAddItem={(item) => () => { }}
            isLocationValidate={isLocationValidate}
            locationData={locationData}
            setLocationData={setLocationData}
            isModalOpen={isModalOpenForLocations}
        />
    );

    const customizeHeaderForLocations = (
        <div className="flex justify-between">
            <div>
                <p className="flex items-center font-[Roboto-Medium] md:text-[#056433] font-bold text-xl">Select Locations</p>
            </div>
            <div className="flex">
                <PButton
                    onClick={() => {
                        //const checkLocation = locationData.include?.length || locationData.exclude?.length;
                        // if (checkLocation) {
                        //     setDisplayLocationData({ exclude: locationData?.exclude, include: locationData?.include })
                        //     handleSaveItem(locationData?.include, locationData?.exclude);
                        //     closeLocationsModal();
                        //     setIsLocationValidate(false);
                        // } else {
                        //     setIsLocationValidate(true);
                        // }
                        setDisplayLocationData({ exclude: locationData?.exclude, include: locationData?.include })
                        handleSaveItem(locationData?.include, locationData?.exclude);
                        closeLocationsModal();
                        setIsLocationValidate(false);
                        setUnsaveChanges(true)

                    }}
                    title="Submit"
                    className="md:rounded-full md:h-[auto] text-xs py-px md:px-6 mr-4"
                />
                <AiOutlineCloseCircle size={'1.75rem'} className="cursor-pointer" onClick={closeLocationsModal} />
            </div>
        </div>
    );
    const ShowSelectedLocation = ({ title, array, color, openLocationsModal }: ShowSelectedLocationProps) => {
        return (
            <>
                <label className={`ml-1 font-[Roboto] text-xs md:text-[${color}]`}>{title}</label>
                <div className="mt-2 md:mb-4 flex">
                    <div className="border md:border-[#d9d9d9] p-2 flex flex-wrap  w-[100%]">
                        {array?.slice(0, 2).map((item, index) => (
                            <div key={index}>
                                {
                                    (item?.country === item?.state && item?.state === item?.city) ?
                                        <span className="font-[Roboto] font-bold text-xs flex mb-1">
                                            Country&nbsp;-&nbsp;<p>{item.country}</p>&nbsp;&nbsp;
                                        </span> :
                                        <span className="font-[Roboto] font-bold text-xs flex mb-1">
                                            Country&nbsp;-&nbsp;<p>{item.country}</p>&nbsp;&nbsp;|&nbsp;&nbsp;State&nbsp;-&nbsp;{item.state}&nbsp;&nbsp;|&nbsp;&nbsp;City&nbsp;-&nbsp;{item.city}
                                        </span>
                                }

                            </div>
                        ))}
                    </div>
                    <div className="ml-2">
                        {/* Assuming LiaEdit is an icon or component for opening the modal */}
                        <LiaEdit onClick={openLocationsModal} size={'2rem'} className="cursor-pointer mr-3.5" />
                    </div>
                </div>
            </>
        );
    };
    return (
        <div>
            <p className="flex items-center font-[Roboto-Medium] text-black font-medium text-sm mt-9">Locations</p>
            <div className="p-4 mt-3 bg-white rounded-md shadow-md">
                <ShowSelectedLocation openLocationsModal={openLocationsModal} title={'Audience to Include'} color="#419840" type="include" array={displayLocationData.include} />
                <ShowSelectedLocation openLocationsModal={openLocationsModal} title={'Audience to Exclude'} color="#f55151" type="exclude" array={displayLocationData.exclude} />
                {/* {isValidate && (locationData.include.length === 0 || locationData.exclude.length === 0) && <span className="common_error">Please select Location</span>} */}
            </div>
            {isModalOpenForLocations && <PModal ViewOfModal={ViewOfModalLocations} isModalOpen={isModalOpenForLocations} OnClickOfCancel={closeLocationsModal} customizeHeader={customizeHeaderForLocations} width={'80vw'} />}
        </div>
    );
};
export default PLocation;
