import { useEffect, useState } from "react";
import PButton from "../../../../common/Button";
import PModal from "../../../../common/Modal";
import { ManageActionState } from "../redux/type";
import PNormalInput from "../../../../common/NormalInput";
import { MdAccountCircle } from "react-icons/md";
import Apis from "../../../../../api";
import { useDispatch } from "react-redux";
import MessageActions from "../../../../message/redux/actions";

type Props = {
     open: boolean;
     setActionState: (value: ManageActionState) => void;
     closeModal: () => void;
     email: string;
     prevName: string;
     id: string;
     fetchUsersList:()=>void;
}

const UpdateModal: React.FC<Props> = ({ open, setActionState, closeModal, email, prevName, id,fetchUsersList }) => {
     const dispatch = useDispatch();
     const [loading, setLoading] = useState(false);
     const [name, setName] = useState("");
     const [isValidate, setIsValidate] = useState(false);

     const handleUpdateUser = () => {
          setIsValidate(true);
          if (!name || name.trim() === "") {
               return;
          }
          const payload = {
               name: name,
               user_id: id
          }
          setLoading(true);
          Apis.updateUserManagementUserDetails(payload).then((res) => {
               dispatch(MessageActions.showMessage({ text: String(res.data?.data?.message), error: false }));
               fetchUsersList();
               closeModal();
          }).catch((err) => {
               dispatch(MessageActions.showMessage({ text: String(err?.response?.data?.data?.message), error: true }));
               closeModal();
          }).finally(() => setLoading(false))

     }

     useEffect(() => {
          if (prevName) {
               setName(prevName);
          }
     }, [prevName, open])
     const modalView = (
          <div>
               <div className="px-6 pb-4">
                    <div className="flex justify-center items-center pb-4">
                         <p className="font-bold montserrat-medium text-2xl">Update User</p>
                    </div>
                    <div className="border-t-[1px] font-[Montserrat] pt-4 pb-2">
                         Email : {email}
                    </div>
                    <div className="py-2">
                         <PNormalInput
                              onKeyPress={(event) => {
                                   if (event.key === 'Enter') {
                                        event.preventDefault();
                                        handleUpdateUser();
                                   }
                              }}
                              title="Name"
                              value={name}
                              name="name"
                              onChange={(e: any) => setName(e.value)}
                              isRequired={true}
                              suffix={<MdAccountCircle />}
                         />
                         {isValidate && (name.trim() === "" || !name) && <span className="common_error">Please enter name</span>}
                    </div>

                    <div className="flex items-center mt-6 w-full justify-center">
                         <PButton title="Update" onClick={handleUpdateUser} loading={loading} />
                    </div>
               </div>
          </div>
     )
     return (
          <>
               <PModal
                    isModalOpen={open}
                    OnClickOfCancel={closeModal}
                    width="450px"
                    className="noscrollbar"
                    ViewOfModal={modalView}
               />
          </>
     )
}

export default UpdateModal;