import React from "react";
type Props = {
     hourSelected:string;
     setHourSelected:any;
}
const HourPellet: React.FC <Props>= ({hourSelected,setHourSelected}) => {
     return (
          <div className="flex flex-row w-[250px] px-4 h-[40px] shadow-sm cursor-pointer  justify-around items-center bg-[#B4D0C133] rounded-lg">
               <div
                    onClick={() => setHourSelected('1')}
                    className={hourSelected === '1' ? 'bg-green-700 text-white w-16 h-8 rounded-lg  flex flex-row justify-center items-center' : 'text-gray-400 w-16 h-12 rounded-lg  flex flex-row justify-center items-center'}
               >
                    1hr
               </div>

               <div
                    onClick={() => setHourSelected('6')}
                    className={hourSelected === '6' ? 'bg-green-700 text-white w-16 h-8 rounded-lg  flex flex-row justify-center items-center' : 'text-gray-400 w-16 h-12 rounded-lg  flex flex-row justify-center items-center'}
               >
                    6hr
               </div>

               <div
                    onClick={() => setHourSelected('12')}
                    className={hourSelected === '12' ? 'bg-green-700 text-white w-16 h-8 rounded-lg  flex flex-row justify-center items-center' : 'text-gray-400 w-16 h-12 rounded-lg  flex flex-row justify-center items-center'}
               >
                    12hr
               </div>
          </div>
     )
}

export default HourPellet;