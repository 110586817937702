import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export type DropdownOption = {
    value: any;
    label: string;
};

type Props = {
    value: any;
    onChange: (data: { name: string; value: string | undefined }) => void;
    options: DropdownOption[];
    label: string;
    name: string;
    multiple?: boolean;
    required?: boolean;
    error?: boolean;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    allowClear?:boolean;
};

const PDropdown: React.FC<Props> = ({ options, label, name, multiple, value, onChange, required, error, placeholder, className, disabled,allowClear=true }) => {
    const onSelect = (value: string | undefined) => {
        const targetValue = value;
        let res = undefined;
        if (!multiple) {
            res = targetValue ? String(targetValue) : undefined;
        } else {
            if (Array.isArray(targetValue)) {
                if (targetValue.includes(undefined) || targetValue.length === 0) {
                    res = undefined;
                } else {
                    res = targetValue.join(',');
                }
            }
        }
        onChange({
            name,
            value: res,
        });
    };

    const displayedLabel = `${label}`;
    return (
        <div className="mb-2 common_selection">
            {displayedLabel && (
                <label>
                    {displayedLabel} <span className="text-red-500">{required ? '*' : ''}</span>
                </label>
            )}
            <Select
                mode={multiple ? 'multiple' : undefined}
                className={`appearance-none  rounded w-full  text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-1 ${error ? 'border-red-500' : ''} ${className || ''}`}
                placeholder={placeholder}
                showSearch
                optionFilterProp="children"
                onChange={onSelect}
                allowClear={allowClear}
                value={value}
                disabled={disabled}
            >
                {!required && (
                    <Option value={''}>
                        <em>None</em>
                    </Option>
                )}
                {options.map((op) => (
                    <Option value={op.value} key={op.label}>
                        {op.label}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default PDropdown;
