import React, { useEffect, useState } from 'react'
import { RiEdit2Line } from 'react-icons/ri'
import PModal from '../../../../common/Modal';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import PNormalTextArea from '../../../../common/NormalTextArea';
import PNormalInput from '../../../../common/NormalInput';
import PButton from '../../../../common/Button';
import {isValidUrl} from '../../../../../utils/Validation';

type Props = {
     item: any;
     aiList: any;
     setAiList: any;
}

const TextEditModal: React.FC<Props> = ({ item, aiList, setAiList }) => {
     const [shrink, setShrink] = useState(false);
     const [validate, setValidate] = useState(false);
     const [textFields, setTextFields] = useState<{ name: any, body: any, url: any, isValid?: boolean | undefined; }>({
          name: '',
          body: '',
          url: '',
          isValid: true
     })

     const handleSubmit = () => {
          setValidate(true)
          if (!textFields.name || textFields.name.trim() === "" ||
               !textFields.body || textFields.body.trim() === "" ||
               !textFields.url || textFields.url.trim() === "" ||
               !textFields.isValid
          ) {
               return;
          }
          setValidate(false)
          let idx = aiList.findIndex((el: any) => el.id === item?.id);
          let dumArr = [...aiList];
          dumArr[idx].name = textFields?.name;
          dumArr[idx].body = textFields?.body;
          dumArr[idx].url = textFields?.url;

          setAiList(dumArr);
          setShrink(false);
     }

     const customizeHeaderForAddImage = (
          <div className="flex justify-between w-full">
               <div>
                    <p className="flex items-center font-[Roboto-Medium] text-black font-bold text-xl">
                         Edit Text
                    </p>
               </div>
               <div className="flex">
                    <AiOutlineCloseCircle size={'1.75rem'} className="cursor-pointer" onClick={() => setShrink(false)} />
               </div>
          </div>
     );

     const ViewOfAiListModal = (
          <div className='w-full '>
               <div className=' mt-2  p-2'>
                    <div className="mt-4">
                         <PNormalTextArea isRequired className="md:rounded md:py-2 text-xs" onChange={(e) => setTextFields({ ...textFields, name: e.value })} rows={3} value={textFields.name} name="Prompt" title="Prompt" placeholder="Enter Prompt" />
                         {validate && !textFields.name && <span className="common_error">Please Enter Prompt</span>}
                    </div>
                    <div className="mt-4">
                         <PNormalTextArea isRequired className="md:rounded md:py-2 text-xs" onChange={(e) => setTextFields({ ...textFields, body: e.value })} rows={3} value={textFields.body} name="body" title="Body" placeholder="Enter Body" />
                         {validate && !textFields.body && <span className="common_error">Please Enter Body</span>}
                    </div>
                    <div className="mt-4">
                         <PNormalInput isRequired className="md:rounded md:py-2 text-xs" detail={' (Url should be start with http:// or https://)'} onChange={(e) => (
                              setTextFields({ ...textFields, url: e.value, isValid: isValidUrl(e.value ? e.value.toString() : '') })
                         )} value={textFields.url} name="url" title="URL" placeholder="Enter URL" />
                         {validate && (!textFields.url || !textFields.isValid) && <span className="common_error">Please Enter valid url</span>}
                    </div>
               </div>
               <div>
                    <PButton onClick={handleSubmit} title="Proceed" className="md:rounded-full mt-5 md:h-[auto] text-xs py-px md:px-5 w-32" />

               </div>
          </div>
     )
     useEffect(() => {
          setTextFields({
               name: item?.name,
               body: item?.body,
               url: item?.url,
               isValid: item?.isValid
          })
           // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [])
     return (
          <>
               <RiEdit2Line size={20} className="cursor-pointer mr-3 mt-1" onClick={() => setShrink(true)} />
               {shrink && <PModal ViewOfModal={ViewOfAiListModal} isModalOpen={shrink} OnClickOfCancel={() => setShrink(false)} customizeHeader={customizeHeaderForAddImage} />}

          </>
     )
}

export default TextEditModal