import { RadioChangeEvent } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import Apis from '../../../../api';
import { HEADERMENU_PATH } from '../../../../routes/RoutesURL';
import { RootState } from '../../../../store/RootReducer';
import MessageActions from '../../../message/redux/actions';
import PBidDetails from './components/BidDetails';
import PCategoriesOfCamp from './components/CategoriesOfCamp';
import PImagePreviewUI from './components/ImagePreviewUI';
import PInputNameDes from './components/InputNameDes';
import PLoaction, { LocationItem } from './components/Loaction';
import PMediaSize from './components/MediaSize';
import PNewCampaignHeader from './components/NewCampaignHeader';
import PTargetBrowserAndDevice from './components/TargetBrowserAndDevice';
import PWebsiteOfCamp from './components/WebsiteOfCamp';
import LocationAction from './modal/Redux/action';
import campaginsActions from './redux/actions';
import { Campaign } from './redux/type';



const CreateOrEditCampaign: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { campaign_id } = useParams<{ campaign_id?: string }>();
    const constants = useSelector((state: RootState) => state.campaign.constants);
    const [unsaveChanges, setUnsaveChanges] = useState(false);
    const [isValidate, setValidate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [prevName, setPrevName] = useState('');
    const [aiPrompt, setAiPrompt] = useState<string>("");
    const [formFields, setFormFields] = useState<Campaign>({
        description: undefined,
        name: undefined,
        type: undefined,
        isCreative: undefined,
        durationStart: undefined,
        durationEnd: undefined,
        startDataRun: undefined,
        endDateRun: undefined,
        rate: undefined,
        frequencyCap: 'None',
        frequencyPerDay: undefined,
        conversionType: 'static',
        conversionValue: undefined,
        selectedBudget: undefined,
        publirFee: 15,
        enteredBudget: undefined,
        categoriesType: 'All',
        WebsitesType: 'All',
        targetDevices: undefined,
        targetBrowsers: undefined,
        isStartImmeditialey: undefined,
        selectedMediaSizes: undefined,
        isContinuosly: undefined,
        include_gender_type: 'Both',
        include_min_age: 1,
        include_max_age: 120,
        include_Occupation: [],
        include_Education: [],
        exclude_gender_type: undefined,
        exclude_min_age: undefined,
        exclude_max_age: undefined,
        exclude_Occupation: [],
        exclude_Education: [],
        include_Purchase: [],
        include_Interest: [],
        exclude_Purchase: [],
        exclude_Interest: [],
        selected_in_Category: [],
        selected_ex_Category: [],
        in_selectedWebsite: [],
        ex_selectedWebsite: [],
        in_selectedLocation: [],
        ex_selectedLocation: [],
        previewImagesDetail: [],
        error: '',
        overLapError: false,
        media: [],
        daysAndTimeDetails: { scheduleLineItems: '', daysTime: false, schedule: [{ start: '', end: '', dontRunDays: false, repeatOn: [] }] },
        mediaPartner: 'others',
    });

    useEffect(() => {
        dispatch(campaginsActions.fetchConstants());
        if (campaign_id) {
            dispatch(campaginsActions.fetchCompaignView());
            fetchDetailOfCompaign();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const fetchDetailOfCompaign = () => {
        Apis.fetchCompaignView(campaign_id ? campaign_id : '')
            .then((data) => {
                const detailsOfCam = data?.data?.data;
                const {
                    dayAndTime,
                    start_dt,
                    end_dt,
                    sizes,
                    is_duration_continues,
                    audiences,
                    rate,
                    frequency,
                    name,
                    browsers,
                    budget,
                    publir_fee,
                    budget_type,
                    conversion_type,
                    conversion_value,
                    campaign_type,
                    categories,
                    frequency_type,
                    description,
                    devices,
                    websites,
                    locations,
                    media_partner,
                    ai_prompt,
                } = data?.data?.data || {};
                const { exclude: excludeAudi, include: includeAudi } = audiences || {};
                const { exclude: locationEx, include: locationIn } = locations;
                setPrevName(name);
                setFormFields({
                    ...formFields,
                    description: description,
                    name: name,
                    type: campaign_type,
                    isCreative: start_dt,
                    durationStart: start_dt,
                    durationEnd: end_dt,
                    startDataRun: undefined,
                    endDateRun: undefined,
                    rate: rate === 'undefined' ? '' : parseFloat(rate),
                    frequencyCap: frequency_type,
                    frequencyPerDay: frequency,
                    selectedBudget: budget_type,
                    enteredBudget: budget,
                    publirFee: parseInt(publir_fee),
                    targetDevices: devices,
                    targetBrowsers: browsers,
                    isStartImmeditialey: is_duration_continues,
                    selectedMediaSizes: sizes,
                    isContinuosly: is_duration_continues === '1' ? true : false,
                    conversionType: conversion_type === 'undefined' ? '' : conversion_type,
                    conversionValue: conversion_value === 'undefined' ? '' : parseInt(conversion_value),
                    mediaPartner: media_partner === 'undefined' ? '' : media_partner,
                    selected_in_Category: categories?.Include,
                    selected_ex_Category: categories?.Exclude,
                    categoriesType: categories?.Include?.length > 0 ? 'Include' : categories?.Exclude?.length > 0 ? 'Exclude' : 'All',

                    in_selectedWebsite: websites?.Include,
                    ex_selectedWebsite: websites?.Exclude,
                    WebsitesType: websites?.Include?.length > 0 ? 'Include' : websites?.Exclude?.length > 0 ? 'Exclude' : 'All',

                    in_selectedLocation: locationIn?.length ? (locationIn !== 'All' ? locationIn : []) : [],
                    ex_selectedLocation: locationEx?.length ? (locationEx !== 'All' ? locationEx : []) : [],

                    previewImagesDetail: detailsOfCam.media,
                    media: detailsOfCam.media,
                    /** audi exclude */
                    exclude_gender_type: '',
                    exclude_min_age: excludeAudi?.min_age,
                    exclude_max_age: excludeAudi?.max_age,
                    exclude_Occupation: excludeAudi?.occupation,
                    exclude_Education: excludeAudi?.education,
                    exclude_Purchase: excludeAudi?.purchase,
                    exclude_Interest: excludeAudi?.interest,

                    /** audi Include */
                    include_gender_type: includeAudi?.gender,
                    include_min_age: includeAudi?.min_age,
                    include_max_age: includeAudi?.max_age,
                    include_Occupation: includeAudi?.occupation,
                    include_Education: includeAudi?.education,
                    include_Purchase: includeAudi?.purchase,
                    include_Interest: includeAudi?.interest,
                    daysAndTimeDetails: dayAndTime === null ? { scheduleLineItems: '', daysTime: false, schedule: [{ start: '', end: '', dontRunDays: false, repeatOn: [] }] } : dayAndTime,
                });
                setAiPrompt(ai_prompt && ai_prompt !== undefined && ai_prompt !== "undefined" && ai_prompt !== null ? ai_prompt : "");

                dispatch(LocationAction.editLocation({ exclude: locationEx?.length ? (locationEx !== 'All' ? locationEx : []) : [], include: locationIn?.length ? (locationIn !== 'All' ? locationIn : []) : [] }));
            })
            .catch((err) => {
                console.log(err);
                dispatch(MessageActions.showMessage({ text: String(err), error: true }));
            });
    };

    const backToListing = () => {
        navigate(HEADERMENU_PATH.compaigns);
    };

    const validateScheduleStartDateAndEndDate = () => {
        let list = formFields.daysAndTimeDetails?.schedule;
        if (formFields.daysAndTimeDetails?.daysTime === false) {
            return false;
        }
        let ans = false;
        list?.forEach((item) => {
            if (item?.start === undefined || item?.start === '' || item?.end === undefined || item?.end === '' || item?.repeatOn?.length === 0) {
                ans = true;
            }
        });
        return ans;
    };

    const validateConversionVaue = () => {
        if (formFields.type === 'CPA') {
            if (formFields.conversionType === undefined || formFields.conversionType === '' || formFields.conversionValue === undefined || formFields.conversionValue === '' || formFields.conversionValue === 'NaN') {
                return true;
            }
        } else if (formFields.type === 'CPM' || formFields.type === 'CPC') {
            if (formFields.rate === undefined || formFields.rate === '') {
                return true;
            }
        }
        return false;
    };

    const onSaveButton = () => {
        setValidate(true);
        if (
            formFields.error ||
            // !formFields.name ||
            // formFields.name.trim() === '' ||
            !prevName ||
            prevName.trim() === '' ||
            !formFields.selectedMediaSizes ||
            formFields.selectedMediaSizes.length === 0 ||
            !formFields.type ||
            formFields.isContinuosly === undefined ||
            formFields.type.trim() === '' ||
            !formFields.durationStart ||
            !formFields.selectedBudget ||
            !formFields.enteredBudget ||
            !formFields.targetDevices ||
            !formFields.targetBrowsers ||
            !formFields.categoriesType ||
            !formFields.WebsitesType ||
            (formFields.frequencyCap && formFields.frequencyCap !== 'None' && !formFields.frequencyPerDay) ||
            (formFields.daysAndTimeDetails?.daysTime && !formFields.daysAndTimeDetails?.scheduleLineItems) ||
            formFields.overLapError ||
            validateScheduleStartDateAndEndDate() ||
            validateConversionVaue() ||
            (formFields.WebsitesType === 'Include' && (formFields.in_selectedWebsite?.length === 0 || formFields.in_selectedWebsite === undefined)) ||
            (formFields.WebsitesType === 'Exclude' && (formFields.ex_selectedWebsite?.length === 0 || formFields.ex_selectedWebsite === undefined)) ||
            (formFields.categoriesType === 'Include' && (formFields.selected_in_Category?.length === 0 || formFields.selected_in_Category === undefined)) ||
            (formFields.categoriesType === 'Exclude' && (formFields.selected_ex_Category?.length === 0 || formFields.selected_ex_Category === undefined))
        ) {
            return;
        }
        setValidate(false);
        const appendFormDataArray = (formData: any, dataArray: any, form_Key: string) => {
            if (!dataArray?.length) {
                return;
            }
            dataArray?.forEach((item: any, index: number) => {
                formData.append(`${form_Key}[${index}]`, item);
            });
        };

        const appendLocationFormData = (editFormData: any, locations: any, type: string) => {
            if (!locations || locations.length === 0) {
                return;
            }
            locations?.forEach((item: any, index: number) => {
                editFormData.append(`locations[${type}][${index}][country]`, item?.country);
                editFormData.append(`locations[${type}][${index}][city]`, item?.city);
                editFormData.append(`locations[${type}][${index}][state]`, item?.state);
            });
        };
        const editFormData = new FormData();

        editFormData.append('name', prevName);
        editFormData.append('description', formFields?.description || '');
        editFormData.append('campaign_type', formFields.type);
        editFormData.append('is_duration_continues', formFields.isContinuosly ? '1' : '0');
        editFormData.append('is_display_creative', formFields.isCreative ? '1' : '0');

        const start_dt = checkSecondIsAvailable(formFields.durationStart);
        editFormData.append('start_dt', String(start_dt));
        if (formFields.durationEnd) {
            const end_dt = checkSecondIsAvailable(formFields.durationEnd);
            editFormData.append('end_dt', String(end_dt));
        }
        editFormData.append('frequency_type', formFields.frequencyCap);
        editFormData.append('frequency', (formFields.frequencyCap !== 'None' ? formFields?.frequencyPerDay : '') || '');
        editFormData.append('budget_type', formFields.selectedBudget);
        editFormData.append('budget', formFields.enteredBudget);
        editFormData.append('publir_fee', formFields.publirFee);
        editFormData.append('ai_prompt', aiPrompt || '');
        if (formFields.type === 'CPA') {
            editFormData.append('conversion_type', formFields.conversionType);
            editFormData.append('conversion_value', formFields.conversionValue);
            editFormData.append('media_partner', formFields.mediaPartner);
        }

        editFormData.append('rate', formFields.type === 'CPM' || formFields.type === 'CPC' ? formFields.rate : formFields.conversionValue);

        /** devices array */
        appendFormDataArray(editFormData, formFields.targetDevices, 'devices');

        /** sizes Array */
        appendFormDataArray(editFormData, formFields.targetBrowsers, 'browsers');

        /** media size Array */
        appendFormDataArray(editFormData, formFields.selectedMediaSizes, 'sizes');

        // /** audiences include */
        // editFormData.append('audiences[include][gender]', formFields.include_gender_type || '');
        // editFormData.append('audiences[include][min_age]', String(formFields.include_min_age || ''));
        // editFormData.append('audiences[include][max_age]', String(formFields.include_max_age || ''));
        // appendFormDataArray(editFormData, formFields.include_Education, 'audiences[include][education]');
        // appendFormDataArray(editFormData, formFields.include_Occupation, 'audiences[include][occupation]');
        // appendFormDataArray(editFormData, formFields.include_Purchase, 'audiences[include][purchase]');
        // appendFormDataArray(editFormData, formFields.include_Interest, 'audiences[include][interest]');

        // /** audiences exclude */
        // editFormData.append('audiences[exclude][gender]', formFields.exclude_gender_type || '');
        // editFormData.append('audiences[exclude][min_age]', String(formFields.exclude_min_age || ''));
        // editFormData.append('audiences[exclude][max_age]', String(formFields.exclude_max_age || ''));
        // appendFormDataArray(editFormData, formFields.exclude_Education, 'audiences[exclude][education]');
        // appendFormDataArray(editFormData, formFields.exclude_Occupation, 'audiences[exclude][occupation]');
        // appendFormDataArray(editFormData, formFields.exclude_Purchase, 'audiences[exclude][purchase]');
        // appendFormDataArray(editFormData, formFields.exclude_Interest, 'audiences[exclude][interest]');

        /**location */
        // if (!getLocation.include.length && !getLocation.exclude.length) {
        //     editFormData.append('locations', 'All');
        // } else {
        //     appendLocationFormData(editFormData, getLocation.include, 'include');
        //     appendLocationFormData(editFormData, getLocation.exclude, 'exclude');
        // }

        if (!formFields?.in_selectedLocation?.length && !formFields?.ex_selectedLocation?.length) {
            editFormData.append('locations', 'All');
        } else {
            appendLocationFormData(editFormData, formFields?.in_selectedLocation, 'include');
            appendLocationFormData(editFormData, formFields?.ex_selectedLocation, 'exclude');
        }

        /** category */
        if (formFields.categoriesType === 'All') {
            editFormData.append('categories', formFields.categoriesType);
        } else {
            appendFormDataArray(editFormData, formFields.selected_in_Category, 'categories[Include]');
            appendFormDataArray(editFormData, formFields.selected_ex_Category, 'categories[Exclude]');
        }

        /** website */
        if (formFields.WebsitesType === 'All' || (formFields?.ex_selectedWebsite?.length === 0 && formFields.in_selectedWebsite?.length === 0)) {
            editFormData.append('websites', formFields.WebsitesType);
        } else {
            appendFormDataArray(editFormData, formFields.ex_selectedWebsite, 'websites[Exclude]');
            appendFormDataArray(editFormData, formFields.in_selectedWebsite, 'websites[Include]');
        }

        /** media */
        formFields.previewImagesDetail &&
            formFields.previewImagesDetail.forEach((item: any, index: number) => {
                editFormData.append(`media[${index}][size]`, item.size);
                item.image?.forEach((data: any, ind: number) => {
                    editFormData.append(`media[${index}][image][${ind}][name]`, data.name);
                    editFormData.append(`media[${index}][image][${ind}][destination_url]`, data.destination_url);
                    editFormData.append(`media[${index}][image][${ind}][tracker_script]`, data.tracker_script);
                    editFormData.append(`media[${index}][image][${ind}][file]`, data.file);
                    editFormData.append(`media[${index}][image][${ind}][prompt]`, data.prompt ?? '');

                });
                item.script?.forEach((data: any, ind: number) => {
                    editFormData.append(`media[${index}][script][${ind}][script]`, data.script);
                });
                item.text?.forEach((data: any, ind: number) => {
                    editFormData.append(`media[${index}][text][${ind}][text]`, data.text);
                    editFormData.append(`media[${index}][text][${ind}][name]`, data.name);
                    editFormData.append(`media[${index}][text][${ind}][destination_url]`, data.destination_url);
                });
            });

        if (formFields.daysAndTimeDetails && formFields.daysAndTimeDetails.daysTime) {
            editFormData.append('dayAndTime[scheduleLineItems]', formFields.daysAndTimeDetails.scheduleLineItems);
            editFormData.append('dayAndTime[daysTime]', String(formFields.daysAndTimeDetails.daysTime));

            formFields.daysAndTimeDetails.schedule.forEach((item: any, index: number) => {
                editFormData.append(`dayAndTime[schedule][${index}][dontRunDays]`, item.dontRunDays);
                editFormData.append(`dayAndTime[schedule][${index}][start]`, item.start);
                editFormData.append(`dayAndTime[schedule][${index}][end]`, item.end);
                item.repeatOn.forEach((day: any, ind: number) => {
                    editFormData.append(`dayAndTime[schedule][${index}][repeatOn][${ind}]`, day);
                });
            });
        } else {
            editFormData.append(`dayAndTime`, '');
        }

        setLoading(true);
        if (campaign_id) {
            Apis.editCampaign(editFormData, campaign_id)
                .then(({ data }) => {
                    dispatch(MessageActions.showMessage({ text: 'Campaign Update successfully!', error: false }));
                    Apis.generateTemperoryCampeign(campaign_id);
                    setUnsaveChanges(false);
                    //navigate(`${HEADERMENU_PATH.compaigns}`);
                })
                .catch((err) => {
                    console.log('err', err);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            Apis.createCampaign(editFormData)
                .then(({ data }) => {
                    dispatch(MessageActions.showMessage({ text: 'Campaign Added successfully!', error: false }));
                    Apis.generateTemperoryCampeign(data?.data?.campaign_id);
                    navigate(`${HEADERMENU_PATH.compaigns}`);
                })
                .catch((err) => {
                    console.log('err', err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const onChangeFields = (data: { name: string; value: string | number | undefined | boolean }) => {
        const { name, value } = data;
        if (data.name === 'isContinuosly') {
            setFormFields({ ...formFields, [name]: value, isStartImmeditialey: false, durationStart: undefined, durationEnd: undefined });
        } else if (name === 'durationStart') {
            setFormFields({ ...formFields, [name]: value, durationEnd: undefined });
        } else if (data.name === 'isStartImmeditialey') {
            setFormFields({ ...formFields, [name]: value, durationStart: value ? moment().format('YYYY-MM-DD HH:mm') : undefined });
        } else if (data.name === 'frequencyPerDay') {
            setFormFields({ ...formFields, [name]: value });
        } else {
            setFormFields({ ...formFields, [name]: value });
        }
        setUnsaveChanges(true);
    };

    const onChangeCamType = (data: RadioChangeEvent) => {
        const { name, value } = data.target;
        if (name === 'WebsitesType') {
            setFormFields({
                ...formFields,
                [name]: value,
                // in_selectedWebsite: []
            });
        } else if (name) {
            setFormFields({ ...formFields, [name]: value });
        }
        setUnsaveChanges(true);
    };

    const checkSecondIsAvailable = (date: string) => {
        if (date) {
            const dateString = date;
            const secondsAreZero = dateString.split(':');
            if (secondsAreZero && secondsAreZero[2]) {
                return dateString;
            } else {
                return `${dateString}:00`;
            }
        }
    };

    const onCategoryChange = (data: string) => {
        setFormFields({
            ...formFields,
            categoriesType: data,
            // selected_in_Category: []
        });
    };

    const onSelectOnlyOne = (data: CheckboxChangeEvent) => {
        setFormFields({ ...formFields, isCreative: data.target.checked });
        setUnsaveChanges(true);
    };

    const fetchMediaSizes = (data: any) => {
        setFormFields({ ...formFields, selectedMediaSizes: data });
    };

    const getSelectedDevice = (data: CheckboxValueType[]) => {
        setFormFields({ ...formFields, targetDevices: data });
    };

    const getSelectedBrowser = (data: CheckboxValueType[]) => {
        setFormFields({ ...formFields, targetBrowsers: data });
    };

    // const getInAndExTargetAudeience = (
    //     gender: string,
    //     min_age: number | undefined,
    //     max_age: number | undefined,
    //     occupation: string[] | undefined,
    //     education: string[] | undefined,
    //     modalType: string | undefined,
    //     interest: string[] | undefined,
    //     purchase: string[] | undefined,
    // ) => {
    //     if (modalType === 'in') {
    //         setFormFields({
    //             ...formFields,
    //             include_gender_type: gender,
    //             include_min_age: min_age,
    //             include_max_age: max_age,
    //             include_Occupation: occupation,
    //             include_Education: education,
    //             include_Purchase: purchase,
    //             include_Interest: interest,
    //         });
    //     } else {
    //         setFormFields({
    //             ...formFields,
    //             exclude_gender_type: gender,
    //             exclude_min_age: min_age,
    //             exclude_max_age: max_age,
    //             exclude_Occupation: occupation,
    //             exclude_Education: education,
    //             exclude_Purchase: purchase,
    //             exclude_Interest: interest,
    //         });
    //     }
    // };

    const getSubmitedDetail = (obj: object[]) => {
        setFormFields({ ...formFields, previewImagesDetail: obj });
    };

    const getDetailOfDaysAndTime = (detail: any) => {
        setFormFields({ ...formFields, daysAndTimeDetails: detail });
    };

    const setAiPromptTextGlobally = (text: string) => {
        setAiPrompt(text);
    }

    return (
        <div>
            <div className="fixed md:top-[70px] left-0 w-full colot-white md:z-[1] p-2.5">
                {/* Start Header of New Campaign */}
                <PNewCampaignHeader onClick={backToListing} onSaveButton={onSaveButton} loading={loading} campaign_id={campaign_id} unsaveChanges={unsaveChanges} />
                {/* End Header of New Campaign */}
            </div>
            <div id="create-new-camp" className="pt-4 mt-10">
                {/* Start Form of New Campaign */}
                <div className="">
                    <div className="flex mt-6 md:bg-[#f6f9f7]">
                        <div className="m-4 w-full ml-11">
                            <div>
                                <p className="flex items-center font-[Roboto-Medium] text-black font-medium text-sm">Basic Detail</p>
                                <div className="flex justify-between">
                                    <div className="w-6/12">
                                        {/* Basic Deatil start */}
                                        <PInputNameDes
                                            error={formFields.error}
                                            onChangeFields={onChangeFields}
                                            description={formFields.description}
                                            name={formFields.name}
                                            isValidate={isValidate}
                                            setUnsaveChanges={setUnsaveChanges}
                                            prevName={prevName}
                                            setPrevName={setPrevName}
                                        />
                                        {/* End basic detail */}

                                        {/* Start Expected Media sizes */}
                                        <PMediaSize selectedMediaSize={formFields.selectedMediaSizes} fetchMediaSizes={fetchMediaSizes} isValidate={isValidate} setUnsaveChanges={setUnsaveChanges} />
                                        {/* End Expected Media sizes */}

                                        {/* Start Bid Detail UI */}
                                        <PBidDetails
                                            onChangeFields={onChangeFields}
                                            onChangeCamType={onChangeCamType}
                                            onSelectOnlyOne={onSelectOnlyOne}
                                            type={formFields.type}
                                            isCreative={formFields.isCreative}
                                            durationEnd={formFields.durationEnd}
                                            durationStart={formFields.durationStart}
                                            frequencyCap={formFields.frequencyCap}
                                            frequencyPerDay={formFields.frequencyPerDay}
                                            selectedBudget={formFields.selectedBudget}
                                            publirFee={formFields.publirFee}
                                            enteredBudget={formFields.enteredBudget}
                                            isStartImmeditialey={formFields.isStartImmeditialey}
                                            campaign_types={constants.campaign_types}
                                            frequency_types={constants.frequency_types}
                                            budget_types={constants.budget_types}
                                            isValidate={isValidate}
                                            isContinuosly={formFields.isContinuosly}
                                            daysAndTimeDetails={formFields.daysAndTimeDetails}
                                            getDetailOfDaysAndTime={getDetailOfDaysAndTime}
                                            formFields={formFields}
                                            setFormFields={setFormFields}
                                            setUnsaveChanges={setUnsaveChanges}
                                            campId={campaign_id}
                                        />
                                        {/* End Bid Detail UI */}

                                        {/* Start Location UI */}
                                        <PLoaction
                                            ex_selectedLocation={formFields.ex_selectedLocation}
                                            in_selectedLocation={formFields.in_selectedLocation}
                                            isValidate={isValidate}
                                            locationList={constants?.cities || []}
                                            handleSaveItem={(in_item: LocationItem[], ex_item: LocationItem[]) => setFormFields({ ...formFields, ex_selectedLocation: ex_item, in_selectedLocation: in_item })}
                                            setUnsaveChanges={setUnsaveChanges}
                                        />
                                        {/* End Target Audience & Devices UI */}

                                        {/* Start Location UI */}
                                        <PTargetBrowserAndDevice
                                            checkedListOfDevice={formFields.targetDevices}
                                            checkedListOfBrowser={formFields.targetBrowsers}
                                            getSelectedDevice={getSelectedDevice}
                                            getSelectedBrowser={getSelectedBrowser}
                                            isValidate={isValidate}
                                            setUnsaveChanges={setUnsaveChanges}
                                        />
                                        {/* End Target Browser & Devices UI */}

                                        {/* Start Target Audience & Devices UI */}
                                        {/* <PTargetAudience
                                            getInAndExTargetAudeience={getInAndExTargetAudeience}
                                            includeGenderType={formFields.include_gender_type}
                                            includeMinAge={formFields.include_min_age}
                                            includeMaxAge={formFields.include_max_age}
                                            includeEducation={formFields.include_Education}
                                            includeOccupation={formFields.include_Occupation}
                                            excludeGenderType={formFields.exclude_gender_type}
                                            excludeMinAge={formFields.exclude_min_age}
                                            excludeMaxAge={formFields.exclude_max_age}
                                            excludeEducation={formFields.exclude_Education}
                                            excludeOccupation={formFields.exclude_Occupation}
                                            isValidate={isValidate}
                                            includePurchase={formFields.include_Purchase}
                                            includeInterest={formFields.include_Interest}
                                            excludePurchase={formFields.exclude_Purchase}
                                            excludeInterest={formFields.exclude_Interest}
                                            setUnsaveChanges={setUnsaveChanges}
                                        /> */}
                                        {/* End Target Audience & Devices UI */}

                                        {/* Start Categories UI */}
                                        <PCategoriesOfCamp
                                            categoriesType={formFields.categoriesType}
                                            onCategoryChange={onCategoryChange}
                                            selected_in_Category={formFields.selected_in_Category}
                                            selected_ex_Category={formFields.selected_ex_Category}
                                            categoryChooseType={constants.websites_types}
                                            isValidate={isValidate}
                                            handleSaveItem={(item) => {
                                                if (formFields.categoriesType === 'Include') {
                                                    setFormFields({ ...formFields, selected_in_Category: item });
                                                } else if (formFields.categoriesType === 'Exclude') {
                                                    setFormFields({ ...formFields, selected_ex_Category: item });
                                                }
                                            }}
                                            setUnsaveChanges={setUnsaveChanges}
                                        />
                                        {/* End Categories UI */}

                                        {/* Start Websites Categories UI */}
                                        <PWebsiteOfCamp
                                            WebsitesType={formFields.WebsitesType}
                                            in_selectedWebsite={formFields.in_selectedWebsite}
                                            ex_selectedWebsite={formFields.ex_selectedWebsite}
                                            onChangeCamType={onChangeCamType}
                                            websiteChooseType={constants.websites_types}
                                            isValidate={isValidate}
                                            websiteList={constants?.websites || []}
                                            handleSaveItem={(item) => {
                                                if (formFields.WebsitesType === 'Include') {
                                                    setFormFields({ ...formFields, in_selectedWebsite: item });
                                                } else if (formFields.WebsitesType === 'Exclude') {
                                                    setFormFields({ ...formFields, ex_selectedWebsite: item });
                                                }
                                            }}
                                            setUnsaveChanges={setUnsaveChanges}
                                        />
                                        {/* End Websites Categories UI */}
                                    </div>

                                    <div className="w-5/12 mr-4">
                                        {/* Start Preview Image of 970 dimension UI */}
                                        <PImagePreviewUI
                                            selectedMediaSizes={formFields.selectedMediaSizes}
                                            getSubmitedDetail={getSubmitedDetail}
                                            previewData={formFields.media}
                                            isValidate={isValidate}
                                            setUnsaveChanges={setUnsaveChanges}
                                            aiPrompt={aiPrompt}
                                            setAiPromptTextGlobally={setAiPromptTextGlobally}
                                        />
                                        {/* End Preview Image of 970 dimension UI */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Form of New Campaign */}
            </div>
        </div>
    );
};
export default CreateOrEditCampaign;
