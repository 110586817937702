import PTable from "../../../../common/Table";
import CompareOldNewValuesModal from "./compareOldNewValuesModal";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserActivitiesActions from "../redux/actions";
import { RootState } from "../../../../../store/RootReducer";
import { UserActivitiesFilterState } from "../redux/type";

type Props = {
     userActivitiesFilters: UserActivitiesFilterState,
     startDate: any,
     endDate: any,
}
                        
const UserActivitiesTable: React.FC<Props> = ({ userActivitiesFilters, startDate, endDate }) => {
     const dispatch = useDispatch();
     const userActivityData = useSelector((state: RootState) => state.userActivity.userActivityListData);
     const userActivityLoader = useSelector((state: RootState) => state.userActivity.userActivityListDataLoader);

     useEffect(() => {
          dispatch(UserActivitiesActions.fetchUserActivitiesList({
               startDate: startDate,
               endDate: endDate,
               module: userActivitiesFilters.module ? userActivitiesFilters.module : '',
               email: userActivitiesFilters.email ? userActivitiesFilters.email : '',
               campaign: userActivitiesFilters.campaign ? userActivitiesFilters.campaign : ''
          }));
     }, [startDate, endDate, userActivitiesFilters,dispatch])

     const columns = [
          {
               dataIndex: 'user_email',
               title: 'User Email',
               width: 200,
               fixed: 'left',
               sorter: (a: any, b: any) => String(a.user_email).localeCompare(String(b.user_email)),
          },
          {
               dataIndex: 'datetime',
               title: 'Date',
               width: 150,
               sorter: (a: any, b: any) => moment(a.datetime).unix() - moment(b.datetime).unix(),
          },
          {
               dataIndex: 'campaign_name',
               title: 'Campaign Name',
               width: 200,
               sorter: (a: any, b: any) => String(a.campaign_name).localeCompare(String(b.campaign_name)),
          },
          {
               dataIndex: 'module_name',
               title: 'Module Name',
               width: 150,
               sorter: (a: any, b: any) => String(a.module_name).localeCompare(String(b.module_name)),
          },
          {
               dataIndex: 'configuration_action',
               title: 'Action',
               width: 100,
               sorter: (a: any, b: any) => String(a.configuration_action).localeCompare(String(b.configuration_action)),
          },
          {
               dataIndex: 'change',
               title: 'View Logs',
               width: 150,
               sorter: (a: any, b: any) => String(a.configuration_old_value).localeCompare(String(b.configuration_old_value)),
               render: (text: any, row: any) => (
                    <div className="flex justify-center">
                         <div><CompareOldNewValuesModal data={row} /></div>
                    </div>
               )
          },

     ]
     return (
          <div className="mt-5">
               <PTable columns={columns} data={userActivityData || []} pagination={{ isShow: true }} loading={userActivityLoader} />
          </div>
     )
}

export default UserActivitiesTable;
