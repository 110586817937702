import { call, put, takeLatest } from 'redux-saga/effects';
import Apis from '../../../../../api';
import campaginsActions from './actions';
import campaignTypes, { emptyConstants } from './type';
import LocationAction from '../modal/Redux/action';

const fetchCampaignsRequest = function* fetchCampaignsRequest({payload}:any) {
    try {
        const { data } = yield call(Apis.fetchCampaigns);
        if (data.statusCode === 200) {
            const response = {
                data:data?.data?.data,
                totalRecords:data?.data?.total
            }
            yield put(campaginsActions.setCampaigns(response || {data:[],totalRecords:0}));
        } else {
            yield put(campaginsActions.setCampaigns({data:[],totalRecords:0}));
        }
    } catch (error) {
        yield put(campaginsActions.setCampaigns({data:[],totalRecords:0}));
    }
};

const fetchActiveCampaignsRequest = function* fetchActiveCampaignsRequest({payload}:any) {
    try {
        const { data } = yield call(Apis.fetchActiveCampaigns);
        if (data.statusCode === 200) {
            const response = {
                data:data?.data?.data,
                totalRecords:data?.data?.total
            }
            yield put(campaginsActions.setActiveCampaigns(response || {data:[],totalRecords:0}));
        } else {
            yield put(campaginsActions.setActiveCampaigns({data:[],totalRecords:0}));
        }
    } catch (error) {
        yield put(campaginsActions.setActiveCampaigns({data:[],totalRecords:0}));
    }
};

const fetchPausedCampaignsRequest = function* fetchPausedCampaignsRequest({payload}:any) {
    try {
        const { data } = yield call(Apis.fetchPausedCampaigns);
        if (data.statusCode === 200) {
            const response = {
                data:data?.data?.data,
                totalRecords:data?.data?.total
            }
            yield put(campaginsActions.setPausedCampaigns(response || {data:[],totalRecords:0}));
        } else {
            yield put(campaginsActions.setPausedCampaigns({data:[],totalRecords:0}));
        }
    } catch (error) {
        yield put(campaginsActions.setPausedCampaigns({data:[],totalRecords:0}));
    }
};

const fetchCompletedCampaignsRequest = function* fetchCompletedCampaignsRequest({payload}:any) {
    try {
        const { data } = yield call(Apis.fetchCompletedCampaigns);
        if (data.statusCode === 200) {
            const response = {
                data:data?.data?.data,
                totalRecords:data?.data?.total
            }
            yield put(campaginsActions.setCompletedCampaigns(response || {data:[],totalRecords:0}));
        } else {
            yield put(campaginsActions.setCompletedCampaigns({data:[],totalRecords:0}));
        }
    } catch (error) {
        yield put(campaginsActions.setCompletedCampaigns({data:[],totalRecords:0}));
    }
};


const fetchConstantsReq = function* fetchConstantsReq() {
    try {

        const { data } = yield call(Apis.fetchConstants);
        let newData = data?.data;
        let freq = ['None', 'Hourly', ...newData?.frequency_types];
        newData.frequency_types = freq;

        let raw = [...Object.values(newData.all_countries).sort()];
        const indx = raw.indexOf('United States');
        raw?.splice(indx, 1);
        const countries = ['United States', ...raw];

        let locationMap: any = {};
        for (let i = 0; i < countries.length; i++) {
            let item: any = countries[i];
            locationMap[item] = { data: {}, allStatesPresent: false, citiesForStatesArr: [], contentType: 'all', display: true };
        }
        
        yield put(LocationAction.setAllDefaultCountries(locationMap));
        yield put(campaginsActions.setConstants(newData || emptyConstants));
    } catch (error) {
        yield put(campaginsActions.setConstants(emptyConstants));
    }
};

export default function* sagas() {
    yield takeLatest(campaignTypes.FETCH_CAMPAIGNS, fetchCampaignsRequest);
    yield takeLatest(campaignTypes.FETCH_ACTIVE_CAMPAIGNS, fetchActiveCampaignsRequest);
    yield takeLatest(campaignTypes.FETCH_PAUSED_CAMPAIGNS, fetchPausedCampaignsRequest);
    yield takeLatest(campaignTypes.FETCH_COMPLETED_CAMPAIGNS, fetchCompletedCampaignsRequest);
    yield takeLatest(campaignTypes.FETCH_CONSTANTS, fetchConstantsReq);
}
