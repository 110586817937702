const PageNotFound = () => {
     return (
          <div className="flex justify-center">
               <div className="flex flex-col items-center mt-10">
                    <div className="text-[32px] font-bold">404</div>
                    <div className="font-bold">Page Not Found.</div>
               </div>
          </div>
     )
}

export default PageNotFound;