import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import createSagaMiddleware from 'redux-saga';
import { getRootReducer } from './RootReducer';
import rootSagas from './RootSagas';
declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}
const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user'],
};
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
    const middlewareEnhancer = applyMiddleware(sagaMiddleware);

    const composedEnhancers = composeEnhancer(middlewareEnhancer);
    const persistedReducer = persistReducer(persistConfig, getRootReducer());

    const store = createStore(persistedReducer, undefined, composedEnhancers);
    let persistor = persistStore(store);
    sagaMiddleware.run(rootSagas);
    return { store, persistor };
}
