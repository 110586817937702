import React from 'react'


type Props = {
     color?: any;
     handleColor?: any;
     className?: any;
}
const ColorPicker: React.FC<Props> = ({ color, handleColor, className }) => {

     // function handleColor(e:any){
     //      setColor(e.target.value)
     // }

     return (
          <div>
               <input type='color' value={color} className={className} onChange={handleColor} />
          </div>
     )
}

export default ColorPicker
