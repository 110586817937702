import download from 'downloadjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Apis from '../../../../../api';
import { RootState } from '../../../../../store/RootReducer';
import PButton from '../../../../common/Button';
import CustomTabSwitch from '../../../../common/CustomTabSwitch';
import MessageActions from '../../../../message/redux/actions';
import TopItemsBarGraph from '../graphs&charts/TopItemsBarGraph';
import OverAllReportAction from '../redux/actions';

type Props = {
    title: string;
    top: string;
    startDate: any;
    endDate: any;
    campId: any;
};

const CpaRevenueGraph: React.FC<Props> = ({ title, top, startDate, endDate, campId }) => {
    const dispatch = useDispatch();
    const [exportLoading, setExportLoading] = useState(false);
    const [impressionClickSwitch, setImpressionClickSwitch] = useState<string>('Impressions');
    const switchTabs = ['Impressions', 'Clicks'];
    const {
        topCountriesImpData,
        topCountriesClickData,
        topCountriesDataLoader,
        topDevicesImpData,
        topDevicesClickData,
        topDevicesDataLoader,
        topMediaSizesImpData,
        topMediaSizesClickData,
        topMediaSizesDataLoader,
        topSitesImpData,
        topSitesClickData,
        topSitesDataLoader,
    } = useSelector((state: RootState) => state.report);

    const filterArrayData = (arr: any) => {
        return arr.filter((item: any) => item?.value && item?.value !== 0);
    };

    const graphData: { data: any; loading: boolean } = useMemo(() => {
        switch (top) {
            case 'country':
                return { data: impressionClickSwitch === 'Impressions' ? topCountriesImpData : filterArrayData(topCountriesClickData), loading: topCountriesDataLoader };
            case 'device':
                return { data: impressionClickSwitch === 'Impressions' ? topDevicesImpData : filterArrayData(topDevicesClickData), loading: topDevicesDataLoader };
            case 'size':
                return { data: impressionClickSwitch === 'Impressions' ? topMediaSizesImpData : filterArrayData(topMediaSizesClickData), loading: topMediaSizesDataLoader };
            case 'site':
                return { data: impressionClickSwitch === 'Impressions' ? topSitesImpData : filterArrayData(topSitesClickData), loading: topSitesDataLoader };
            default:
                return { data: [], loading: false };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [top, switchTabs]);

    useEffect(() => {
        if (top === 'country') {
            if (startDate && endDate && campId) {
                dispatch(
                    OverAllReportAction.fetchTopCountriesReportsData(
                        {
                            start_dt: startDate,
                            end_dt: endDate,
                            value: top,
                        },
                        campId,
                    ),
                );
            }
        }
        if (top === 'device') {
            if (startDate && endDate && campId) {
                dispatch(
                    OverAllReportAction.fetchTopDevicesReportsData(
                        {
                            start_dt: startDate,
                            end_dt: endDate,
                            value: top,
                        },
                        campId,
                    ),
                );
            }
        }
        if (top === 'size') {
            if (startDate && endDate && campId) {
                dispatch(
                    OverAllReportAction.fetchTopMediaSizesReportsData(
                        {
                            start_dt: startDate,
                            end_dt: endDate,
                            value: top,
                        },
                        campId,
                    ),
                );
            }
        }
        if (top === 'site') {
            if (startDate && endDate && campId) {
                dispatch(
                    OverAllReportAction.fetchTopSitesReportsData(
                        {
                            start_dt: startDate,
                            end_dt: endDate,
                            value: top,
                        },
                        campId,
                    ),
                );
            }
        }
    }, [startDate, endDate, campId, top, dispatch]);

    const GraphExport = () => {
        if (startDate && endDate) {
            const payload = {
                start_dt: startDate,
                end_dt: endDate,
                type: top,
            };
            setExportLoading(true);

            Apis.exportIndividualCampTopDataApi(payload, campId)
                .then(({ data }) => {
                    download(data, `Overall report of top${top}.xlsx`);
                })
                .catch((err) => {
                    dispatch(MessageActions.showMessage({ text: String('Something went wrong'), error: true }));
                })
                .finally(() => {
                    setExportLoading(false);
                });
        }
    };

    return (
        <div className="rounded-lg drop-shadow-md bg-white px-3  h-full">
            <div className="pt-5 pb-2 px-2 border-b-[1px]">
                <div className="flex justify-between items-center">
                    <div className="font-[Roboto] font-[700] text-[18px]">{title}</div>
                    <div className="flex items-center mr-5">
                        <div className="">
                            <CustomTabSwitch tabs={switchTabs} activeTab={impressionClickSwitch} onChangeTab={(item: string) => setImpressionClickSwitch(item)} />
                        </div>
                        {campId === 'overall-report' && (
                            <div className="">
                                <PButton loading={exportLoading} className="w-[80px]" title={'Export'} onClick={() => GraphExport()} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <TopItemsBarGraph data={graphData.data || []} loading={graphData.loading} />
        </div>
    );
};

export default CpaRevenueGraph;
