import { Modal } from 'antd';
import React from 'react';

type Props = {
    OnClickOfCancel?: () => void;
    OnClickOfOk?: () => void;
    className?: string;
    ViewOfModal?: any;
    isModalOpen?: boolean;
    customizeFooter?: any;
    customizeHeader?: any;
    width?: any;
};

const PModal: React.FC<Props> = ({ customizeHeader, customizeFooter, OnClickOfOk, OnClickOfCancel, className, ViewOfModal, isModalOpen, width }) => {
    return (
        <div>
            <Modal title={customizeHeader ? customizeHeader : null} onOk={OnClickOfOk} onCancel={OnClickOfCancel} open={isModalOpen} footer={customizeFooter ? customizeFooter : null} closeIcon={null} width={width}>
                {ViewOfModal}
            </Modal>
        </div>
    );
};

export default PModal;
