import React from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid, LabelList } from 'recharts';
import { Empty, Spin } from 'antd';
import { commaSeperator, numberFormatter } from '../../../../../utils/Validation';


type Props = {
    data: any;
    loading: boolean;
    suffix?: string;
    prefix?: string;
};



const TopItemsBarGraph: React.FC<Props> = ({ data, loading, suffix, prefix }) => {

    const formatYAxisTick = (tickValue: number):any => {
        // Format tick values in K format
        return numberFormatter(tickValue);
    };


    const CustomizedLabel = (Pro: any) => {
        const { color, x, y, name } = Pro;
        return (
            <text x={x} y={y} dy={-5} fill={color} fontSize={10} textAnchor="left">
                {name}
            </text>
        );
    };



    return (
        <div>
            {!loading && (
                <>
                    {
                        data?.length !== 0 ?
                            <ResponsiveContainer height={300} width="100%">
                                <BarChart
                                    width={600}
                                    height={400}
                                    data={data}
                                    layout="vertical"
                                    margin={{ top: 20, right: 50, left: 0, bottom: 5 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis  type="number" tickFormatter={formatYAxisTick} tick={{ fill: '#615E83', fontSize: '0.8rem', fontFamily: 'Roboto' }} />
                                    <YAxis tick={false} axisLine={false} dataKey="name" type="category" />
                                    <Tooltip content={<CustomTooltip labelClassName={suffix ? suffix : ''} />} />
                                    <Bar dataKey="value" fill="#056433" maxBarSize={20} radius={4} label={{ formatter: (value: any) => { return commaSeperator(value) }, position: 'right', fill: '#8884d8', fontSize: '0.8rem', fontFamily: 'Roboto' }}  >
                                        <LabelList content={<CustomizedLabel />} />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                            :
                            <div className="flex justify-center items-center h-[200px]">
                                <Empty className='mt-5' description={'No data found'} />
                            </div>
                    }
                </>
            )}
            {loading && (
                    <div className="flex align-center justify-center h-[200px]">
                         <Spin className="m-auto" />
                    </div>
               )}

        </div>
    );
};

export default TopItemsBarGraph;


const CustomTooltip = ({ active, payload, label, labelClassName, wrapperClassName }: any) => {
    if (active) {
        return (
            <div className="shadow rounded" style={{ background: 'rgba(30, 30, 30, 0.8)' }}>
                <div className="px-4 py-1" style={{ background: 'rgba(0, 0, 0, 0.7)', borderBottom: '1px solid #333' }}>
                    <p className="label text-xs roboto-medium text-white">{label}</p>
                </div>
                <div className="desc px-4 py-1 text-center">
                    {payload?.map((p: any, index: number) => (
                        <div key={`${index}_${p.name}`} className={'text-xs text-white flex justify-between items-center'}>
                            <p>{p.name}&nbsp;</p>
                            <p>{labelClassName ? `${labelClassName}${commaSeperator(p.value)}` : commaSeperator(p.value)}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return null;
};
