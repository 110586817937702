const chars = [
     'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
     'abcdefghijklmnopqrstuvwxyz',
     '!@#$%^&*?',
     '0123456789'
]

const getRandomInteger = (min: number, max: number) => {
     return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const generateCustomPassword = () => {
     let password = "";
     for (let i = 0; i < 12; i++) {
          const str = chars[i % 4];
          const idx = getRandomInteger(0, str.length - 1);
          password += str[idx];
     }
     return password;
}

