import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import React, { useMemo, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { CiSearch } from 'react-icons/ci';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/RootReducer';
import PNormalInput from '../../../../common/NormalInput';
import { MdClose } from "react-icons/md";

type Props = {
    getSelectedSizes: (data: CheckboxValueType[]) => void;
    showCheckedBoxes: CheckboxValueType[];
};

interface searchItem {
    searchText: string;
}

const PImageSizeModalView: React.FC<Props> = ({ getSelectedSizes, showCheckedBoxes }) => {
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(showCheckedBoxes);
    const [search, setSearch] = useState<searchItem>({
        searchText: '',
    });
    const constants = useSelector((state: RootState) => state.campaign.constants);

    // Map the array to replace key names
    const updatedMediaSizes: { label: string; value: string }[] = constants.media_sizes.map((s) => {
        return { label: s.name, value: s.size };
    });

    const onChange = (checkedValues: CheckboxValueType[]) => {
        setCheckedList(checkedValues);
        getSelectedSizes(checkedValues);
    };

    const removeItem = (item: string) => {
        const updatedArray = checkedList.filter((data) => data !== item);
        setCheckedList(updatedArray);
        getSelectedSizes(updatedArray);
    };

    const onSearchName = (e: { name: string; value: string | number | undefined }) => {
        const { name, value } = e;
        setSearch({
            ...search,
            [name]: value,
        });
    };

    const filteredData = useMemo(() => {
        const lowerSearchText = search.searchText.toLowerCase();
        return updatedMediaSizes.filter((item) => {
            // Check if label or value includes the search text (case-insensitive)
            return item.label.toLowerCase().includes(lowerSearchText) || item.value.toLowerCase().includes(lowerSearchText);
        });
    }, [updatedMediaSizes, search.searchText]);

    const removeEnteredText = () => {
        setSearch({
            ...search,
            searchText: '',
        });
    }

    return (
        <div>
            <PNormalInput prefix={<CiSearch />} 
                value={search.searchText} 
                name="searchText" 
                className="md:py-2 md:px-2 mt-6" 
                onChange={onSearchName} 
                placeholder="Search Sizes" 
                suffix={
                    <MdClose className='cursor-pointer' onClick={removeEnteredText} />
                }
            />
            <div className="mt-8 mb-4">
                <Checkbox.Group value={checkedList} onChange={onChange}>
                    {filteredData.length > 0 &&
                        filteredData.map((item, index) => (
                            <Checkbox key={index} value={item.value}>
                                {item.value} ({item.label})
                            </Checkbox>
                        ))}
                </Checkbox.Group>
            </div>
            <div className="flex flex-wrap border md:border-[#000] rounded-md px-3 pb-4 pt-3 my-10">
                {checkedList?.map((item, index) => (
                    <p key={index} className="flex items-center justify-around border md:border-[#e5e7eb] px-2.5 h-6 md:w-[auto] font-[Roboto-Medium] text-black font-medium text-xs mt-2 ml-1 ">
                        {item}
                        <AiOutlineCloseCircle onClick={() => removeItem(String(item))} size={'1rem'} className="cursor-pointer mr-1 ml-2.5" />
                    </p>
                ))}
            </div>
        </div>
    );
};

export default PImageSizeModalView;
