import { Radio } from 'antd';
import OpenAI from 'openai';
import React, { useEffect, useState } from 'react';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { IoArrowBackCircleOutline, IoRadioButtonOffSharp, IoRadioButtonOn } from 'react-icons/io5';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { isValidUrl } from '../../../../../utils/Validation';
import PButton from '../../../../common/Button';
import PModal from '../../../../common/Modal';
import PNormalInput from '../../../../common/NormalInput';
import PNormalTextArea from '../../../../common/NormalTextArea';
import PImageUploadForm from './ImageUploadForm';
import ImageEditTextAndPreview from './ImageEditTextAndPreview';
import domtoimage from 'dom-to-image';
import { useDispatch } from 'react-redux';
import MessageActions from '../../../../message/redux/actions';
import moment from 'moment';
type PAddImagesModalViewProps = {
    onSubmitImageDetail: (name: string, destination_url: string, file: File | any, tracker_script: string, prompt: string) => void;
    selectedSize?: any;
    selectedEditData: any;
    setUnsaveChanges: any;
    aiPrompt: string;
    setAiPromptTextGlobally: (text:string)=>void;
};
interface addImage {
    name?: string;
    destination_url?: string;
    tracker_script?: any;
    selectedFile?: any;
    imagePreview?: string | null;
    isValidate?: boolean;
    errorMessage: string;
    typeOfText?: boolean;
    responseList: { revised_prompt: any | null; url: any | null }[] | null;
    selectedValue?: any;
    isValid?: boolean;
    aiImageSelected?: any;
    isDesSelect?: string;
    isTitleSelect?: string;
    rawImg: any;
    prompt?: any;
}

export interface CropImg {
    unit: any;
    width: number;
    height: number;
    x: number;
    y: number;
}

const PAddImagesModalView: React.FC<PAddImagesModalViewProps> = ({ onSubmitImageDetail, selectedSize, selectedEditData, setUnsaveChanges, aiPrompt, setAiPromptTextGlobally }) => {
    const [addImageForm, setAddImageForm] = useState<addImage>({
        destination_url: selectedEditData?.destination_url ? selectedEditData?.destination_url : undefined,
        name: selectedEditData?.name ? selectedEditData?.name : undefined,
        tracker_script: selectedEditData?.tracker_script && selectedEditData?.tracker_script !== 'undefined' ? selectedEditData?.tracker_script : undefined,
        selectedFile: selectedEditData?.file ? selectedEditData?.file : undefined,
        imagePreview: undefined,
        isValidate: false,
        errorMessage: '',
        typeOfText: false,
        responseList: null,
        selectedValue: selectedEditData?.file || '',
        isValid: true,
        isTitleSelect: '',
        isDesSelect: '',
        aiImageSelected: undefined,
        rawImg: undefined,
        prompt: selectedEditData?.prompt ?? '',
    });
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [upComingWidth, upComingHeight] = selectedSize?.size?.split('*');
    const [crop, setCrop] = useState<CropImg>();
    const [cropModal, setCropModal] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [isImageValidAfterPreview, setIsImageValidAfterPreview] = useState(false);
    const [aiSearchPrompt, setAiSearchPrompt] = useState("")
    const onChangeFields = (data: { name: string; value: string | number | undefined }) => {
        const { name, value } = data;
        if (name === 'destination_url' && value) {
            setAddImageForm({ ...addImageForm, isValid: isValidUrl(value.toString()), [name]: value.toString() });
        } else {
            setAddImageForm({ ...addImageForm, [name]: value, responseList: [] });
        }
    };

    const toBase64 = (file: File) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleImageUpload = async (file: File) => {
        // Handle the image upload logic here, e.g., send the file to a server or process it locally.
        const imageSrc = await toBase64(file);
        //const imageSrc = URL.createObjectURL(file);
        if (imageSrc) {
            //setAddImageForm({ ...addImageForm, aiImageSelected: imageSrc });
            onChangeImage(file, true, imageSrc)
            setCropModal(true);
        }

    };

    const onClickSubmitAi = async () => {
        setAddImageForm({ ...addImageForm, isValidate: true, errorMessage: '' });
        // Check if all necessary form fields are filled
        // if (!addImageForm.name || addImageForm.name.trim() === '') {
        //     return;
        // }
        let localPromptText = addImageForm?.prompt ?? aiSearchPrompt;
        if (!localPromptText || localPromptText.trim() === '') {
            return;
        }
        try {
            const openai = new OpenAI({ apiKey: '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', organization: 'org-HF8ZjLmN98kuQpcqgKIE5NBL', dangerouslyAllowBrowser: true });
            setLoader(true);
            const numberOfImages = 2;
            const imageResults = [];
            for (let i = 0; i < numberOfImages; i++) {
                try {
                    const result = await openai.images.generate({
                        model: 'dall-e-2', // Specify the model you want to use
                        n: 1,
                        response_format: 'b64_json',
                        quality: 'hd',
                        prompt:
                            addImageForm.isTitleSelect && addImageForm.isDesSelect
                                ? `Generate an image ad for ${localPromptText} without any generated text add the following title to the image without making any changes to text , text: "${addImageForm.isTitleSelect}" and add the following description to the image without making any changes to text, text: "${addImageForm.isDesSelect}" make sure the color for the title has a nice contrast`
                                : addImageForm.isTitleSelect
                                    ? `Generate an image ad for ${localPromptText} without any generated text add the following title to the image without making any changes to text: "${addImageForm.isTitleSelect}" and make sure the color for the title has a nice contrast`
                                    : addImageForm.isDesSelect
                                        ? `Generate an image ad for ${localPromptText} without any generated text and add the following description to the image without making any changes to text: "${addImageForm.isDesSelect}" make sure the color for the title has a nice contrast`
                                        : `generate image of ads ${localPromptText} without text`,
                        size: (parseInt(upComingWidth) <= 256 && parseInt(upComingHeight) <= 256) ? '256x256' : (parseInt(upComingWidth) <= 512 && parseInt(upComingHeight) <= 512) ? '512x512' : '1024x1024',
                    });

                    imageResults.push(`data:image/png;base64,${result?.data[0]?.b64_json}`);
                } catch (error: any) {
                    dispatch(MessageActions.showMessage({ text: String(error.message), error: true }));
                }

            }

            let dataList = addImageForm.responseList;
            const formattedResults: { revised_prompt: any; url: any }[] = imageResults.map((image: any) => ({
                revised_prompt: image.revised_prompt,
                url: image,
            }));
            dataList?.push(...formattedResults);
            setLoader(false);
            setAddImageForm({ ...addImageForm, responseList: dataList });
        } catch (error: any) {
            console.log('Error interacting with ChatGPT:', error);
            setLoader(false);
        }
    };

    //called on procced button after selecting an image works  for both ai image and manual image
    const onClickSubmit = async () => {
        setAddImageForm({ ...addImageForm, isValidate: true, errorMessage: '' });
        if (!addImageForm.selectedValue) {
            setAddImageForm({ ...addImageForm, isValidate: true, errorMessage: 'Please select an image' });
        }

        // Check if all necessary form fields are filled
        if (!addImageForm.name || addImageForm.name.trim() === '' || !addImageForm.destination_url || addImageForm.destination_url.trim() === '' || !addImageForm?.selectedFile || !addImageForm.isValid) {
            return;
        }

        // Validate image resolution
        const image = new Image();
        let imageSrc = '';
        if (typeof addImageForm?.selectedFile === 'string') {
            image.src = addImageForm?.selectedFile;
        } else {
            imageSrc = URL.createObjectURL(addImageForm.selectedFile);
            image.src = imageSrc;
        }

        const imageResolution = await new Promise<{ width: number; height: number }>((resolve, reject) => {
            image.onload = () => {
                resolve({ width: image.width, height: image.height });
            };
            image.onerror = (error) => {
                reject(error);
            };
        }).catch((error) => {
            console.error('Error loading image:', error);
            return null;
        });
        if (imageResolution && imageResolution.width === parseInt(upComingWidth) && imageResolution.height === parseInt(upComingHeight)) {
            // Proceed with the form submission if the image meets the resolution criteria
            setAddImageForm({ ...addImageForm, isValidate: false, errorMessage: '' });
            const newName = `${moment().unix()}-${addImageForm.name}`
            let newDataImage:any;
            if(typeof addImageForm?.selectedFile != 'string'){
                newDataImage = new File([addImageForm?.selectedFile], newName, {
                    type: addImageForm?.selectedFile?.type,
                    lastModified: new Date().getTime()
                })
            }else{
                newDataImage = addImageForm?.selectedFile;
            }
            if (aiSearchPrompt) {
                setAiPromptTextGlobally(aiSearchPrompt);
            }
            console.log(addImageForm?.prompt);
            onSubmitImageDetail(addImageForm.name, addImageForm.destination_url, newDataImage, addImageForm.tracker_script, addImageForm?.prompt);
            setUnsaveChanges(true)

        } else {
            // Display an error message or prevent the submission if the resolution is not 100x100
            setAddImageForm({
                ...addImageForm,
                isValidate: true,
                errorMessage: `Sorry,  this image cannot be saved. Please upload an image with the following dimensions: ${upComingWidth} by ${upComingHeight}`,
            });
            // Optionally, you can set an error state or show an error message to the user.
        }
    };


    const onChangeImage = (e: any, manualImageUpload = false, manualImageSrc?: any) => {
        const i1 = document.getElementById('greaterWidthImage');
        const i2 = document.getElementById('greaterHeightImage');
        i1?.remove();
        i2?.remove();
        var fileInput = false;
        if (manualImageUpload) {
            fileInput = true;
        } else {
            if (e.target.value) {
                fileInput = true;
            }
        }

        if (fileInput) {
            const imageUrl = manualImageUpload ? manualImageSrc : e.target.value;
            if (imageUrl) {
                // Set the dimensions you want for the cropped image
                const cropDimensions: CropImg = {
                    unit: 'px',
                    x: 0,
                    y: 0,
                    width: parseInt(upComingWidth),
                    height: parseInt(upComingHeight),
                };
                const maxWidthImage = document.createElement('img');
                maxWidthImage.src = imageUrl;
                maxWidthImage.id = 'greaterWidthImage';
                maxWidthImage.width = upComingWidth;
                document.body.appendChild(maxWidthImage);

                const maxHeightImage = document.createElement('img');
                maxHeightImage.src = imageUrl;
                maxHeightImage.id = 'greaterHeightImage';
                maxHeightImage.height = upComingHeight;
                document.body.appendChild(maxHeightImage);

                let node: any = parseInt(upComingWidth) > parseInt(upComingHeight) ? document.getElementById('greaterWidthImage') : document.getElementById('greaterHeightImage');
                domtoimage.toBlob(node)
                    .then(function (blob: any) {
                        const newUrl = URL.createObjectURL(blob);
                        setAddImageForm({ ...addImageForm, aiImageSelected: newUrl, rawImg: imageUrl });
                        setCrop(cropDimensions);
                    }).catch(() => {
                        setPreviewModal(false);
                        console.log('some error occured')
                    })


            }
        }
    };


    const getCroppedImage = async (imageSrc: string): Promise<string> => {
        // Fetch the image and convert it to a Blob
        const response = await fetch(imageSrc);
        const blob = await response.blob();
        const newCrop: any = { ...crop };
        return new Promise<string>((resolve) => {
            const image = new Image();
            image.src = URL.createObjectURL(blob);
            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = newCrop.width;
                canvas.height = newCrop.height;
                const ctx = canvas.getContext('2d')!;
                ctx.drawImage(image, newCrop.x, newCrop.y, newCrop.width, newCrop.height, 0, 0, newCrop.width, newCrop.height);

                canvas.toBlob((newBlob) => {
                    if (newBlob) {
                        const file = new File([newBlob!], 'croppedImage.jpeg', { type: 'image/jpeg' });
                        //const url = URL.createObjectURL(newBlob);
                        // resolve(URL.createObjectURL(file));
                        setAddImageForm({ ...addImageForm, selectedValue: file, selectedFile: newBlob, aiImageSelected: imageSrc });
                        // resolve(file);
                        resolve(URL.createObjectURL(file));
                    }
                }, 'image/jpeg');
            };
        });
    };

    const onClickProceed1 = () => {

        if (!addImageForm.selectedValue) {
            setAddImageForm({ ...addImageForm, isValidate: true, errorMessage: 'Please select an image' });
        }

        if (addImageForm?.aiImageSelected) {
            setCropModal(true);
        }
    };

    // called after preview and edit
    const onClickProceed = () => {
        setAddImageForm({ ...addImageForm, isValidate: true });
        if (!addImageForm?.selectedValue) {
            return;
        }

        getEditedBlob();

    };

    const changeTypeOfText = () => {
        setAddImageForm({
            ...addImageForm,
            typeOfText: !addImageForm.typeOfText,
            name: undefined,
            isValidate: false,
            destination_url: undefined,
            tracker_script: undefined,
            responseList: [],
            isDesSelect: '',
            isTitleSelect: '',
        });
    };

    const validateImageSize = async (blob: any) => {
        const image = new Image();
        let imageSrc = '';
        imageSrc = URL.createObjectURL(blob);
        image.src = imageSrc;

        const imageResolution = await new Promise<{ width: number; height: number }>((resolve, reject) => {
            image.onload = () => {
                resolve({ width: image.width, height: image.height });
            };
            image.onerror = (error) => {
                reject(error);
            };
        }).catch((error) => {
            return null;
        });

        setIsImageValidAfterPreview(true);
        if (imageResolution && imageResolution.width === parseInt(upComingWidth) && imageResolution.height === parseInt(upComingHeight)) {
            setAddImageForm({ ...addImageForm, selectedFile: blob, selectedValue: blob });
            setAddImageForm({
                ...addImageForm,
                typeOfText: false,
                name: undefined,
                isDesSelect: '',
                isTitleSelect: '',
                selectedFile: blob,
                selectedValue: blob
            });
            setPreviewModal(false);
            setCropModal(false);
            setIsImageValidAfterPreview(false);
        } else {
            return;
        }
    }

    const getEditedBlob = async () => {
        let node: any = document.getElementById('customImage');
        domtoimage.toBlob(node)
            .then(function (blob: any) {
                validateImageSize(blob);
            }).catch(() => {
                setPreviewModal(false);
                setCropModal(false);
            })
    }

    const customizeHeaderImageCroper = (
        <div className="flex justify-between w-full">
            <div>
                <p className="flex items-center font-[Roboto-Medium] text-black font-bold text-xl">{previewModal ? 'Preview Image' : ' Crop Image'}</p>
            </div>
            <div>
                {previewModal ? (
                    <div className='flex flex-col items-end'>
                        <div className="flex gap-2 items-center">
                            <IoArrowBackCircleOutline size={'1.75rem'} className="cursor-pointer" onClick={() => setPreviewModal(false)} />
                            <PButton onClick={() => onClickProceed()} title="Proceed" className="md:rounded-full  md:h-[auto] text-xs py-px md:px-5 w-32" />
                        </div>
                        {isImageValidAfterPreview && (
                            <div>
                                <div className="common_error">{`Image size has exceeded beyond ${upComingWidth}px by ${upComingHeight}px`}</div>
                                <div className="common_error">(Title and Description should be inside image.)</div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="flex gap-2">
                        <AiOutlineCheckCircle
                            size={'1.75rem'}
                            className="cursor-pointer"
                            onClick={() => {
                                setPreviewModal(true);
                            }}
                        />
                        <AiOutlineCloseCircle size={'1.75rem'} className="cursor-pointer" onClick={() => setCropModal(false)} />
                    </div>
                )}
            </div>
        </div>
    );
    const viewOfImageCroper = (
        <div className="flex justify-center w-full">
            {!previewModal ? (
                <div className='bg-[#4f4e4e] p-5 w-[100%] flex justify-center items-center'>
                    {
                        parseInt(upComingWidth) > parseInt(upComingHeight) ? (
                            <ReactCrop locked={true} crop={crop} onChange={(c: any) => setCrop(c)}>
                                {
                                    upComingHeight && upComingWidth && <img width={upComingWidth} alt='Ai generated' src={addImageForm?.aiImageSelected} />
                                }
                            </ReactCrop>
                        ) : (
                            <ReactCrop locked={true} crop={crop} onChange={(c: any) => setCrop(c)}>
                                {
                                    upComingHeight && upComingWidth && <img height={upComingHeight} alt='Ai generated' src={addImageForm?.aiImageSelected} />
                                }
                            </ReactCrop>
                        )
                    }
                </div>
            ) : (
                <ImageEditTextAndPreview upComingHeight={upComingHeight} upComingWidth={upComingWidth} file={addImageForm?.selectedFile} />
            )}
        </div>
    );

    useEffect(() => {
        getCroppedImage(addImageForm?.aiImageSelected)
            .then((croppedImage) => {
                //console.log("croppedImage", croppedImage)
                // setAddImageForm({ ...addImageForm, selectedValue: croppedImage , selectedFile: croppedImage })
                // setCroppedImageUrl(croppedImage);
            })
            .catch((error) => {
                console.error('Error cropping image:', error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crop]);

    useEffect(() => {
        if (cropModal === false) {
            const i1 = document.getElementById('greaterWidthImage');
            const i2 = document.getElementById('greaterHeightImage');
            i1?.remove();
            i2?.remove();
        }
    }, [cropModal])

    useEffect(()=>{
        if(aiPrompt){
            setAiSearchPrompt(aiPrompt)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[aiPrompt])

    return (
        <div className={`container-ad-text ${!addImageForm.typeOfText || (addImageForm.responseList && addImageForm.responseList.length > 0) ? 'expanded' : ''}`}>
            {addImageForm.typeOfText ? (
                <div>
                    <div>
                        <div className="flex justify-end mb-4 mr-6">
                            <label onClick={changeTypeOfText} className="ml-1 text-sm cursor-pointer font-bold">
                                Manually generate ad images
                            </label>
                        </div>
                        <div>

                            {
                                addImageForm.typeOfText ?
                                    (
                                        <>
                                            <PNormalTextArea
                                                className="md:rounded md:py-2 text-xs"
                                                onChange={(e: any) => onChangeFields({'name': 'prompt', 'value': e.value})}
                                                rows={5}
                                                value={addImageForm?.prompt}
                                                name="name"
                                                title="Prompt"
                                                placeholder="e.g. Photograph a cozy corner in a local coffee shop. Use natural light to highlight the textures and colors of the setting. There is a man sitting by the table, drinking a cup of coffee and typing on a laptop."
                                            />
                                            {addImageForm.isValidate && !addImageForm?.prompt && <span className="common_error">Please Enter Prompt</span>}
                                        </>

                                    )
                                    :
                                    (
                                        <>
                                            <PNormalInput
                                                isRequired
                                                className="md:rounded md:py-2 text-xs"
                                                onChange={onChangeFields}
                                                value={addImageForm.name}
                                                name="name"
                                                title={'Title'}
                                                placeholder={'Enter Title'}
                                            />
                                            {addImageForm.isValidate && !addImageForm.name && <span className="common_error">Please Enter Prompt</span>}
                                        </>
                                    )
                            }

                        </div>
                        {/* <div className="mt-4 mb-4">
                            <PNormalInput className="md:rounded md:py-2 text-xs" onChange={onChangeFields} value={addImageForm.isTitleSelect} name="isTitleSelect" title={'Title'} placeholder={'Enter Title'} />
                        </div>
                        <div className="mt-4 mb-4">
                            <PNormalInput className="md:rounded md:py-2 text-xs" onChange={onChangeFields} value={addImageForm.isDesSelect} name="isDesSelect" title={'Description'} placeholder={'Enter Description'} />
                        </div> */}
                        {(!addImageForm.responseList || addImageForm.responseList.length === 0) && <PButton loading={loader} onClick={onClickSubmitAi} title="Submit" className="md:rounded-full mt-5 md:h-[auto] text-xs py-px md:px-5 w-32" />}
                    </div>

                    {addImageForm.responseList && addImageForm.responseList.length > 0 && (
                        <div>
                            <div className="mt-4">
                                <Radio.Group
                                    defaultValue=""
                                    // value={addTextForm.selectedValue}
                                    onChange={(e) => onChangeImage(e)}
                                >
                                    {addImageForm.responseList?.map((item, index) => (
                                        <Radio.Button key={index} className="mb-4 md:h-48 w-full leading-5 border rounded-md" value={item.url}>
                                            <div className="flex">
                                                <div className="mt-1.5 mr-2">{addImageForm?.rawImg === item.url ? <IoRadioButtonOn /> : <IoRadioButtonOffSharp />}</div>
                                                <div>
                                                    <img className="object-contain cursor-pointer max-w-full max-h-48" src={item.url} alt="Selected Preview" />
                                                </div>
                                            </div>
                                        </Radio.Button>
                                    ))}
                                </Radio.Group>
                                {addImageForm.responseList.length < 6 && (
                                    <p className=" md:text-[#056433] flex items-center justify-around ">
                                        <PButton loading={loader} onClick={onClickSubmitAi} title="Show more" className="custom-hover-color md:py-0.5 md:rounded-full  md:h-[auto] text-xs md:px-5 md:bg-[unset] md:text-[unset] md:border-[0]" />
                                    </p>
                                )}
                                {/* {addImageForm.isValidate && !addImageForm?.selectedValue && <span className="common_error">Please select an image</span>} */}
                            </div>
                            {/* <PButton onClick={onClickProceed} title="Proceed" className="md:rounded-full mt-5 md:h-[auto] text-xs py-px md:px-5 w-32" /> */}
                            {addImageForm.isValidate && !addImageForm?.selectedValue && <span className="common_error">{addImageForm.errorMessage}</span>}

                            <PButton onClick={onClickProceed1} title="Proceed" className="md:rounded-full mt-5 md:h-[auto] text-xs py-px md:px-5 w-32" />
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <div className="flex justify-end mb-4 mr-6">
                        <label onClick={changeTypeOfText} className="ml-1 text-sm cursor-pointer font-bold">
                            Use AI to generate ad images
                        </label>
                    </div>
                    <div className="flex w-full">
                        <div className="w-6/12">
                            <div>
                                <PNormalInput isRequired className="md:rounded md:py-2 text-xs" onChange={onChangeFields} value={addImageForm.name} name="name" title="Name" placeholder="Enter Name" />
                                {addImageForm.isValidate && !addImageForm.name && <span className="common_error">Please Enter Name</span>}
                            </div>
                            <div className="mt-4">
                                <PNormalInput
                                    isRequired
                                    className="md:rounded md:py-2 text-xs"
                                    onChange={onChangeFields}
                                    value={addImageForm.destination_url}
                                    name="destination_url"
                                    title="Destination URL"
                                    detail={' (Url should be start with http:// or https://)'}
                                    placeholder="Enter Destination URL"
                                />
                                {addImageForm.isValidate && (!addImageForm.destination_url || !addImageForm.isValid) && <span className="common_error">Please Enter valid Destination URL</span>}
                            </div>
                            <PNormalTextArea className="md:rounded md:py-2 text-xs" onChange={onChangeFields} rows={3} value={addImageForm.tracker_script} name="tracker_script" title="Tracker Script" placeholder="Enter Tracker Script" />
                        </div>
                        <div className="w-5/12 ml-4 md:bg-[#f8f8f8] flex items-center justify-center">
                            <div className="flex items-center flex-col">
                                <PImageUploadForm onImageUpload={handleImageUpload} selectedImgOfAi={addImageForm?.selectedValue} />
                                {addImageForm.isValidate && !addImageForm?.selectedFile && !addImageForm?.selectedValue && <span className="common_error">Please upload image</span>}
                                {addImageForm.isValidate && addImageForm.errorMessage && <span className="common_error">{addImageForm.errorMessage}</span>}
                            </div>
                        </div>
                    </div>
                    {addImageForm.isValidate && !addImageForm?.selectedValue && <span className="common_error">{addImageForm.errorMessage}</span>}

                    <PButton onClick={onClickSubmit} title="Submit" className="md:rounded-full mt-5 md:h-[auto] text-xs py-px md:px-5 w-32" />
                </div>
            )}
            {/* {cropModal && <PModal width={parseInt(upComingHeight) < parseInt(upComingWidth) ? parseInt(upComingWidth) + 50 : undefined} ViewOfModal={viewOfImageCroper} isModalOpen={cropModal} OnClickOfCancel={() => setCropModal(false)} customizeHeader={customizeHeaderImageCroper} />} */}
            {cropModal && <PModal width={'100%'} ViewOfModal={viewOfImageCroper} isModalOpen={cropModal} OnClickOfCancel={() => setCropModal(false)} customizeHeader={customizeHeaderImageCroper} />}
        </div>
    );
};

export default PAddImagesModalView;
