import { RoundButton } from "../../../../common/BButton";
import PModal from "../../../../common/Modal";
import { ManageActionState } from "../redux/type";

type Props = {
     open: boolean;
     setActionState: (value: ManageActionState) => void;
     closeModal: any;
     msg: string;
     title: string;
     onOkay: any;
     loading: boolean;
}
const ConfirmModal: React.FC<Props> = ({ open, setActionState, closeModal, msg, title, onOkay, loading }) => {
     const modalView: any = (
          <div>
               <div className="font-bold text-[22px] border-b-2">{title}</div>
               <div className="text-center mb-2 mt-4">{msg}</div>
               <div className="flex gap-3 justify-center pt-3 pb-3 items-center w-full ">
                    <div className="border rounded-3xl border-green-800">
                         <RoundButton light={true} title="Cancel" className={'w-[120px] text-[14px]'} onClick={() => setActionState({ assignModal: false, updateModal: false, deleteModal: false, type: '' })} />
                    </div>
                    <div>
                         <RoundButton
                              title="Ok"
                              className="w-[120px]"
                              onClick={() => onOkay()}
                              loading={loading}
                         />
                    </div>
               </div>
          </div>
     )
     return (
          <>
               <PModal
                    isModalOpen={open}
                    OnClickOfCancel={closeModal}
                    width="450px"
                    className="noscrollbar"
                    ViewOfModal={modalView}
               />
          </>
     )
}

export default ConfirmModal;
