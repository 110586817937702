import { call, delay, put, takeLatest } from 'redux-saga/effects';
import loginTypes, { loginReqAction } from './types';
import LoginActions from './actions';
import { setJwtToken } from '../../../../api/apiServices';

const loginReq = function* loginReq({ payload }: loginReqAction) {
    try {
        const { jwtToken } = payload;
        if (!jwtToken) return;
        yield delay(800);
        yield call(setJwtToken, jwtToken);
        yield put(LoginActions.loginReqSuccess());

        yield put(LoginActions.setUserData(payload));
        yield call(window.open, '/', '_self');
    } catch (err) {
        yield put(LoginActions.loginReqFailed());
    }
};

export default function* sagas() {
    yield takeLatest(loginTypes.LOGIN_REQ, loginReq);
}
