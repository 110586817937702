import { CheckboxValueType } from 'antd/es/checkbox/Group';
import React, { useState, useEffect } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import PButton from '../../../../common/Button';
import PModal from '../../../../common/Modal';
import PImageSizeModalView from '../modal/ImageSizeModalView';

type Props = {
    fetchMediaSizes: (data: any) => void;
    isValidate?: boolean;
    selectedMediaSize: string[];
    setUnsaveChanges:any;
};

interface sizes {
    isModalOpenForMediaSize?: boolean;
    isSubmitCall?: boolean;
    checkedSizes?: any;
    showCheckedBoxes?: any;
}

const PMediaSize: React.FC<Props> = ({ fetchMediaSizes, isValidate,  selectedMediaSize,setUnsaveChanges }) => {
    const [mediaSizes, setMediaSizes] = useState<sizes>({
        isModalOpenForMediaSize: false,
        isSubmitCall: false,
        checkedSizes: [],
        showCheckedBoxes: selectedMediaSize ? selectedMediaSize : [],
    });

    useEffect(() => {
        setMediaSizes({
            ...mediaSizes,
            showCheckedBoxes: selectedMediaSize ? selectedMediaSize : [],
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMediaSize]);

    const openModal = () => {
        setMediaSizes({ ...mediaSizes, isSubmitCall: false, isModalOpenForMediaSize: true });
    };

    const OnClickOfCancelMediaSize = () => {
        setMediaSizes({ ...mediaSizes, isSubmitCall: false, isModalOpenForMediaSize: false });
    };

    const getSelectedSizes = (data: CheckboxValueType[]) => {
        setMediaSizes({ ...mediaSizes, checkedSizes: data });
    };

    const onSubmitOfselectedSizes = () => {
        setMediaSizes({ ...mediaSizes, isSubmitCall: true, isModalOpenForMediaSize: false, showCheckedBoxes: mediaSizes.checkedSizes });
        fetchMediaSizes(mediaSizes.checkedSizes);
        setUnsaveChanges(true)
    };

    const removeItem = (item: any) => {
        const updatedArray = mediaSizes.showCheckedBoxes.filter((data: string) => data !== item);
        setMediaSizes({ ...mediaSizes, showCheckedBoxes: updatedArray });
        fetchMediaSizes(updatedArray);
        setUnsaveChanges(true)
    };

    const ViewOfModalMediaSize = <PImageSizeModalView getSelectedSizes={getSelectedSizes} showCheckedBoxes={mediaSizes.showCheckedBoxes} />;

    const customizeHeader = (
        <div className="flex justify-between">
            <div>
                <p className="flex items-center font-[Roboto-Medium] text-black font-bold text-xl">Select Image Sizes</p>
            </div>
            <div className="flex">
                <PButton title="Submit" onClick={onSubmitOfselectedSizes} className="md:rounded-full md:h-[auto] text-xs py-px md:px-6 mr-4" />
                <AiOutlineCloseCircle size={'1.75rem'} className="cursor-pointer" onClick={OnClickOfCancelMediaSize} />
            </div>
        </div>
    );

    return (
        <div>
            <div className="flex justify-between items-end">
                <p className="flex items-center font-[Roboto-Medium] text-black font-medium text-sm mt-9">Expected Media Sizes</p>
                {/* <PButton title="Add Images" onClick={openAddImageModal} className="md:rounded-full md:h-[auto] text-xs py-px md:px-6" /> */}
            </div>

            <div className=" p-4 mt-3 bg-white rounded-md shadow-md">
                <label className="ml-1 text-xs">
                    Sizes
                    <span className="text-red-500">{'*'}</span>
                </label>
                <div className="flex flex-wrap">
                    {mediaSizes.showCheckedBoxes?.map((item: string, index: number) => (
                        <p key={index} className="flex items-center justify-around border md:border-[#e5e7eb] h-8 md:w-[auto] font-[Roboto-Medium] text-black font-medium text-sm mt-2 ml-1 px-2.5">
                            {item}
                            <AiOutlineCloseCircle size={'1.25rem'} onClick={() => removeItem(item)} className="cursor-pointer ml-2.5 " />
                        </p>
                    ))}
                </div>
                <PButton title="Add Sizes" onClick={openModal} light={true} className="md:rounded-full md:bg-[#9c9c9c] md:text-[#fff] mt-4 md:h-[auto] text-xs py-px md:px-5" />
                {isValidate && !mediaSizes.showCheckedBoxes?.length && <span className="common_error">Please add at least one size</span>}
            </div>

            {mediaSizes.isModalOpenForMediaSize && <PModal ViewOfModal={ViewOfModalMediaSize} isModalOpen={mediaSizes.isModalOpenForMediaSize} OnClickOfCancel={OnClickOfCancelMediaSize} customizeHeader={customizeHeader} />}
        </div>
    );
};

export default PMediaSize;
