import React from 'react';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, LineChart, Line, CartesianGrid } from 'recharts';
import { Empty, Spin } from 'antd';
import { TooltipProps, } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { commaSeperator, numberFormatter } from '../../../../../utils/Validation';
import moment from 'moment-timezone';

type Props = {
     data: any;
     loading?: boolean;
     suffix?: string;
     prefix?: string;
     title1?: string;
     title2?: string;
     name?: string;
     showfirstLine?: boolean;
     showSecondLine?: boolean;
     isHourly?: boolean;
};


const LineGraph: React.FC<Props> = ({ data, loading, suffix, prefix, title1, title2, name, showfirstLine = true, showSecondLine = true, isHourly }) => {
     const formatterLeft = (value: string | number) => `${prefix ? prefix : " "}${String(numberFormatter(Number(value)))}${suffix ? suffix : ""}`;
     const xAxisFormatter = (value: string) => {
          if (isHourly) {
               return value;
          }
          return `${moment(value).format("MMM DD")}`;
     };

     return (
          <div className="">
               {!loading && (
                    <>
                         {
                              data?.length !== 0 ?
                                   <ResponsiveContainer height={300} width="100%">
                                        <LineChart
                                             data={data?.map((d: any) => {
                                                  return { ...d, revenue: parseFloat(d.revenue), request: parseInt(d.request) }
                                             })}
                                             margin={{
                                                  top: 30,
                                                  right: 15,
                                                  bottom: 20,
                                                  left: 0,
                                             }}
                                        >

                                             <XAxis axisLine={true} dataKey="date" stroke="#fff" tickMargin={20} tickFormatter={xAxisFormatter} tick={{ fill: '#615E83', fontSize: '0.7rem', fontFamily: 'Roboto' }} />
                                             <YAxis axisLine={false} tickLine={false} tickFormatter={formatterLeft} tick={{ fill: '#615E83', fontSize: '0.8rem', fontFamily: 'Roboto' }} />
                                             <Tooltip content={<CustomTooltip labelClassName={prefix} />} />
                                             <CartesianGrid vertical={false} />
                                             {showfirstLine && <Line type="monotone" dot={false} dataKey={`${title1}`} stroke="#056433" strokeWidth={3} />}
                                             {showSecondLine && <Line type="monotone" dot={false} dataKey={`${title2}`} stroke="#00a7a7" strokeWidth={3} />}
                                        </LineChart>
                                   </ResponsiveContainer>
                                   :
                                   <div className="flex justify-center items-center h-[200px]">
                                        <Empty className='mt-5' description={'No data found'} />
                                   </div>
                         }

                    </>
               )}

               {loading && (
                    <div className="flex align-center justify-center h-[200px]">
                         <Spin className="m-auto" />
                    </div>
               )}
          </div>
     );
};

export default React.memo(LineGraph);

const CustomTooltip = ({ active, payload, label, labelClassName, wrapperClassName }: TooltipProps<ValueType, NameType>) => {
     if (active) {
          return (
               <div className="shadow rounded" style={{ background: 'rgba(30, 30, 30, 0.8)' }}>
                    <div className="px-4 py-1" style={{ background: 'rgba(0, 0, 0, 0.7)', borderBottom: '1px solid #333' }}>
                         <p className="label text-xs roboto-medium text-white">{label}</p>
                    </div>
                    <div className="desc px-4 py-1 text-center">
                         {payload?.map((p: any, index) => (
                              <div key={`${index}_${p.name}`} className={'text-xs text-white flex justify-between items-center'}>
                                   <p>{p.name}&nbsp;</p>
                                   <p>{labelClassName ? `${labelClassName}${commaSeperator(p.value)}` : `${commaSeperator(p.value)}%`}</p>
                              </div>
                         ))}
                    </div>
               </div>
          );
     }

     return null;
};
