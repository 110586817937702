/** @format */

import React, { useState } from 'react';
import { BsFillKeyFill } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { MdKeyOff } from 'react-icons/md';
import PCard from '../../common/Card';
import PNormalInput from '../../common/NormalInput';
import PButton from '../../common/Button';
import { isEmail } from '../../../utils/Validation';
import logo from '../../../assets/images/publir.svg';
import Apis from '../../../api';
import { LoginResponse, UserState } from './redux/types';
import { useDispatch } from 'react-redux';
import MessageActions from '../../message/redux/actions';
import LoginActions from './redux/actions';
import { useNavigate } from 'react-router-dom';
import { HEADERMENU_PATH } from '../../../routes/RoutesURL';

interface LoginState {
    email: string;
    password: string;
    passwordShow: boolean;
}

const Login: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [form, setForm] = useState<LoginState>({
        email: '',
        password: '',
        passwordShow: false,
    });
    const [isValidate, setValidate] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = (e: { name: string; value: string | number | undefined }) => {
        const { name, value } = e;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleShowPassword = (value: boolean) => {
        setForm({
            ...form,
            passwordShow: value,
        });
    };

    const handleLogin = () => {
        setValidate(true);
        if (!isEmail(form.email) || !form.password || form.password.trim() === '') {
            return;
        }
        setValidate(false);
        setLoading(true);
        let payload: any = { email: form.email.trim(), password: form.password, login_type: "new" };
        Apis.login(payload)
            .then(({ data }) => {
                const response: LoginResponse = data?.data;
                const { jwt_token, user,reset_link } = response;

                if (user?.new_user === true || user?.new_user === "true"){
                    //navigate(`${reset_link}`,{replace:true});
                    window.open(reset_link,"_self");
                    return;
                }

                    const userData: UserState = {
                        jwtToken: jwt_token?.token,
                        user,
                    };
                dispatch(LoginActions.loginReq(userData));
            })
            .catch((err) => {
                dispatch(MessageActions.showMessage({ text: String(err?.response?.data?.data?.message), error: true }));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="w-full h-screen bg-gray-100">
            <div className="flex justify-center items-center h-full flex-col">
                <img src={logo} alt="publirLogo" className="pb-5 w-36" />
                <PCard className="px-6 py-4">
                    <div className="flex justify-center items-center pb-6">
                        <p className="font-bold montserrat-medium text-2xl">Login</p>
                    </div>
                    <div className="py-2">
                        <PNormalInput
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                    handleLogin();
                                }
                            }}
                            title="Username or Email"
                            value={form.email}
                            name="email"
                            onChange={handleChange}
                            isRequired={true}
                            suffix={<HiOutlineMail />}
                        />
                        {isValidate && !isEmail(form.email) && <span className="common_error">Please enter valid email</span>}
                    </div>
                    <div className="py-2">
                        <PNormalInput
                            title="Password"
                            type={form.passwordShow ? 'text' : 'password'}
                            value={form.password}
                            name="password"
                            onChange={handleChange}
                            isRequired={true}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                    handleLogin();
                                }
                            }}
                            suffix={form.passwordShow ? <MdKeyOff className="cursor-pointer " onClick={() => handleShowPassword(false)} /> : <BsFillKeyFill className="cursor-pointer" onClick={() => handleShowPassword(true)} />}
                        />
                        {isValidate && (!form.password || form.password.trim() === '') && <span className="common_error">Please enter password</span>}
                    </div>

                    <div className="flex justify-end items-center my-1">
                        <div className="font-bold underline text-[14px] cursor-pointer" onClick={() => navigate(HEADERMENU_PATH.forgotPassword)}>Forgot Password</div>
                    </div>

                    <div className="flex items-center mt-6 w-full justify-center">
                        <PButton title="Login" onClick={handleLogin} loading={loading} />
                    </div>

                    {/* <div className="flex justify-center items-center my-1">
                        <div className="font-bold underline text-[14px] cursor-pointer" onClick={() => navigate(HEADERMENU_PATH.signup)}>Sign Up</div>
                    </div> */}
                </PCard>
            </div>
        </div>
    );
};

export default Login;
