import Apis from "../../../../../api";
import { CommonAction } from "../../../../message/redux/types";
import { call, put, takeLatest } from 'redux-saga/effects';
import UserManagementActions from "./actions";
import userManagementTypes from "./type";
const fetchUserManagementUserListData = function* fetchUserManagementUserListData(action: CommonAction) {
     try {
          const { pageNo, pageSize, activeTab } = action.payload;
          const { data } = yield call(Apis.userManagementUserListApi, pageSize, pageNo, activeTab);
          yield put(UserManagementActions.setUserManagementUserList({ data: data?.data?.data, count: data?.data?.total }));
     } catch (error) {
          yield put(UserManagementActions.setUserManagementUserList({}));
     }
}

const fetchAllAssignCampaignList = function* fetchAllAssignCampaignList(action: CommonAction) {
     try {
          const search = action.payload;
          const { data } = yield call(Apis.assignCampList, search);
          yield put(UserManagementActions.setAllAssignCampList(data?.data?.data));
     } catch (error) {
          yield put(UserManagementActions.setAllAssignCampList([]));
     }
}

export default function* sagas() {
     yield takeLatest(userManagementTypes.FETCH_USER_MANAG_USER_LIST, fetchUserManagementUserListData);
     yield takeLatest(userManagementTypes.FETCH_ASSIGN_CAMP_LIST, fetchAllAssignCampaignList);
}
