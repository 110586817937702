import React, { useEffect, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/RootReducer';
import DisplayStructured from '../../../../common/DisplayStructured';
import PNormalInput from '../../../../common/NormalInput';
import { LocationItem } from '../components/Loaction';
import LocationItems from './LocationItems';
import LocationAction from './Redux/action';
import { MdClose } from "react-icons/md";

type Props = {
    in_selectedLocation?: LocationItem[];
    ex_selectedLocation?: LocationItem[];
    handleAddItem: (item: string) => void;
    isLocationValidate: boolean;
    locationData: { exclude: LocationItem[]; include: LocationItem[] };
    setLocationData: any;
    isModalOpen: any;
};

interface searchItem {
    searchLocation: string;
    searchLength: number;
}

const PLocationModalView: React.FC<Props> = ({ in_selectedLocation, ex_selectedLocation, handleAddItem, isLocationValidate, locationData, setLocationData, isModalOpen }) => {
    const dispatch = useDispatch();
    const location = useSelector((state: RootState) => state.location);

    const [searchValue, setSearchValue] = useState<searchItem>({
        searchLocation: '',
        searchLength: 0,
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            // Call the API with the current input value after 300ms
            if (searchValue.searchLocation) {
                dispatch(LocationAction.getLocationAction({ country: searchValue.searchLocation, prevData: location.modifyLocation }));
            } else {
                let defaultData = { ...location.modifyLocation }
                let defaultKeys = Object.keys(defaultData);

                for (let i = 0; i < defaultKeys.length; i++) {
                    const key = defaultKeys[i];
                    defaultData[key].display = true;
                }
                dispatch(LocationAction.modifyLocation(defaultData));
            }
        }, 900);

        return () => clearTimeout(timer); // Clear the timer on component unmount or when inputValue changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue.searchLocation, isModalOpen]);
    // const checkLocation = locationData.include.length || locationData.exclude.length;

    const removeEnteredText = () => {
        setSearchValue({
            ...searchValue,
            searchLocation: '',
        });
    }

    return (
        <div>
            <div className="w-full border border-current mt-8" />
            <div className="flex md:h-[auto]">
                <div className="md:flex-[50%]">
                    <div className="mr-4">
                        <PNormalInput
                            prefix={<CiSearch />}
                            value={searchValue.searchLocation}
                            name="searchLocation"
                            className="md:py-1 md:px-2 mt-6"
                            onChange={({ value }) => {
                                setSearchValue({ ...searchValue, searchLocation: String(value) || '' });
                            }}
                            placeholder=""
                            suffix={
                                <MdClose className='cursor-pointer' onClick={removeEnteredText} />
                            }
                        />
                    </div>
                    <div className="flex justify-between">
                        <p className="cursor-pointer font-[Roboto-Medium] font-medium text-xs mt-6">{location.location.length} Results</p>
                        {/* <p className="cursor-pointer md:text-[#056433] font-[Roboto-Medium] font-medium text-xs mt-6 mr-4">Include All</p> */}
                    </div>

                    <DisplayStructured dataSource={location.modifyLocation} locationData={locationData} setLocationData={setLocationData} searchValue={searchValue} />
                </div>
                <div className="border-l md:border-[black]" />
                <div className="md:flex-[50%]">
                    <div>
                        <p className="cursor-pointer md:text-[#056433] flex justify-end font-[Roboto-Medium] font-medium text-xs mt-6 "
                            onClick={() => {
                                setLocationData({ exclude: [], include: [] })
                                dispatch(LocationAction.setSelectedLocation({ exclude: {}, include: {} }))
                            }}>
                            Clear All
                        </p>
                    </div>
                    {/* {isLocationValidate && !checkLocation ? <span className="common_error ml-2">Please select at least one location</span> : null} */}

                    <LocationItems locationIncludeData={locationData.include} locationData={locationData} setLocationData={setLocationData} color="#056433" title="Include" type="include" />
                    <LocationItems locationIncludeData={locationData.exclude} locationData={locationData} setLocationData={setLocationData} color="#f43838" title="Exclude" type="exclude" />
                </div>
            </div>
        </div>
    );
};

export default PLocationModalView;
