import React, { useEffect } from 'react'
import { GoDotFill } from "react-icons/go";
import LineGraph from '../graphs&charts/LineGraph';
import { useDispatch, useSelector } from 'react-redux';
import OverAllReportAction from '../redux/actions';
import { RootState } from '../../../../../store/RootReducer';

type Props = {
     startDate: any;
     endDate: any;
     campId: any;
     showExpected: boolean;
}
const BudgetSpentGraph: React.FC<Props> = ({ startDate, endDate, campId, showExpected }) => {
     const dispatch = useDispatch();
     const budgetSpentGraphData = useSelector((state: RootState) => state.report.budgetSpentReportData);
     const budgetSpentGraphLoader = useSelector((state: RootState) => state.report.budgetSpentReportLoader);
     const isHourly = useSelector((state: RootState) => state.report.isHourBudgetInterval);

     useEffect(() => {
          if (startDate && endDate && campId) {
               dispatch(
                    OverAllReportAction.fetchBudgetSpentReportsData({
                         start_dt: startDate,
                         end_dt: endDate
                    }, campId)
               )
          }
     }, [startDate, endDate, campId, dispatch])
     return (
          <div className="rounded-lg drop-shadow-md bg-white px-3  h-full">
               <div className='pt-5 pb-2 px-2 border-b-[1px] flex justify-between'>
                    <div className=''>
                         <div className='font-[Roboto] font-[700] text-[18px]'>Budget Spent</div>
                         {/* <p className="font-[Roboto] font-[600] text-[24px]"><p>{totalBudget ?`$${commaSeperator(parseFloat(totalBudget).toFixed(2))}`:"$0.00"}
                         <span className='text-[12px]'>/day</span>
                         </p></p> */}
                    </div>
                    <div className='flex gap-5'>
                         {showExpected && <div className='flex items-center'><GoDotFill color='#056433' className='mr-1' /> Expected</div>}
                         <div className='flex items-center'><GoDotFill color='#00a7a7' className='mr-1' /> Actual</div>
                    </div>
               </div>
               <LineGraph data={budgetSpentGraphData} prefix='$' title1='expected' title2='actual' loading={budgetSpentGraphLoader} name={'budget'} showfirstLine={showExpected} showSecondLine={true} isHourly={isHourly}/>
          </div>
     )
}

export default BudgetSpentGraph

