const locationTypes = {
    FETCH_LOCATION: 'FETCH_LOCATION',
    SET_LOCATION: 'SET_LOCATION',
    MODIFY_LOCATION: 'MODIFY_LOCATION',
    SELECTED_LOCATION: 'SELECTED_LOCATION',
    EDIT_LOCATION: 'EDIT_LOCATION',
    FETCH_SINGLE_COUNTRY_LOCATION: 'FETCH_SINGLE_COUNTRY_LOCATION',
    SET_SINGLE_COUNTRY_LOCATION: 'SET_SINGLE_COUNTRY_LOCATION',
    SET_ALLDEFAULT_COUNTRY_LOCATION: 'SET_ALLDEFAULT_COUNTRY_LOCATION',
    SET_ALL_CITIES_LOCATION_LOADING: 'SET_ALL_CITIES_LOCATION_LOADING',
    SET_ALL_STATES_LOCATION_LOADING: 'SET_ALL_STATES_LOCATION_LOADING',

};
export default locationTypes;

// export const locationDefaultData = {
//     "United States": { data: {}, type: '', display: true },
//     "Australia": { data: {}, type: '', display: true },
//     "Canada": { data: {}, type: '', display: true },
//     "France": { data: {}, type: '', display: true },
//     "Germany": { data: {}, type: '', display: true },
//     "India": { data: {}, type: '', display: true },
//     "Japan": { data: {}, type: '', display: true },
//     "Netherlands": { data: {}, type: '', display: true },
//     "Norway": { data: {}, type: '', display: true },
//     "Singapore": { data: {}, type: '', display: true },
//     "South Korea": { data: {}, type: '', display: true },

// }

