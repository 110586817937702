import { Input } from 'antd';
import React from 'react';
import { commaSeperator } from '../../utils/Validation';
const { TextArea } = Input;

type Props = {
    title?: string | any;
    placeholder?: string;
    value?: string;
    type?: string;
    name: string;
    disabled?: boolean;
    onChange: (data: { name: string; value: string | number | undefined }) => void;
    isRequired?: boolean;
    error?: boolean;
    bordered?: boolean;
    className?: string;
    style?: React.CSSProperties;
    rows?: number;
};

const PNormalTextArea: React.FC<Props> = ({ title, value, type, name, disabled, onChange, isRequired, error, bordered, placeholder, className, rows }) => {
    const onFormFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>) => {
        const newValue = String(event.target.value);

        onChange({
            name,
            value: newValue,
        });
    };
    const displayedValue = type === 'number' ? commaSeperator(String(value || '')) : value ? String(value) : '';
    return (
        <div className="common_normalInput mt-3">
            {title && (
                <label className={`ml-1`}>
                    {title}
                    <span className="text-red-500">{isRequired ? '*' : ''}</span>
                </label>
            )}
            <TextArea
                className={`appearance-none rounded-xl w-full py-1.5 px-3 text-gray-700 leading-tight mt-1 ${error ? 'border-red-500' : ''} ${className || ''}`}
                placeholder={isRequired ? (placeholder ? `${placeholder}*` : '') : placeholder}
                value={displayedValue}
                disabled={disabled}
                onChange={onFormFieldChange}
                bordered={bordered}
                rows={rows}
            />
        </div>
    );
};

export default PNormalTextArea;
