import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/RootReducer';

type Props = {
    checkedListOfDevice: any;
    checkedListOfBrowser: any;
    getSelectedDevice: (data: CheckboxValueType[]) => void;
    getSelectedBrowser: (data: CheckboxValueType[]) => void;
    isValidate?: boolean;
    setUnsaveChanges:any;
};

const PTargetBrowserAndDevice: React.FC<Props> = ({ getSelectedDevice, checkedListOfDevice, getSelectedBrowser, checkedListOfBrowser, isValidate ,setUnsaveChanges}) => {
    const constants = useSelector((state: RootState) => state.campaign.constants);

    const onChangeDevice = (checkedValues: any, checked: any) => {
        if (checkedValues === 'All') {
            if (checked) {
                getSelectedDevice(constants.devices);
            } else {
                getSelectedDevice([]);
            }
        } else {
            if (checked) {
                getSelectedDevice([...(checkedListOfDevice || []), checkedValues]);
            } else {
                const array = [...checkedListOfDevice];
                const arrValue = array.indexOf(checkedValues);
                array.splice(arrValue, 1);
                getSelectedDevice(array);
            }
        }
        setUnsaveChanges(true);
    };

    const onChangeBrowser = (checkedValues: any, checked: any) => {
        if (checkedValues === 'All') {
            if (checked) {
                getSelectedBrowser(constants.browsers);
            } else {
                getSelectedBrowser([]);
            }
        } else {
            if (checked) {
                getSelectedBrowser([...(checkedListOfBrowser || []), checkedValues]);
            } else {
                const array = [...checkedListOfBrowser];
                const arrValue = array.indexOf(checkedValues);
                array.splice(arrValue, 1);
                getSelectedBrowser(array);
            }
        }
        setUnsaveChanges(true);
    };

    return (
        <div>
            <p className="flex items-center font-[Roboto-Medium] text-black font-medium text-sm mt-9">Target Browsers & Devices</p>
            <div className=" p-4 mt-3 bg-white rounded-md shadow-md">
                <div>
                    <label className="ml-1 text-xs">
                        Target Devices
                        <span className="text-red-500">{'*'}</span>
                    </label>
                    {constants.devices && constants.devices.length > 0 && (
                        <div className="mt-2 mb-4">
                            {['All'].concat(constants.devices.sort((a, b) => a.localeCompare(b))).map((item: string, index: number) => (
                                <Checkbox
                                    indeterminate={item === 'All' && checkedListOfDevice?.length && checkedListOfDevice?.length !== constants.devices?.length}
                                    key={index}
                                    onChange={(e: any) => onChangeDevice(e.target.value, e.target.checked)}
                                    value={item}
                                    checked={item === 'All' ? checkedListOfDevice?.length === constants.devices?.length : ![-1, undefined].includes(checkedListOfDevice?.indexOf(item))}
                                >
                                    {item}
                                </Checkbox>
                            ))}
                        </div>
                    )}
                    {isValidate && !checkedListOfDevice?.length && <span className="common_error">Please select Target Devices</span>}
                </div>

                <div>
                    <label className="ml-1 text-xs">
                        Target Browser
                        <span className="text-red-500">{'*'}</span>
                    </label>
                    {constants.browsers && constants.browsers.length > 0 && (
                        <div className="mt-2 mb-4">
                            {['All'].concat(constants.browsers.sort((a, b) => a.localeCompare(b))).map((item: string, index: number) => (
                                <Checkbox
                                    indeterminate={item === 'All' && checkedListOfBrowser?.length && checkedListOfBrowser?.length !== constants.browsers?.length}
                                    key={index}
                                    value={item}
                                    checked={item === 'All' ? checkedListOfBrowser?.length === constants.browsers?.length : ![-1, undefined].includes(checkedListOfBrowser?.indexOf(item))}
                                    onChange={(e: any) => onChangeBrowser(e.target.value, e.target.checked)}
                                >
                                    {item}
                                </Checkbox>
                            ))}
                        </div>
                    )}
                    {isValidate && !checkedListOfBrowser?.length && <span className="common_error">Please select Target Browser</span>}
                </div>
            </div>
        </div>
    );
};
export default PTargetBrowserAndDevice;
