import React from 'react'
type Props = {
     tabs: string[];
     activeTab: string;
     onChangeTab: any;
}
const CustomTabSwitch: React.FC<Props> = ({ tabs, activeTab, onChangeTab }) => {
     return (
          <div contentEditable={false} className='flex bg-[#F8F8FF] p-2 rounded-2xl'>
               {
                    tabs.map((item,key) => (
                         <div key={key} contentEditable={false} className={activeTab === item ? ' py-2 px-3 text-[white] bg-[#1E1B39] rounded-2xl cursor-pointer' : ' py-2 px-3 text-[#9291A5] cursor-pointer'}
                              onClick={()=> onChangeTab(item)}
                         >
                              {item}
                         </div>
                    ))
               }
          </div>
     )
}

export default CustomTabSwitch